<template>
  <!-- Le template -->
  <v-container>

    <v-row>
      <v-col>
        <v-tabs v-model="activTab" vertical show-arrows>
          <v-tab v-for="tab in tabs" :key="tab.id">{{ tab.name }}</v-tab>
        
        
          <v-tab-item v-for="tab in tabs" :key="tab.id" class="pa-8 custombgcolor">
              
           <customPcgDatatable v-if="selectedClient"
              :excel_file_name="`pcg_modifie_class`+tab.id+`.xls`"
              :selectedClient="selectedClient"
              :possibleHeaders="pcg_possibleHeaders"
              :api_endpoint_url="pcg_apiEndPointUrl(tab.id)"
              :default_column_names="tab.pcg_default_column_names"
              :default_column_order="pcg_default_column_order"
              :create_route_name="pcg_create_route_name"
              :show_route_name="pcg_show_route_name"
              :update_route_name="pcg_update_route_name"
              :api_delete_endpoint_url="pcg_api_delete_endpoint_url"
            ></customPcgDatatable>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.custombgcolor {background-color: #1E4461;}
</style>

<script>

import {EventBus} from "../EventBus";

// Le script
export default {
  name: "pcgview",

  data() {
    return {
      selectedClient: null,  
      activTab: null,
      tabs: [
        { id: 1 , name: "classe 1" , pcg_default_column_names: ["compte", "intitule"] },
        { id: 2 , name: "classe 2" , pcg_default_column_names: ["compte", "intitule"] },
        { id: 3 , name: "classe 3" , pcg_default_column_names: ["compte", "intitule"] },
        { id: 4 , name: "classe 4" , pcg_default_column_names: ["compte", "intitule"] },
        { id: 5 , name: "classe 5" , pcg_default_column_names: ["compte", "intitule"] },
        { id: 6 , name: "classe 6" , pcg_default_column_names: ["compte", "intitule","variable"] },
        { id: 7 , name: "classe 7" , pcg_default_column_names: ["compte", "intitule"] },
      ],

      pcg_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Numéro(s) de compte",
          value: "compte",
          name: "compte",
          filterable: true,
          sortable: true,
          order: 1.1,
        },
        {
          text: "Intitulé",
          value: "intitule",
          name: "intitule",
          filterable: true,
          sortable: true,
          order: 1.2,
        },
        {
          text: "Variable",
          value: "variable",
          name: "variable",
          filterable: false,
          sortable: true,
          order: 1.3,
        },        
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },
      ],

      pcg_default_column_order: "compte",
      pcg_show_route_name: null,
      pcg_create_route_name: null,
      pcg_update_route_name: null,
      pcg_api_delete_endpoint_url: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    pcg_apiEndPointUrl(subcompte) {
      return "/api/load_pcg/?client_id="+this.selectedClient.id+"&subcompte=" + subcompte;
    },
  },

  mounted() {

     // recuperation sauvegarde du client selectionné
     if(localStorage.selectedClient){
        this.selectedClient = JSON.parse(localStorage.selectedClient);
     }


         // on client changed
    EventBus.$on('ClientChanged', client => {
     
      this.selectedClient = client
    })     


  },
};
</script>