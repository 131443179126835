<template>
    <!-- Le template -->
    <v-container fill-height v-if="isLoggedIn">
        <v-row><v-col align="center" justify="center">
        <h1>Bienvenue sur DIGI&FI</h1> 
        <p>Choisissez une action dans le menu </p>
        </v-col></v-row>
    </v-container>
</template>

<script>
    // Le script
    export default {
        computed : {
            isLoggedIn () {
                return this.$store.getters["currentUser/isLoggedIn"]
            }
        },
        methods : {
        }
    }

</script>