<template>
    <!-- Le template -->
    <v-container fill-height v-if="isLoggedIn">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title v-if="!isLoading"> Modifier le paramètre Subvention d'Investissement Conventionné pour  <span v-if="!isSubventionLoading">{{subventionInvestissementConventionne.client.denomination_sociale}}</span></v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-progress-linear :active="isLoading" color="red" :indeterminate="true" />

          <v-card-text class="pt-8 pb-4">
                <v-alert v-if="serverAlert" type="error" 
                close-text="Fermer"
                dismissible>
                <ul>
                    <div v-for="(value, propertyName, index) in formError" :key="index">
                        <li v-if="value && Array.isArray(value)">{{ propertyName }}: {{ value[0] }}</li> 
                        <li v-if="value && typeof value =='string'">{{ propertyName }}: {{ value }}</li>                      
                    </div>
                </ul>
                </v-alert>

        <v-snackbar
      v-model="snackbar"
      bottom
      color="success"      
      multi-line
      :timeout=6000 
   >
      <p v-html="snackbar_text"></p>
        
      <v-btn dark color="green darken-4" @click="snackbar_text =''; snackbar= false">Fermer</v-btn>
      <v-btn dark color="green darken-4" @click="snackbar= false;$router.push({name:'client/parametrage', params: { activtab : 7}})">Retour au listing</v-btn>
    </v-snackbar>

            <v-form ref="form" v-model="valid" v-if="!isLoading">
              <v-row>
                <v-col>

                <!--Annee-->
                  <v-select
                  dense
                    v-model="subventionInvestissementConventionne.annee"                    
                    :items="annees"
                    item-text="cloture"
                    item-value="cloture"
                    label="Année"                                       
                    single-line
                    :rules="[
                      rules.required,
                      rules.numeric,
                      rules.fourdigits,
                      rules.positif,
                    ]"
                    :error-messages="formError.annee"
                    @focus="formError.annee = null"                    
                  ></v-select>                   
                  
                </v-col>
                <v-col>
                  <!--REMBOURSEMENT -->  
                  <v-text-field  dense required                 
                    label="Montant"
                    name="montant"  
                    v-model="subventionInvestissementConventionne.montant"                                         
                    :rules="[rules.required,rules.numeric]"
                    :error-messages="formError.montant"
                    @focus="formError.montant=null"                    
                  />
                </v-col>              
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="red" @click.prevent="$router.push({name:'client/parametrage', params: { activtab : 7}})">
              <v-icon left>mdi-arrow-left-circle</v-icon>Retour
            </v-btn>
            <v-spacer />
            <v-btn color="primary" @click.prevent="submit" :disabled="!valid">
              <v-icon left>mdi-check</v-icon>
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
    // Le script
    export default {
        data() {return {

            selectedClient :{},
            annees: [],
            loading:true,          

            isSubventionLoading:true,
            isSaving:false,

            serverAlert: null,            
            snackbar_text : '', 
            snackbar : false,   

            subventionInvestissementConventionne:{},

            valid: false,

            formError: {
                annee: null,
                montant: null,           
            },

            rules: {

                required: v => !!v || "Ce champ est requis",

                positif: v => v>=0 || "Valeur positive attendue",

                numeric: v => !isNaN(v) || "Valeur numérique attendue",

                fourdigits: v => /^\d{4}$/.test(v) || "4 chiffres attendus",

                

            },            

          

        }},
        computed : {
            isLoading: function(){
                if(this.isSaving ||this.isSubventionLoading ){ return true}
                return false
            },
            isLoggedIn (){                
                return this.$store.getters["currentUser/isLoggedIn"]
            },            
        },
        watch:{         


        },
        methods : {

  getannees() {
      this.loading = true;
      this.serverAlert = false;

      axios
        .get(
          "/api/load_balanceannuelle_history_select?client_id=" +
            this.selectedClient.id
        )
        .then((response) => {

          console.log(response.data);

          if (response.data.length == 0) {
            this.formError.balance =
              "Vous devez d'abord importer une balance annuelle";
            this.serverAlert = true;
          }

          this.annees = _.uniq(_.map(response.data,function(v){return parseInt(v.cloture.substring(0,4))}));

          this.Loading = false;          
          
        })
        .catch((err) => {
          this.Loading = false;
          
        });
    },           
            /*
             * get client info
             */            

            getSubvention(){
                console.log('get DIVIDENDE info')
                this.isSubventionLoading = true
                axios.get('/api/subvention_ic/'+this.$route.params.itemId)
                .then((response)=>{
                    Object.assign(this.subventionInvestissementConventionne, response.data)  
                    Object.assign(this.selectedClient, response.data.client)   
                    this.getannees()                     
                    this.isSubventionLoading = false
                    
                })
                .catch((err)=>{
                    console.log('/api/subvention_ic/'+this.$route.params.itemId+' KO',err)
                    console.log(err.response)
                    this.isSubventionLoading = false
                    if (err.response.status == 403) {
                        this.$router.push({name:'unauthorized'})
                    }
                })

            },
           
            
            submit(){

                this.isSaving = true;
                this.serverAlert= null;

                let payload = {client_id:this.subventionInvestissementConventionne.client.id,annee:this.subventionInvestissementConventionne.annee,montant:this.subventionInvestissementConventionne.montant};

                console.log(payload);

                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post("/api/subvention_ic/update/"+this.subventionInvestissementConventionne.id, payload)
                    .then(response => {

                        console.log('submit OK',response);
                        
                        this.isSaving = false;
                        this.snackbar= true 
                        this.snackbar_text="Element modifié avec succès"
                       
                        this.getSubvention()                       
                        
                    })
                    .catch(err => {
                    this.serverAlert= true;    
                    this.isSaving = false;
                    console.log("submit_ko", err);

                    if (err.response.status == 422) {
                        Object.assign(this.formError, err.response.data.errors);
                    }

                    this.formError.general = err.response.data.message;

                    });


                })
                return ;

            },            


        },
        mounted(){
            // subvention info
            this.getSubvention();
        }
    }
</script>