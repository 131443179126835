<template>
  <!-- Le template -->
  <v-container>

    <v-row>
      <v-col>
        <v-tabs v-model="activTab" vertical show-arrows>
          <v-tab v-for="tab in tabs" :key="tab.id">{{ tab.name }}</v-tab>
        
        
          <v-tab-item v-for="tab in tabs" :key="tab.id" class="pa-8 custombgcolor">
           <!---->   
           <datatable-balance v-if="selectedClient"
              :selectedClient="selectedClient"
              :possibleHeaders="possibleHeaders"
              :api_endpoint_url="get_apiEndPointUrl(tab.id)"
              :default_column_names="default_column_names"
              :default_column_order="default_column_order"
              :create_route_name="null"
              :show_route_name="null"
              :update_route_name="null"
              :api_delete_endpoint_url="null"
              :excel_file_name="`exercice_`+exercice+`_balance_class`+tab.id+`.xls`"
            ></datatable-balance>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.custombgcolor {background-color: #1E4461;}
</style>

<script>
import {EventBus} from "../EventBus";

// Le script
export default {
  name: "balance-view",
  props: {
    selectedClient : Object,
    exercice: String,
  },  

  data() {
    return {
      activTab: null,
      tabs: [
        { id: 1, name: "classe 1" },
        { id: 2, name: "classe 2" },
        { id: 3, name: "classe 3" },
        { id: 4, name: "classe 4" },
        { id: 5, name: "classe 5" },
        { id: 6, name: "classe 6" },
        { id: 7, name: "classe 7" },
      ],

      possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Numéro de compte",
          value: "compte",
          name: "compte",
          filterable: true,
          sortable: true,
          order: 1.1,
        },
        {
          text: "Intitulé",
          value: "intitule",
          name: "intitule",
          filterable: true,
          sortable: true,
          order: 1.2,
        },
        {
          text: "Affectation",
          value: "custom_intitule.intitule",
          name: "pcgintitules.intitule",
          filterable: true,
          sortable: true,
          order: 1.21,
        }, 
        {
          text: "Débit",
          value: "debit",
          name: "debit",
          filterable: true,
          sortable: true,
          order: 1.22,
        },  
        {
          text: "Crédit",
          value: "credit",
          name: "credit",
          filterable: true,
          sortable: true,
          order: 1.23,
        },    
        {
          text: "Solde",
          value: "solde",
          name: "solde",
          filterable: true,
          sortable: true,
          order: 1.24,
        }, 
        {
          text: "Cloture",
          value: "cloture",
          name: "cloture",
          filterable: true,
          sortable: true,
          order: 1.25,
        },                                  
        {
          text: "Variable",
          value: "pcg.variable",
          name: "pcg.variable",
          filterable: false,
          sortable: true,
          order: 1.3,
        },        
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },
      ],
      default_column_names: ["compte", "intitule","pcgintitules.intitule","debit","credit","solde"],
      default_column_order: "compte",

    };
  },
  computed: {


  },
  watch: {

    
  },
  methods: {
    get_apiEndPointUrl(subcompte) {      
      return "/api/load_balance_annuelle/?client_id="+this.selectedClient.id+"&exercice="+this.exercice+"&subcompte=" + subcompte;
    },
  },

  mounted() {


  },
};
</script>