<template>
    <!-- Le template -->
    <v-container fill-height>
        
                <v-row >
            <!--Search-->
            <v-col align="center" justify="center" >
            <h1>Bienvenue sur DIGIFI</h1>  
            <small v-if="!$store.getters['currentUser/isLoggedIn']">Veuillez vous connecter</small>            
            </v-col>
                </v-row>
                
    </v-container>
</template>

<script>
    // Le script
    export default {
        name : "welcome",
        computed : {

        },        
        
        methods : {
            
        }
    }
</script>