<template>
  <div>
    <v-row dense class="teal darken-1 flex-nowrap no-gutters px-1 py-1">
      <v-col class="pt-1 px-0">Résultat<br />€</v-col>
      <v-col
        class="px-0 pt-1"
        v-for="(month, index) in moisExercice"
        :key="index"
      >
        <v-text-field
          class="custom-padding"
          :value="formattedValue(index)"
          :label="month"
          outlined
          readonly
          dense
          hide-details
          dark
        ></v-text-field>
      </v-col>

      <v-col class="px-1 pt-1" style="border-left: 1px solid white">
        <v-text-field
          class="custom-padding"
          :value="formattedTotalValue"
          label="TOTAL"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.custom-padding >>> .v-input__slot {
  padding-left: 5px !important; /* Personnalisez le padding selon vos besoins */
  padding-right: 5px !important;
  min-width: 45px !important;
}
.custom-padding >>> input {
  font-size: 0.75em !important;
}
</style>

<script>
export default {
  name: "resultat-classe",
  props: {
    moisExercice: { type: Array },
    currentPrevis: { type: Array },
  },
  data() {
    return {
      sumByMonth: [],
    };
  },
  computed: {
    formattedTotalValue() {
      const total = this.sumByMonth.reduce(
        (acc, value) => acc + parseFloat(value),
        0
      );
      return total.toLocaleString("fr-FR");
    },
  },
  methods: {
    formattedValue(index) {
      return parseFloat(this.sumByMonth[index]).toLocaleString("fr-FR");
    },

    monthsum(index) {
      const sumproduits = _.sumBy(
        _.filter(this.currentPrevis, { class: 7 }),
        (item) => Number(item["mois_" + index])
      );
      const sumcharges = _.sumBy(
        _.filter(this.currentPrevis, { class: 6 }),
        (item) => Number(item["mois_" + index])
      );

      const sum = parseInt(sumproduits - sumcharges);

      return sum;
    },

    updateMonthSum() {
      this.sumByMonth = [];
      this.moisExercice.forEach((item, index) => {
        this.sumByMonth.push(this.monthsum(index + 1));
      });
    },
  },
  watch: {
    currentPrevis: {
      handler(newValues) {
        this.updateMonthSum();
      },
      deep: true,
    },
  },
  mounted() {
    this.updateMonthSum();
  },
};
</script>
