<template>
  <!-- Le template -->
  <v-container>
    <v-row>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">
          Paramétrages du client :
        </h1>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row v-if="selectedClient">
      <v-col>
        <v-tabs v-model="activtab" class="pb-8" show-arrows>
          <v-tab>Période d'exercice</v-tab>
          <v-tab>TVA</v-tab>
          <v-tab>E.T.P.</v-tab>
          <v-tab>Dividendes décidées</v-tab>
          <v-tab>Prix moyen pondéré</v-tab>
          <v-tab>Remb. Emprunt Struct.</v-tab>
          <v-tab>Invest. Réalisé</v-tab>
          <v-tab>Augm. Capital</v-tab>
          <v-tab>Subv. Invest. Conv.</v-tab>
          <v-tab>Autre Augm. Q.F.P.</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activtab" class="pa-8">
          <!--PERIODE EXCERCICE -->
          <v-tab-item>
            <exercice-form :clientID="selectedClient.id"></exercice-form>
          </v-tab-item>
          <!--TVA -->
          <v-tab-item>
            <tva-form :clientID="selectedClient.id"></tva-form>
          </v-tab-item>

          <!--LISTING ETP-->
          <v-tab-item>
            <datatable
              excel_file_name="etp.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="etp_possibleHeaders"
              :api_endpoint_url="etp_apiEndPointUrl"
              :default_column_names="etp_default_column_names"
              :default_column_order="etp_default_column_order"
              :create_route_name="null"
              :show_route_name="etp_show_route_name"
              :update_route_name="etp_update_route_name"
              :api_delete_endpoint_url="etp_api_delete_endpoint_url"
            ></datatable>

            <etp-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></etp-createform>
          </v-tab-item>
          <!--LISTING DIVIDENDE-->
          <v-tab-item>
            <datatable
              excel_file_name="dividende.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="dividende_possibleHeaders"
              :api_endpoint_url="dividende_apiEndPointUrl"
              :default_column_names="dividende_default_column_names"
              :default_column_order="dividende_default_column_order"
              :create_route_name="null"
              :show_route_name="dividende_show_route_name"
              :update_route_name="dividende_update_route_name"
              :api_delete_endpoint_url="dividende_api_delete_endpoint_url"
            ></datatable>
            <dividende-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></dividende-createform>
          </v-tab-item>

          <!--LISTING PRIX MOYEN PONDERE-->
          <v-tab-item>
            <datatable
              excel_file_name="prix_moyen_pondere.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="prixmoyen_possibleHeaders"
              :api_endpoint_url="prixmoyen_apiEndPointUrl"
              :default_column_names="prixmoyen_default_column_names"
              :default_column_order="prixmoyen_default_column_order"
              :create_route_name="null"
              :show_route_name="prixmoyen_show_route_name"
              :update_route_name="prixmoyen_update_route_name"
              :api_delete_endpoint_url="prixmoyen_api_delete_endpoint_url"
            ></datatable>
            <prixmoyen-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></prixmoyen-createform>
          </v-tab-item>
          <!--REMBOURSEMENT EMPRUNT STRUCTUREL-->
          <v-tab-item>
            <datatable
              excel_file_name="remboursement_emprunt_structurel.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="remboursement_possibleHeaders"
              :api_endpoint_url="remboursement_apiEndPointUrl"
              :default_column_names="remboursement_default_column_names"
              :default_column_order="remboursement_default_column_order"
              :create_route_name="null"
              :show_route_name="remboursement_show_route_name"
              :update_route_name="remboursement_update_route_name"
              :api_delete_endpoint_url="remboursement_api_delete_endpoint_url"
            ></datatable>
            <remboursement_es-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></remboursement_es-createform>
          </v-tab-item>
          <!--INVESTISSEMENT REALISE-->
          <v-tab-item>
            <datatable
              excel_file_name="investissement_realise.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="investissement_possibleHeaders"
              :api_endpoint_url="investissement_apiEndPointUrl"
              :default_column_names="investissement_default_column_names"
              :default_column_order="investissement_default_column_order"
              :create_route_name="null"
              :show_route_name="investissement_show_route_name"
              :update_route_name="investissement_update_route_name"
              :api_delete_endpoint_url="investissement_api_delete_endpoint_url"
            ></datatable>

            <investissement_realise-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></investissement_realise-createform>
          </v-tab-item>
          <!--AUGMENTATION CAPITAL-->
          <v-tab-item>
            <datatable
              excel_file_name="augmentation_capital.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="augmentation_capital_possibleHeaders"
              :api_endpoint_url="augmentation_capital_apiEndPointUrl"
              :default_column_names="augmentation_capital_default_column_names"
              :default_column_order="augmentation_capital_default_column_order"
              :create_route_name="null"
              :show_route_name="augmentation_capital_show_route_name"
              :update_route_name="augmentation_capital_update_route_name"
              :api_delete_endpoint_url="
                augmentation_capital_api_delete_endpoint_url
              "
            ></datatable>
            <augmentation_capital-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></augmentation_capital-createform>
          </v-tab-item>
          <!--SUBVENTION INVESTISSEMENT CONVENTIONNE-->
          <v-tab-item>
            <datatable
              excel_file_name="subvention_investissement_conventionne.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="subvention_possibleHeaders"
              :api_endpoint_url="subvention_apiEndPointUrl"
              :default_column_names="subvention_default_column_names"
              :default_column_order="subvention_default_column_order"
              :create_route_name="null"
              :show_route_name="subvention_show_route_name"
              :update_route_name="subvention_update_route_name"
              :api_delete_endpoint_url="subvention_api_delete_endpoint_url"
            ></datatable>
            <subvention_ic-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></subvention_ic-createform>
          </v-tab-item>
          <!--AUTRE AUGMENTATION QFP-->
          <v-tab-item>
            <datatable
              excel_file_name="autre_augmentation_qfp.xls"
              :selectedClient="selectedClient"
              :possibleHeaders="autre_augmentation_qfp_possibleHeaders"
              :api_endpoint_url="autre_augmentation_qfp_apiEndPointUrl"
              :default_column_names="
                autre_augmentation_qfp_default_column_names
              "
              :default_column_order="
                autre_augmentation_qfp_default_column_order
              "
              :create_route_name="null"
              :show_route_name="autre_augmentation_qfp_show_route_name"
              :update_route_name="autre_augmentation_qfp_update_route_name"
              :api_delete_endpoint_url="
                autre_augmentation_qfp_api_delete_endpoint_url
              "
            ></datatable>
            <augmentation_qfp-createform
              class="mt-8"
              :selectedClient="selectedClient"
            ></augmentation_qfp-createform>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row> <v-col> </v-col></v-row>
  </v-container>
</template>

<script>
//import excerciceForm from '../../../components/forms/exerciceForm.vue';
import { EventBus } from "../../../EventBus";

// Le script
export default {
  //components: { excerciceForm },
  data() {
    return {
      Loading: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      activtab: null,

      //ETP
      etp_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "ETP Direct",
          value: "etp_direct",
          align: "left",
          name: "etp_direct",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "ETP Indirect",
          value: "etp_indirect",
          align: "left",
          name: "etp_indirect",
          filterable: true,
          sortable: true,
          order: 4,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      etp_default_column_names: [
        "annee",
        "etp_direct",
        "etp_indirect",
        "updated_at",
        "actions",
      ],
      etp_default_column_order: "annee",
      etp_show_route_name: null,
      etp_create_route_name: "client/parametrage/etp/create",
      etp_update_route_name: "client/parametrage/etp/update",
      etp_api_delete_endpoint_url: "/api/etp/delete/",

      //DIVIDENDE
      dividende_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Dividende",
          value: "dividende",
          align: "left",
          name: "dividende",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      dividende_default_column_names: [
        "annee",
        "dividende",
        "updated_at",
        "actions",
      ],
      dividende_default_column_order: "annee",
      dividende_show_route_name: null,
      dividende_create_route_name: "client/parametrage/dividende/create",
      dividende_update_route_name: "client/parametrage/dividende/update",
      dividende_api_delete_endpoint_url: "/api/dividende/delete/",

      //PRIX MOYEN
      prixmoyen_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Montant",
          value: "montant",
          align: "left",
          name: "montant",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      prixmoyen_default_column_names: [
        "annee",
        "montant",
        "updated_at",
        "actions",
      ],
      prixmoyen_default_column_order: "annee",
      prixmoyen_show_route_name: null,
      prixmoyen_create_route_name: "client/parametrage/prixmoyen/create",
      prixmoyen_update_route_name: "client/parametrage/prixmoyen/update",
      prixmoyen_api_delete_endpoint_url: "/api/prixmoyen/delete/",

      //REMBOURSEMENT EMPRUNT STRUCTUREL
      remboursement_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Montant",
          value: "montant",
          align: "left",
          name: "montant",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      remboursement_default_column_names: [
        "annee",
        "montant",
        "updated_at",
        "actions",
      ],
      remboursement_default_column_order: "annee",
      remboursement_show_route_name: null,
      remboursement_create_route_name:
        "client/parametrage/remboursement_es/create",
      remboursement_update_route_name:
        "client/parametrage/remboursement_es/update",
      remboursement_api_delete_endpoint_url: "/api/remboursement_es/delete/",

      //INVESTISSEMENT REALISE
      investissement_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Montant",
          value: "montant",
          align: "left",
          name: "montant",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      investissement_default_column_names: [
        "annee",
        "montant",
        "updated_at",
        "actions",
      ],
      investissement_default_column_order: "annee",
      investissement_show_route_name: null,
      investissement_create_route_name:
        "client/parametrage/investissement_realise/create",
      investissement_update_route_name:
        "client/parametrage/investissement_realise/update",
      investissement_api_delete_endpoint_url:
        "/api/investissement_realise/delete/",

      //AUGMENTATION CAPITAL
      augmentation_capital_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Montant",
          value: "montant",
          align: "left",
          name: "montant",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      augmentation_capital_default_column_names: [
        "annee",
        "montant",
        "updated_at",
        "actions",
      ],
      augmentation_capital_default_column_order: "annee",
      augmentation_capital_show_route_name: null,
      augmentation_capital_create_route_name:
        "client/parametrage/augmentation_capital/create",
      augmentation_capital_update_route_name:
        "client/parametrage/augmentation_capital/update",
      augmentation_capital_api_delete_endpoint_url:
        "/api/augmentation_capital/delete/",
      //SUBVENTION INVESTISSEMENT CONVENTIONNE
      subvention_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Montant",
          value: "montant",
          align: "left",
          name: "montant",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      subvention_default_column_names: [
        "annee",
        "montant",
        "updated_at",
        "actions",
      ],
      subvention_default_column_order: "annee",
      subvention_show_route_name: null,
      subvention_create_route_name: "client/parametrage/subvention_ic/create",
      subvention_update_route_name: "client/parametrage/subvention_ic/update",
      subvention_api_delete_endpoint_url: "/api/subvention_ic/delete/",
      //AUTRE AUGMENTATION QFP
      autre_augmentation_qfp_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Montant",
          value: "montant",
          align: "left",
          name: "montant",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 5,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 8,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      autre_augmentation_qfp_default_column_names: [
        "annee",
        "montant",
        "updated_at",
        "actions",
      ],
      autre_augmentation_qfp_default_column_order: "annee",
      autre_augmentation_qfp_show_route_name: null,
      autre_augmentation_qfp_create_route_name:
        "client/parametrage/augmentation_qfp/create",
      autre_augmentation_qfp_update_route_name:
        "client/parametrage/augmentation_qfp/update",
      autre_augmentation_qfp_api_delete_endpoint_url:
        "/api/augmentation_qfp/delete/",
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    etp_apiEndPointUrl() {
      return "/api/load_etp?client_id=" + this.selectedClient.id;
    },
    dividende_apiEndPointUrl() {
      return "/api/load_dividende?client_id=" + this.selectedClient.id;
    },
    prixmoyen_apiEndPointUrl() {
      return "/api/load_prixmoyen?client_id=" + this.selectedClient.id;
    },
    remboursement_apiEndPointUrl() {
      return "/api/load_remboursement_es?client_id=" + this.selectedClient.id;
    },
    investissement_apiEndPointUrl() {
      return (
        "/api/load_investissement_realise?client_id=" + this.selectedClient.id
      );
    },
    augmentation_capital_apiEndPointUrl() {
      return (
        "/api/load_augmentation_capital?client_id=" + this.selectedClient.id
      );
    },
    subvention_apiEndPointUrl() {
      return "/api/load_subvention_ic?client_id=" + this.selectedClient.id;
    },
    autre_augmentation_qfp_apiEndPointUrl() {
      return "/api/load_augmentation_qfp?client_id=" + this.selectedClient.id;
    },
  },
  watch: {
    selectedClient(val) {
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);
    },
  },
  methods: {
    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },
  },

  mounted() {
    if (this.$route.params.activtab) {
      this.activtab = parseInt(this.$route.params.activtab);
    }

    // recuperation sauvegarde du client selectionné
    if (localStorage.selectedClient) {
      this.selectedClient = JSON.parse(localStorage.selectedClient);
    }
    this.getClients();
  },
};
</script>