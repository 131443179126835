<!-- Le template -->
<template>
  <v-container :key="componentKey">
    <v-row class="mt-1">
      <v-col cols="12" class="d-flex justify-start align-center">
        <!--Choix du client-->
        <h3 class="mr-3 my-0">Charges / Budgets Prévisionnel de :</h3>
        <v-autocomplete
          class="mr-3 my-0"
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
          dense
          :rules="[rules.required]"
        ></v-autocomplete>

        <!--Annee-->
        <v-select
          v-if="!diagnostic_years_loading"
          menu-props="auto"
          class="mr-4 my-0"
          dense
          multiple
          small-chips
          deletable-chips
          @change="get_crs()"
          label="Année de prévisionnel"
          v-model="diagnostic_years"
          :items="possibles_diagnostic_years"
          :rules="[rules.required_select]"
        ></v-select>

        <!--Switch K€ -->
        <v-switch
          class="mr-3 my-0"
          dense
          label="K€"
          v-model="kilo_euro"
        ></v-switch>
        <!--Switch % Visibility -->
        <v-switch
          class="mr-3 my-0"
          dense
          label="K€"
          v-model="show_pourcent"
        >
        <template v-slot:label>
          <v-icon v-if="show_pourcent">mdi-eye-outline</v-icon><v-icon v-else>mdi-eye-off-outline</v-icon>%
        </template>

        </v-switch>        

        <!--Switch base % -->
        <v-switch
          v-model="pourcent_PE"          
          class="mr-3 my-0"
          dense
          label="%PE"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="selectedClient && diagnostic_years.length != 0">
      <v-col>
        
             <v-row>
              <v-col class="d-flex justify-space-between pb-5 align-center">
                <h6 class="mb-0"></h6>
                <div class="d-flex justify-space-between">
                  <v-btn
                    @click="exportImage('cbimagetable')"
                    color="grey darken-3"
                    class="mr-1"
                  >
                    <v-icon dark left>mdi-file-image</v-icon>image
                  </v-btn>

                  <export-excel
                    v-if="!cb_loading"
                    :data="cbs"
                    name="charges-budgets.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3 ">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>



                </div>
              </v-col>
            </v-row>

            <v-progress-linear
              v-if="GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>


            <!-- CBS Visible Table -->
            <v-simple-table
              v-if="!cb_loading && !GlobalLoading"
              fixed-header
              dark
              dense
              :key="cbDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" rowspan="2" style="padding: 5px">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            @click="toggleAllRowVisibility(selectall_cb, cbs)"
                            v-model="selectall_cb"
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left" rowspan="2">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years_ordered"
                      :key="annee.entete1"
                      class="text-right border-left border-right"
                      :colspan="show_pourcent ? 2 : 1"
                    >
                      {{ annee }}
                    </th>
                  </tr>

                  <tr>
                    <template v-for="annee in diagnostic_years_ordered">
                      <th
                        :key="annee.entete2"
                        class="text-right euro_header border-left"
                        :class="show_pourcent ? 'text-right euro_header ':'text-right euro_header border-right' "
                      >
                        <span v-if="kilo_euro">K</span>€
                      </th>
                      <th v-if="show_pourcent"
                        :key="annee.entete2"
                        class="text-right pourcent_header border-right"
                      >
                        %
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cb, cbindex) in cbs"
                    :key="cbindex"
                    :class="`rang` + cb.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="cbs[cbindex].show"
                            @click="
                              toggleRowVisibility(
                                cbs[cbindex].show,
                                cbs,
                                cbindex
                              )
                            "
                            v-on="on"
                            :input-value="cbs[cbindex].show"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>{{ cb.intitule }}</td>
                    <template v-for="annee in diagnostic_years_ordered">

                      <td :key="annee.index" :class="show_pourcent ? 'text-right  border-left':'text-right border-right border-left' " >        <!--class="text-right border-left"-->                 
                        <span v-if="cb.rang != 2">{{ format(cb[annee], cb) }}</span>
                      </td>
                      <td v-if="show_pourcent" class="text-right pourcent border-right">
                        <span v-if="cb.rang != 2">{{ pourcent_cb(cb, annee) }}</span>
                      </td> 
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>


         <!-- SIG Export image Table  -->
            <div  style="position: absolute; z-index: -1;top:-10000px" >
              <v-simple-table
                v-if="!cb_loading && !GlobalLoading"
                dense
                id="cbimagetable"
                class="pb-3 imgtable"
                light
                :key="exportCBDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th
                        class="text-left"
                        rowspan="2"
                        style="color: #ffffff !important"
                      >
                        Intitulé
                      </th>
                      <th
                        v-for="annee in diagnostic_years_ordered"
                        :key="annee.entete1"
                        class="text-right border-left"
                        :colspan="show_pourcent ? 2 : 1"
                        style="color: #ffffff !important"
                      >
                        {{ annee }}
                      </th>
                    </tr>

                    <tr style="background-color: #0c6682">
                      <template v-for="annee in diagnostic_years_ordered">
                        <th
                          :key="annee.entete2"
                          class="text-right euro_header border-left"
                          style="color: #ffffff !important"
                        >
                          <span v-if="kilo_euro">K</span>€
                        </th>
                        <th v-if="show_pourcent"
                          :key="annee.entete2"
                          class="text-right pourcent_header"
                          style="color: #ffffff !important"
                        >
                          %
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(cbitem, cbitemindex) in cbs">
                      <tr
                        v-if="cbs[cbitemindex].show == '1'"
                        :key="cbitemindex"
                        :class="`rang` + cbitem.rang"
                      >
                        <td>{{ cbitem.intitule }}</td>
                        <template v-for="annee in diagnostic_years_ordered">                         
                          <td  :key="annee.index" ><span v-if="cbitem.rang != 2">{{ format(cbitem[annee], cbitem) }}</span></td>
                          <td v-if="show_pourcent" class="text-right pourcent" :key="annee.index" >{{ pourcent_cb(cbitem, annee) }}</td>
                        </template>
                      </tr>

                      <tr
                        style="background-color: #ffffff !important"
                        v-if="
                          cbitem.rang == 3 && cbs[cbitemindex].show == '1'
                        "
                        :key="cbitemindex + 'ter1'"
                      >
                        <td style="border-bottom: 0px !important">&nbsp;</td>
                        <template v-for="annee in diagnostic_years_ordered">
                          <td
                            :key="annee.index"
                            class="text-right value border-left"
                            style="border-bottom: 0px !important"
                          ></td>
                          <td
                            :key="annee.index"
                            class="text-right value"
                            style="border-bottom: 0px !important"
                          ></td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>        
        
      </v-col>
    </v-row>

  </v-container>
</template>

<style>
.imgtable td,
.imgtable th {
  height: auto !important;
  line-height: 1.3 !important;
}
.imgtable .rang4 td {
  padding: 8px !important;
}

/* 
alternance gris / blanc pour l'export en image des tableaux
tout en blanc cf recette 14/12

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
*/

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

/* .rang0 {font-weight: 200;} */

.rang0 ul {
  margin-bottom: 0px !important;
  font-size: 0.9em !important;
}

.rang0 .value {
  font-size: 0.9em !important;
}

.pourcent {
  font-style: italic;
}

.rang2 {
  background-color: #a6a6a6 !important;
}

.rang3 {
  background-color: #1ab7ea !important;
}

.rang4 {
  background-color: #0c6682 !important;
}

.rang4 td {
  padding: 20px !important;
}

.checkboxTD {
  width: 10px !important;
  padding: 5px 0 5px 5px !important;
}
.imgtable .rang4,
.imgtable .rang3 {
  color: #ffffff;
  font-weight: 400;
}
.imgtable .rang2 {
  color: #000000;
  font-weight: 400;
}
.br_header {
  width: 220px;
}
.euro_header {
  width: 100px;
}
.pourcent_header {
  width: 75px;
}

.border-left {
  border-left: 1px solid gray;
}
</style>

<script>
import { EventBus } from "../../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  data() {
    return {
      rules: {
        required: (v) => !!v || "Ce champ est requis",
        required_select: (v) => v.length > 0 || "Ce champ est requis",
      },

      cbDatatableKey: 0,
      exportCBDatatableKey: 0,

      componentKey: 0,

      GlobalLoading: true,

      Loading: true,
      cr_loading: true,     
      cb_loading: true,      

      diagnostic_years_loading: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      possibles_diagnostic_years: [],
      diagnostic_years: [],
      kilo_euro: false,
      pourcent_PE: false,
      show_pourcent:true,

      selectall_cb: "1",


      crs: [], // compte de résultat par année

      cbs: [
        {
          intitule: "Budgets détaillés de fonctionnement à engager",
          rang: 2,
        },

        {
          // Budget Achats & Transports  = "Achats" + "Variation de stocks" + "Transports de biens et transports collectifs du personnel"
          // = 16(cr)+22(cr)+44(cr)
          intitule: "Budget Achats & Transports",
          rang: 1,
          ajouter: [{ base: "crs", lignes: [16,22,44] },],
        },

        {
          intitule: "Budget matériel et fourniture",
          rang: 1,
          ajouter: [{ base: "crs", lignes: [24,25,26,27,28] },],
        },

        {
          intitule: "Budget sous-traitance",
          rang: 1,
          ajouter: [{ base: "crs", lignes: [29] },],
        },

        {
          intitule:"Budget rémunération d'intermédiaires & personnel ext à l'entreprise",
          rang: 1,
          ajouter: [{ base: "crs", lignes: [36,39,40] },],
        },

        {
          intitule: "Budget marketing / développement",
          rang: 1,

          ajouter: [{ base: "crs", lignes: [37,41,42,43] },],
        },

        {
          intitule: "Budget déplacement / mission",
          rang: 1,

          ajouter: [{ base: "crs", lignes: [45,46] },],
        },

        {
          intitule: "Budget loyer/charges immobilières",
          rang: 1,

          ajouter: [{ base: "crs", lignes: [31,33] },],
        },

        {
          intitule: "Budget Maintenance / entretien",
          rang: 1,

          ajouter: [{ base: "crs", lignes: [34] },],
        },
        {
          intitule: "Budget frais généraux",
          rang: 1,
          ajouter: [{ base: "crs", lignes: [30,32,35,38,47,48,49,50] },],
        },

        {
          intitule: "Budget impôt et taxes",
          rang: 1,
          ajouter: [{ base: "crs", lignes: [51] },],
        },

        {
          intitule: "Budget masse salariale",
          rang: 1,

          ajouter: [{ base: "crs", lignes: [52,53] },],
        },

        {
          intitule: "Autres charges de gestion courante",
          rang: 1,

          ajouter: [{ base: "crs", lignes: [54] },],
        },

        {
          intitule: "Dotations aux amortissements et provisions d'exploitation",
          rang: 1,

          ajouter: [{ base: "crs", lignes: [55] },],
        },


      ],
    
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    diagnostic_years_ordered(){
      return _.sortBy(this.diagnostic_years)
    }
  },

  watch: {
    kilo_euro(val) {
      EventBus.$emit("kilo_euroChanged", val);
    },
    //possibles_diagnostic_years
    diagnostic_years(val, old) {

      if (this.possibles_diagnostic_years.length > 0 && this.selectedClient) {


        this.cr_loading = true;
        this.cb_loading = true;
        this.GlobalLoading = true;

        this.get_crs().then(() => {
          this.cr_loading = false;          
            this.set_cbs().then(() => {
              this.cb_loading = false;
              this.GlobalLoading = false;
            });          

        });
      }
    },

    selectedClient(val) {
      this.GlobalLoading = true;

      this.diagnostic_years = [];

      if (val) {       

        //chargement de la liste des années disponibles pour le previsionnel CB (depend uniquement des années CR previs)
        this.getCBprevisYears();
        localStorage.selectedClient = JSON.stringify(val);
        EventBus.$emit("ClientChanged", val);
      }
    },
  },

  methods: {
    refresh() {
      this.cbDatatableKey++;      
      this.exportCBDatatableKey++;      
    },

    // toggleAllRowVisibility pour afficher masquer toutes les lignes
    // dans l'exportation image d'un tableau

    toggleAllRowVisibility(state, targetData) {
      _.map(targetData, function (obj) {
        obj.show = state;
      });
      this.refresh();
    },

    // toggleRowVisibility pour afficher masquer une ligne
    // avec ses enfants selon les cas
    // target = jeux de donnée cible
    // row = identifiant de la ligne

    toggleRowVisibility(state, target, index) {
      let i = index;

      // si rang = 3
      // on ne s'occupe pas des enfants
      if (target[index].rang == 3) {
        target[index].show = state;
      } else if (target == this.ffs) {
        do {
          target[i].show = state;
          i--;
        } while (target[i] && target[i].rang < target[index].rang);
      } else {
        do {
          target[i].show = state;
          i++;
        } while (target[i] && target[i].rang < target[index].rang);
      }
      this.refresh();
    },

    exportImage(tab) {
      var node = document.getElementById(tab);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "previsionnel-cb.png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    set_excelHeaders() {
      var headers = { intitule: "intitule" };
      this.diagnostic_years_ordered.forEach((year) => {
        headers["année " + year] = year;
      });
      return headers;
    },

    /*
     * format value in K€
     */
    format(value, item) {
      // exception au formatage en Euro (precision au centième)
      if (
        item.intitule ==
        "Productivité du personnel rapportée au budget annuel (hors personnel extérieur) (N°3)"
      ) {
        return this.kilo_euro == true
          ? parseFloat(value / 1000).toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            })
          : parseFloat(value).toLocaleString("fr-FR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            });
      }

      if (isNaN(parseFloat(value)) || item.taux || item.sans_unite) {
        if (isNaN(value)) {
          return value;
        } else {
          return parseFloat(value).toFixed(1);
        }
      }
      if (item.jour || item.annee) {
        return Math.round(value);
      }
      // formatage en K€ on préfère que les montants soient arrondis à l’unité cf recette du 31/03/2023 et mail du 12/04
      return this.kilo_euro == true
        ? parseFloat(value / 1000).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : parseFloat(value).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    },

    pourcent_cb(cb, annee) {

      var pourcent;
      var base;
      if (this.pourcent_PE == true) {
        base = parseFloat(
          _.filter(this.crs, {"intitule":"PRODUITS D'EXPLOITATION","annee":annee})[0].montant
        );
      } else {

        // pourcentage basé sur chiffre d'affaire
        // CA = 2(cr) + 3(cr)

        base = _.chain(this.crs)
        .filter(item=>item.annee === annee && _.includes([2,3],item.order) )
        .sumBy(item => parseFloat(item.montant))
        .value()


        //ajouter: [{ base: "crs", lignes: [2,3] },],


        //base = parseFloat(
        //  _.filter(this.sigs, ["intitule", "Chiffre d'affaires"])[0][annee]
        //);
      }

      if (parseFloat(base) != 0) {
        pourcent = parseFloat(
          (parseFloat(cb[annee]) * 100) / parseFloat(base)
        ).toFixed(1);

        if (pourcent > 200) {
          return ">200";
        }
        if (pourcent < -200) {
          return "<200";
        }
      } else {
        pourcent = 0.0; // Modifier les «-» provenant d’une division par 0 (N/A) et remplacer par 0 (cf recette 14/12/2023)
      }

      return parseFloat(pourcent).toFixed(1);
    },

    /*
     * cb
     */

    set_cbs() {
      this.cb_loading = true;

      
      return new Promise((resolve, reject) => {
        var years_values;
        var value;

        _.forEach(this.cbs, (cb, key) => { 
          years_values = {};
          
          _.forEach(this.diagnostic_years_ordered, (annee) => {
            value = 0;
            if (cb.ajouter) {
              _.forEach(cb.ajouter, (ajout) => {               
                
                
                // somme des lignes du CR dont l'ordre est dans ajout.lignes pour l'annee considérée 
                // sera ajouter au total
                let ajouter = _.chain(this.crs)
                .filter(item => _.includes(ajout.lignes,item.order) && item.annee == annee)
                .sumBy (item => parseFloat(item.montant)) 
                .value()

                // console.log(`Pour l'année ${annee} La somme des montants de  ${cb.intitule} est: ${ajouter}`);

                value = parseFloat(value) + parseFloat(ajouter);
              });
            }

            if (cb.soustraire) {
              _.forEach(cb.soustraire, (soustraction) => {


                // somme des lignes du CR dont l'ordre est dans ajout.lignes pour l'annee considérée 
                // sera soustrait du total
                
                let soustraire = _.chain(this.crs)
                .filter(item => _.includes(soustraction.lignes,item.order) && item.annee == annee)
                .sumBy (item => parseFloat(item.montant)) 
                .value()

                value = parseFloat(value) - parseFloat(soustraire);
              });
            }

            years_values[annee] = parseFloat(value).toFixed(2);
          });

          this.cbs[key] = Object.assign({}, this.cbs[key], years_values, {
            show: "1",
          });
        });
        this.cb_loading = false;
        resolve();
      });
    },

    /*
     * somme selon class
     */

    get_somme(classes, type, annee) {
      let payload = {
        client_id: this.selectedClient.id,
        annee: annee,
        classes: classes,
        type: type,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/diag/analyse/somme", payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    /*
     * Compte de resultat
     */

    get_crs() {
      this.cr_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/previsionnel-sig-load-cr", payload)
          .then((response) => {
            this.crs = _.orderBy(response.data, ["order"], ["asc"]);
            this.cr_loading = false;
            resolve();
          })
          .catch((err) => {
            this.cr_loading = false;
            reject(err);
          });
      });
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

    getCBprevisYears() {
      if (!this.selectedClient.id) {
        this.diagnostic_years = [];
        return;
      }
      this.diagnostic_years_loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/previsionnel-sig/years/" +
              this.selectedClient.id
          )
          .then((response) => {

            console.log(response.data)

            this.possibles_diagnostic_years = response.data;

            // les trois dernieres années sont selectionnées par default
            this.diagnostic_years = _.sortBy(
              _.take(this.possibles_diagnostic_years, 3)
            );

            this.diagnostic_years_loading = false;
            resolve(response);
          })
          .catch((err) => {
            this.possibles_diagnostic_years = [];
            this.diagnostic_years_loading = false;
            reject(err);
          });
      });
    },
  },

  mounted() {
    this.getClients().then(() => {
      // recuperation du client selectionné sauvegardé en local storage
      // mise a jour du local storage si entretemps un paramétrage client a été mis à jour

      if (localStorage.selectedClient) {
        var selectedClientInlocalstorage = JSON.parse(
          localStorage.selectedClient
        );
        this.selectedClient = _.find(this.clients, {
          id: selectedClientInlocalstorage.id,
        });
        localStorage.selectedClient = JSON.stringify(this.selectedClient);
      }
    });
  },
};
</script>