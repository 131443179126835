var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-snackbar',{attrs:{"bottom":"","color":"success","multi-line":"","timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n    "+_vm._s(_vm.snackbar_text)+"\n    "),_c('v-btn',{attrs:{"dark":"","color":"green darken-4"},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Fermer")])],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Confirmer la suppression ?")])]),_vm._v(" "),_c('v-card-text',[(_vm.deleteItem != null)?_c('v-container',[_c('v-row',[_c('v-col')],1)],1):_vm._e()],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancel.apply(null, arguments)}}},[_vm._v("Annuler")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirm.apply(null, arguments)}}},[_vm._v("Confirmer")])],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center","cols":"auto"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"align":"center","justify":"center","cols":"auto"}},[_c('v-select',{attrs:{"items":_vm.possibleHeaders,"label":"Visibilité des colonnes","multiple":"","return-object":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(_vm.defaultHeaders.length +
            " colonnes sur " +
            _vm.possibleHeaders.length +
            " possibles"))]):_vm._e()]}}]),model:{value:(_vm.defaultHeaders),callback:function ($$v) {_vm.defaultHeaders=$$v},expression:"defaultHeaders"}})],1),_vm._v(" "),_c('v-col',{attrs:{"align":"center","justify":"center","cols":"auto"}},[_c('export-excel',{attrs:{"data":_vm.myitems,"name":_vm.excel_file_name,"fields":_vm.excelheaders}},[_c('v-btn',{attrs:{"color":"grey darken-3"}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-file-export-outline")]),_vm._v("Excel\n        ")],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"text-right",attrs:{"cols":"auto"}},[(_vm.create_route_name && _vm.canCreateEtp === true)?_c('v-btn',{attrs:{"color":"primary","width":"100%","to":{ name: _vm.create_route_name , params: { clientID: _vm.selectedClient.id }}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-plus")]),_vm._v("Ajouter un élément\n      ")],1):_vm._e()],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sortBy":"id","footer-props":{
      'items-per-page-all-text': 'tout',
      'items-per-page-text': 'Résultats par page :',
    },"headers":_vm.headers,"items":_vm.myitems,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:items-per-page":_vm.setItemPerPage,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v("Lignes: "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" sur\n      "+_vm._s(itemsLength))]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.update_route_name && item.can.update == true)?_c('v-btn',{attrs:{"icon":"","to":{ name: _vm.update_route_name, params: { itemId: item.id } },"title":"Modifier"}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_vm._v(" "),(_vm.show_route_name && item.can.view == true & !_vm.show_route_name.startsWith('/api'))?_c('v-btn',{attrs:{"icon":"","to":{ name: _vm.show_route_name, params: { itemId: item.id } },"title":"Voir le détail"}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),_vm._v(" "),(_vm.show_route_name && item.can.view == true & _vm.show_route_name.startsWith('/api'))?_c('v-btn',{attrs:{"icon":"","href":_vm.show_route_name +'/'+ item.id,"title":"Télécharger"}},[_c('v-icon',[_vm._v("mdi-file-download")])],1):_vm._e(),_vm._v(" "),(_vm.api_delete_endpoint_url && item.can.delete == true && item.id!=_vm.maxId && _vm.api_delete_endpoint_url.startsWith('/api/pcg_history'))?_c('v-btn',{attrs:{"icon":"","color":"red","title":"Supprimer"}},[_c('v-icon',{on:{"click":function($event){return _vm.modalDelete(item)}}},[_vm._v("mdi-delete")])],1):_vm._e(),_vm._v(" "),(_vm.api_delete_endpoint_url && item.can.delete == true && !_vm.api_delete_endpoint_url.startsWith('/api/pcg_history'))?_c('v-btn',{attrs:{"icon":"","color":"red","title":"Supprimer"}},[_c('v-icon',{on:{"click":function($event){return _vm.modalDelete(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.compte",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:_vm.notUsedInDiag(item) ? 'red--text' : 'default'},[_vm._v(_vm._s(item.compte))])]}},{key:"item.variable",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"readonly":""},model:{value:(item.variable),callback:function ($$v) {_vm.$set(item, "variable", $$v)},expression:"item.variable"}})]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formaDatetWithDuration(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formaDatetWithDuration(item.updated_at)))])]}},{key:"item.cloture",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formaDatetWithDuration(item.cloture)))])]}}])}),_vm._v(" "),(_vm.api_endpoint_url.includes('load_pcg') && !this.no_show_info)?_c('v-row',[_c('v-col',{staticClass:"text-right red--text",attrs:{"cols":"auto"}},[_c('strong',[_vm._v("Les comptes indiqués en rouge ne sont pas utilisés dans le Compte de Résultat ni le Bilan")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }