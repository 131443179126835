<template>
  <div>
    <v-row>
      <v-col class="black--text text-center text-h5"
        >Situation à {{ selectedMonth }} : Suivi du CA mensuel

        <BarMensuel
          :data="ca_mensuel_datas"
          :options="ca_mensuel_option"
          ref="camensuel"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Bar as BarMensuel } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "CaMensuel",
  components: { BarMensuel },
  props: {
    selectedClient: { type: Object },
    moisExercice: { type: Array },
    exercice: { type: Number },
    balances: { type: Array },
    currentPrevis: { type: Array },
    kilo_euro: { type: Boolean },
    situationMonth: { type: String },
    selectedMonth: { type: String },
  },

  data() {
    return {};
  },
  computed: {
    ca_mensuel_option() {
      return {
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: this.unite(),
            },
          },
        },
      };
    },

    ca_mensuel_datas() {
      return {
        labels: this.moisExercice,
        datasets: [
          {
            label: "Réel",
            data: this.get_ca_mensuel_reel(),
            backgroundColor: "#ffbde9",
          },
          {
            label: "Prévu",
            data: this.get_ca_mensuel_prevu(),
            backgroundColor: "#0070c0",
          },
        ],
      };
    },
  },
  methods: {
    unite() {
      if (this.kilo_euro == true) {
        return "K€";
      }
      return "€";
    },

    get_ca_mensuel_prevu() {
      const sommesParMois = [];

      if (this.moisExercice == null) {
        return sommesParMois;
      }

      // Boucler à travers les mois du tableau moisExercice
      for (const moisCourant of this.moisExercice) {
        // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 1 (cat CA)
        const currentPrevis_cat_CA = this.currentPrevis.filter(
          (item) => item.budgetline_categorie_id === 1
        );

        // Calculer la somme des valeurs pour le mois courant
        const sommeMoisCourant = currentPrevis_cat_CA.reduce(
          (somme, item) =>
            somme + item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
          0
        );

        // Ajouter la somme au tableau sommesParMois
        sommesParMois.push(sommeMoisCourant);
      }

      return sommesParMois.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },

    get_ca_mensuel_reel() {
      let sommeCumulee = 0;
      let valeursMensuelles = [];
      let CAcumule = [];

      if (this.moisExercice == null || this.balances.length == 0) {
        return valeursMensuelles;
      }

      // comptes à prendre en compte pour le calcul du CA
      const comptes = [
        -701, -702, -703, -704, -705, -706, -707, -708, -7092, -7093, -7094,
        -7095, -7096, -7097, -7098,
      ];

      // on filtre les balances sur la classe = 7
      const filteredBalances = _.filter(this.balances, { subcompte1: "7" });

      // Parcourir les moisExercice
      this.moisExercice.forEach((moisCourant, index) => {
        sommeCumulee = 0;
        //console.log("////////////////////////////////////////////")
        //console.log("mois "+moisCourant)
        //console.log("////////////////////////////////////////////")

        // Parcourir les balances
        filteredBalances.forEach((objet) => {
          const solde = parseFloat(objet.solde);
          if (!isNaN(solde)) {
            
            comptes.forEach((numero) => {
              
              if (objet.compte.startsWith(Math.abs(numero).toString())) {

                const moisCloture = this.$moment(objet.cloture);
                // Si le mois de clôture correspond au mois en cours, ajoutez ou soustrayez le solde
                if (moisCloture.format("MMMYY") === moisCourant) {               
                  sommeCumulee += numero < 0 ? -solde : solde;                  
                }               
               
              }
            });
          }
        });

        CAcumule.push(sommeCumulee);
       
        // à la valeurcumulée trouvée, on doit soustraire la valeur du mois précédent (s'il existe et s'il est non null)
        // sauf pour le premier mois où dans ce cas la valeur correspond à la valeur trouvée (sommeCumulee)
        // sauf egalement si la valeur trouvée est nulle (ce qui ne devrait jamais arriver car les balances sont un cumul)

        if (index == 0 || sommeCumulee == 0) {
          //console.log('premier mois on prend la valeur depuis la balance')
          //console.log('valeur mensuelle =>',sommeCumulee)
          valeursMensuelles.push(sommeCumulee);


        } else {

          //console.log('pas le premier mois => valeursMensuelles = sommeCumulee - CAcumule[precedent] = ' + parseFloat(sommeCumulee-CAcumule[index-1]))
          //console.log('sommeCumulee',sommeCumulee)
          //console.log('CAcumule[precedent]',CAcumule[index-1])

          valeursMensuelles.push(parseFloat(sommeCumulee-CAcumule[index-1]));
          
        }
      });

      //console.log("CAcumule",CAcumule)
      //console.log("valeursMensuelles",valeursMensuelles)

      return valeursMensuelles.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },
  },

  mounted() {},
};
</script>