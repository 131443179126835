<template>
  <div>
    <v-row>
      <v-col class="black--text text-center text-h5"
        >Situation à {{ selectedMonth }} : Suivi du résultat net cumulé

        <BarcaCumule v-if="loaded" :data="chartDatas" :options="chartOptions" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Bar as BarcaCumule } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "resnetCumule",
  components: { BarcaCumule },
  props: {
    selectedClient: { type: Object },
    moisExercice: { type: Array },
    exercice: { type: Number },
    balances: { type: Array },
    currentPrevis: { type: Array },
    kilo_euro: { type: Boolean },
    situationMonth: { type: String },
    selectedMonth: { type: String },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    cumulreel() {

      // formule modifiée cf recette mai 2024 & mail du 06/06/2024
      // 

      let values = this.get_resnet_reel_cumule([
        -601,-602,-603,-604,-605,-606,-607,-608,-609,-611,-612,-613,
        -614,-615,-616,-617,-618,-619,-621,-622,-623,-624,-625,-626,
        -627,-628,-629,-63,-64,-65,-66,-67,-681,-686,-687,-689,-695,
        -696,-698,-699,-701,-702,-703,-704,-705,-706,-707,-708,-7091,
        -7092,-7093,-7094,-7095,-7096,-7097,-7098,
        -71,-72,-73,-74,-75,-76,-77,-781,-786,-787,-789,-79,
      ]);

      return values.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },
    cumulprevu() {
      let values = this.get_resnet_prevu_cumule();

      return values.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },

    chartOptions() {
      return {
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: this.unite(),
            },
          },
        },
      };
    },
    chartDatas() {
      return {
        labels: this.moisExercice,
        datasets: [
          {
            type: "line",
            label: "Réel",
            data: this.cumulreel,
            backgroundColor: "#ffbde9",
            borderColor: "#ffbde9",
            borderWidth: 2,
          },
          {
            label: "Prévu",
            data: this.cumulprevu,
            backgroundColor: "#0070c0",
          },
        ],
      };
    },
  },
  methods: {
    unite() {
      if (this.kilo_euro == true) {
        return "K€";
      }
      return "€";
    },

    get_resnet_prevu_cumule() {
      /*
        Résultat net prévu = Cat. Chiffre d’affaires du prévi. (Produits) ID 1 – Cat. Achats du prévi. ID 6 (Charges) 
        – Cat. Frais Généraux du prévi. ID 7 (Charges) + Cat. Subvention d’exploitation du prévi. ID 2 (Produits) 
        – Cat. Masse salariale du prévi.  ID 9 (Charges) – Cat. Impôts, taxes et versements assimilés du prévi. ID 8 (Charges) + 
        - Cat. Charges financières du prévi. ID 11 (Charges) 
        – Cat. Autres Charges financières du prévi ID 13 (Charges)- Cat. Autres Charges exceptionnelles du prévis ID 14 (Charges) 
        + Cat. Autres Produits financiers du prévi ID 4 (Charges) + Cat. Autres Produits exceptionnels du prévi ID 5 (Charges) 
        – Cat. Dotations aux amortissements et provisions du prévi ID 10 (Charges)   <= Mod formule ajout ceci cf recette avril2024
        */

      const sommesParMoisCumules = [];

      let cumul_ca = 0; // ID 1
      let cumul_achats = 0; // ID 6
      let cumul_frais_generaux = 0; // ID 7
      let cumul_subv_exploitation = 0; // ID 2
      let cumul_masse_salariale = 0; // ID 9
      let cumul_impot = 0; // ID 8
      let cumul_charges_financieres = 0; // ID 11
      let cumul_autres_charges_financieres = 0; // ID 13
      let cumul_autres_charges_exceptionnelles = 0; // ID 14
      let cumul_autres_produits_financiers = 0; // ID 4
      let cumul_autres_produits_exceptionnels = 0; // ID 5
      let cumul_dotations_amortissements_provision = 0; // ID 10

      if (this.moisExercice == null) {
        return sommesParMoisCumules;
      }
      

      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 1 (cat cumul_ca)
      const currentPrevis_cumul_ca = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 1
      );
 
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 6 (cat cumul_achats)
      const currentPrevis_cumul_achats = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 6
      );

      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 7 (cat cumul_frais_generaux)
      const currentPrevis_cumul_frais_generaux = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 7
      );
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 2 (cat cumul_subv_exploitation)
      const currentPrevis_cumul_subv_exploitation = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 2
      );
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 9 (cat cumul_masse_salariale)
      const currentPrevis_cumul_masse_salariale = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 9
      );
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 8 (cat cumul_impot)
      const currentPrevis_cumul_impot = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 8
      );
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 11 (cat cumul_charges_financieres)
      const currentPrevis_cumul_charges_financieres = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 11
      );
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 13 (cat cumul_autres_charges_financieres)
      const currentPrevis_cumul_autres_charges_financieres =
        this.currentPrevis.filter(
          (item) => item.budgetline_categorie_id === 13
        );
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 14 (cat cumul_autres_charges_exceptionnelles)
      const currentPrevis_cumul_autres_charges_exceptionnelles =
        this.currentPrevis.filter(
          (item) => item.budgetline_categorie_id === 14
        );
      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 4 (cat cumul_autres_produits_financiers)
      const currentPrevis_cumul_autres_produits_financiers =
        this.currentPrevis.filter((item) => item.budgetline_categorie_id === 4);

      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 5 (cat cumul_autres_produits_exceptionnels)
      const currentPrevis_cumul_autres_produits_exceptionnels =
        this.currentPrevis.filter((item) => item.budgetline_categorie_id === 5);

      // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 10 (cat dotations_amortissements_provision)
      const currentPrevis_cumul_dotations_amortissements_provision = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id === 10
      );        

      // Boucler à travers les mois du tableau moisExercice

      // console.log("Boucler à travers les mois du tableau moisExercice",this.moisExercice)

      for (const moisCourant of this.moisExercice) {

      //  console.log("/////////////////////////////////////////////////");
      //  console.log(moisCourant);
      //  console.log("/////////////////////////////////////////////////");

        // Calculer la somme des valeurs cumul_ca pour le mois courant
        let cumul_caMoisCourant = currentPrevis_cumul_ca.reduce(
          (somme, item) =>
            somme + item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
          0
        );
        // console.log("currentPrevis_cumul_ca",currentPrevis_cumul_ca);
        // console.log("cumul_caMoisCourant",cumul_caMoisCourant);

        // Calculer la somme des valeurs cumul_achats pour le mois courant
        let cumul_achatsMoisCourant = currentPrevis_cumul_achats.reduce(
          (somme, item) =>
            somme + item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
          0
        );
        // console.log("currentPrevis_cumul_achats",currentPrevis_cumul_achats)
        // console.log("cumul_achatsMoisCourant",cumul_achatsMoisCourant);        

        // Calculer la somme des valeurs cumul_frais_generaux pour le mois courant
        let cumul_frais_generauxMoisCourant =
          currentPrevis_cumul_frais_generaux.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );

        // console.log("currentPrevis_cumul_frais_generaux",currentPrevis_cumul_frais_generaux);
        // console.log("cumul_frais_generauxMoisCourant",cumul_frais_generauxMoisCourant);

        // Calculer la somme des valeurs cumul_subv_exploitation pour le mois courant
        let cumul_subv_exploitationMoisCourant =
          currentPrevis_cumul_subv_exploitation.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );
        // console.log("currentPrevis_cumul_subv_exploitation",currentPrevis_cumul_subv_exploitation); 
        // console.log("cumul_subv_exploitationMoisCourant",cumul_subv_exploitationMoisCourant);  

        // Calculer la somme des valeurs cumul_masse_salariale pour le mois courant
        let cumul_masse_salarialeMoisCourant =
          currentPrevis_cumul_masse_salariale.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );
        // console.log("currentPrevis_cumul_masse_salariale",currentPrevis_cumul_masse_salariale);    
        // console.log("cumul_masse_salarialeMoisCourant",cumul_masse_salarialeMoisCourant);  

        // Calculer la somme des valeurs cumul_impot pour le mois courant
        let cumul_impotMoisCourant = currentPrevis_cumul_impot.reduce(
          (somme, item) =>
            somme + item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
          0
        );
        // console.log("currentPrevis_cumul_impot",currentPrevis_cumul_impot); 
        // console.log("cumul_impotMoisCourant",cumul_impotMoisCourant);  

        // Calculer la somme des valeurs cumul_charges_financieres pour le mois courant
        let cumul_charges_financieresMoisCourant =
          currentPrevis_cumul_charges_financieres.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );
        // console.log("currentPrevis_cumul_charges_financieres",currentPrevis_cumul_charges_financieres);
        // console.log("cumul_charges_financieresMoisCourant",cumul_charges_financieresMoisCourant); 
        
        
        // Calculer la somme des valeurs cumul_autres_charges_financieres pour le mois courant
        let cumul_autres_charges_financieresMoisCourant =
          currentPrevis_cumul_autres_charges_financieres.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );
        // console.log("currentPrevis_cumul_autres_charges_financieres",currentPrevis_cumul_autres_charges_financieres)
        // console.log("cumul_autres_charges_financieresMoisCourant",cumul_autres_charges_financieresMoisCourant)

        // Calculer la somme des valeurs cumul_autres_charges_exceptionnelles pour le mois courant
        let cumul_autres_charges_exceptionnellesMoisCourant =
          currentPrevis_cumul_autres_charges_exceptionnelles.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );
        // console.log("currentPrevis_cumul_autres_charges_exceptionnelles",currentPrevis_cumul_autres_charges_exceptionnelles)  
        // console.log("cumul_autres_charges_exceptionnellesMoisCourant",cumul_autres_charges_exceptionnellesMoisCourant)


        // Calculer la somme des valeurs cumul_autres_produits_financiers pour le mois courant
        let cumul_autres_produits_financiersMoisCourant =
          currentPrevis_cumul_autres_produits_financiers.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );
        // console.log("currentPrevis_cumul_autres_produits_financiers",currentPrevis_cumul_autres_produits_financiers)  
        // console.log("cumul_autres_produits_financiersMoisCourant",cumul_autres_produits_financiersMoisCourant)

        // Calculer la somme des valeurs cumul_autres_produits_exceptionnels pour le mois courant
        let cumul_autres_produits_exceptionnelsMoisCourant =
          currentPrevis_cumul_autres_produits_exceptionnels.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );

        // console.log("currentPrevis_cumul_autres_produits_exceptionnels",currentPrevis_cumul_autres_produits_exceptionnels)  
        // console.log("cumul_autres_produits_exceptionnelsMoisCourant",cumul_autres_produits_exceptionnelsMoisCourant)


        // Calculer la somme des valeurs currentPrevis_cumul_dotations_amortissements_provision pour le mois courant
        let cumul_dotations_amortissements_provisionMoisCourant =
          currentPrevis_cumul_dotations_amortissements_provision.reduce(
            (somme, item) =>
              somme +
              item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
            0
          );

        // console.log("currentPrevis_cumul_dotations_amortissements_provision",currentPrevis_cumul_dotations_amortissements_provision)  
        // console.log("cumul_dotations_amortissements_provisionMoisCourant",cumul_dotations_amortissements_provisionMoisCourant)

        // Ajouter le cumul de la somme
        // console.log('cumul_ca',cumul_ca)
        // console.log('cumul_caMoisCourant',cumul_caMoisCourant)
        cumul_ca += cumul_caMoisCourant;
        // console.log('cumul_ca',cumul_ca)
        cumul_achats += cumul_achatsMoisCourant;
        cumul_frais_generaux += cumul_frais_generauxMoisCourant;
        cumul_subv_exploitation += cumul_subv_exploitationMoisCourant;
        cumul_masse_salariale += cumul_masse_salarialeMoisCourant;
        cumul_impot += cumul_impotMoisCourant;
        cumul_charges_financieres += cumul_charges_financieresMoisCourant;
        cumul_autres_charges_financieres += cumul_autres_charges_financieresMoisCourant;
        cumul_autres_charges_exceptionnelles += cumul_autres_charges_exceptionnellesMoisCourant;
        cumul_autres_produits_financiers += cumul_autres_produits_financiersMoisCourant;
        cumul_autres_produits_exceptionnels += cumul_autres_produits_exceptionnelsMoisCourant;
        cumul_dotations_amortissements_provision += cumul_dotations_amortissements_provisionMoisCourant;

        let resnet = parseFloat(
          cumul_ca -
            cumul_achats -
            cumul_frais_generaux +
            cumul_subv_exploitation -
            cumul_masse_salariale -
            cumul_impot -
            cumul_charges_financieres -
            cumul_autres_charges_financieres -
            cumul_autres_charges_exceptionnelles +
            cumul_autres_produits_financiers +
            cumul_autres_produits_exceptionnels -
            cumul_dotations_amortissements_provision
        );
        
        // console.log('cumul_ca - cumul_achats - cumul_frais_generaux + cumul_subv_exploitation - cumul_masse_salariale - cumul_impot - cumul_charges_financieres - cumul_autres_charges_financieres -cumul_autres_charges_exceptionnelles + cumul_autres_produits_financiers + cumul_autres_produits_exceptionnels');
        // console.log(cumul_ca +  "-" + cumul_achats +"-"+ cumul_frais_generaux +"+"+ cumul_subv_exploitation +"-"+ cumul_masse_salariale +"-"+ cumul_impot +"-"+ cumul_charges_financieres +"-"+ cumul_autres_charges_financieres +"-"+cumul_autres_charges_exceptionnelles +"+"+ cumul_autres_produits_financiers +"+"+ cumul_autres_produits_exceptionnels)
        // console.log('resnet',resnet)
        

        // Ajouter la somme au tableau sommesParMois
        sommesParMoisCumules.push(resnet);



      }

      // console.log("sommesParMoisCumules",sommesParMoisCumules);

      return sommesParMoisCumules;
    },

    get_resnet_reel_cumule(comptes) {
      let sommesMensuelles = {};
      let sommeSolde = 0;
      let valeursCumulees = [];

      if (this.moisExercice == null || this.balances.length == 0) {
        return valeursCumulees;
      }

      // Parcourir les moisExercice
      this.moisExercice.forEach((moisCourant) => {

        //console.log(moisCourant);
        // Si l'objet sommesMensuelles n'a pas encore de clé pour ce mois, utilisez la somme du mois précédent
        if (!sommesMensuelles[moisCourant]) {
          sommesMensuelles[moisCourant] = sommeSolde;
        }

        // on reinitialise sommeSolde à 0 (car les valeurs des balances sont déjà un cumul d'un mois sur l'autre)
        sommeSolde = 0;

        // Parcourir les balances
        /*if(moisCourant=='déc.22'){
          console.log("Parcourir les balances");
          console.log("Comptes recherchés",comptes)
        }*/
        this.balances.forEach((objet) => {
          let moisCloture = this.$moment(objet.cloture).format("MMMYY");
          if (moisCloture === moisCourant) {
            const solde = parseFloat(objet.solde);
            
            if (!isNaN(solde)) {
              comptes.forEach((numero) => {

                

                // cas d'un numero de compte standard
                if (!isNaN(numero)) {
                  if (objet.compte.startsWith(Math.abs(numero).toString())) {
                    /*if(moisCourant=='déc.22'){console.log("Compte trouvé "+objet.compte)}
                    if(moisCourant=='déc.22'){console.log("numero "+numero+" solde "+ solde)}
                    if(moisCourant=='déc.22' && numero < 0){console.log("AJOUT STANDARD : "+sommeSolde+'+'+-solde)}
                    if(moisCourant=='déc.22' && numero >= 0){console.log("AJOUT STANDARD : "+sommeSolde+'+'+solde)}*/
                    sommeSolde += numero < 0 ? -solde : solde;
                    //if(moisCourant=='déc.22'){console.log("CUMUL : "+sommeSolde)}
                  }
                  
                }

                // cas d'un numero de compte avec prefixe D
                // on prend uniquement la valeur absolue du solde s'il est positif,
                // RQ. attention ! ici tous les comptes avec prefix D sont ajoutés (+=)
                if (numero[0] == "D") {
                  if (objet.compte.startsWith(numero.slice(1)) && solde >= 0) {
                    /*if(moisCourant=='déc.22'){console.log("Compte trouvé "+objet.compte)}
                    if(moisCourant=='déc.22'){console.log("numero "+numero+" solde "+ solde)}
                    if(moisCourant=='déc.22'){console.log("AJOUT  prefixe D : "+sommeSolde+'+'+Math.abs(solde))}*/
                    sommeSolde += Math.abs(solde);
                    //if(moisCourant=='déc.22'){console.log("CUMUL : "+sommeSolde)}
                  }
                }

                // cas d'un numero de compte avec prefixe C
                //on prend uniquement la valeur absolue du solde s'il est negatif,
                // RQ. attention ! ici tous les comptes avec prefix C sont retranchés  (-=)
                if (numero[1] == "C") {
                  if (objet.compte.startsWith(numero.slice(1)) && solde <= 0) {
                    /*if(moisCourant=='déc.22'){console.log("Compte trouvé "+objet.compte)}
                    if(moisCourant=='déc.22'){console.log("numero "+numero+" solde "+ solde)}
                    if(moisCourant=='déc.22'){console.log("AJOUT  prefixe C : "+sommeSolde+'-'+Math.abs(solde))}*/
                    sommeSolde -= Math.abs(solde);
                    //if(moisCourant=='déc.22'){console.log("CUMUL : "+sommeSolde)}
                  }
                }
              });
            }
          }
        });

        // si la somme est nulle pour le mois en cour
        // on laisse la valeur qui a déjà été initialisée avec le mois précédent

        if (sommeSolde != 0) {
          sommesMensuelles[moisCourant] = sommeSolde;
          valeursCumulees.push(sommeSolde);
        } else {
          valeursCumulees.push(sommesMensuelles[moisCourant]);
          sommeSolde = sommesMensuelles[moisCourant];
        }
      });
      //console.log(valeursCumulees);
      return valeursCumulees;
    },
  },

  mounted() {
    this.loaded = true;
  },
};
</script>