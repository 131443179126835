<template>
    <!-- Le template -->
    <v-container fill-height>

        <v-row><v-col align="center" justify="center">
        <h1>Non Autorisé</h1> 
        <p>Votre profil ne vous permet pas d'accèder à cette page.<br>Pour y accéder, veuillez vous déconnecter et vous reconnecter avec le profil adéquat</p>
        </v-col></v-row>
       
    </v-container>
</template>

<script>
    // Le script
    export default {
        computed : {       },
        methods : {        }
    }
</script>