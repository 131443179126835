<!-- Le template -->
<template>
  <v-container :key="componentKey" fluid>
    <v-row class="mt-1">
      <v-col cols="12" class="d-flex justify-space-between align-center">
        <!--Choix du client -->

        <h3 class="mr-3 my-0">Suivi budgétaire cumulé :</h3>
        <v-spacer></v-spacer>
        <v-autocomplete
          class="mr-3 my-0"
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
          dense
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <!-- annee  -->

        <v-select
          v-if="selectedClient && !Loading"
          v-model="exercice"
          :items="exercices"
          item-text="annee"
          item-value="annee"
          label="Année du début d'exercice"
          persistent-hint
          return-object
          single-line
        ></v-select>
        <v-spacer></v-spacer>

        <!--Switch K€ -->
        <v-switch
          class="mr-3 my-0"
          dense
          label="K€"
          v-model="kilo_euro"
        ></v-switch>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="this.exercice && this.balances.length == 0"
      color="light-blue darken-1"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>

    <v-row v-if="!this.Loading && this.balances.length > 0">
      <v-col>
        <v-tabs v-model="monthtab" class="mb-8"
          ><!-- MENU onlgets horizontaux (mois) -->
          <v-tab v-for="(mois, indexmois) in moisExercice" :key="indexmois">{{
            mois
          }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="monthtab">
          <v-tab-item
            class="pa-8"
            v-for="(mois, indexmois) in moisExercice"
            :key="indexmois"
            ><!-- CONTENU onlgets horizontaux (mois)  -->
            <v-row>
              <v-col
                ><h4 class="mr-3 my-0" style="margin-left: 118px">
                  Situation au mois de {{ mois }}
                </h4></v-col
              >
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col
                    class="custombgcolor"
                    style="margin-left: 118px; margin-right: 14px"
                  >
                    <!-- RESULTAT Table  -->

                    <v-simple-table v-if="!Loading" fixed-header dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left"></th>
                            <th class="text-left"></th>
                            <th class="text-left"></th>
                            <th class="text-right">
                              Réalisé<br />N-1 (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              BP Annuel<br />Initial (<span v-if="kilo_euro"
                                >K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              BP Annuel<br />Révisé (<span v-if="kilo_euro"
                                >K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              Prévu (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              Réel (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-left">
                              Ecart (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="background-color: #00bcd4 !important">
                            <!--#43a047-->
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td class="text-right">
                              {{ colspace(38) }}Résultat{{ colspace(38) }}
                            </td>
                            <!--réalisé n-1 -->
                            <td class="text-right">
                              {{ format(ResultatNmoins1()) }}
                            </td>
                            <!--initial  -->
                            <td class="text-right">
                              {{ format(ResultatAnnuelInitial()) }}
                            </td>
                            <!--révisé -->
                            <td class="text-right">
                              {{ format(ResultatAnnuelRevise()) }}
                            </td>
                            <!--Prévu  -->
                            <td class="text-right">
                              {{ format(ResultatPrevu(mois)) }}
                            </td>
                            <!--Réel -->
                            <td class="text-right">
                              {{ format(ResultatReel(mois)) }}
                            </td>
                            <!--Ecart -->
                            <td>
                              {{
                                ecart(ResultatReel(mois), ResultatPrevu(mois))
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-col></v-row
            >

            <v-row>
              <v-col>
                <v-tabs vertical
                  ><!-- MENU onlgets vertical (produits|charges) -->
                  <v-tab>Produits</v-tab>
                  <v-tab>Charges</v-tab>
                  <!-- panels Produits-->
                  <v-tab-item class="pa-4 custombgcolor">
                    <v-row
                      ><!-- exports-->
                      <v-col class="d-flex justify-end">
                        <v-btn
                          @click="exportImage('situation_produits_' + mois)"
                          color="grey darken-3"
                          class="mr-1"
                        >
                          <v-icon dark left>mdi-file-image</v-icon>image</v-btn
                        >

                        <!-- EXPORTATION EXCEL :data="exportPrevis(mois,previs7)" -->

                        <export-excel
                          :data="previs7"
                          :name="'situation_produits_' + mois + '.xls'"
                          :fields="excelfields(indexmois + 1)"
                        >
                          <v-btn color="grey darken-3 "
                            ><v-icon dark left>mdi-file-export-outline</v-icon
                            >Excel {{ mois }}</v-btn
                          >
                        </export-excel>
                      </v-col>
                    </v-row>
                    <v-row
                      ><!-- datatable Produits -->
                      <v-col>
                        <v-simple-table fixed-header dark dense id="1"
                          ><!-- v-if=""-->
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left" style="padding: 5px">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-simple-checkbox
                                        v-model="selectallprevis7"
                                        v-on="on"
                                        dense
                                        hide-details
                                        :value="false"
                                        color="grey lighten-5"
                                        @click="
                                          toggleAllRowVisibility(
                                            selectallprevis7,
                                            previs7
                                          )
                                        "
                                      ></v-simple-checkbox>
                                    </template>
                                    <span
                                      >Tout Masquer / Tout Afficher (pour
                                      l'exportation en image)</span
                                    >
                                  </v-tooltip>
                                </th>
                                <th class="text-left">Comptes</th>
                                <th class="text-left">Intitulé</th>
                                <th class="text-right">
                                  Réalisé<br />N-1 (<span v-if="kilo_euro"
                                    >K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  BP Annuel<br />Initial (<span v-if="kilo_euro"
                                    >K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  BP Annuel<br />Révisé (<span v-if="kilo_euro"
                                    >K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  Prévu (<span v-if="kilo_euro">K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  Réel (<span v-if="kilo_euro">K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-left">
                                  Ecart (<span v-if="kilo_euro">K€</span
                                  ><span v-else>€</span>)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(lb, lbindex) in previs7"
                                :key="lbindex"
                                :class="lb.entete"
                              >
                                <td class="checkboxTD">
                                  <v-checkbox
                                    @click="
                                      toggleRowVisibility(previs7, lbindex)
                                    "
                                    :input-value="lb.show"
                                    value
                                    dense
                                    hide-details
                                    color="grey lighten-5"
                                  ></v-checkbox>
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    content-class="custom-tooltip"
                                    max-width="80%"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon dark v-bind="attrs" v-on="on"
                                        ><v-icon color="grey lighten-1"
                                          >mdi-information</v-icon
                                        ></v-btn
                                      >
                                    </template>
                                    <!-- comptes (etat, lbid, lbcategorie, lbclass)  -->
                                    <span>
                                      <span>Liste des comptes concernés :</span
                                      ><br />
                                      <span
                                        v-html="
                                          comptes(
                                            null,
                                            lb.id,
                                            lb.budgetline_categorie_id,
                                            7
                                          ).join(', ')
                                        "
                                      ></span>
                                    </span>
                                  </v-tooltip>
                                </td>
                                <td>{{ lb.intitule }}</td>

                                <!--balance réalisé n-1   -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb[
                                        "mois_" +
                                          (indexmois + 1) +
                                          "_realise_n_1"
                                      ]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--previs annuel initial -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb[
                                        "mois_" +
                                          (indexmois + 1) +
                                          "_bp_annuel_initial"
                                      ]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--previs annuel révisé -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb[
                                        "mois_" +
                                          (indexmois + 1) +
                                          "_bp_annuel_revise"
                                      ]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--previ mensuel -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb["mois_" + (indexmois + 1) + "_prevu"]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--Réel mensuel -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb["mois_" + (indexmois + 1) + "_reel"]
                                    )
                                  }}&nbsp;
                                </td>
                                <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                                <td>
                                  <v-icon
                                    v-if="
                                      lb[
                                        'mois_' + (indexmois + 1) + '_ecart'
                                      ] != '-'
                                    "
                                    :color="
                                      lb['mois_' + (indexmois + 1) + '_ecart'] <
                                      0
                                        ? 'red'
                                        : '#43a047'
                                    "
                                    >mdi-circle</v-icon
                                  >
                                  &nbsp;
                                  {{
                                    format(
                                      lb["mois_" + (indexmois + 1) + "_ecart"]
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                    <!-- Table pour exportation -->
                    <div style="position: absolute; z-index: -1">
                      <v-row
                        ><v-col>
                          <v-simple-table
                            fixed-header
                            light
                            dense
                            :id="'situation_produits_' + mois"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Intitulé</th>
                                  <th class="text-right">
                                    Réalisé<br />N-1 (<span v-if="kilo_euro"
                                      >K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    BP Annuel<br />Initial (<span
                                      v-if="kilo_euro"
                                      >K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    BP Annuel<br />Révisé (<span
                                      v-if="kilo_euro"
                                      >K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    Prévu (<span v-if="kilo_euro">K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    Réel (<span v-if="kilo_euro">K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-left">
                                    Ecart (<span v-if="kilo_euro">K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(lb, lbindexbis) in previs7">
                                  <tr
                                    :class="lb.entete"
                                    :key="lbindexbis"
                                    v-if="lb.show == true"
                                  >
                                    <td>{{ lb.intitule }}</td>
                                    <!--balance réalisé n-1   -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" +
                                              (indexmois + 1) +
                                              "_realise_n_1"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--previs annuel initial -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" +
                                              (indexmois + 1) +
                                              "_bp_annuel_initial"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--previs annuel révisé -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" +
                                              (indexmois + 1) +
                                              "_bp_annuel_revise"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--previ mensuel -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" + (indexmois + 1) + "_prevu"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--Réel mensuel -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" + (indexmois + 1) + "_reel"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                                    <td>
                                      <v-icon
                                        v-if="
                                          lb[
                                            'mois_' + (indexmois + 1) + '_ecart'
                                          ] != '-'
                                        "
                                        :color="
                                          lb[
                                            'mois_' + (indexmois + 1) + '_ecart'
                                          ] < 0
                                            ? 'red'
                                            : '#43a047'
                                        "
                                        >mdi-circle</v-icon
                                      >
                                      &nbsp;
                                      {{
                                        format(
                                          lb[
                                            "mois_" + (indexmois + 1) + "_ecart"
                                          ]
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                  <!-- panels Charges-->
                  <v-tab-item class="pa-4 custombgcolor">
                    <v-row
                      ><!-- exports-->
                      <v-col class="d-flex justify-end">
                        <v-btn
                          @click="exportImage('situation_charges_' + mois)"
                          color="grey darken-3"
                          class="mr-1"
                        >
                          <v-icon dark left>mdi-file-image</v-icon>image</v-btn
                        >

                        <!-- EXPORTATION EXCEL " -->

                        <export-excel
                          :data="previs6"
                          :name="'situation_charges_' + mois + '.xls'"
                          :fields="excelfields(indexmois + 1)"
                        >
                          <v-btn color="grey darken-3 "
                            ><v-icon dark left>mdi-file-export-outline</v-icon
                            >Excel {{ mois }}</v-btn
                          >
                        </export-excel>
                      </v-col>
                    </v-row>
                    <v-row
                      ><!-- datatable charge -->
                      <v-col>
                        <v-simple-table fixed-header dark dense id="1"
                          ><!-- v-if=""-->
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left" style="padding: 5px">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-simple-checkbox
                                        v-model="selectallprevis6"
                                        v-on="on"
                                        dense
                                        hide-details
                                        :value="false"
                                        color="grey lighten-5"
                                        @click="
                                          toggleAllRowVisibility(
                                            selectallprevis6,
                                            previs6
                                          )
                                        "
                                      ></v-simple-checkbox>
                                    </template>
                                    <span
                                      >Tout Masquer (pour l'exportation en
                                      image)</span
                                    >
                                  </v-tooltip>
                                </th>
                                <th class="text-left">Comptes</th>
                                <th class="text-left">Intitulé</th>
                                <th class="text-right">
                                  Réalisé<br />N-1 (<span v-if="kilo_euro"
                                    >K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  BP Annuel<br />Initial (<span v-if="kilo_euro"
                                    >K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  BP Annuel<br />Révisé (<span v-if="kilo_euro"
                                    >K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  Prévu (<span v-if="kilo_euro">K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-right">
                                  Réel (<span v-if="kilo_euro">K€</span
                                  ><span v-else>€</span>)
                                </th>
                                <th class="text-left">
                                  Ecart (<span v-if="kilo_euro">K€</span
                                  ><span v-else>€</span>)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(lb, lbindex) in previs6"
                                :key="lbindex"
                                :class="lb.entete"
                              >
                                <td class="checkboxTD">
                                  <v-checkbox
                                    @click="
                                      toggleRowVisibility(previs6, lbindex)
                                    "
                                    :input-value="lb.show"
                                    value
                                    dense
                                    hide-details
                                    color="grey lighten-5"
                                  ></v-checkbox>
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    content-class="custom-tooltip"
                                    max-width="80%"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon dark v-bind="attrs" v-on="on"
                                        ><v-icon color="grey lighten-1"
                                          >mdi-information</v-icon
                                        ></v-btn
                                      >
                                    </template>
                                    <!-- comptes (etat, lbid, lbcategorie, lbclass)  -->
                                    <span>
                                      <span>Liste des comptes concernés :</span
                                      ><br />
                                      <span
                                        v-html="
                                          comptes(
                                            null,
                                            lb.id,
                                            lb.budgetline_categorie_id,
                                            6
                                          ).join(', ')
                                        "
                                      ></span>
                                    </span>
                                  </v-tooltip>
                                </td>
                                <td>{{ lb.intitule }}</td>

                                <!--balance réalisé n-1   -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb[
                                        "mois_" +
                                          (indexmois + 1) +
                                          "_realise_n_1"
                                      ]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--previs annuel initial -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb[
                                        "mois_" +
                                          (indexmois + 1) +
                                          "_bp_annuel_initial"
                                      ]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--previs annuel révisé -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb[
                                        "mois_" +
                                          (indexmois + 1) +
                                          "_bp_annuel_revise"
                                      ]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--previ mensuel -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb["mois_" + (indexmois + 1) + "_prevu"]
                                    )
                                  }}&nbsp;
                                </td>
                                <!--Réel mensuel -->
                                <td class="text-right">
                                  {{
                                    format(
                                      lb["mois_" + (indexmois + 1) + "_reel"]
                                    )
                                  }}&nbsp;
                                </td>
                                <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                                <td>
                                  <v-icon
                                    v-if="
                                      lb[
                                        'mois_' + (indexmois + 1) + '_ecart'
                                      ] != '-'
                                    "
                                    :color="
                                      lb[
                                        'mois_' + (indexmois + 1) + '_ecart'
                                      ] <= 0
                                        ? '#43a047'
                                        : 'red'
                                    "
                                    >mdi-circle</v-icon
                                  >
                                  &nbsp;
                                  {{
                                    format(
                                      lb["mois_" + (indexmois + 1) + "_ecart"]
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                    <!-- Table pour exportation -->
                    <div style="position: absolute; z-index: -1">
                      <v-row
                        ><v-col>
                          <v-simple-table
                            fixed-header
                            light
                            dense
                            :id="'situation_charges_' + mois"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Intitulé</th>
                                  <th class="text-right">
                                    Réalisé<br />N-1 (<span v-if="kilo_euro"
                                      >K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    BP Annuel<br />Initial (<span
                                      v-if="kilo_euro"
                                      >K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    BP Annuel<br />Révisé (<span
                                      v-if="kilo_euro"
                                      >K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    Prévu (<span v-if="kilo_euro">K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-right">
                                    Réel (<span v-if="kilo_euro">K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                  <th class="text-left">
                                    Ecart (<span v-if="kilo_euro">K€</span
                                    ><span v-else>€</span>)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(lb, lbindexbis) in previs6">
                                  <tr
                                    :class="lb.entete"
                                    :key="lbindexbis"
                                    v-if="lb.show == true"
                                  >
                                    <td>{{ lb.intitule }}</td>
                                    <!--balance réalisé n-1   -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" +
                                              (indexmois + 1) +
                                              "_realise_n_1"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--previs annuel initial -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" +
                                              (indexmois + 1) +
                                              "_bp_annuel_initial"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--previs annuel révisé -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" +
                                              (indexmois + 1) +
                                              "_bp_annuel_revise"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--previ mensuel -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" + (indexmois + 1) + "_prevu"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!--Réel mensuel -->
                                    <td class="text-right">
                                      {{
                                        format(
                                          lb[
                                            "mois_" + (indexmois + 1) + "_reel"
                                          ]
                                        )
                                      }}&nbsp;
                                    </td>
                                    <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                                    <td>
                                      <v-icon
                                        v-if="
                                          lb[
                                            'mois_' + (indexmois + 1) + '_ecart'
                                          ] != '-'
                                        "
                                        :color="
                                          lb[
                                            'mois_' + (indexmois + 1) + '_ecart'
                                          ] <= 0
                                            ? '#43a047'
                                            : 'red'
                                        "
                                        >mdi-circle</v-icon
                                      >
                                      &nbsp;
                                      {{
                                        format(
                                          lb[
                                            "mois_" + (indexmois + 1) + "_ecart"
                                          ]
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <!-- fin onlgets horizontaux (mois) -->
      </v-col></v-row
    >
  </v-container>
</template>

<style scoped>
.custom-tooltip {
  opacity: 1 !important;
  background-color: rgba(97, 97, 97, 1) !important;
}

.custombgcolor {
  background-color: #1e4461;
}

/* 
alternance gris / blanc pour l'export en image des tableaux
tout en blanc cf recette 14/12
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
*/
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

/* .rang0 {font-weight: 200;} */

.rang0 ul {
  margin-bottom: 0px !important;
}

.entete-niv1 {
  background-color: #039be5 !important;
}

.rang3 {
  background-color: #43a047 !important;
}

.entete-niv3 {
  background-color: #00bcd4 !important; /* #1b5e20 */
}

.entete-niv2 {
  background-color: #086999 !important;
}

.rang4 td {
  padding: 20px !important;
}

.checkboxTD {
  width: 10px !important;
  padding: 5px 0 5px 5px !important;
}
</style>

<script>
import { EventBus } from "../../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  data() {
    return {
      monthtab: null,

      selectallprevis6: true,
      selectallprevis7: true,

      exercices: [],
      exercice: null,
      moisExercice: null,
      componentKey: 0,
      Loading: true,
      calcul_encours: true,
      pcgs: [],
      currentPrevis: [],
      balances: [],
      balances_annuelles: [],
      etatDeComparaison: null,

      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      kilo_euro: false,

      categories: [
        { intitule: "Chiffre d'affaires", id: 1, order: 1, classe: 6 },
        { intitule: "Subvention d'exploitation", id: 2, order: 2, classe: 6 },
        {
          intitule: "Autres produits d'exploitation",
          id: 3,
          order: 3,
          classe: 6,
        },
        { intitule: "Autres produits financiers", id: 4, order: 4, classe: 6 },
        {
          intitule: "Autres produits exceptionnels",
          id: 5,
          order: 5,
          classe: 6,
        },

        { intitule: "Achats", id: 6, order: 1, classe: 7 },
        { intitule: "Frais généraux", id: 7, order: 2, classe: 7 },
        {
          intitule: "Impôts, taxes et versements assimilés",
          id: 8,
          order: 3,
          classe: 7,
        },
        { intitule: "Masse salariale", id: 9, order: 4, classe: 7 },
        {
          intitule: "Dotations aux amortissements et provisions",
          id: 10,
          order: 5,
          classe: 7,
        },
        { intitule: "Charges financières", id: 11, order: 6, classe: 7 },
        {
          intitule: "Autres charges d'exploitation",
          id: 12,
          order: 7,
          classe: 7,
        },
        { intitule: "Autres charges financières", id: 13, order: 8, classe: 7 },
        {
          intitule: "Autres charges exceptionnelles",
          id: 14,
          order: 9,
          classe: 7,
        },
      ],

      //exportation_suivi : null,
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    previs6() {
      // pour la liste des lignes budgetaires on prend l'etat revise s'il existe, initial sinon
      let etat;
      if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
        etat = "revise";
      } else {
        etat = "initial";
      }

      // construction des lignes du tableau
      let previs6 = [];
      let previous_categorie_id = "";

      _.orderBy(
        _.filter(this.currentPrevis, { class: 6, etat: etat }),
        ["budgetline_categorie_id", "order"],
        ["asc", "asc"]
      ).forEach((element) => {
        // ajout des entetes niv1
        if (
          element.budgetline_categorie_id &&
          previous_categorie_id != element.budgetline_categorie_id
        ) {
          previs6.push({
            budgetline_categorie_id: element.budgetline_categorie_id,
            intitule: _.find(this.categories, {
              id: element.budgetline_categorie_id,
            }).intitule,
            show: true,
            entete: "entete-niv1",
            class: 6,
          });

          element.entete = null;
          element.show = true;
          element.class = 6;

          previs6.push(element);
        }
        // ajout entetes niv2
        else if (!element.budgetline_categorie_id) {
          element.intitule =
            "Autres Charges non affectées à une ligne budgétaire";
          element.show = true;
          element.entete = "entete-niv2";
          element.class = 6;
          previs6.push(element);
        }
        // ligne budgetaire sans entete
        else {
          element.entete = null;
          element.show = true;
          previs6.push(element);
        }

        previous_categorie_id = element.budgetline_categorie_id;
      });

      // ajout entete niv3 (TOTAL charges mensuel)
      previs6.push({
        budgetline_categorie_id: null,
        intitule: "Total charges",
        show: true,
        entete: "entete-niv3",
        class: 6,
      });

      //on ajoute le resultat des calculs

      previs6.forEach((lb, index) => {
        this.moisExercice.forEach((mois, index) => {
          let comptesAssocies = this.comptes(
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class,
            lb
          );

          if (comptesAssocies.length == 0) {
            lb["mois_" + (index + 1) + "_realise_n_1"] = 0;
          } else {
            lb["mois_" + (index + 1) + "_realise_n_1"] =
              this.SommeComptesBalance(comptesAssocies, null, lb);
          }

          // attention ici pour le calcul du bp initial
          // quand on parcours le previs révisé
          // bl.id doit etre recherché dans le previs initial

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv2
          // dont l'intitulé est null ainsi que budgetline_categorie_id

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv1
          // dont seul l'intitulé est null

          let lb_id_initial;

          if (lb.entete == "entete-niv2") {
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: null,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else if (lb.entete == "entete-niv1") {
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else {
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              class: lb.class,
              budgetline_categorie_id: lb.budgetline_categorie_id,
            });
          }

          // cas d'une somme de LB non trouvée dans previs initial
          if (lb.id && lb_id_initial == undefined) {
            lb["mois_" + (index + 1) + "_bp_annuel_initial"] = 0;
          }
          // cas d'une somme de LB trouvée dans previs initial
          if (lb.id && lb_id_initial) {
            lb["mois_" + (index + 1) + "_bp_annuel_initial"] =
              this.SommeComptesPrevis(
                "initial",
                null,
                lb_id_initial.id,
                lb_id_initial.budgetline_categorie_id,
                lb_id_initial.class,
                lb_id_initial
              );
          }
          // cas d'une somme de categorie
          if (!lb.id) {
            lb["mois_" + (index + 1) + "_bp_annuel_initial"] =
              this.SommeComptesPrevis(
                "initial",
                null,
                null,
                lb.budgetline_categorie_id,
                lb.class,
                lb
              );
          }

          // calcul bp annuel révisé

          if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
            lb["mois_" + (index + 1) + "_bp_annuel_revise"] =
              this.SommeComptesPrevis(
                "revise",
                null,
                lb.id,
                lb.budgetline_categorie_id,
                lb.class
              );
          } else {
            lb["mois_" + (index + 1) + "_bp_annuel_revise"] = 0;
          }

          // calcul prévu

          lb["mois_" + (index + 1) + "_prevu"] = this.SommeComptesPrevis(
            null,
            mois,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class
          );

          comptesAssocies = this.comptes(
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class
          );

          if (comptesAssocies.length == 0) {
            lb["mois_" + (index + 1) + "_reel"] = 0;
          } else {
            lb["mois_" + (index + 1) + "_reel"] = this.SommeComptesBalance(
              comptesAssocies,
              mois,
              lb
            );
          }

          lb["mois_" + (index + 1) + "_ecart"] = this.ecart(
            lb["mois_" + (index + 1) + "_reel"],
            lb["mois_" + (index + 1) + "_prevu"]
          );
        });
      });
      return previs6;
    },
    previs7() {
      // pour la liste des lignes budgetaires on prend l'etat revise s'il existe, initial sinon
      let etat;
      if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
        etat = "revise";
      } else {
        etat = "initial";
      }

      // construction des lignes du tableau
      let previs7 = [];
      let previous_categorie_id = "";

      _.orderBy(
        _.filter(this.currentPrevis, { class: 7, etat: etat }),
        ["budgetline_categorie_id", "order"],
        ["asc", "asc"]
      ).forEach((element) => {
        // ajout des entetes niv1
        if (
          element.budgetline_categorie_id &&
          previous_categorie_id != element.budgetline_categorie_id
        ) {
          previs7.push({
            budgetline_categorie_id: element.budgetline_categorie_id,
            intitule: _.find(this.categories, {
              id: element.budgetline_categorie_id,
            }).intitule,
            show: true,
            entete: "entete-niv1",
            class: 7,
          });

          element.entete = null;
          element.show = true;
          element.class = 7;

          previs7.push(element);
        }
        // ajout entetes niv2
        else if (!element.budgetline_categorie_id) {
          element.intitule =
            "Autres Produits non affectés à une ligne budgétaire";
          element.show = true;
          element.entete = "entete-niv2";
          element.class = 7;
          previs7.push(element);
        }
        // ligne budgetaire sans entete
        else {
          element.entete = null;
          element.show = true;
          previs7.push(element);
        }

        previous_categorie_id = element.budgetline_categorie_id;
      });

      // ajout entete niv3 (TOTAL produit mensuel)

      previs7.push({
        budgetline_categorie_id: null,
        intitule: "Total produits",
        show: true,
        entete: "entete-niv3",
        class: 7,
      });

      //on ajoute le resultat des calculs

      previs7.forEach((lb, index) => {
        this.moisExercice.forEach((mois, index) => {
          let comptesAssocies = this.comptes(
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class,
            lb
          );

          // calcul pour réalisé N-1
          if (comptesAssocies.length == 0) {
            lb["mois_" + (index + 1) + "_realise_n_1"] = 0;
          } else {
            lb["mois_" + (index + 1) + "_realise_n_1"] =
              this.SommeComptesBalance(comptesAssocies, null, lb);
          }

          // attention ici pour le calcul du bp initial
          // quand on parcours le previs révisé
          // bl.id doit etre recherché dans le previs initial

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv2
          // dont l'intitulé est null ainsi que budgetline_categorie_id

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv1
          // dont seul l'intitulé est null

          let lb_id_initial;

          if (lb.entete == "entete-niv2") {
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: null,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else if (lb.entete == "entete-niv1") {
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else {
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              class: lb.class,
              budgetline_categorie_id: lb.budgetline_categorie_id,
            });
          }

          // cas d'une somme de LB non trouvée dans previs initial
          if (lb.id && lb_id_initial == undefined) {
            lb["mois_" + (index + 1) + "_bp_annuel_initial"] = 0;
          }

          // cas d'une somme de LB trouvée dans previs initial
          if (lb.id && lb_id_initial) {
            lb["mois_" + (index + 1) + "_bp_annuel_initial"] =
              this.SommeComptesPrevis(
                "initial",
                null,
                lb_id_initial.id,
                lb_id_initial.budgetline_categorie_id,
                lb_id_initial.class,
                lb_id_initial
              );
          }
          // cas d'une somme de categorie
          if (!lb.id) {
            lb["mois_" + (index + 1) + "_bp_annuel_initial"] =
              this.SommeComptesPrevis(
                "initial",
                null,
                null,
                lb.budgetline_categorie_id,
                lb.class,
                lb
              );
          }

          // calcul bp_annuel_revise

          if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
            lb["mois_" + (index + 1) + "_bp_annuel_revise"] =
              this.SommeComptesPrevis(
                "revise",
                null,
                lb.id,
                lb.budgetline_categorie_id,
                lb.class
              );
          } else {
            lb["mois_" + (index + 1) + "_bp_annuel_revise"] = 0;
          }

          // calcul _prevu

          lb["mois_" + (index + 1) + "_prevu"] = this.SommeComptesPrevis(
            null,
            mois,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class
          );

          // calcul _reel

          comptesAssocies = this.comptes(
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class
          );
          if (comptesAssocies.length == 0) {
            lb["mois_" + (index + 1) + "_reel"] = 0;
          } else {
            lb["mois_" + (index + 1) + "_reel"] = this.SommeComptesBalance(
              comptesAssocies,
              mois,
              lb
            );
          }

          /*if(lb.intitule=="Mutualisation informatique"){
            console.log("Mutualisation informatique")
            console.log("calcul realise")
            console.log("comptes associes" , comptesAssocies , comptesAssocies.length, comptesAssocies.length == 0)
            console.log("this.SommeComptesBalance(comptesAssocies,mois,lb)",this.SommeComptesBalance(comptesAssocies,mois,lb))
            console.log("lb[mois_" + (index + 1) + "_reel]" , lb["mois_" + (index + 1) + "_reel"])
          }*/

          // calcul ecart
          lb["mois_" + (index + 1) + "_ecart"] = this.ecart(
            lb["mois_" + (index + 1) + "_reel"],
            lb["mois_" + (index + 1) + "_prevu"]
          );
        });
      });
      return previs7;
    },
  },

  watch: {
    kilo_euro(val) {
      EventBus.$emit("kilo_euroChanged", val);
    },

    selectedClient(val) {
      this.error = null;
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);
      this.moisExercice = null;
      this.exercices = [];
      this.exercice = null;
      this.balances = [];
      this.currentPrevis = [];
      this.pcgs = [];

      //console.log("client changed" + val )
      if (val != null) {
        this.getPcgs();
        this.getExcercices();
      }
    },
    exercice(val) {
      this.error = null;
      this.balances = [];
      this.getPeriodeExcercices();
      this.getCurrentPrevis();
      this.getbalances();
      this.getbalancesannuelles();
    },
  },

  methods: {
    ecart(reel, prevu) {
      if (isNaN(parseFloat(reel)) || isNaN(parseFloat(prevu))) {
        return 0;
      }

      return parseInt(parseInt(reel) - parseInt(prevu));
    },

    excelfields(indexmois) {
      return {
        Intitule: "intitule",
        "Réalisé N-1": "mois_" + indexmois + "_realise_n_1",
        "BP Annuel initial": "mois_" + indexmois + "_bp_annuel_initial",
        "BP Annuel révisé": "mois_" + indexmois + "_bp_annuel_revise",
        Prévu: "mois_" + indexmois + "_prevu",
        Réel: "mois_" + indexmois + "_reel",
        Ecart: "mois_" + indexmois + "_ecart",
      };
    },

    colspace(nbr) {
      if (nbr) {
        return "\xa0".repeat(nbr);
      }
      let longueurMax = 0;

      for (const objet of this.currentPrevis) {
        if (objet.intitule && objet.intitule.length > longueurMax) {
          longueurMax = objet.intitule.length;
        }
      }

      return "\xa0".repeat(longueurMax / 2);
    },

    /*
     * calcul des sommes dans le previs
     */

    SommeComptesPrevis(etat, mois, lbid, lbcategorie, lbclass, lb) {
      let filered_previs;
      let data = this.currentPrevis;

      // si aucun etat n'est transmis
      // on prend l'etat revise s'il existe
      // sinon on prend l'initial

      if (!etat) {
        if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
          etat = "revise";
        } else {
          etat = "initial";
        }
      }

      if (lbid) {
        // on filtre currentPrevis sur l'id de la LB
        filered_previs = _.filter(
          data,
          (item) => item.id == lbid && item.etat == etat
        );

        /*if(lb && lb.intitule=="Site internet trottinettes"){
          console.log("non filtré");
          console.log(this.currentPrevis)
          console.log("cas lbid existe => on filtre currentPrevis sur l'id ("+lbid+") de la LB")
          console.log("item.id == lbid && item.etat == etat")
          console.log(lbid,etat)
          console.log(filered_previs)
        }*/
      } else if (lbcategorie) {
        // on filtre currentPrevis sur l'id de la categorie
        filered_previs = _.filter(
          data,
          (item) =>
            item.budgetline_categorie_id == lbcategorie && item.etat == etat
        );
        /*if(lb && lb.intitule=="Site internet trottinettes"){
          console.log("cas lbcategorie existe => on filtre currentPrevis sur l'id de la categorie")
          console.log(filered_previs)
        }*/
      } else if (lbclass) {
        // on filtre currentPrevis sur l'id de la classe
        filered_previs = _.filter(
          data,
          (item) => item.class == lbclass && item.etat == etat
        );
        /*if(lb && lb.intitule=="Site internet trottinettes"){
          console.log("cas lbclass existe => on filtre currentPrevis sur l'id de la classe")
          console.log(filered_previs)
        }*/
      } else if (!lbid && !lbcategorie && !lbclass) {
        /*if(lb && lb.intitule=="Site internet trottinettes"){
          console.log("cas !lbid & !lbcategorie & !lbclass => on filtre currentPrevis sur l'etat")
          console.log(filered_previs)
        }*/

        // on filtre uniquement sur l'etat
        filered_previs = _.filter(data, (item) => item.etat == etat);
      }

      //index du mois
      let numeroduMois;

      if (mois) {
        numeroduMois = parseInt(
          _.findIndex(this.moisExercice, (m) => m === mois) + 1
        );
      }
      // si aucun mois n'est fourni la somme s'effectue sur la totalité des mois
      else {
        numeroduMois = this.moisExercice.length;
      }

      // Utilisez _.reduce pour calculer la somme de tous les mois de mois_1 à mois_x.
      // X etant le numeroduMois

      const sommeDesMois = _.reduce(
        filered_previs,
        (acc, objet) => {
          for (let i = 1; i <= numeroduMois; i++) {
            acc += objet[`mois_${i}`];
          }
          return acc;
        },
        0
      );

      /*if(lb && lb.intitule=="Site internet trottinettes"){
        console.log("numeroduMois,etat, mois, lbid, lbcategorie, lbclass,lb")
        console.log(numeroduMois,etat, mois, lbid, lbcategorie, lbclass,lb)
        console.log('filered_previs')
        console.log(filered_previs)
        console.log('sommeDesMois')
        console.log(sommeDesMois)
      }*/

      return sommeDesMois;
    },

    /*
     * calcul du resultat reel
     */
    ResultatReel(mois) {
      let filered_balance_produits = _.filter(this.balances, (item) => {
        return item.subcompte1 == 7;
      });
      let filered_balance_charges = _.filter(this.balances, (item) => {
        return item.subcompte1 == 6;
      });

      // Obtenez l'index du mois spécifié dans la liste des moisExercice.
      const indexMois = this.moisExercice.indexOf(mois);

      // filtrer les balances selon le mois
      // si le mois actuel n'est pas dans la balance on prend le mois antérieur
      // jusqu'a ce qu'une balance soit trouvée

      let data;
      // Parcourez les mois en partant du mois spécifié vers les mois antérieurs jusqu'à ce que des données soient trouvées.
      for (let i = indexMois; i >= 0; i--) {
        const moisCourant = this.moisExercice[i];

        // Utilisez _.filter pour filtrer la collection en fonction du mois de "cloture".
        data = _.filter(this.balances, (objet) => {
          const moisCloture = this.$moment(objet.cloture)
            .format("MMMYY")
            .toLowerCase(); // Convertir la date en format "janv.22", "févr.22", ...
          return moisCloture === moisCourant;
        });

        // Si data.length est supérieur à 0, nous avons trouvé des données correspondant au mois courant, sortez de la boucle.
        if (data.length > 0) {
          break;
        }
      }

      // s'il n'y a pas de balance on retourne "-"
      if (data.length == 0) {
        return 0;
      }

      let totalProduits;
      let totalCharges;

      totalProduits = _.sumBy(data, (obj) => {
        if (obj.subcompte1 == 7) {
          return -parseFloat(obj.solde);
        }
      });

      totalCharges = _.sumBy(data, (obj) => {
        if (obj.subcompte1 == 6) {
          return parseFloat(obj.solde);
        }
      });

      if (isNaN(totalProduits) || isNaN(totalCharges)) {
        return 0;
      }

      return parseFloat(totalProduits - totalCharges);
    },

    /*
     * calcul du resultat prevu
     */
    ResultatPrevu(mois) {
      let etat;
      // si un previs revise existe sinon on considère le previs initial
      if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
        etat = "revise";
      } else {
        etat = "initial";
      }

      let filered_previs_produits = _.filter(this.currentPrevis, (item) => {
        return item.etat == etat && item.class == 7;
      });
      let filered_previs_charges = _.filter(this.currentPrevis, (item) => {
        return item.etat == etat && item.class == 6;
      });

      //index du mois
      let numeroduMois = parseInt(
        _.findIndex(this.moisExercice, (m) => m === mois) + 1
      );

      // Utilisez _.reduce pour calculer la somme de tous les mois de mois_1 à mois_x.
      // X etant le numeroduMois

      const TotalCharges = _.reduce(
        filered_previs_charges,
        (acc, objet) => {
          for (let i = 1; i <= numeroduMois; i++) {
            acc += objet[`mois_${i}`];
          }
          return acc;
        },
        0
      );

      const TotalProduits = _.reduce(
        filered_previs_produits,
        (acc, objet) => {
          for (let i = 1; i <= numeroduMois; i++) {
            acc += objet[`mois_${i}`];
          }
          return acc;
        },
        0
      );

      if (isNaN(TotalProduits) || isNaN(TotalCharges)) {
        return 0;
      }

      return parseFloat(TotalProduits - TotalCharges);
    },

    /*
     * calcul du resultat annuel revise
     */
    ResultatAnnuelRevise() {
      const TotalCharges = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "revise" && obj.class == 6) {
          return parseFloat(obj.total);
        }
      });

      const TotalProduits = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "revise" && obj.class == 7) {
          return parseFloat(obj.total);
        }
      });

      if (isNaN(TotalProduits) || isNaN(TotalCharges)) {
        return 0;
      }

      return parseFloat(TotalProduits - TotalCharges);
    },
    /*
     * calcul du resultat annuel initial
     */
    ResultatAnnuelInitial() {
      const TotalCharges = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "initial" && obj.class == 6) {
          return parseFloat(obj.total);
        }
      });

      const TotalProduits = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "initial" && obj.class == 7) {
          return parseFloat(obj.total);
        }
      });

      if (isNaN(TotalProduits) || isNaN(TotalCharges)) {
        return 0;
      }

      return parseFloat(TotalProduits - TotalCharges);
    },
    /*
     * calcul du resultat N-1
     */
    ResultatNmoins1() {
      const TotalCharges = _.sumBy(this.balances_annuelles, (obj) => {
        if (obj.subcompte1 == 6) {
          return parseFloat(obj.solde);
        }
      });

      const TotalProduits = _.sumBy(this.balances_annuelles, (obj) => {
        if (obj.subcompte1 == 7) {
          return -parseFloat(obj.solde);
        }
      });

      if (isNaN(TotalProduits) || isNaN(TotalCharges)) {
        return 0;
      }

      return parseFloat(TotalProduits - TotalCharges);
    },

    /*
     * calcul des sommes dans la balance de comptes associés
     */
    SommeComptesBalance(comptes, mois, lb) {
      // mois abrv.fr avec annee sur 2 chiffres ex. juil.22

      //console.log(comptes)
      //console.log(mois)
      //console.log(lb)
      //console.log('calcul pour realise N-1')
      //console.log('this.balances_annuelles.length',data.length)

      let data; // collection sur laquelle la somme sera calculée

      // somme depuis la balance annuelle
      if (!mois) {
        data = this.balances_annuelles;
      }
      // somme depuis la balance mensuelle
      else {
        // filtrer les balances selon le mois
        // si le mois actuel n'est pas dans la balance on prend le mois antérieur
        // jusqu'a ce qu'une balance soit trouvée

        //if(mois=="mars22" && lb && lb.intitule=="Chiffre d'affaires"){console.log(lb)}

        // Obtenez l'index du mois spécifié dans la liste des moisExercice.
        const indexMois = this.moisExercice.indexOf(mois);

        // Parcourez les mois en partant du mois spécifié vers les mois antérieurs jusqu'à ce que des données soient trouvées.
        for (let i = indexMois; i >= 0; i--) {
          const moisCourant = this.moisExercice[i];

          // Utilisez _.filter pour filtrer la collection en fonction du mois de "cloture".
          data = _.filter(this.balances, (objet) => {
            const moisCloture = this.$moment(objet.cloture)
              .format("MMMYY")
              .toLowerCase(); // Convertir la date en format "janv.22", "févr.22", ...
            return moisCloture === moisCourant;
          });

          // Si data.length est supérieur à 0, nous avons trouvé des données correspondant au mois courant, sortez de la boucle.
          if (data.length > 0) {
            break;
          }
        }
      }

      // s'il n'y a pas de balance on retourne "-"
      if (data.length == 0) {
        return 0;
      }

      // si le calcul s'effectue sur les lignes
      // "Autres Charges non affectées à une ligne budgétaire"
      // ou
      // "Autres Produits non affectés à une ligne budgétaire"
      // alors nous ne devons pas tenir compte du radical

      let sommeTotale;

      if (
        lb &&
        (lb.intitule == "Autres Produits non affectés à une ligne budgétaire") |
          (lb.intitule == "Autres Charges non affectées à une ligne budgétaire")
      ) {
        // Utilisez la méthode "reduce" pour calculer la somme en fonction des comptes spécifiés.
        // sans tenir compte du radical
        // pour les produits (7) la somme s'effectue sur -solde
        // pour les charges (6) la somme s'effectue sur +solde

        sommeTotale = data.reduce((acc, obj) => {
          // Utilisez la méthode "find" pour rechercher l'objet ayant le même compte dans la liste des comptes spécifiés.
          const compteSpecifie = comptes.find(
            (compte) => compte === obj.compte.toString()
          );

          // Si l'objet est trouvé dans la liste des comptes spécifiés, effectuez la somme.
          if (compteSpecifie) {
            const solde = parseFloat(obj.solde); // Convertir le solde en nombre décimal

            //console.log("compte" + obj.compte + "solde : " + solde);

            if (obj.subcompte1 == "7") {
              acc -= solde;
            } else if (obj.subcompte1 == "6") {
              acc += solde;
            }

            //console.log("acc" + acc);
          }
          return acc;
        }, 0);
      } else if (lb && lb.intitule == "Total charges") {
        sommeTotale = _.sumBy(data, (obj) => {
          if (obj.subcompte1 == 6) {
            return parseFloat(obj.solde);
          }
        });
      } else if (lb && lb.intitule == "Total produits") {
        sommeTotale = _.sumBy(data, (obj) => {
          if (obj.subcompte1 == 7) {
            return -parseFloat(obj.solde);
          }
        });
      } else {
        // Utilisez la méthode "reduce" pour calculer la somme en fonction des comptes spécifiés.
        // en tenant compte du radical ex: si 625100000 est dans la liste alors 625110000 doit aussi être
        // pris en compte dans la somme.
        // pour les produits (7) la somme s'effectue sur -solde
        // pour les charges (6) la somme s'effectue sur +solde

        sommeTotale = data.reduce((acc, obj) => {
          // Utilisez la méthode "find" pour rechercher un compte spécifié qui a le même radical que l'objet en cours.
          const compteSpecifie = comptes.find((compte) =>
            obj.compte.toString().startsWith(compte.replace(/0+$/, ""))
          );

          // Si l'objet est trouvé dans la liste des comptes spécifiés, effectuez la somme.
          if (compteSpecifie) {
            const solde = parseFloat(obj.solde); // Convertir le solde en nombre décimal

            //console.log("compte" + obj.compte + "solde : " + solde);

            if (obj.subcompte1 == "7") {
              acc -= solde;
            } else if (obj.subcompte1 == "6") {
              acc += solde;
            }

            //console.log("acc" + acc);
          }
          return acc;
        }, 0);
      }

      return Math.round(sommeTotale);
    },

    /*
     * Liste des comptes associés
     */
    comptes(etat, lbid, lbcategorie, lbclass, lb) {
      let filered_previs;

      // si aucun etat n'est transmis on prend l'etat revise s'il existe
      // sinon on prend l'initial

      if (!etat) {
        if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
          etat = "revise";
        } else {
          etat = "initial";
        }
      }

      const data = this.currentPrevis;

      if (lbid) {
        // on filtre currentPrevis sur l'id de la LB
        filered_previs = _.filter(
          data,
          (item) => item.id == lbid && item.etat == etat
        );
      } else if (lbcategorie) {
        // on filtre currentPrevis sur l'id de la categorie
        filered_previs = _.filter(
          data,
          (item) =>
            item.budgetline_categorie_id == lbcategorie && item.etat == etat
        );
      } else if (lbclass) {
        // on filtre currentPrevis sur l'id de la classe
        filered_previs = _.filter(
          data,
          (item) => item.class == lbclass && item.etat == etat
        );
      } else if (!lbid && !lbcategorie && !lbclass) {
        // on filtre uniquement sur l'etat
        filered_previs = _.filter(data, (item) => item.etat == etat);
      }

      const numerosDeCompte = filered_previs.flatMap((item) =>
        item.comptes.map((compte) => compte.compte)
      );

      /*if(lb){
      console.log(etat, lbid, lbcategorie, lbclass);
      }*/
      // console.log(numerosDeCompte);

      return numerosDeCompte;
    },

    /*
     * Chargement du previs
     */

    getCurrentPrevis() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/suivibudgetaire-getprevis/" +
              this.selectedClient.id +
              "/" +
              this.exercice
          )
          .then((response) => {
            console.log("/api/getprevis/ OK");
            this.currentPrevis = response.data;
            this.currentPrevis = _.orderBy(
              this.currentPrevis,
              ["etat", "budgetline_categorie_id", "order"],
              ["asc", "asc", "asc"]
            );
            this.Loading = false;

            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getprevis/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    /*
     * Liste des mois de l'excercice
     */

    getPeriodeExcercices() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/exerciceperiode/" +
              this.selectedClient.id +
              "/" +
              this.exercice
          )
          .then((response) => {
            console.log("/api/exerciceperiode/ OK");
            this.moisExercice = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceperiode/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    /*
     * Liste des exercices
     */

    getExcercices() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/exerciceschoice-suivi/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/exerciceschoice-suivi/ OK");
            this.exercices = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceschoice-suivi/ KO", err);
            console.log(err);

            this.Loading = false;
            reject(err);
          });
      });
    },
    /*
     * PCG du client selectionné
     */

    getPcgs() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/getpcgs/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/getpcgs/ OK");
            this.pcgs = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getpcgs/ KO", err);
            console.log(err);
            this.Loading = false;
            reject(err);
          });
      });
    },
    /*
     * chargement des balances annuelles
     */

    getbalancesannuelles() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/suivibudgetaire-balanceannuelle/" +
              this.selectedClient.id +
              "/" +
              parseInt(this.exercice - 1)
          )
          .then((response) => {
            console.log("/api/suivibudgetaire-balanceannuelle/ OK");
            this.balances_annuelles = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/suivibudgetaire-balanceannuelle/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },
    /*
     * chargement des balances mensuelles
     */

    getbalances() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/suivibudgetaire-balancemensuelle/" +
              this.selectedClient.id +
              "/" +
              this.exercice
          )
          .then((response) => {
            console.log("/api/suivibudgetaire-balancemensuelle/ OK");
            this.balances = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/suivibudgetaire-balancemensuelle/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    // toggleAllRowVisibility pour afficher masquer toutes les lignes
    // dans l'exporation image d'un tableau

    toggleAllRowVisibility(booleanValue, targetData) {
      _.map(targetData, function (obj) {
        obj.show = booleanValue;
      });

      this.$forceUpdate();
    },
    // toggleRowVisibility pour afficher masquer une ligne
    // avec ses enfants selon les cas
    // target = jeux de donnée cible
    // row = identifiant de la ligne

    toggleRowVisibility(target, index) {
      let i = index;
      const initialstate = target[index].show;

      // si pas une entete ou si "entete-niv2" ou si "entete-niv3"
      // on ne s'occupe pas des enfants

      if (!target[index].entete) {
        target[i].show = !target[i].show;
        this.$forceUpdate();
        return;
      }

      if (
        target[index].entete == "entete-niv2" ||
        target[index].entete == "entete-niv3"
      ) {
        target[i].show = !target[i].show;
        this.$forceUpdate();
        return;
      }

      // si entete = entete-niv1
      // on masque tout les enfants

      do {
        target[i].show = !initialstate;
        i++;
      } while (
        target[i] &&
        target[i].budgetline_categorie_id ==
          target[index].budgetline_categorie_id
      );

      this.$forceUpdate();
      return;
    },

    exportImage(Elementid) {
      var node = document.getElementById(Elementid);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = Elementid + ".png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    /*
     * format value in K€
     */
    format(value) {
      if (isNaN(parseFloat(value))) {
        return value;
      }
      // formatage en K€ on préfère que les montants soient arrondis à l’unité cf recette du 31/03/2023 et mail du 12/04
      return this.kilo_euro == true
        ? parseFloat(value / 1000).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : parseFloat(value).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            // console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },
  },

  mounted() {
    this.getClients().then(() => {
      // recuperation du client selectionné sauvegardé en local storage
      // mise a jour du local storage si entretemps un paramétrage client a été mis à jour

      if (
        localStorage.selectedClient &&
        localStorage.selectedClient != "null"
      ) {
        var selectedClientInlocalstorage = JSON.parse(
          localStorage.selectedClient
        );
        this.selectedClient = _.find(this.clients, {
          id: selectedClientInlocalstorage.id,
        });
        localStorage.selectedClient = JSON.stringify(this.selectedClient);
      }
    });
  },
};
</script>