<template>
  <!-- Le template -->
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col align="center" justify="center" cols="6">
    <v-card class="elevation-12">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Identification</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear
        :active="isLoading"
        color="red"
        :indeterminate="true"
      />
      <v-card-text>
        <v-alert
          v-if="formErrors.length != 0"
          type="error"
          close-text="Fermer"
          dismissible
          ><ul>
          <li v-for="item in formErrors" :key="item.message">
          {{ item.message }}</li>
          </ul>
          </v-alert
        >

        <v-form method="post" autocomplete="no" ref="form" :value="validform">
          <v-text-field
            autocomplete="email"
            autofocus
            label="Login"
            name="email"
            prepend-icon="mdi-at"
            type="text"
            :error-messages="emailError"
            v-model="credentials.email"
            @keyup.enter.prevent="loginPost"
          />
          <v-text-field
            autocomplete="current-password"
            id="password"
            label="Password"
            name="password"
            prepend-icon="mdi-lock"
            type="password"
            v-model="credentials.password"
            :error-messages="passwordError"
            @keyup.enter.prevent="loginPost"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <a href="auth/password/reset">Mot de passe oublié ?</a>
        <v-spacer />
        <v-btn x-large color="primary" @click.prevent="loginPost" :disabled="!validform">CONNEXION</v-btn>
      </v-card-actions>
    </v-card>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
// Le script
export default {

  data() {
    return {
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),

      credentials: {
        email: "",
        password: "",
        remember: false,
      },

      emailError: null,
      passwordError: null,
      formErrors: [],
      isLoading: false,
    };
  },
  computed: {

      validform : function(){
          if(!this.credentials.email || this.credentials.email=="" || !this.credentials.password || this.credentials.password==""){
              return false
          }
          return true
      },
    
  },
  methods: {
    loginPost() {

       
      this.formErrors = [];
      this.isLoading = true;
      this.$store.dispatch('currentUser/loginUser',this.credentials)
            .then(() => {
              this.isLoading = false; 
                                       
              })
            .catch(err => {
              this.isLoading = false;
              for (const [key,value] of Object.entries(err.response.data.errors)) {
                this.formErrors.push({message:  value[0]});
              } 
              this.credentials.email = null;
              this.credentials.password = null;              
              
            })        
    },
  },
};
</script>