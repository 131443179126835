<!-- Le template -->
<template>
  <v-container :key="componentKey">
    <v-row>
      <v-col class="d-flex justify-end pb-5 align-center">
        <!--Switch K€ -->
        <v-switch class="mr-3 my-0 align-center" dense label="K€" v-model="kilo_euro"></v-switch>
        <!--Export image -->
        <v-btn @click="exportImage('plan-de-tresorerie')" color="grey darken-3" class="mr-1">
          <v-icon dark left>mdi-file-image</v-icon>image
        </v-btn>
        <!--Export excel -->
        <export-excel v-if="!loading" :data="pts" name="plan-de-tresorerie.xls" :fields="set_excelHeaders() ">
          <v-btn color="grey darken-3 ">
            <v-icon dark left>mdi-file-export-outline</v-icon>
            Excel
          </v-btn>
        </export-excel>
      </v-col>

    </v-row>

    <v-progress-linear v-if="loading" color="light-blue darken-1" indeterminate rounded
      height="6"></v-progress-linear>

    <!-- PLAN treso Visible Table -->
    <v-simple-table v-if="!loading" fixed-header dark dense id="pttable" :key="resultatDatatableKey">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="padding: 5px">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-checkbox v-model="selectall" @click="toggleAllRowVisibility(selectall, pts)" v-on="on"
                    true-value="1" false-value="0" dense hide-details color="grey lighten-5"></v-checkbox>
                </template>
                <span>Tout Masquer (pour l'exportation en image)</span>
              </v-tooltip>
            </th>
            <th class="text-left">Intitulé</th>
            <th v-for="(month,index) in mois_exercice_entete" class="text-right border-left" :key="'tabannee'+index">              
              {{month}}<br><span v-if="kilo_euro">K</span>€
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(pt,ptindex) in pts" >
          <tr :key="ptindex">
            <td class="checkboxTD">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-checkbox v-model="pts[ptindex].show" :input-value="pts[ptindex].show" @click="
                toggleRowVisibility(
                  pts[ptindex].show,
                  pts,
                  ptindex
                )
              " true-value="1" false-value="0" v-on="on" dense hide-details color="grey lighten-5"></v-checkbox>
                </template>
                <span>Masquer cette ligne pour l'exportation en image</span>
              </v-tooltip>
            </td>
            <td v-if="pt.niv==4">&nbsp;&nbsp;{{pt.intitule}}</td>
            <td v-else>{{pt.intitule}}</td>   

            <template v-for="(month,index) in mois_exercice" >
              <td :key="'montant'+index" class="text-right value border-left" >
                <span>{{format(pt['mois_'+(index+1)],pt)}}</span>
              </td>            
            </template>
          </tr>
          <!--Ajout ligne vierge -->
          <tr v-if="pt.order==78 || pt.order==79 || pt.order==89" :key="ptindex+'espace'">
            <td></td>
            <td></td>
            <template v-for="(month,index) in mois_exercice" >
              <td :key="'montant'+index">
              </td>            
            </template>
          </tr>          
        </template>
       
        </tbody>
      </template>
    </v-simple-table>

    <!-- pt Export Image Table -->
    <div style="position: absolute; z-index: -1">
      <v-simple-table id="plan-de-tresorerie" v-if="!loading" light dense class="pb-3 imgtable" style="overflow-x: auto !important" :key="exportResultatDatatableKey">
        <template v-slot:default>
          <thead style="overflow-x: auto !important">
            <tr style="background-color: #0c6682">
              <th class="text-left" style="color: #ffffff !important">
                Intitulé
              </th>
            <th v-for="(month,index) in mois_exercice_entete" class="text-right border-left" :key="'imagetable'+index" 
            style="color: #ffffff !important" >             
              {{month}}<br><span v-if="kilo_euro">K</span>€
            </th>              
            </tr>
          </thead>
          <tbody style="overflow-x: auto !important">
            <template v-for="(pt, ptindex) in pts">
              <tr v-if="pts[ptindex].show == '1'" :key="'intitule'+ptindex" :class="`niv` + pt.niv">
                <td class='intitule'>
                  {{pt.intitule}}                                   
                </td>
            <template v-for="(month,index) in mois_exercice" >
              <td :key="'imagetablemontant'+index" class="text-right value border-left" >
                <span>{{format(pt['mois_'+(index+1)],pt)}}</span>
              </td>            
            </template>
              </tr>
          <!--Ajout ligne vierge  -->
          <tr v-if="pt.order==78 || pt.order==79 || pt.order==89"  :key="ptindex+'espace'">
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <template v-for="(month,index) in mois_exercice" >
              <td :key="'montant'+index">&nbsp;</td>            
            </template>
          </tr>                
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-container>
</template>
<style>
.imgtable td,
.imgtable th {
  height: auto !important;
  line-height: 1.3 !important;
}

/* 
alternance gris / blanc pour l'export en image des tableaux
tout en blanc cf recette 14/12

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
*/

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

.niv1 {
  background-color: #1ab7ea !important;
}
.niv1 td {
  padding: 15px !important;
}

.niv2 {
  background-color: #a6a6a6 !important;
}
.niv4 td {  
  font-size: 0.9em !important;
}
.niv4 .intitule {padding-left:30px !important;}



.checkboxTD {
  width: 10px !important;
  padding: 5px 0 5px 5px !important;
}

.br_header {
  width: 220px;
}
.euro_header {
  width: 100px;
}

.border-left {
  border-left: 1px solid gray;
}
</style>

<script>
import { EventBus } from "../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  props: {
    selectedClient: Object,
    annee: Number,
    api_endpoint_url: String
  },
  data() {
    return {
     
      resultatDatatableKey: 0,
      exportResultatDatatableKey: 0,
      componentKey: 0,
      loading: true,
      kilo_euro: false,
      mois_exercice : [],
      mois_exercice_entete : [],
      

      pts: [], // plan de financement par année

      selectall: "1",

    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    years(){ // prendra les valeurs des 5 dernière annee dispo si annee = null
      
      if(this.annee != null ){ 
        return [this.annee]
      }
      else{
      const last5Years = Object.keys(this.pts[0])
          .filter(key => key.startsWith('montant_'))
          .map(key => key.split('_')[1]);

        return last5Years
      }

    }, 
  },

  watch: {
    kilo_euro(val) {
      EventBus.$emit("kilo_euroChanged", val);
    },

  },

  methods: {
    refresh() {
      //this.$forceUpdate()
      this.resultatDatatableKey++;
      this.exportResultatDatatableKey++;

    },

    // toggleAllRowVisibility pour afficher masquer toutes les lignes
    // dans l'exporation image d'un tableau

    toggleAllRowVisibility(state, targetData) {
      
      _.map(targetData, function (obj) {
        obj.show = state;
      });
      this.refresh();
    },


    toggleRowVisibility(state, target, index) {

      let i = index;
      target[index].show = state;
      
      this.refresh();
    },

    exportImage(tab) {
      var node = document.getElementById(tab);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = tab + ".png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    set_excelHeaders() {
      var headers = { intitule: "intitule" };
      this.mois_exercice_entete.forEach((month, index) => {
        headers[month] = "mois_" + (index+1);
      });
      return headers;
    },

    /*
     * format value in K€
     */
    format(value, item) {

      // formatage en K€ on préfère que les montants soient arrondis à l’unité cf recette du 31/03/2023 et mail du 12/04
      return this.kilo_euro == true
        ? parseFloat(value / 1000).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : parseFloat(value).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    },


    /*
     * load plan tresorerie
     */

    get_pts() {
      this.loading = true;
      this.pts = [];

      let payload = {
        client_id: this.selectedClient.id,
        annee: this.annee,
      };

      // console.log("payload",payload)

      return new Promise((resolve, reject) => {
        axios
          .post(this.api_endpoint_url, payload)
          .then((response) => {

          // suppression des mois non concernés par la période d'exercice du client
          const dateDebutExercice = this.$moment(this.selectedClient.debut_exercice);
          const dateFinExercice = this.$moment(this.selectedClient.fin_exercice);
          
          // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
          // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 12 mois

          //console.log("this.annee ",this.annee ,"dateFinExercice.year()",dateFinExercice.year(),"this.annee > dateFinExercice.year()",this.annee > dateFinExercice.year())
          let nbrMonths = 0 ;

          if(this.annee > dateFinExercice.year()){nbrMonths = 12;}
          else{nbrMonths = dateFinExercice.diff(dateDebutExercice, 'months') + 1;}
          

          // liste des mois concernés
          for (let i=0; i< nbrMonths;i++){
            this.mois_exercice.push(dateDebutExercice.clone().add(i,'months').format('MMM'));
            this.mois_exercice_entete.push(dateDebutExercice.clone().add(i,'months').format('MMM YYYY'));
          }

          //Supprimer les propriétés mois_x inutiles :
          response.data.forEach(item => {
            for (let i = nbrMonths+1; i <= 23; i++) {
              delete item[`mois_${i}`];
            }
          });

            
            // ajout de la propriete show et niveau d'entete
            // niv1 : fond bleu
            // niv2 : fond gris
            // niv3 : default
            // niv4 : legère indentation

            this.pts = _.orderBy(response.data, ["order"], ["asc"]);
            _.map(this.pts, function (obj) {
              obj.show = "1";
              obj.niv = "3";

              //niv 1 fond bleu pour les orders suivants
              const niv1 =  [1,90,91,95]
              //niv 2 fond gris pour les orders suivants               
              const niv2 =  [7,21,18,61,75,78]
              //niv 4 indentation pour les orders suivants  
              const niv4 =  [23,24,25,26,27,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,63,64,65,81,82,83,85,86,87,88,89] 

              if(niv1.includes(obj.order)){
                obj.niv = "1";
              }
              if(niv2.includes(obj.order)){
                obj.niv = "2";
              }
              if(niv4.includes(obj.order)){
                obj.niv = "4";
              }

            });

            this.loading = false;
            resolve();
          })
          .catch((err) => {
            this.loading = false;

            reject(err);
          });
      });
    },


  },

  mounted() {

    this.get_pts();
    EventBus.$on("ptuploaded", (data) => {      
      this.get_pts();
    });
  },
};
</script>