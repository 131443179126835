<template>
  <div>
    <v-row>
      <v-col class="black--text text-center text-h5"
        >Situation à {{ selectedMonth }} : Solde intermédiaire de Gestion

        <BarSig :data="sig_datas" :options="sig_option" ref="camensuel" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Bar as BarSig } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "Sig",
  components: { BarSig },
  props: {
    selectedClient: { type: Object },
    moisExercice: { type: Array },
    exercice: { type: Number },
    balances: { type: Array },
    currentPrevis: { type: Array },
    kilo_euro: { type: Boolean },
    situationMonth: { type: String },
    selectedMonth: { type: String },
  },

  data() {
    return {
      labels: [
        "CA",
        "Marge Brute",
        "Valeur Ajoutée",
        "Exédent brut d'expl.",
        "Résultat d'expl.",
        "Résultat net",
      ],
    };
  },
  computed: {
    sig_option() {
      return {
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: this.unite(),
            },
          },
        },
      };
    },

    sig_datas() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "Réel",
            data: this.get_sig_reel(),
            backgroundColor: "#ffbde9",
          },
          {
            label: "Prévu",
            data: this.get_sig_prevu(),
            backgroundColor: "#0070c0",
          },
        ],
      };
    },
  },
  methods: {
    unite() {
      if (this.kilo_euro == true) {
        return "K€";
      }
      return "€";
    },
    // Fonction pour formater la valeur en euros ou K€
    formatAmount(amount) {
      if (this.kilo_euro == true) {
        amount = amount / 1000;
      }
      return parseFloat(amount);
    },

    get_sig_prevu() {
      /*
      CA prévu = Cat. Chiffre d’affaires du prévi. (Produits)

      Marge Brute prévue =  Chiffre d’affaires  – Cat. Achats du prévi. (Charges)

      Valeur Ajoutée prévue = Marge Brute – Cat. Frais Généraux du prévi. (Charges)

      Excédent brut d’exploitation prévu =  Valeur Ajoutée + Cat. Subvention d’exploitation du prévi. (Produits) 
      – Cat. Masse salariale du prévi. (Charges) – Cat. Impôts, taxes et versements assimilés du prévi. (Charges)

      Résultat d’exploitation prévu = Excédent Brut d’exploitation  + Cat. Autres Produits exploitation du prévi. (Produits) 
      - Cat. Autres Charges exploitation du prévi. (Charges) - Cat. Dotations aux amortis-sements et provisions du prévi. (Charges))

      Résultat net prévu = Excédent Brut d’exploitation  - Cat. Charges financières du prévi. (Charges) 
      – Cat. Autres Charges financières du prévi (Charges)- Cat. Autres Charges exceptionnelles du prévis (Charges) 
      + Cat. Autres Produits financiers du prévi (Charges) + Cat. Autres Produits exceptionnels du prévi (Charges) 

      */

      const sommesParMois = [];

      if (this.moisExercice == null) {
        return sommesParMois;
      }

      // Déterminer l'index du mois courant dans la liste des mois d'exercice
      const indexMoisCourant = this.moisExercice.indexOf(this.selectedMonth);

      //
      // --- Calcul pour le CA ---
      // CA prévu = Cat. Chiffre d’affaires du prévi. (Produits) id : 6
      //

      // Initialiser la somme CA
      let sommeCA = 0;

      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 1
      const previsionnelsCA = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 1
      );

      // Parcourir les prévisionnels de la catégorie 1
      previsionnelsCA.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            sommeCA += previsionnel[moisKey];
          }
        }
      });

      // La variable "sommeCA" contient maintenant la somme des valeurs des mois

      //
      // --- Marge Brute prévue ---
      // Marge Brute prévue =  Chiffre d’affaires  – Cat. Achats du prévi. (Charges) id : 6
      //

      let marge_Brute = 0;

      // Initialiser la somme Achat
      let sommeAchat = 0;

      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 6 (achats)
      const previsionnelsAchat = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 6
      );

      // Parcourir les prévisionnels de la catégorie 6
      previsionnelsAchat.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            sommeAchat += previsionnel[moisKey];
          }
        }
      });

      // La variable "sommeAchat" contient maintenant la somme des valeurs des mois

      marge_Brute = parseFloat(sommeCA - sommeAchat);

      //
      // --- Valeur Ajoutée prévue ---
      // Valeur Ajoutée prévue = Marge Brute – Cat. Frais Généraux du prévi. (Charges) id : 7
      //

      let valeur_ajoute = 0;
      let somme_frais_generaux = 0;

      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 7 (Frais Généraux)
      const previsionnelsFraisGeneraux = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 7
      );

      // Parcourir les prévisionnels de la catégorie 7
      previsionnelsFraisGeneraux.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            somme_frais_generaux += previsionnel[moisKey];
          }
        }
      });

      // La variable "somme_frais_generaux" contient maintenant la somme des valeurs des mois

      // Valeur Ajoutée prévue = Marge Brute – Cat. Frais Généraux du prévi. (Charges) id : 7
      valeur_ajoute = parseFloat(marge_Brute - somme_frais_generaux);

      //
      // --------- Excédent brut d’exploitation prévu -----------
      // Excédent brut d’exploitation prévu =  Valeur Ajoutée + Cat. Subvention d’exploitation du prévi. (Produits) ID : 2
      // – Cat. Masse salariale du prévi. (Charges) ID : 9 – Cat. Impôts, taxes et versements assimilés du prévi. (Charges) ID : 8
      //

      let exedent_brut_exploitation = 0;
      let somme_subv_exploitation = 0;
      let masse_salariale = 0;
      let impot_taxes_versements = 0;

      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 2 (Subvention d’exploitation)
      const previsionnelsSubv_exploitation = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 2
      );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 9 (Masse salariale)
      const previsionnelsMasse_salariale = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 9
      );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 8 (impot_taxes_versements)
      const previsionnelsimpot_taxes_versements = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 8
      );

      // Parcourir les prévisionnels de la catégorie 2
      previsionnelsSubv_exploitation.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            somme_subv_exploitation += previsionnel[moisKey];
          }
        }
      });
      // Parcourir les prévisionnels de la catégorie 9
      previsionnelsMasse_salariale.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            masse_salariale += previsionnel[moisKey];
          }
        }
      });
      // Parcourir les prévisionnels de la catégorie 8
      previsionnelsimpot_taxes_versements.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            impot_taxes_versements += previsionnel[moisKey];
          }
        }
      });

      // Excédent brut d’exploitation prévu =  Valeur Ajoutée + Cat. Subvention d’exploitation du prévi. (Produits) ID : 2
      // – Cat. Masse salariale du prévi. (Charges) ID : 9 – Cat. Impôts, taxes et versements assimilés du prévi. (Charges) ID : 8

      exedent_brut_exploitation = parseFloat(
        valeur_ajoute +
          somme_subv_exploitation -
          masse_salariale -
          impot_taxes_versements
      );

      //
      // --- Calcul pour Résultat d’exploitation ---
      // Résultat d’exploitation prévu = Excédent Brut d’exploitation  + Cat. Autres Produits exploitation du prévi. ID : 3(Produits)
      // - Cat. Autres Charges exploitation du prévi. ID : 12 (Charges) - Cat. Dotations aux amortissements et provisions du prévi. ID : 10 (Charges))
      //

      let resultat_brut_exploitation = 0;
      let autres_produits_exploitation = 0;
      let autres_charges_exploitation = 0;
      let dotation_amortissements_provisions = 0;

      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 3 (Autres Produits exploitation)
      const previsionnelsAutresProduitsExploitation = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 3
      );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 12 (Autres Charges exploitation)
      const previsionnelsAutresChargesExploitation = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 12
      );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 10 (Dotations aux amortissements et provisions)
      const previsionnelsDotationsAmortissements = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 10
      );

      // Parcourir les prévisionnels de la catégorie 3
      previsionnelsAutresProduitsExploitation.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            autres_produits_exploitation += previsionnel[moisKey];
          }
        }
      });
      // Parcourir les prévisionnels de la catégorie 12
      previsionnelsAutresChargesExploitation.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            autres_charges_exploitation += previsionnel[moisKey];
          }
        }
      });
      // Parcourir les prévisionnels de la catégorie 10
      previsionnelsDotationsAmortissements.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            dotation_amortissements_provisions += previsionnel[moisKey];
          }
        }
      });

      // Résultat d’exploitation prévu = Excédent Brut d’exploitation  + Cat. Autres Produits exploitation du prévi. ID : 3(Produits)
      // - Cat. Autres Charges exploitation du prévi. ID : 12 (Charges) - Cat. Dotations aux amortissements et provisions du prévi. ID : 10 (Charges))

      resultat_brut_exploitation = parseFloat(
        exedent_brut_exploitation +
          autres_produits_exploitation -
          autres_charges_exploitation -
          dotation_amortissements_provisions
      );

      //
      // --- Calcul pour Résultat net ---
      // Résultat net prévu = Excédent Brut d’exploitation  - Cat. Charges financières du prévi. (Charges) ID 11
      //– Cat. Autres Charges financières du prévi (Charges) ID 13 - Cat. Autres Charges exceptionnelles du prévis ID 14 (Charges)
      //+ Cat. Autres Produits financiers du prévi ID 4 (Produits) + Cat. Autres Produits exceptionnels du prévi ID 5 (Produits)


      // modification de la formule cf recette mai 2024 & complement mail 06/06/2024

      // Résultat net prévu = Cat. Chiffre d’affaires du prévi. – Cat. Achats du prévi.– Cat. Frais Généraux du prévi 
      //+ Cat. Subvention d’exploitation du prévi. – Cat. Masse salariale du prévi– Cat. Impôts, taxes et versements assimilés du prévi 
      //- Cat. Charges financières du prévi.– Cat. Autres Charges financières du prévi - Cat. Autres Charges exceptionnelles du prévis 
      //+ Cat. Autres Produits financiers du prévi + Cat. Autres Produits exceptionnels du prévi – Cat. Dotations aux amortissements et provisions du prévi

      //Cat. Chiffre d’affaires du prévi. => sommeCA
      //Cat. Achats du prévi. => sommeAchat
      //Cat. Frais Généraux du prévi =>somme_frais_generaux
      //Cat. Subvention d’exploitation du prévi =>somme_subv_exploitation
      //Cat. Masse salariale du prévi => masse_salariale
      //Cat. Impôts, taxes et versements assimilés du prévi => impot_taxes_versements


      let resultat_net = 0;
      let charges_financieres = 0;
      let autres_charges_financieres = 0;
      let autres_charges_exceptionnelles = 0;
      let autres_produits_financiers = 0;
      let autres_produits_excetionnels = 0;

      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 11 (charges_financieres)
      const previsionnelscharges_financieres = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 11
      );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 13 (autres_charges_financieres)
      const previsionnelsautres_charges_financieres = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 13
      );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 14 (autres_charges_exceptionnelles)
      const previsionnelsautres_charges_exceptionnelles =
        this.currentPrevis.filter(
          (prev) => prev.budgetline_categorie_id === 14
        );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 4 (autres_produits_financiers)
      const previsionnelsautres_produits_financiers = this.currentPrevis.filter(
        (prev) => prev.budgetline_categorie_id === 4
      );
      // Filtrer les prévisionnels ayant budgetline_categorie_id égal à 5 (autres_produits_excetionnels)
      const previsionnelsautres_produits_excetionnels =
        this.currentPrevis.filter((prev) => prev.budgetline_categorie_id === 5);

      // Parcourir les prévisionnels de la catégorie 11
      previsionnelscharges_financieres.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            charges_financieres += previsionnel[moisKey];
          }
        }
      });

      // Parcourir les prévisionnels de la catégorie 13
      previsionnelsautres_charges_financieres.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            autres_charges_financieres += previsionnel[moisKey];
          }
        }
      });

      // Parcourir les prévisionnels de la catégorie 14
      previsionnelsautres_charges_exceptionnelles.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            autres_charges_exceptionnelles += previsionnel[moisKey];
          }
        }
      });

      // Parcourir les prévisionnels de la catégorie 4
      previsionnelsautres_produits_financiers.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            autres_produits_financiers += previsionnel[moisKey];
          }
        }
      });

      // Parcourir les prévisionnels de la catégorie 5
      previsionnelsautres_produits_excetionnels.forEach((previsionnel) => {
        for (let i = 0; i <= indexMoisCourant; i++) {
          // Construire la clé de mois (ex: "mois_1", "mois_2", ...)
          const moisKey = `mois_${i + 1}`;

          // Vérifier si la propriété existe et si elle est un nombre
          if (
            previsionnel.hasOwnProperty(moisKey) &&
            !isNaN(previsionnel[moisKey])
          ) {
            autres_produits_excetionnels += previsionnel[moisKey];
          }
        }
      });

      // Résultat net prévu = Excédent Brut d’exploitation  - Cat. Charges financières du prévi. (Charges) ID 11
      //– Cat. Autres Charges financières du prévi (Charges) ID 13 - Cat. Autres Charges exceptionnelles du prévis ID 14 (Charges)
      //+ Cat. Autres Produits financiers du prévi ID 4 (Produits) + Cat. Autres Produits exceptionnels du prévi ID 5 (Produits)


      // modification de la formule cf recette mai 2024 & complement mail 06/06/2024

      // Résultat net prévu = Cat. Chiffre d’affaires du prévi. – Cat. Achats du prévi.– Cat. Frais Généraux du prévi 
      //+ Cat. Subvention d’exploitation du prévi. – Cat. Masse salariale du prévi – Cat. Impôts, taxes et versements assimilés du prévi 
      //- Cat. Charges financières du prévi.– Cat. Autres Charges financières du prévi - Cat. Autres Charges exceptionnelles du prévis 
      //+ Cat. Autres Produits financiers du prévi + Cat. Autres Produits exceptionnels du prévi – Cat. Dotations aux amortissements et provisions du prévi

      //Cat. Chiffre d’affaires du prévi. => sommeCA
      //Cat. Achats du prévi. => sommeAchat
      //Cat. Frais Généraux du prévi =>somme_frais_generaux
      //Cat. Subvention d’exploitation du prévi =>somme_subv_exploitation
      //Cat. Masse salariale du prévi => masse_salariale
      //Cat. Impôts, taxes et versements assimilés du prévi => impot_taxes_versements



      /*resultat_net = parseFloat(
        exedent_brut_exploitation -
          charges_financieres -
          autres_charges_financieres -
          autres_charges_exceptionnelles +
          autres_produits_financiers +
          autres_produits_excetionnels
      );*/

      resultat_net = parseFloat(
        sommeCA - sommeAchat - somme_frais_generaux + somme_subv_exploitation - masse_salariale - impot_taxes_versements - charges_financieres
        - autres_charges_financieres - autres_charges_exceptionnelles + autres_produits_financiers +  autres_produits_excetionnels
        - dotation_amortissements_provisions
      );      

      /*console.log('exedent_brut_exploitation',exedent_brut_exploitation)
      console.log('charges_financieres',charges_financieres)
      console.log('autres_charges_financieres',autres_charges_financieres)
      console.log('autres_charges_exceptionnelles',autres_charges_exceptionnelles)
      console.log('autres_produits_financiers',autres_produits_financiers)
      console.log('autres_produits_excetionnels',autres_produits_excetionnels)
      console.log('==',resultat_net)*/

      let datas = [
        sommeCA,
        marge_Brute,
        valeur_ajoute,
        exedent_brut_exploitation,
        resultat_brut_exploitation,
        resultat_net,
      ];

      //console.log(datas);

      return datas.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },

    get_sig_reel() {
      /*
    Chiffre d’affaires réel = Ventes (CR) + Prestations de services (CR)
    = ∑ [-701, -702, -703, -704,-705,-706,-707,-708,-7091, -7092, -7093,-7094, -7095, -7096, -7097, -7098]
    Marge Brute réelle = Chiffre d’affaires (SIG) + Production stockée / immobilisée (SIG) - Achat con-sommé (SIG)
    = ∑ [-601, -602,-603, -604, -607, -608, -609,-701, -702, -703, -704,-705,-706,-707,-708, -7091, -7092, -7093,-7094, -7095, -7096, -7097, -7098,-71, -72] 
    Valeur Ajoutée réelle = Marge brute (SIG) - Autres achats et charges externes (CR)
    = ∑ [-601, -602,-603, -604, -605,-606,- 607, -608, -609, -611, -612, -613, -614, -615, -616, -617, -618, -619, -621, -622, -623, -624, -625, -626, -627, -628, -629,-701, -702, -703, -704,-705,-706,-707,-708, -7091, -7092, -7093,-7094, -7095, -7096, -7097, -7098 -71, -72] 
    Excédent brut d’exploitation réel = Valeur Ajoutée (SIG) + Subvention d'exploitation (SIG) + Trans-fert de charges (SIG) - Frais de personnel (SIG) - Impôts, taxes et versement assimilés (SIG)
    =∑ [-601, -602, -603, -604, -605, -606, -607, -608, -609, -611, -612, -613, -614, -615, -616, -617, -618, -619, -621, -622, -623, -624, -625, -626, -627, -628, -629 ,-63, -64, -689,-701, -702, -703, -704,-705,-706,-707,-708,-7091, -7092, -7093,-7094, -7095, -7096, -7097, -7098 -71, -72,-73,-74,-789,-79] 

    Résultat d’exploitation = Excédent brut d’exploitation + Autres charges & produits de gestion courante + Dotations nettes aux amortissements et provisions exploitation
    = ∑ [-601,-602,-603,-604,-605,-607,-608,-609,-63,-64,-65,-681,-689,-701,-702,-703,-704,-705,-706,-707,-708,-7091,-7092,-7093,-7094,-7095,-7096,-7097,-7098,-71,-72,-73,-74,-75,-781,-789,-79]

    Résultat net réel = Résultat courant (SIG) + Résultat exceptionnel hors DNAP exceptionnel (SIG) + DNAP exceptionnel (SIG) - Impôt sur les sociétés (SIG)
    = ∑ [-601, -602, -603, -604, -605, -606, -607, -608, -609, -611, -612, -613, -614, -615, -616, -617, -618, -619, -621, -622, -623, -624, -625, -626, -627, -628, -629 ,-63, -64,-65,-66,-67,-681,-686,-687,-689,D695,-C695,D696,-C696,D698,-C698,D699,-C699,-701, -702, -703, -704,-705,-706,-707,-708,-7091, -7092, -7093,-7094, -7095, -7096, -7097, -7098 -71, -72,-73,-74,-75,-76,-77,-781,-786,-787,-789,-79]
    */
      let sommesParMois = [];

      let ca = 0;
      let marge_brute = 0;
      let valeur_ajoutee = 0;
      let excedent_brut_exploitation = 0;
      let resultat_exploitation = 0;
      let resultat_net = 0;

      const comptes_ca = [
        -701, -702, -703, -704, -705, -706, -707, -708, -7091, -7092, -7093,
        -7094, -7095, -7096, -7097, -7098,
      ];
      const comptes_marge_brute = [
        -601, -602, -603, -604, -607, -608, -609, -701, -702, -703, -704, -705,
        -706, -707, -708, -7091, -7092, -7093, -7094, -7095, -7096, -7097,
        -7098, -71, -72,
      ];
      const comptes_valeur_ajoutee = [
        -601, -602, -603, -604, -605, -606, -607, -608, -609, -611, -612, -613,
        -614, -615, -616, -617, -618, -619, -621, -622, -623, -624, -625, -626,
        -627, -628, -629, -701, -702, -703, -704, -705, -706, -707, -708, -7091,
        -7092, -7093, -7094, -7095, -7096, -7097, -7098, -71, -72,
      ];
      const comptes_excedent_brut_exploitation = [
        -601, -602, -603, -604, -605, -606, -607, -608, -609, -611, -612, -613,
        -614, -615, -616, -617, -618, -619, -621, -622, -623, -624, -625, -626,
        -627, -628, -629, -63, -64, -689, -701, -702, -703, -704, -705, -706,
        -707, -708, -7091, -7092, -7093, -7094, -7095, -7096, -7097, -7098, -71,
        -72, -73, -74, -789, -79,
      ];
      // modification de la formule cf recette mai 2024 & complément mail 06/06/2024
      /*
      = ∑ [-601, -602, -603, -604, -605,-606, -607, -608, -609, -61, -62, -63, -64, -65, -681, -689, 
      -701, -702, -703, -704, -705, -706, -707, -708, -7091, -7092, -7093, -7094, -7095, -7096, -7097,
       -7098, -71, -72, -73, -74, -75, -781, -789, -79]
      */
      const comptes_resultat_exploitation = [
        -601, -602, -603, -604, -605, -606, -607, -608, -609, -61, -62, -63, -64, -65, -681,
        -689, -701, -702, -703, -704, -705, -706, -707, -708, -7091, -7092,
        -7093, -7094, -7095, -7096, -7097, -7098, -71, -72, -73, -74, -75, -781,
        -789, -79,
      ];

      // formule modifiée cf recette mai 2024 & mail 06/06/2024
      const comptes_resultat_net = [
        -601, -602, -603, -604, -605, -606, -607, -608, -609, -611, -612, -613, -614,
        -615, -616, -617, -618, -619, -621, -622, -623, -624, -625, -626, -627, -628,
        -629, -63, -64, -65, -66, -67, -681, -686, -687, -689, -695, -696,-698, -699,
        -701, -702, -703, -704, -705, -706, -707, -708,-7091,-7092,-7093,-7094,-7095,
        -7096,-7097,-7098,-71,-72,-73,-74,-75,-76,-77,-781,-786,-787,-789,-79,
      ];

      if (this.moisExercice == null || this.balances.length == 0) {
        return sommesParMois;
      }

      // recherche de la date de cloture de balance la plus proche de selectedMonth
      // sans la depasser
      
      let selectedCloture = this.$moment(this.selectedMonth, "MMMYY").endOf('month');
      


      // Trier la collection en fonction de la proximité des dates avec 'selectedCloture'
      const sortedBalances = this.balances.sort((a, b) => {
        // Convertir les dates 'cloture' de chaque élément en objets Moment
        let dateA = this.$moment(a.cloture);
        let dateB = this.$moment(b.cloture);

        // Calculer les différences entre les dates et les comparer
        return Math.abs(dateA.diff(selectedCloture)) - Math.abs(dateB.diff(selectedCloture));
      });

      // La date la plus proche de selectedMonth sans la dépasser est maintenant dans le premier élément de la collection triée
      let dateCloturePlusProche= sortedBalances.length > 0 ? this.$moment(sortedBalances[0].cloture) : null;



      // Filtrer les balances ayant la date de clôture dateCloturePlusProche 
      // du mois selectionné selectedMonth      
      
      const filteredBalances = this.balances.filter((balance) => {
        let clotureDate = this.$moment(balance.cloture);       

        return clotureDate.isSame(dateCloturePlusProche);
      });

      //console.log('selectedCloture: ',selectedCloture);
      //console.log('dateCloturePlusProche: ',dateCloturePlusProche);
      //console.log('filteredBalances: ',filteredBalances);       
      //console.log('liste des comptes concernés par le calcul resultat net : ',comptes_resultat_net);
      //console.log('Detail pour le calcul de la somme pour resultat net: ');

      // Parcourir les balances pour faire la somme des comptes concernés
      filteredBalances.forEach((objet) => {
        const solde = parseFloat(objet.solde);
        // somme pour CA
        comptes_ca.forEach((numero) => {
          if (!isNaN(numero)) {
            if (objet.compte.startsWith(Math.abs(numero).toString())) {
              ca += numero < 0 ? -solde : solde;
            }
          }
        });

        
        

        // somme pour marge_brute
        comptes_marge_brute.forEach((numero) => {
            

          if (!isNaN(numero)) {
            if (objet.compte.startsWith(Math.abs(numero).toString())) {
              //console.log('compte N°' + numero + ' solde:'+ solde);
                                        
              marge_brute += numero < 0 ? -solde : solde;
              //console.log('marge_brute:',marge_brute);
            }
          }
        });
   


        // somme pour valeur_ajoutee
        comptes_valeur_ajoutee.forEach((numero) => {
          if (!isNaN(numero)) {
            if (objet.compte.startsWith(Math.abs(numero).toString())) {
              valeur_ajoutee += numero < 0 ? -solde : solde;
            }
          }
        });

        // somme pour comptes_excedent_brut_exploitation
        comptes_excedent_brut_exploitation.forEach((numero) => {
          if (!isNaN(numero)) {
            if (objet.compte.startsWith(Math.abs(numero).toString())) {
              excedent_brut_exploitation += numero < 0 ? -solde : solde;
            }
          }
        });

        // somme pour comptes_resultat_exploitation

        comptes_resultat_exploitation.forEach((numero) => {
          if (!isNaN(numero)) {
            if (objet.compte.startsWith(Math.abs(numero).toString())) {              
              resultat_exploitation += numero < 0 ? -solde : solde;              
            }
          }
        });

        // somme pour comptes_resultat_net

        /*
        console.log("##########################");
        console.log("balance ", objet.compte);
        console.log("##########################");
        */
        comptes_resultat_net.forEach((numero) => {
          //console.log("cumul", resultat_net);
          //console.log("compte ", numero);
          //console.log("solde", solde);

          // cas d'un numero de compte standard
          if (!isNaN(numero)) {
            if (objet.compte.startsWith(Math.abs(numero).toString())) {
              //console.log("compte solde", numero, solde);

              /*if (numero < 0) {
                console.log("AJOUT compte standard ajout de ", -solde);
              } else {
                console.log("AJOUT compte standard ajout de ", solde);
              }*/
              //console.log('compte N°' + numero + ' solde:'+ solde);
              resultat_net += numero < 0 ? -solde : solde;
              //console.log('res net:',resultat_net);

              //console.log("cumul", resultat_net);
            }
          }

          // cas d'un numero de compte avec prefixe D
          // on prend uniquement la valeur absolue du solde s'il est positif,
          if (numero[0] == "D") {
            if (objet.compte.startsWith(numero.slice(1)) && solde >= 0) {
              //console.log("compte solde", numero, solde);
              //console.log("AJOUT compte avec prefixe D ajout de ",Math.abs(solde));
              resultat_net += Math.abs(solde);
              //console.log("cumul", resultat_net);
            }
            /*if (objet.compte.startsWith(numero.slice(1)) && solde < 0) {
              console.log("solde <0 on passe");
            }*/
          }
          // cas d'un numero de compte avec prefixe C
          //on prend uniquement la valeur absolue du solde s'il est negatif,
          if (numero[1] == "C") {
            if (objet.compte.startsWith(numero.slice(2)) && solde <= 0) {
              //console.log("compte solde", numero, solde);
              //console.log("AJOUT compte avec prefixe C ajout de ",-Math.abs(solde));
              resultat_net -= Math.abs(solde);
              //console.log("cumul", resultat_net);
            }
            /*if (objet.compte.startsWith(numero.slice(2)) && solde > 0) {
              console.log("solde >0 on passe");
            }*/
          }
        });
      });

      sommesParMois = [
        Math.round(ca),
        Math.round(marge_brute),
        Math.round(valeur_ajoutee),
        Math.round(excedent_brut_exploitation),
        Math.round(resultat_exploitation),
        Math.round(resultat_net),
      ];

      //console.log("sommesParMois ", sommesParMois);

      return sommesParMois.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },
  },

  mounted() {},
};
</script>    