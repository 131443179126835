<template>
  <!-- Le template -->
  <v-container fluid class="pa-10" v-if="isLoggedIn">
    <v-row dense>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">
          Hitoriques Prévisionnels :
        </h1>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn
          v-if="selectedClient && !Loading"
          to="/app/previsionnels/previsionnels-cr"
        >
          <v-icon left>mdi-history</v-icon>
          Retour aux prévisionnels cr
        </v-btn>

        <!--Snackbar-->
        <v-snackbar
          v-model="snackbar"
          bottom
          color="success"
          multi-line
          :timeout="6000"
        >
          {{ snackbar_text }}
          <v-btn dark color="green darken-4" @click="snackbar = false"
            >Fermer</v-btn
          >
        </v-snackbar>

        <!-- Modal -->
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Confirmer la suppression ?</span>
            </v-card-title>
            <v-card-text>
              <v-container v-if="deleteItem != null">
                <v-row>
                  <v-col>
                    Annee : {{ deleteItem.annee }}<br />
                    Etat : {{ deleteItem.etat }}<br />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.stop="cancel"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click.stop="confirm"
                >Confirmer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row v-if="selectedClient">
      <v-col>
        <!--Barre outil listing-->
        <v-row>
          <!--Search-->
          <v-col align="center" justify="center" cols="auto">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherche"
              single-line
              dense
            ></v-text-field>
          </v-col>
          <!--Colonne visibility-->
          <v-col align="center" justify="center" cols="auto">
            <v-select
              v-model="defaultHeaders"
              :items="possibleHeaders"
              label="Visibilité des colonnes"
              multiple
              return-object
              dense
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{
                  defaultHeaders.length +
                  " colonnes sur " +
                  possibleHeaders.length +
                  " possibles"
                }}</span>
              </template>
            </v-select>
          </v-col>
          <!--Export excel-->
          <v-col align="center" justify="center" cols="auto">
            <export-excel
              :data="myitems"
              name="historique-previsionnel.xls"
              :fields="excelheaders"
            >
              <v-btn color="grey darken-3">
                <v-icon dark left>mdi-file-export-outline</v-icon>Excel
              </v-btn>
            </export-excel>
          </v-col>
        </v-row>

        <!--DataTable -->
        <v-data-table
          sortBy="id"
          :footer-props="{
            'items-per-page-all-text': 'tout',
            'items-per-page-text': 'Résultats par page :',
          }"
          @update:items-per-page="setItemPerPage"
          :headers="headers"
          :items="myitems"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-1"
        >
          <!--FOOTER SLOTS -->
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
            >Lignes: {{ pageStart }} à {{ pageStop }} sur
            {{ itemsLength }}</template
          >

          <!--ACTIONS SLOTS-->
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="item.can.view == true"
              icon
              :to="{
                name: 'previsionnels/view-previsionnels-cr',
                params: {
                  selectedClient: item.client,
                  annee: item.annee,
                  etat: item.etat,
                },
              }"
              title="Voir le détail"
              ><v-icon>mdi-eye</v-icon></v-btn
            >
            <v-btn
              v-if="item.can.delete == true && canbedeleted(item)"
              icon
              color="red"
              title="Supprimer"
              ><v-icon @click="modalDelete(item)">mdi-delete</v-icon></v-btn
            >
          </template>

          <!-- DATE SLOTS-->
          <template v-slot:item.created_at="{ item }">
            <span> {{ formaDatetWithDuration(item.created_at) }}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <span> {{ formaDatetWithDuration(item.updated_at) }}</span>
          </template>
        </v-data-table>
      </v-col></v-row
    >

    <v-row>
      <v-col
        ><v-alert dense type="info">
          Un prévisionnel initial ne peut être supprimé que si le prévisionnel
          révisé correspondant est supprimé
        </v-alert></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
// Le script
export default {
  data() {
    return {
      ClientsLoading: true,
      selectedClient: null,
      clients: [],

      default_column_names: ["annee", "etat", "created_at", "actions"],

      possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Année d'exercice",
          value: "annee",
          align: "left",
          name: "annee",
          filterable: true,
          sortable: true,
          order: 2,
        },
        {
          text: "Etat",
          value: "etat",
          align: "left",
          name: "etat",
          filterable: true,
          sortable: true,
          order: 3,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 4,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 5,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 6,
        },
        {
          text: "Modifié le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 7,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 8,
        },
      ],

      search: null,
      searchQuery: null,
      columns: {},
      excelheaders: {},
      defaultHeaders: [],
      headers: [],
      datatableQuery: null,

      myitems: [],
      totalItems: 0,
      loading: true,
      options: { page: 1, itemsPerPage: 10 },

      deleteItem: null,
      dialog: false,

      snackbar: null,
      snackbar_text: null,
    };
  },
  computed: {
    //policies
    canCreateUser: {
      get() {
        return this.$store.getters["currentUser/LoggedUser"].can.create;
      },
    },

    //computed params to return pagination and search criteria
    params(nv) {
      return {
        ...this.options,
        query: this.search,
      };
    },
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
  },
  watch: {
    dialog(val) {
      console.log("Watch dialog");
      !val && this.close();
    },

    defaultHeaders(val) {
      console.log("defaultHeaders changed");
      this.headers = _.orderBy(this.defaultHeaders, "order", "asc");
      this.headers.forEach((header) => {
        if (header.text != "actions") {
          this.excelheaders[header.text] = header.value;
        }
      });
    },

    params: {
      handler() {
        console.log("params changed");
        this.setDatatableQuery();
        this.getDataFromApi();
      },
      deep: true,
    },

    search: {
      handler() {
        console.log("search changed");
        this.options.page = 1;
        if (this.search != "") {
          this.filtered = true;
        } else {
          this.filtered = false;
        }
      },
    },
  },
  methods: {
    canbedeleted(item) {
      //un previs initial ne peut etre supprimé que si le previs révisé est supprimé

      //si le previ est un etat revisé => delete ok
      if (item.etat == "revise") {
        return true;
      }

      //recherche d'un previ revise pour la meme annne
      const result = _.find(this.myitems, {
        annee: item.annee,
        etat: "revise",
      });
      if (!result) {
        return true;
      }

      return false;
    },

    setItemPerPage(val) {
      localStorage.list_item_per_page = val;
      this.options.itemsPerPage = parseInt(localStorage.list_item_per_page);
    },

    formaDatetWithDuration(date) {
      let fromnow = this.$moment(date).fromNow(); // moment(thedate).fromNow();
      let output = this.$moment(date).format("ddd D MMM YYYY");
      return output + " (" + fromnow + ")";
    },

    setDatatableQuery() {
      //Based on our Headers we create query data for DataTables
      //I've added a new param "searchable" to let DataBales know that this column is not searchable
      //You can also set name as "table.column eg users.name" if you select from more then table to avoid "Ambitious column name error from SQL"

      for (var i = 0; i < this.headers.length; i++) {
        this.columns[i] = {
          data: this.headers[i].value,
          name:
            typeof this.headers[i].name != "undefined"
              ? this.headers[i].name
              : this.headers[i].value,
          searchable: this.headers[i].filterable,
          orderable: this.headers[i].sortable,
          search: { value: null, regex: false },
        };
      }

      this.datatableQuery = {};
      this.datatableQuery.length = this.params.itemsPerPage;
      this.datatableQuery.start =
        this.params.page == 1
          ? 0
          : this.params.itemsPerPage * (this.params.page - 1); //set offset
      this.datatableQuery.search = { value: this.params.query }; //our search query
      this.datatableQuery.columns = this.columns;
      this.datatableQuery.archive = this.archive;

      let sortBy_index = _.findIndex(this.headers, {
        value: this.params.sortBy[0],
      });

      if (sortBy_index != -1) {
        this.datatableQuery.order = {
          0: {
            column: sortBy_index,
            dir: this.params.sortDesc[0] === true ? "desc" : "asc",
          },
        };
      } else {
        this.datatableQuery.order = { 0: { column: 0, dir: "asc" } };
      }

      this.datatableQuery = window.jQuery.param(this.datatableQuery);
    },

    getDataFromApi() {
      console.log("getDataFromApi");

      this.loading = true;

      // Fetching the data
      axios
        .get(
          "/api/previsionnel-historique/" +
            this.selectedClient.id +
            "/?" +
            this.datatableQuery
        )
        .then((response) => {
          console.log("datafetched");
          this.myitems = response.data.data;
          if (this.search) {
            this.totalItems = response.data.recordsFiltered;
          } else {
            this.totalItems = response.data.recordsTotal;
          }
          if (response.data.error != "") {
            this.serverAlert = response.data.error;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("erreur", err);
          this.serverAlert = err;
          this.loading = false;
        });
    },

    deleteFromApi() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        axios
          .delete("/api/previsionnel-historique/" + this.deleteItem.id)
          .then((response) => {
            console.log("item deleted from api", response);
            this.deleteItem = null;
            this.getDataFromApi();
            this.snackbar_text = "Prévisionnel supprimé avec succès";
            this.snackbar = true;
            this.loading = false;
            this.dialog = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("item deleted from api failed", err);
            this.serverAlert = err.response.data.message;
            this.loading = false;
            this.dialog = false;
            reject(err);
          });
      });
    },

    modalDelete(item) {
      let deleteItemIndex = this.myitems.indexOf(item);
      this.deleteItem = this.myitems[deleteItemIndex];

      this.dialog = true;
      console.log("modal shown");
      console.log("deleteItem", this.deleteItem);
    },

    close() {
      this.dialog = false;
      this.deleteItem = null;
      console.log("close");
      console.log("deleteItem", this.deleteItem);
    },

    cancel() {
      this.dialog = false;
      this.deleteItem = null;
      console.log("cancel");
      console.log("this.deleteItem", this.deleteItem);
    },
    confirm(item) {
      console.log("clic onfirmed");
      console.log("this.deleteItem", this.deleteItem);

      if (this.deleteItem != null) {
        this.deleteFromApi(this.deleteItem.id).then((response) => {
          this.deleteItem = null;
          this.close();
          this.getDataFromApi();
        });
      }
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },
  },
  mounted() {
    // recuperation sauvegarde du client selectionné
    if (localStorage.selectedClient) {
      this.selectedClient = JSON.parse(localStorage.selectedClient);
    }
    this.getClients();

    // initialisation des colonnes par defaut
    this.defaultHeaders = _.filter(this.possibleHeaders, (o) => {
      return this.default_column_names.includes(o.name);
    });

    // initialisation des colonnes excel
    this.headers = this.defaultHeaders;

    this.headers.forEach((header) => {
      if (header.text != "actions") {
        this.excelheaders[header.text] = header.value;
      }
    });
  },
};
</script>