<template>
  <!-- Le template -->
  <!-- Le template -->
  <section>
    <v-container>
      <h4 class="mb-8">Importation de la balance Mensuelle</h4>

      <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
        <ul>
          <div v-for="(value, propertyName, index) in formError" :key="index">
            <li v-if="value && Array.isArray(value)" >
              {{ propertyName }}: <span class="pre-formatted">{{ value[0] }}</span>
            </li>
            <li v-if="value && typeof value == 'string'" >
              {{ propertyName }}: <span class="pre-formatted">{{ value }}</span>
            </li>
          </div>
        </ul>
      </v-alert>

      <v-snackbar
        v-model="snackbar"
        bottom
        color="success"
        multi-line
        :timeout="6000"
      >
        <p v-html="snackbar_text"></p>

        <v-btn
          dark
          color="green darken-4"
          @click="
            snackbar_text = '';
            snackbar = false;            
          "
          >Fermer</v-btn
        >
      </v-snackbar>
      <v-form ref="form" v-model="valid" >
        <v-row>
          <v-col>
            <v-menu
              offset-y
              max-width="290px"
              min-width="290px"
              transition="scale-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  readonly
                  clearable
                  class="my-0 py-0"
                  :value="dateSituation"
                  label="Date de situation"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  :rules="[rules.required]"
                  :error-messages="formError.dateSituation"                  
                  @focus="formError.dateSituation = null"
                  @click:clear="dateSituation = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="fr"
                color="primary"
                first-day-of-week="1"
                :show-current="true"
                v-model="dateSituation"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <!-- balancemensuelle FILE -->
          <v-col>
            <v-file-input dense show-size
            
            v-model="balancemensuellefile"
            ref="balancemensuellefile"
            accept=".csv"
            hint="Fichier au format CSV '(*.csv) avec encodage UTF8 si le fichier comporte des accents.<br>
            La première ligne du fichier csv doit etre vierge de donnée (Juste laisser les entetes)<br>
            Cinq colonnes sont attendues.<br><br>
            Colonne 1 : numéro de compte<br>
            Colonne 2 : libelle (pourra être vide)<br>
            Colonne 3 : débit (pourra être vide)<br>
            Colonne 4 : crédit (pourra être vide)<br>
            Colonne 5 : solde <br><br>

            Une ligne par compte"
            persistent-hint
            label="Fichier balancemensuelle (CSV)"
                  :error-messages="formError.balancemensuelle"
                  :rules="[rules.required]"
                  @focus="formError.balancemensuelle = null" >
            <template v-slot:message="{ message, key }">
            <div class="mt-4" v-html="message"></div>
            </template>      
            </v-file-input>

          </v-col>
        </v-row>
        <v-btn
          class="mt-4"
          color="primary"
          @click.prevent="submit"
          :disabled="!valid"
        >
          <v-icon left>mdi-check</v-icon>
          Enregistrer
        </v-btn>
      </v-form>
    </v-container>
  </section>
</template>

<style>
.pre-formatted {white-space: pre-wrap;}
</style>

<script>

import {EventBus} from "../EventBus";

// Le script
export default {
  name: "balanceMensuelleImportForm",
  props: {
    clientID: Number,
  },
  data() {
    return {

      isSaving: false,

      serverAlert: null,
      snackbar_text: "",
      snackbar: false,

      balancemensuellefile : null,
      dateSituation : null,
      valid: false,

      formError: {
        balancemensuellefile: null,
        dateSituation: null
      },

      rules: {
        required: (v) => !!v || "Ce champ est requis",

      },
    };
  },
  methods: {
    submit() {
      this.isSaving = true;
      this.serverAlert = null;

      let formData = new FormData();

      formData.append("balancemensuellefile",this.balancemensuellefile);
      formData.append("client_id", this.clientID);
      formData.append("dateSituation", this.dateSituation);
        
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/balance-mensuelle/import/" + this.clientID, formData)
          .then((response) => {
            console.log("submit OK", response);

            this.isSaving = false;
            this.snackbar = true;
            this.snackbar_text = "Fichier Balance Mensuelle uploadé";
            this.$refs.balancemensuellefile.reset()
            this.balancemensuellefile=null
            EventBus.$emit('balanceuploaded',this.clientID)
            this.$emit('balanceuploaded',this.clientID)

            
            

          })
          .catch((err) => {
            this.serverAlert = true;
            this.isSaving = false;
            console.log("submit_ko", err);

            if (err.response.status == 422) {
              Object.assign(this.formError, err.response.data.errors);
            }

            this.formError.general = err.response.data.message;
            this.$refs.balancemensuellefile.reset()
            this.balancemensuellefile=null
          });
      });
      return;
    },      
  },
};
</script>