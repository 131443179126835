<template>
    <!-- Le template -->
    <v-container fill-height>
        <h1>Page 2</h1>  
    </v-container>
</template>

<script>
    // Le script
    export default {
        data() {return {

        }},
        computed : {
        },
        methods : {
        }
    }
</script>