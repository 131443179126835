<template>
  <!-- Le template -->
  <v-container fluid class="pa-10" v-scroll="onScrolling">
    <v-snackbar
      v-model="snackbar"
      bottom
      color="success"
      multi-line
      :timeout="6000"
    >
      <p v-html="snackbar_text"></p>

      <v-btn
        dark
        color="green darken-4"
        @click="
          snackbar_text = '';
          snackbar = false;
        "
        >Fermer</v-btn
      >
    </v-snackbar>

    <v-row dense>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h3 class="mr-3 my-0" style="line-height: 1.5em">Prévisionnels :</h3>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <v-spacer></v-spacer>
        <v-select
          v-if="selectedClient && !Loading"
          v-model="exercice"
          :items="exercices"
          item-text="annee"
          item-value="annee"
          label="Année du début d'exercice"
          persistent-hint
          return-object
          single-line
        ></v-select>
        <v-spacer></v-spacer>
        <v-btn
          v-if="selectedClient && !Loading"
          to="historique-previsionnels-cr"
        >
          <v-icon left>mdi-history</v-icon>
          Historique
        </v-btn>
        <v-spacer v-if="currentPrevis && !Loading"></v-spacer>
        <v-btn
          @click="enregistrer"
          color="primary"
          v-if="currentPrevis && !Loading"
          >Enregistrer</v-btn
        >
        <v-btn
          class="ml-1"
          @click="reviser"
          color="primary"
          v-if="
            currentPrevis &&
            !Loading &&
            exercice.etat == 'initial' &&
            this.existing_cr_revise() == false
          "
          >Réviser</v-btn
        >
      </v-col>
    </v-row>

    <v-alert
      v-if="error"
      type="error"
      close-text="Fermer"
      dismissible
      @input="closeerror"
      ><div v-html="this.error"></div
    ></v-alert>

    <!-- Bouton "Enregistrer" toujours visible -->
    <v-banner
      app
      v-show="showOnScroll"
      elevation="0"
      class="pa-0 grey darken-4"
      style="z-index: 10000"
    >
      <v-alert
        v-if="error"
        type="error"
        close-text="Fermer"
        dismissible
        @input="closeerror"
      >
        <div v-html="this.error"></div>
      </v-alert>
      <v-row dense v-if="currentPrevis && !Loading" v-show="showOnScroll">
        <v-col cols="12" class="text-center">
          <v-btn
            @click="enregistrer"
            color="primary"
            v-if="currentPrevis && !Loading"
            >Enregistrer</v-btn
          >
          <v-btn
            class="ml-1"
            @click="reviser"
            color="primary"
            v-if="
              currentPrevis &&
              !Loading &&
              currentPrevis[0].etat == 'initial' &&
              this.existing_cr_revise() == false
            "
            >Réviser</v-btn
          >
        </v-col>
      </v-row>
    </v-banner>
    <!--
    <v-row dense class="scroll-horizontal">
      <v-col>
        <resultat
          v-if="currentPrevis && !Loading"
          :moisExercice="moisExercice"
          :currentPrevis="currentPrevis"
        ></resultat>
      </v-col>
    </v-row>-->

    <v-row v-if="currentPrevis && !Loading"
      ><v-col>
        <v-tabs vertical>
          <v-tab>Produits</v-tab>
          <v-tab>Charges</v-tab>

          <!-- panels Produits-->
          <v-tab-item>
            <!---->
            <v-row dense class="scroll-horizontal"
              ><v-col>
                <resultat
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                ></resultat>

                <!---->
                <resultat-classe
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :classe="{ intitule: 'Produits', id: 7 }"
                ></resultat-classe>
                <v-expansion-panels accordion :value="[0, 1, 2, 3, 4]" multiple>
                  <v-expansion-panel
                    v-for="(categorie, indexcat) in categories(7)"
                    :key="indexcat"
                  >
                    <v-expansion-panel-header hide-actions>
                      <template v-slot:default="{ open }">
                        <div>
                          <v-row dense>
                            <v-col>
                              <v-icon class="icon">$expand</v-icon>
                              {{ categorie.intitule }}
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    @click.native.stop="
                                      addlb(categorie, (classe = 7))
                                    "
                                    v-on="on"
                                    ><v-icon>mdi-plus</v-icon></v-btn
                                  >
                                </template>
                                <span>Ajouter une ligne bugétaire</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-row dense
                            ><v-col>
                              <resultat-categorie
                                v-if="currentPrevis && !Loading"
                                :moisExercice="moisExercice"
                                :currentPrevis="currentPrevis"
                                :categorie="categorie"
                              ></resultat-categorie>
                            </v-col>
                          </v-row>
                        </div>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="currentPrevis && !Loading">
                      <ligne-budgetaire
                        @comptesupdate="comptesupdate"
                        @lbupdate="lbupdate"
                        @lbdelete="lbdelete"
                        @lbup="lbup"
                        @lbdown="lbdown"
                        v-for="(lb, indexlbcat) in lbincategorie(categorie.id)"
                        :pcg="pcg7"
                        :key="indexlbcat"
                        :bgcolor="'blue-grey darken-4'"
                        :moisExercice="moisExercice"
                        :lb="lb"
                        :first="indexlbcat == 0 ? true : false"
                        :last="
                          indexlbcat ==
                          Object.keys(lbincategorie(categorie.id)).length - 1
                            ? true
                            : false
                        "
                      ></ligne-budgetaire>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <ligne-budgetaire-hc
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :lbhc="lbhc((classe = 7))"
                  :pcgs="pcg7"
                ></ligne-budgetaire-hc>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- panels Charges-->
          <v-tab-item>
            <!---->
            <v-row dense class="scroll-horizontal"
              ><v-col>
                <resultat
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                ></resultat>
                <resultat-classe
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :classe="{ intitule: 'Charges', id: 6 }"
                ></resultat-classe>
                <v-expansion-panels
                  accordion
                  :value="[0, 1, 2, 3, 4, 5, 6, 7, 8]"
                  multiple
                >
                  <v-expansion-panel
                    v-for="(categorie, indexcat) in categories(6)"
                    :key="indexcat"
                  >
                    <v-expansion-panel-header hide-actions
                      ><!--hide-actions-->

                      <template v-slot:default="{ open }">
                        <div>
                          <v-row dense>
                            <v-col>
                              <v-icon class="icon">$expand</v-icon>
                              {{ categorie.intitule }}
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    icon
                                    @click.native.stop="
                                      addlb(categorie, (classe = 6))
                                    "
                                    v-on="on"
                                    ><v-icon>mdi-plus</v-icon></v-btn
                                  >
                                </template>
                                <span>Ajouter une ligne bugétaire</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-row dense
                            ><v-col
                              ><resultat-categorie
                                v-if="currentPrevis && !Loading"
                                :moisExercice="moisExercice"
                                :currentPrevis="currentPrevis"
                                :categorie="categorie"
                              ></resultat-categorie></v-col
                          ></v-row>
                        </div>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ligne-budgetaire
                        @comptesupdate="comptesupdate"
                        @lbupdate="lbupdate"
                        @lbdelete="lbdelete"
                        @lbup="lbup"
                        @lbdown="lbdown"
                        v-for="(lb, indexlbcat) in lbincategorie(categorie.id)"
                        :pcg="pcg6"
                        :key="indexlbcat"
                        :bgcolor="'blue-grey darken-4'"
                        :moisExercice="moisExercice"
                        :lb="lb"
                        :first="indexlbcat == 0 ? true : false"
                        :last="
                          indexlbcat ==
                          Object.keys(lbincategorie(categorie.id)).length - 1
                            ? true
                            : false
                        "
                      ></ligne-budgetaire>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <ligne-budgetaire-hc
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :pcgs="pcg6"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :lbhc="lbhc((classe = 6))"
                ></ligne-budgetaire-hc>
              </v-col>
            </v-row>
          </v-tab-item> </v-tabs
      ></v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.scroll-horizontal {
  overflow-x: auto;
  /* white-space: nowrap; Pour que les éléments restent sur une seule ligne */
}

.v-expansion-panel-header--active .icon {
  transform: rotate(-180deg);
}
</style>
<script>
import { EventBus } from "../../../EventBus";

// Le script
export default {
  data() {
    return {
      Loading: true,
      showOnScroll: false,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      tab: null,
      exercices: [],
      exercice: null,
      moisExercice: null,
      currentPrevis: null,
      error: null,
      pcgs: [],
      pcgs_original: [],

      snackbar_text: "",
      snackbar: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },

    produits() {
      let produitfilter = _.filter(
        this.currentPrevis,
        (item) => item.class == 7 && !_.isNil(item.budgetline_categorie_id)
      );

      return _.orderBy(produitfilter, "budgetline_categorie_id");
    },

    charges() {
      return _.filter(
        this.currentPrevis,
        (item) => item.class == 6 && !_.isNil(item.budgetline_categorie_id)
      );
    },
    pcg6() {
      return _.filter(this.pcgs, (item) => item.subcompte1 == 6);
    },
    pcg7() {
      return _.filter(this.pcgs, (item) => item.subcompte1 == 7);
    },
  },
  watch: {
    selectedClient(val) {
      this.error = null;
      this.currentPrevis = null;
      this.exercice = null;
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);

      //console.log("chargement du pcg du client");
      this.getPcgs();

      //console.log("chargement du chois d'exercice");
      this.getExcercices();
    },
    exercice(val) {
      this.error = null;
      this.currentPrevis = null;

      //console.log("chargement de nombre de mois de l'exercice");
      this.getPeriodeExcercices();
      this.getCurrentPrevis();
    },
  },
  methods: {
    existing_cr_revise() {
      let existing_cr_revise = _.find(this.exercices, {
        annee: this.currentPrevis[0].annee + "(r)",
      });

      if (existing_cr_revise == undefined) {
        return false;
      }

      return true;
    },
    categories(classe) {
      if (classe == 7) {
        return [
          { intitule: "Chiffre d'afffaires", id: 1, order: 1 },
          { intitule: "Subvention d'exploitation", id: 2, order: 2 },
          { intitule: "Autres produits d'exploitation", id: 3, order: 3 },
          { intitule: "Autres produits financiers", id: 4, order: 4 },
          { intitule: "Autres produits exceptionnels", id: 5, order: 5 },
        ];
      }
      if (classe == 6) {
        return [
          { intitule: "Achats", id: 6, order: 1 },
          { intitule: "Frais généraux", id: 7, order: 2 },
          {
            intitule: "Impôts, taxes et versements assimilés",
            id: 8,
            order: 3,
          },
          { intitule: "Masse salariale", id: 9, order: 4 },
          {
            intitule: "Dotations aux amortissements et provisions",
            id: 10,
            order: 5,
          },
          { intitule: "Charges financières", id: 11, order: 6 },
          { intitule: "Autres charges d'exploitation", id: 12, order: 7 },
          { intitule: "Autres charges financières", id: 13, order: 8 },
          { intitule: "Autres charges exceptionnelles", id: 14, order: 9 },
        ];
      }
    },
    closeerror() {
      this.error = null;
    },
    onScrolling(e) {
      if (typeof window === "undefined") return;
      let topOffset = window.pageYOffset || e.target.scrollTop || 0;
      this.showOnScroll = topOffset > 60;
    },
    // La fonction de validation pour currentPrevis avant envoi
    validateCurrentPrevis() {
      // Mise à zéro du message d'erreur
      this.error = "";

      // Effectuez la validation pour chaque ligne budgétaire dans la collection currentPrevis
      for (const lb of this.currentPrevis) {
        let precision_categorie = "";
        let precision_intitule = "";

        if (lb.categorie) {
          precision_categorie = "Dans la catégorie " + lb.categorie.intitule;
        }

        // Vérifiez si l'intitulé est null ou vide pour les lignes avec une catégorie
        if (
          lb.categorie &&
          (lb.intitule === null || lb.intitule.trim() === "")
        ) {
          this.error +=
            precision_categorie +
            " : L'intitulé ne peut pas être vide pour une ligne budgétaire<br>";
        } else {
          // Si la ligne a une catégorie ou un intitulé non vide, vérifiez l'unicité de l'intitulé.
          if (lb.intitule) {
            precision_intitule = " pour la ligne budgétaire " + lb.intitule;

            // Vérifiez si l'intitulé est unique au sein de sa catégorie
            const duplicateIntitule = this.currentPrevis.find(
              (item) =>
                item !== lb &&
                item.budgetline_categorie_id === lb.budgetline_categorie_id &&
                item.intitule.trim().toLowerCase() ===
                  lb.intitule.trim().toLowerCase()
            );

            if (duplicateIntitule) {
              // La validation échoue si un intitulé en double est trouvé
              this.error +=
                precision_categorie +
                precision_intitule +
                " : l'intitulé est en double au sein d'une même catégorie<br>";
            }
          }

          // Validez la collection de comptes pour les lignes avec une catégorie
          if (lb.categorie && lb.comptes) {
            const duplicateAccount = lb.comptes.find((compte, index) => {
              const comptePrefix = compte.compte.match(/^\d+[^0]/)[0];
              return (
                lb.comptes
                  .slice(0, index)
                  .some(
                    (prevCompte) =>
                      prevCompte.compte.match(/^\d+[^0]/)[0] === comptePrefix ||
                      prevCompte.compte.startsWith(comptePrefix)
                  ) ||
                lb.comptes
                  .slice(index + 1)
                  .some(
                    (nextCompte) =>
                      nextCompte.compte.match(/^\d+[^0]/)[0] === comptePrefix ||
                      nextCompte.compte.startsWith(comptePrefix)
                  )
              );
            });

            if (duplicateAccount) {
              // La validation échoue si un préfixe de compte en double est trouvé
              this.error +=
                precision_categorie +
                precision_intitule +
                " : Un compte en double a été trouvé<br>";
            }
          }
        }

        // Validez les valeurs numériques pour chaque mois dans chaque ligne budgétaire
        for (let i = 0; i < this.moisExercice.length; i++) {
          let monthIndex = i + 1;
          let month = "mois_" + monthIndex;
          let value = Number(lb[month]);

          if (!Number.isInteger(value) || value < 0) {
            // La validation échoue si une valeur de mois n'est pas un entier positif valide

            let produits_charges;
            if (lb.class == 6) {
              produits_charges = "charges";
            } else {
              produits_charges = "produits";
            }
            // cas d'une ligne budgetaire hors categorie
            if (precision_categorie == "" || precision_categorie === null) {
              this.error +=
                "Dans le prévisionnel " +
                produits_charges +
                " pour les comptes non affectés, La valeur du mois " +
                this.moisExercice[i] +
                " n'est pas un entier positif valide<br>";
            } else {
              this.error +=
                precision_categorie +
                precision_intitule +
                " : La valeur du mois " +
                this.moisExercice[i] +
                " n'est pas un entier positif valide<br>";
            }
          }
        }
      }

      // Toutes les vérifications de validation réussissent
      if (this.error === "") {
        this.error = null;
        return true;
      }

      return false;
    },

    enregistrer() {
      this.error = null;
      // Effectuez la validation avant d'envoyer les données au serveur
      if (!this.validateCurrentPrevis()) {
        // Si la validation échoue, affichez un message d'erreur ou gérez l'erreur comme vous le souhaitez
        this.error =
          "La validation a échoué. Veuillez vérifier vos saisies.<br>" +
          this.error;
        return;
      }

      // Si la validation réussit, procédez à l'envoi des données au serveur
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/savePrevis/" + this.selectedClient.id, {
            currentPrevis: this.currentPrevis,
            moisExercice: this.moisExercice,
            exercice: this.exercice,
            reviser: 0, // false
          })
          .then((response) => {
            console.log("/api/savePrevis", response);
            this.snackbar = true;
            this.snackbar_text = "Prévisionnel enregistré !";

            EventBus.$emit("savePrevis", this.currentPrevis);
            this.$emit("savePrevis", this.currentPrevis);

            this.getExcercices().then(() => {
              this.exercice = _.find(this.exercices, {
                annee: response.data.annee,
              });
              this.getCurrentPrevis();
            });
          })
          .catch((err) => {
            console.log("/api/savePrevis", err);

            if (err.response) {
              // Le serveur a répondu avec un code d'erreur (par exemple 422, 500, etc.)
              console.log("Erreur de validation :", err.response.data.errors);
              const serverErrors = err.response.data.errors;
              this.error = this.parseServerErrors(serverErrors); // Utilisez une fonction pour extraire le message d'erreur
            } else if (err.request) {
              // La requête a été faite, mais aucune réponse n'a été reçue
              console.log("Erreur de requête :", err.request);
              this.error =
                "Une erreur de requête s'est produite. Veuillez réessayer plus tard.";
            } else {
              // Une erreur inconnue s'est produite
              console.log("Erreur :", err.message);
              this.error =
                "Une erreur s'est produite. Veuillez réessayer plus tard.";
            }
          });
      });
    },

    reviser() {
      this.error = null;
      // Effectuez la validation avant d'envoyer les données au serveur
      if (!this.validateCurrentPrevis()) {
        // Si la validation échoue, affichez un message d'erreur ou gérez l'erreur comme vous le souhaitez
        this.error =
          "La validation a échoué. Veuillez vérifier vos saisies.<br>" +
          this.error;
        return;
      }

      // Si la validation réussit, procédez à l'envoi des données au serveur
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/savePrevis/" + this.selectedClient.id, {
            currentPrevis: this.currentPrevis,
            moisExercice: this.moisExercice,
            exercice: this.exercice,
            reviser: 1, //true
          })
          .then((response) => {
            console.log("/api/savePrevis", response);
            this.snackbar = true;
            this.snackbar_text = "Prévisionnel révisé !";

            EventBus.$emit("savePrevis", this.currentPrevis);
            this.$emit("savePrevis", this.currentPrevis);

            this.getExcercices().then(() => {
              this.exercice = _.find(this.exercices, {
                annee: response.data.annee,
              });
              this.getCurrentPrevis();
            });
          })
          .catch((err) => {
            console.log("/api/savePrevis", err);

            if (err.response) {
              // Le serveur a répondu avec un code d'erreur (par exemple 422, 500, etc.)
              console.log("Erreur de validation :", err.response.data.errors);
              const serverErrors = err.response.data.errors;
              this.error = this.parseServerErrors(serverErrors); // Utilisez une fonction pour extraire le message d'erreur
            } else if (err.request) {
              // La requête a été faite, mais aucune réponse n'a été reçue
              console.log("Erreur de requête :", err.request);
              this.error =
                "Une erreur de requête s'est produite. Veuillez réessayer plus tard.";
            } else {
              // Une erreur inconnue s'est produite
              console.log("Erreur :", err.message);
              this.error =
                "Une erreur s'est produite. Veuillez réessayer plus tard.";
            }
          });
      });
    },
    // Fonction pour extraire le message d'erreur du serveur
    parseServerErrors(errors) {
      const errorMessage =
        "Une erreur de validation s'est produite. Veuillez vérifier vos données.";

      if (errors && typeof errors === "object") {
        for (const key in errors) {
          if (key.startsWith("currentPrevis.")) {
            return errors[key][0]; // Retourne le message d'erreur trouvé
          }
        }
      }

      return errorMessage;
    },
    // ligne budgetaire hors categorie en fonction de la classe
    lbhc(classe) {
      return _.find(this.currentPrevis, {
        budgetline_categorie_id: null,
        class: classe,
      });
    },

    // Fonction pour trouver l'index de l'objet par son "order" et "categorie"
    findIndexByOrderAndCategory(order, categorie) {
      const filteredItems = _.filter(this.currentPrevis, {
        budgetline_categorie_id: categorie,
      });
      return filteredItems.findIndex(
        (item) =>
          item.order == order && item.budgetline_categorie_id == categorie
      );
    },

    // Fonction pour échanger deux éléments dans la collection, compte tenu des éléments filtrés par catégorie
    swapElements(filteredIndexA, filteredIndexB, categorie_id) {
      const itemA = _.filter(this.currentPrevis, {
        budgetline_categorie_id: categorie_id,
      })[filteredIndexA];
      const itemB = _.filter(this.currentPrevis, {
        budgetline_categorie_id: categorie_id,
      })[filteredIndexB];

      // Trouver les index réels dans this.currentPrevis
      const indexA = this.currentPrevis.findIndex((item) => item === itemA);
      const indexB = this.currentPrevis.findIndex((item) => item === itemB);

      [this.currentPrevis[indexA], this.currentPrevis[indexB]] = [
        this.currentPrevis[indexB],
        this.currentPrevis[indexA],
      ];
    },

    // Fonction pour mettre à jour les numéros "order" après le déplacement
    updateOrderAfterMove(categorie_id) {
      const filteredItems = _.filter(this.currentPrevis, {
        budgetline_categorie_id: categorie_id,
      });

      // Trier les éléments filtrés par ordre avant de les mettre à jour
      // filteredItems.sort((a, b) => a.order - b.order);

      filteredItems.forEach((item, index) => {
        item.order = index + 1;
      });

      // Remplacer les éléments d'origine par les éléments mis à jour

      filteredItems.forEach((item) => {
        const index = this.currentPrevis.findIndex((el) => el === item);
        this.currentPrevis.splice(index, 1, item);
      });
    },

    // Fonction pour monter une ligne dans le classement
    lbup(lb) {
      var order = lb.order;
      var categorie_id = lb.budgetline_categorie_id;

      const currentIndex = this.findIndexByOrderAndCategory(
        order,
        categorie_id
      );

      if (currentIndex > 0) {
        const previousIndex = currentIndex - 1;
        this.swapElements(currentIndex, previousIndex, categorie_id);
        this.updateOrderAfterMove(categorie_id);
      }
    },

    // Fonction pour descendre une ligne dans le classement
    lbdown(lb) {
      var order = lb.order;
      var categorie_id = lb.budgetline_categorie_id;

      const currentIndex = this.findIndexByOrderAndCategory(
        order,
        categorie_id
      );

      if (currentIndex < this.currentPrevis.length - 1) {
        const nextIndex = currentIndex + 1;
        this.swapElements(currentIndex, nextIndex, categorie_id);
        this.updateOrderAfterMove(categorie_id);
      }
    },

    //suppression d'une ligne budgetaire
    lbdelete(lb) {
      // on supprime la ligne
      this.currentPrevis = _.reject(this.currentPrevis, lb);

      // on met à jour du champ order
      var filteredItems = _.filter(this.currentPrevis, {
        budgetline_categorie_id: lb.budgetline_categorie_id,
      });
      var sortedItems = _.sortBy(filteredItems, "order");

      sortedItems.forEach((item, index) => {
        item.order = index + 1;
      });

      this.currentPrevis = _.sortBy(this.currentPrevis, "order");

      this.recomputePcg();
    },

    //ajout d'une LB dans une categorie
    addlb(categorie, classe) {
      // liste des lignes budgetaire de la categorie
      const existingLines = _.filter(this.currentPrevis, {
        budgetline_categorie_id: categorie.id,
      });

      // Trouver le prochain numéro d'ordre
      const nextOrder = existingLines.length + 1;

      // Construire l'intitulé par défaut avec le numéro d'ordre
      let defaultIntitule =
        "Ligne budgétaire de " + `${categorie.intitule} ${nextOrder}`;

      // Vérifier si l'intitulé existe déjà
      const intitules = _.map(existingLines, "intitule");
      let copyNumber = 1;
      while (intitules.includes(defaultIntitule)) {
        defaultIntitule =
          "Ligne budgétaire de " +
          `${categorie.intitule} ${nextOrder} (copy${copyNumber})`;
        copyNumber++;
      }

      var newlb = {
        annee: this.exercice.annee,
        budgetline_categorie_id: categorie.id,
        categorie: { intitule: categorie.intitule, id: categorie.id },
        class: classe,
        client_id: this.selectedClient.id,
        etat: "initial", // n'a pas d'importance - todo à verifier
        id: null,
        intitule: defaultIntitule,
        comptes: [],
        mois_1: 0,
        mois_10: 0,
        mois_11: 0,
        mois_12: 0,
        mois_2: 0,
        mois_3: 0,
        mois_4: 0,
        mois_5: 0,
        mois_6: 0,
        mois_7: 0,
        mois_8: 0,
        mois_9: 0,
        order: nextOrder,
        total: 0,
      };

      this.currentPrevis.push(newlb);

      this.currentPrevis = _.sortBy(
        this.currentPrevis,
        "budgetline_categorie_id"
      );
      this.currentPrevis = _.sortBy(this.currentPrevis, "order");
    },

    // une ligne budgetaire a été modifiée
    lbupdate(lb) {
      //console.log("lbupdate");
    },

    comptesupdate(changes) {
      this.recomputePcg();
    },

    // liste des previs pour une categorie
    lbincategorie(budgetline_categorie_id) {
      return _.filter(
        this.currentPrevis,
        (item) => item.budgetline_categorie_id == budgetline_categorie_id
      );
    },

    /*
     * PCG du client selectionné
     */

    getPcgs() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/getpcgs/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/getpcgs/ OK");
            this.pcgs = response.data;
            this.pcgs_original = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getpcgs/ KO", err);
            console.log(err);
            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
     * Liste des exercices
     */

    getExcercices() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/exerciceschoice/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/exerciceschoice/ OK");
            this.exercices = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceschoice/ KO", err);
            console.log(err);

            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
     * Liste des mois de l'excercice
     */

    getPeriodeExcercices() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/exerciceperiode/" +
              this.selectedClient.id +
              "/" +
              this.exercice.annee
          )
          .then((response) => {
            console.log("/api/exerciceperiode/ OK");
            this.moisExercice = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceperiode/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    /*
     * Chargement du previs
     */

    getCurrentPrevis() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/getprevis/" +
              this.selectedClient.id +
              "/" +
              this.exercice.annee +
              "/" +
              this.exercice.etat
          )
          .then((response) => {
            console.log("/api/getprevis/ OK");
            this.currentPrevis = response.data;
            this.Loading = false;

            this.recomputePcg();

            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getprevis/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    recomputePcg() {
      // on part d'un nouveau pcg basé suyr le pcg original
      this.pcgs = this.pcgs_original;

      // liste des comptes présent dans une categorie
      // qui sont à exclure du pcg
      const prefixesToExclude = [];

      const filteredPrevis = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id !== null
      );

      filteredPrevis.forEach((item) => {
        item.comptes.forEach((compte) => {
          const prefix = compte.compte.match(/^\d+[^0]/)[0];
          if (!prefixesToExclude.includes(prefix)) {
            prefixesToExclude.push(prefix);
          }
        });
      });

      // Filtrer les comptes qui ne partagent pas les mêmes préfixes que prefixesToExclude
      this.pcgs = this.pcgs.filter((pcg) => {
        const comptePrefix = pcg.compte.match(/^\d+[^0]/)[0];
        return !prefixesToExclude.some(
          (prefix) => comptePrefix === prefix || comptePrefix.startsWith(prefix)
        );
      });

      _.orderBy(this.pcgs, "compte");

      // on met à jour dans current previs
      // pour les produits  hors categorie
      let pcg7 = (_.find(this.currentPrevis, {
        class: 7,
        budgetline_categorie_id: null,
      }).comptes = this.pcg7);

      // on met à jour dans current previs
      // pour les charges hors categorie
      let pcg6 = (_.find(this.currentPrevis, {
        class: 6,
        budgetline_categorie_id: null,
      }).comptes = this.pcg6);
    },
  },

  mounted() {
    // recuperation sauvegarde du client selectionné
    if (localStorage.selectedClient) {
      this.selectedClient = JSON.parse(localStorage.selectedClient);
    }
    this.getClients();
  },
};
</script>