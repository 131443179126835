<template>
  <!-- Le template -->
  <!-- Le template -->
  <section>
    <v-container>
      <h4 class="mb-8">Importation de la balance annuelle</h4>

      <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
        <ul>
          <div v-for="(value, propertyName, index) in formError" :key="index">
            <li v-if="value && Array.isArray(value)" >
              {{ propertyName }}: <span class="pre-formatted">{{ value[0] }}</span>
            </li>
            <li v-if="value && typeof value == 'string'" >
              {{ propertyName }}: <span class="pre-formatted">{{ value }}</span>
            </li>
          </div>
        </ul>
      </v-alert>

      <v-snackbar
        v-model="snackbar"
        bottom
        color="success"
        multi-line
        :timeout="6000"
      >
        <p v-html="snackbar_text"></p>

        <v-btn
          dark
          color="green darken-4"
          @click="
            snackbar_text = '';
            snackbar = false;            
          "
          >Fermer</v-btn
        >
      </v-snackbar>
      <v-form ref="form" v-model="valid" >
        <v-row>
          <v-col>
            <v-menu
              offset-y
              max-width="290px"
              min-width="290px"
              :close-on-content-click="false" 
              v-model="datepickermenu"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  readonly
                  clearable
                  class="my-0 py-0"
                  :value="dateCloture ? $moment(dateCloture).format('DD/MM/YYYY') : ''"
                  label="Date de cloture"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  :rules="[rules.required]"
                  :error-messages="formError.dateCloture"                  
                  @focus="formError.dateCloture = null"
                  @click:clear="dateCloture = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="fr"
                color="primary"
                first-day-of-week="1"
                :show-current="true"
                v-model="dateCloture"
                 @change="datepickermenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <!-- balanceannuelle FILE -->
          <v-col>
            <v-file-input dense show-size
            
            v-model="balanceannuellefile"
            ref="balanceannuellefile"
            accept=".csv"
            hint="Fichier au format CSV '(*.csv) avec encodage UTF8 si le fichier comporte des accents.<br>
            Cinq colonnes sont attendues.<br><br>
            Colonne 1 : numéro de compte<br>
            Colonne 2 : libelle (pourra être vide)<br>
            Colonne 3 : débit (pourra être vide)<br>
            Colonne 4 : crédit (pourra être vide)<br>
            Colonne 5 : solde <br><br>

            Une ligne par compte"
            persistent-hint
            label="Fichier balanceannuelle (CSV)"
                  :error-messages="formError.balanceannuelle"
                  :rules="[rules.required]"
                  @focus="formError.balanceannuelle = null" >
            <template v-slot:message="{ message, key }">
            <div class="mt-4" v-html="message"></div>
            </template>      
            </v-file-input>

          </v-col>
        </v-row>
        <v-btn
          class="mt-4"
          color="primary"
          @click.prevent="submit"
          :disabled="!valid"
        >
          <v-icon left>mdi-check</v-icon>
          Enregistrer
        </v-btn>
      </v-form>
    </v-container>
  </section>
</template>

<style>
.pre-formatted {white-space: pre-wrap;}
</style>

<script>

import {EventBus} from "../../EventBus";

// Le script
export default {
  name: "balanceAnnuelleImportForm",
  props: {
    clientID: Number,
  },
  data() {
    return {

      isSaving: false,

      serverAlert: null,
      snackbar_text: "",
      snackbar: false,

      datepickermenu: false,

      balanceannuellefile : null,
      dateCloture : null,
      valid: false,

      formError: {
        balanceannuellefile: null,
        dateCloture: null
      },

      rules: {
        required: (v) => !!v || "Ce champ est requis",

      },
    };
  },
  methods: {
    submit() {
      this.isSaving = true;
      this.serverAlert = null;

      let formData = new FormData();

      formData.append("balanceannuellefile",this.balanceannuellefile);
      formData.append("client_id", this.clientID);
      formData.append("dateCloture", this.dateCloture);
        
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/balance-annuelle/import/" + this.clientID, formData)
          .then((response) => {
            console.log("submit OK", response);

            this.isSaving = false;
            this.snackbar = true;
            this.snackbar_text = "Fichier Balance Annuelle uploadé";
            this.$refs.balanceannuellefile.reset()
            this.balanceannuellefile=null
            EventBus.$emit('balanceannuelleUploaded',this.clientID)


            
            

          })
          .catch((err) => {
            this.serverAlert = true;
            this.isSaving = false;
            console.log("submit_ko", err);

            if (err.response.status == 422) {
              Object.assign(this.formError, err.response.data.errors);
            }

            this.formError.general = err.response.data.message;
            this.$refs.balanceannuellefile.reset()
            this.balanceannuellefile=null
          });
      });
      return;
    },      
  },
};
</script>