<!-- Le template -->
<template>
  <v-container :key="componentKey" fluid>
    <v-row class="mt-1">
      <v-col cols="12">
        <v-form
          class="d-flex justify-space-between align-center"
          lazy-validation
          ref="form"
          :value="validform"
          :v-model="validform"
          @submit.prevent="validateForm"
          cols="12"
          style="width: 100%"
        >
          <h3 class="my-0">Suivi budgétaire personnalisé&nbsp;:</h3>

          <v-spacer></v-spacer>

          <!--Choix du client-->

          <v-autocomplete
            class="my-0"
            label="Rechercher Sélectionner un client"
            :items="clients"
            clearable
            deletable-chips
            item-value="id"
            return-object
            v-model="selectedClient"
            dense
            :rules="[rules.required]"
            :error-messages="formError.selectedClient"
            @focus="clearErrorMessage('selectedClient')"
            @click:clear="clearErrorMessage('selectedClient')"
          ></v-autocomplete>
          <v-spacer></v-spacer>

          <!-- annee d'exercice <v-spacer></v-spacer> -->

          <v-select
            class="my-0"
            v-if="selectedClient && !Loading"
            v-model="exercice"
            :items="exercices"
            item-text="annee"
            item-value="annee"
            label="Année du début d'exercice"
            persistent-hint
            return-object
            single-line
            :rules="[rules.required]"
            :error-messages="formError.exercice"
            @focus="clearErrorMessage('exercice')"
            @click:clear="clearErrorMessage('exercice')"
          ></v-select>
          <v-spacer></v-spacer>

          <!-- debut periode -->
          <v-menu
            offset-y
            max-width="290px"
            min-width="290px"
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                readonly
                clearable
                class="my-0 py-0"
                :value="debutperiode"
                label="Date de début de période"
                prepend-icon="mdi-calendar"
                v-on="on"
                :rules="[rules.required, rules.dateRange]"
                :error-messages="formError.debutperiode"
                @focus="clearErrorMessage('debutperiode')"
                @click:clear="clearErrorMessage('debutperiode')"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="fr"
              color="primary"
              first-day-of-week="1"
              :show-current="true"
              v-model="debutperiode"
            />
          </v-menu>
          <v-spacer></v-spacer>

          <!-- fin periode -->

          <v-menu
            offset-y
            max-width="290px"
            min-width="290px"
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                readonly
                clearable
                class="my-0 py-0"
                :value="finperiode"
                label="Date de fin de période"
                prepend-icon="mdi-calendar"
                v-on="on"
                :rules="[rules.required, rules.dateRange]"
                :error-messages="formError.finperiode"
                @focus="clearErrorMessage('finperiode')"
                @click:clear="clearErrorMessage('finperiode')"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="fr"
              color="primary"
              first-day-of-week="1"
              :show-current="true"
              v-model="finperiode"
            />
          </v-menu>
          <v-spacer></v-spacer>

          <!--Switch K€ -->
          <v-switch
            class="my-0"
            dense
            label="K€"
            v-model="kilo_euro"
          ></v-switch>
          <v-spacer></v-spacer>

          <v-btn type="submit">Actualiser</v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
      <ul>
        <div v-for="(value, propertyName, index) in serverAlert" :key="index">
          <li v-if="value && Array.isArray(value)">
            {{ propertyName }}:
            <span class="pre-formatted">{{ value[0] }}</span>
          </li>
          <li v-if="value && typeof value == 'string'">
            {{ propertyName }}: <span class="pre-formatted">{{ value }}</span>
          </li>
        </div>
      </ul>
    </v-alert>

    <v-progress-linear
      v-if="this.validform && this.currentPrevis.length == 0"
      color="light-blue darken-1"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>

    <v-row
      v-if="
        !this.Loading &&
        this.currentPrevis.length > 0 &&
        this.exercice &&
        !serverAlert
      "
    >
      <v-col>
        <v-row>
          <v-col
            class="custombgcolor"
            style="margin-left: 118px; margin-right: 14px"
          >
            <h4 class="mr-3 my-3">
              Situation du
              {{ this.$moment(debutperiode).format("Do MMMM YYYY") }} au
              {{ this.$moment(finperiode).format("Do MMMM YYYY") }}
            </h4>
            <!-- RESULTAT Table  -->
            <v-simple-table v-if="!Loading" fixed-header dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                    <th class="text-right">
                      Réalisé<br />N-1 (<span v-if="kilo_euro">K€</span
                      ><span v-else>€</span>)
                    </th>
                    <th class="text-right">
                      BP Annuel<br />Initial (<span v-if="kilo_euro">K€</span
                      ><span v-else>€</span>)
                    </th>
                    <th class="text-right">
                      BP Annuel<br />Révisé (<span v-if="kilo_euro">K€</span
                      ><span v-else>€</span>)
                    </th>
                    <th class="text-right">
                      Prévu (<span v-if="kilo_euro">K€</span
                      ><span v-else>€</span>)
                    </th>
                    <th class="text-right">
                      Réel (<span v-if="kilo_euro">K€</span
                      ><span v-else>€</span>)
                    </th>
                    <th class="text-left">
                      Ecart (<span v-if="kilo_euro">K€</span
                      ><span v-else>€</span>)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background-color: #00bcd4 !important">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td class="text-right">
                      {{ colspace(40) }}Résultat{{ colspace(40) }}
                    </td>
                    <!--réalisé  -->
                    <td class="text-right">{{ format(ResultatNmoins1()) }}</td>
                    <!--initial -->
                    <td class="text-right">
                      {{ format(ResultatAnnuelInitial()) }}
                    </td>
                    <!--révisé -->
                    <td class="text-right">
                      {{ format(ResultatAnnuelRevise()) }}
                    </td>
                    <!--Prévu -->
                    <td class="text-right">
                      {{ format(ResultatPrevu()) }}
                    </td>

                    <!--Réel -->
                    <td class="text-right">{{ format(ResultatReel()) }}</td>
                    <!--Ecart -->
                    <td>{{ format(ResultatReel() - ResultatPrevu()) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-tabs vertical
              ><!-- MENU onlgets vertical (produits|charges) -->
              <v-tab>Produits</v-tab>
              <v-tab>Charges</v-tab>
              <!-- panels Produits-->
              <v-tab-item class="pa-4 custombgcolor">
                <v-row
                  ><!-- exports-->
                  <v-col class="d-flex justify-end">
                    <v-btn
                      @click="exportImage('situation_produits')"
                      color="grey darken-3"
                      class="mr-1"
                    >
                      <v-icon dark left>mdi-file-image</v-icon>image</v-btn
                    >

                    <!-- EXPORTATION EXCEL -->

                    <export-excel
                      :data="previs7"
                      :name="'situation_produits.xls'"
                      :fields="excelfields()"
                    >
                      <v-btn color="grey darken-3 "
                        ><v-icon dark left>mdi-file-export-outline</v-icon
                        >Excel</v-btn
                      >
                    </export-excel>
                  </v-col>
                </v-row>
                <v-row
                  ><!-- datatable Produits -->
                  <v-col>
                    <v-simple-table
                      fixed-header
                      dark
                      dense
                      id="1"
                      :key="produitsDatatableKey"
                      ><!-- v-if=""-->
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="padding: 5px">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-checkbox
                                    v-model="selectallprevis7"
                                    v-on="on"
                                    dense
                                    hide-details
                                    true-value="1"
                                    false-value="0"
                                    color="grey lighten-5"
                                    @click="
                                      toggleAllRowVisibility(
                                        selectallprevis7,
                                        previs7
                                      )
                                    "
                                  ></v-checkbox>
                                </template>
                                <span
                                  >Tout Masquer (pour l'exportation en
                                  image)</span
                                >
                              </v-tooltip>
                            </th>
                            <th class="text-left">Comptes</th>
                            <th class="text-left">Intitulé</th>
                            <th class="text-right">
                              Réalisé<br />N-1 (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              BP Annuel<br />Initial (<span v-if="kilo_euro"
                                >K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              BP Annuel<br />Révisé (<span v-if="kilo_euro"
                                >K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              Prévu (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              Réel (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-left">
                              Ecart (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(lb, lbindex) in previs7"
                            :key="lbindex"
                            :class="lb.entete"
                          >
                            <td class="checkboxTD">
                              <v-checkbox
                                @click="toggleRowVisibility(previs7, lbindex)"
                                :input-value="lb.show"
                                v-model="lb.show"
                                true-value="1"
                                false-value="0"
                                dense
                                hide-details
                                color="grey lighten-5"
                              ></v-checkbox>
                            </td>
                            <td>
                              <v-tooltip
                                top
                                content-class="custom-tooltip"
                                max-width="80%"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon dark v-bind="attrs" v-on="on"
                                    ><v-icon color="grey lighten-1"
                                      >mdi-information</v-icon
                                    ></v-btn
                                  >
                                </template>
                                <!-- comptes (etat, lbid, lbcategorie, lbclass)  -->
                                <span>
                                  <span>Liste des comptes concernés :</span
                                  ><br />
                                  <span
                                    v-html="
                                      comptes(
                                        null,
                                        lb.id,
                                        lb.budgetline_categorie_id,
                                        7
                                      ).join(', ')
                                    "
                                  ></span>
                                </span>
                              </v-tooltip>
                            </td>
                            <td>{{ lb.intitule }}</td>

                            <!--balance réalisé n-1   -->
                            <td class="text-right">
                              {{ format(lb["realise_n_1"]) }}&nbsp;
                            </td>
                            <!--previs annuel initial -->
                            <td class="text-right">
                              {{ format(lb["bp_annuel_initial"]) }}&nbsp;
                            </td>
                            <!--previs annuel révisé -->
                            <td class="text-right">
                              {{ format(lb["bp_annuel_revise"]) }}&nbsp;
                            </td>
                            <!--previ mensuel -->
                            <td class="text-right">
                              {{ format(lb["prevu"]) }}&nbsp;
                            </td>
                            <!--Réel mensuel -->
                            <td class="text-right">
                              {{ format(lb["reel"]) }}&nbsp;
                            </td>
                            <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                            <td>
                              <v-icon
                                v-if="lb['ecart'] != '-'"
                                :color="lb['ecart'] < 0 ? 'red' : '#43a047'"
                                >mdi-circle</v-icon
                              >&nbsp;{{ format(lb["ecart"]) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <!-- Table pour exportation -->
                <div style="position: absolute; z-index: -1">
                  <v-row
                    ><v-col>
                      <v-simple-table
                        fixed-header
                        light
                        dense
                        :id="'situation_produits'"
                        :key="exportProduitsDatatableKey"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Intitulé</th>
                              <th class="text-right">
                                Réalisé<br />N-1 (<span v-if="kilo_euro"
                                  >K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                BP Annuel<br />Initial (<span v-if="kilo_euro"
                                  >K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                BP Annuel<br />Révisé (<span v-if="kilo_euro"
                                  >K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                Prévu (<span v-if="kilo_euro">K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                Réel (<span v-if="kilo_euro">K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-left">
                                Ecart (<span v-if="kilo_euro">K€</span
                                ><span v-else>€</span>)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(lb, lbindexbis) in previs7">
                              <tr
                                :class="lb.entete"
                                :key="lbindexbis"
                                v-if="lb.show == 1"
                              >
                                <td>{{ lb.intitule }}</td>
                                <!--balance réalisé n-1   -->
                                <td class="text-right">
                                  {{ format(lb["realise_n_1"]) }}&nbsp;
                                </td>
                                <!--previs annuel initial -->
                                <td class="text-right">
                                  {{ format(lb["bp_annuel_initial"]) }}&nbsp;
                                </td>
                                <!--previs annuel révisé -->
                                <td class="text-right">
                                  {{ format(lb["bp_annuel_revise"]) }}&nbsp;
                                </td>
                                <!--previ mensuel -->
                                <td class="text-right">
                                  {{ format(lb["prevu"]) }}&nbsp;
                                </td>
                                <!--Réel mensuel -->
                                <td class="text-right">
                                  {{ format(lb["reel"]) }}&nbsp;
                                </td>
                                <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                                <td>
                                  <v-icon
                                    v-if="lb['ecart'] != '-'"
                                    :color="lb['ecart'] < 0 ? 'red' : '#43a047'"
                                    >mdi-circle</v-icon
                                  >&nbsp;{{ format(lb["ecart"]) }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
              <!-- panels Charges-->
              <v-tab-item class="pa-4 custombgcolor">
                <v-row
                  ><!-- exports-->
                  <v-col class="d-flex justify-end">
                    <v-btn
                      @click="exportImage('situation_charges')"
                      color="grey darken-3"
                      class="mr-1"
                    >
                      <v-icon dark left>mdi-file-image</v-icon>image</v-btn
                    >

                    <!-- EXPORTATION EXCEL " -->

                    <export-excel
                      :data="previs6"
                      :name="'situation_charges.xls'"
                      :fields="excelfields()"
                    >
                      <v-btn color="grey darken-3 "
                        ><v-icon dark left>mdi-file-export-outline</v-icon>Excel
                      </v-btn>
                    </export-excel>
                  </v-col>
                </v-row>
                <v-row
                  ><!-- datatable charge -->
                  <v-col>
                    <v-simple-table
                      fixed-header
                      dark
                      dense
                      id="1"
                      :key="chargesDatatableKey"
                      ><!-- v-if=""-->
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="padding: 5px">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-checkbox
                                    v-model="selectallprevis6"
                                    v-on="on"
                                    dense
                                    hide-details
                                    true-value="1"
                                    false-value="0"
                                    color="grey lighten-5"
                                    @click="
                                      toggleAllRowVisibility(
                                        selectallprevis6,
                                        previs6
                                      )
                                    "
                                  ></v-checkbox>
                                </template>
                                <span
                                  >Tout Masquer (pour l'exportation en
                                  image)</span
                                >
                              </v-tooltip>
                            </th>
                            <th class="text-left">Comptes</th>
                            <th class="text-left">Intitulé</th>
                            <th class="text-right">
                              Réalisé<br />N-1 (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              BP Annuel<br />Initial (<span v-if="kilo_euro"
                                >K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              BP Annuel<br />Révisé (<span v-if="kilo_euro"
                                >K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              Prévu (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-right">
                              Réel (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                            <th class="text-left">
                              Ecart (<span v-if="kilo_euro">K€</span
                              ><span v-else>€</span>)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(lb, lbindex) in previs6"
                            :key="lbindex"
                            :class="lb.entete"
                          >
                            <td class="checkboxTD">
                              <v-checkbox
                                @click="toggleRowVisibility(previs6, lbindex)"
                                :input-value="lb.show"
                                v-model="lb.show"
                                true-value="1"
                                false-value="0"
                                dense
                                hide-details
                                color="grey lighten-5"
                              ></v-checkbox>
                            </td>
                            <td>
                              <v-tooltip
                                top
                                content-class="custom-tooltip"
                                max-width="80%"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon dark v-bind="attrs" v-on="on"
                                    ><v-icon color="grey lighten-1"
                                      >mdi-information</v-icon
                                    ></v-btn
                                  >
                                </template>
                                <!-- comptes (etat, lbid, lbcategorie, lbclass)  -->
                                <span>
                                  <span>Liste des comptes concernés :</span
                                  ><br />
                                  <span
                                    v-html="
                                      comptes(
                                        null,
                                        lb.id,
                                        lb.budgetline_categorie_id,
                                        6
                                      ).join(', ')
                                    "
                                  ></span>
                                </span>
                              </v-tooltip>
                            </td>
                            <td>{{ lb.intitule }}</td>

                            <!--balance réalisé n-1   -->
                            <td class="text-right">
                              {{ format(lb["realise_n_1"]) }}&nbsp;
                            </td>
                            <!--previs annuel initial -->
                            <td class="text-right">
                              {{ format(lb["bp_annuel_initial"]) }}&nbsp;
                            </td>
                            <!--previs annuel révisé -->
                            <td class="text-right">
                              {{ format(lb["bp_annuel_revise"]) }}&nbsp;
                            </td>
                            <!--previ mensuel -->
                            <td class="text-right">
                              {{ format(lb["prevu"]) }}&nbsp;
                            </td>
                            <!--Réel mensuel -->
                            <td class="text-right">
                              {{ format(lb["reel"]) }}&nbsp;
                            </td>
                            <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                            <td>
                              <v-icon
                                v-if="lb['ecart'] != '-'"
                                :color="lb['ecart'] <= 0 ? '#43a047' : 'red'"
                                >mdi-circle</v-icon
                              >&nbsp;{{ format(lb["ecart"]) }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <!-- Table pour exportation -->
                <div style="position: absolute; z-index: -1">
                  <v-row
                    ><v-col>
                      <v-simple-table
                        fixed-header
                        light
                        dense
                        :id="'situation_charges'"
                        :key="exportChargesDatatableKey"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Intitulé</th>
                              <th class="text-right">
                                Réalisé<br />N-1 (<span v-if="kilo_euro"
                                  >K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                BP Annuel<br />Initial (<span v-if="kilo_euro"
                                  >K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                BP Annuel<br />Révisé (<span v-if="kilo_euro"
                                  >K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                Prévu (<span v-if="kilo_euro">K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-right">
                                Réel (<span v-if="kilo_euro">K€</span
                                ><span v-else>€</span>)
                              </th>
                              <th class="text-left">
                                Ecart (<span v-if="kilo_euro">K€</span
                                ><span v-else>€</span>)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(lb, lbindexbis) in previs6">
                              <tr
                                :class="lb.entete"
                                :key="lbindexbis"
                                v-if="lb.show == 1"
                              >
                                <td>{{ lb.intitule }}</td>
                                <!--balance réalisé n-1   -->
                                <td class="text-right">
                                  {{ format(lb["realise_n_1"]) }}&nbsp;
                                </td>
                                <!--previs annuel initial -->
                                <td class="text-right">
                                  {{ format(lb["bp_annuel_initial"]) }}&nbsp;
                                </td>
                                <!--previs annuel révisé -->
                                <td class="text-right">
                                  {{ format(lb["bp_annuel_revise"]) }}&nbsp;
                                </td>
                                <!--previ mensuel -->
                                <td class="text-right">
                                  {{ format(lb["prevu"]) }}&nbsp;
                                </td>
                                <!--Réel mensuel -->
                                <td class="text-right">
                                  {{ format(lb["reel"]) }}&nbsp;
                                </td>
                                <!-- Ecart (reel - prevu ) 
                                      pour les produits si ecart <0 => rouge si ecart >0 => vert
                                      pour les charges si ecart <0 => vert  si ecart >0 => rouge
                                    -->
                                <td>
                                  <v-icon
                                    v-if="lb['ecart'] != '-'"
                                    :color="
                                      lb['ecart'] <= 0 ? '#43a047' : 'red'
                                    "
                                    >mdi-circle</v-icon
                                  >&nbsp;{{ format(lb["ecart"]) }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col></v-row
    >
  </v-container>
</template>

<style scoped>
.custom-tooltip {
  opacity: 1 !important;
  background-color: rgba(97, 97, 97, 1) !important;
}

.custombgcolor {
  background-color: #1e4461;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

/* .rang0 {font-weight: 200;} */

.rang0 ul {
  margin-bottom: 0px !important;
}

.entete-niv1 {
  background-color: #039be5 !important;
}

.rang3 {
  background-color: #43a047 !important;
}

.entete-niv3 {
  background-color: #00bcd4 !important;
}

.entete-niv2 {
  background-color: #086999 !important;
}

.rang4 td {
  padding: 20px !important;
}

.checkboxTD {
  width: 10px !important;
  padding: 5px 0 5px 5px !important;
}
</style>

<script>
import { EventBus } from "../../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  data() {
    return {
      produitsDatatableKey: 0,
      exportProduitsDatatableKey: 0,
      chargesDatatableKey: 0,
      exportChargesDatatableKey: 0,

      validform: false,

      formError: {
        debutperiode: null,
        finperiode: null,
        exercice: null,
      },

      rules: {
        required: (v) => !!v || "Ce champ est requis",
        dateRange: (value) => {
          if (!this.debutperiode || !this.finperiode) return true;
          return (
            this.debutperiode < this.finperiode ||
            "La date de début doit être antérieure à la date de fin"
          );
        },
      },
      finperiode: null, //"2022-05-31",
      debutperiode: null, //"2022-04-01",
      monthtab: null,

      selectallprevis6: "1",
      selectallprevis7: "1",

      exercices: [],
      exercice: null , // 2022
      moisExercice: null,
      componentKey: 0,
      Loading: true,
      calcul_encours: true,
      pcgs: [],
      currentPrevis: [],
      balances: [],
      balances_annuelles: [],
      etatDeComparaison: null,

      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      kilo_euro: false,

      categories: [
        { intitule: "Chiffre d'affaires", id: 1, order: 1, classe: 6 },
        { intitule: "Subvention d'exploitation", id: 2, order: 2, classe: 6 },
        {
          intitule: "Autres produits d'exploitation",
          id: 3,
          order: 3,
          classe: 6,
        },
        { intitule: "Autres produits financiers", id: 4, order: 4, classe: 6 },
        {
          intitule: "Autres produits exceptionnels",
          id: 5,
          order: 5,
          classe: 6,
        },

        { intitule: "Achats", id: 6, order: 1, classe: 7 },
        { intitule: "Frais généraux", id: 7, order: 2, classe: 7 },
        {
          intitule: "Impôts, taxes et versements assimilés",
          id: 8,
          order: 3,
          classe: 7,
        },
        { intitule: "Masse salariale", id: 9, order: 4, classe: 7 },
        {
          intitule: "Dotations aux amortissements et provisions",
          id: 10,
          order: 5,
          classe: 7,
        },
        { intitule: "Charges financières", id: 11, order: 6, classe: 7 },
        {
          intitule: "Autres charges d'exploitation",
          id: 12,
          order: 7,
          classe: 7,
        },
        { intitule: "Autres charges financières", id: 13, order: 8, classe: 7 },
        {
          intitule: "Autres charges exceptionnelles",
          id: 14,
          order: 9,
          classe: 7,
        },
      ],

      serverAlert: null,
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    previs6() {
      // pour la liste des lignes budgetaires on prend l'etat revise s'il existe, initial sinon
      let etat;
      if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
        etat = "revise";
      } else {
        etat = "initial";
      }

      // construction des lignes du tableau
      let previs6 = [];
      let previous_categorie_id = "";

      _.orderBy(
        _.filter(this.currentPrevis, { class: 6, etat: etat }),
        ["budgetline_categorie_id", "order"],
        ["asc", "asc"]
      ).forEach((element) => {
        // ajout des entetes niv1
        if (
          element.budgetline_categorie_id &&
          previous_categorie_id != element.budgetline_categorie_id
        ) {
          previs6.push({
            budgetline_categorie_id: element.budgetline_categorie_id,
            intitule: _.find(this.categories, {
              id: element.budgetline_categorie_id,
            }).intitule,
            show: "1",
            entete: "entete-niv1",
            class: 6,
          });

          element.entete = null;
          this.$set(element, "show", "1");
          //element.show = 1;
          element.class = 6;

          previs6.push(element);
        }
        // ajout entetes niv2
        else if (!element.budgetline_categorie_id) {
          element.intitule =
            "Autres Charges non affectées à une ligne budgétaire";
          this.$set(element, "show", "1");
          //element.show = 1;
          element.entete = "entete-niv2";
          element.class = 6;
          previs6.push(element);
        }
        // ligne budgetaire sans entete
        else {
          element.entete = null;
          this.$set(element, "show", "1");
          previs6.push(element);
        }

        previous_categorie_id = element.budgetline_categorie_id;
      });

      // ajout entete niv3 (TOTAL charges mensuel)
      let niv3 = {};
      this.$set(niv3, "budgetline_categorie_id", null);
      this.$set(niv3, "intitule", "Total charges");
      this.$set(niv3, "entete", "entete-niv3");
      this.$set(niv3, "class", 6);
      this.$set(niv3, "show", "1");

      previs6.push(niv3);

      //on ajoute le resultat des calculs 

      previs6.forEach((lb, index) => {
        //console.log("================= Charges")
        //console.log(lb)
        //console.log("================= Charges")

        this.moisExercice.forEach((mois, index) => {
          let comptesAssocies = this.comptes(
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class,
            lb
          );

          if (comptesAssocies.length == 0) {
            lb["realise_n_1"] = "-";
          } else {
            //console.log('calcul de realise_n_1 SommeComptesBalance')
            lb["realise_n_1"] = this.SommeComptesBalance(
              comptesAssocies,
              "annuel",
              lb
            );
          }

          // attention ici pour le calcul du bp initial
          // quand on parcours le previs révisé
          // bl.id doit etre recherché dans le previs initial

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv2
          // dont l'intitulé est null ainsi que budgetline_categorie_id

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv1
          // dont seul l'intitulé est null

          let lb_id_initial;

          if (lb.entete == "entete-niv2") {
            //console.log("cas entete-niv2")
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: null,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else if (lb.entete == "entete-niv1") {
            //console.log("cas entete-niv1")
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else {
            //console.log("cas autre")
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              class: lb.class,
              budgetline_categorie_id: lb.budgetline_categorie_id,
            });
          }
          // cas d'une somme de LB non trouvée dans previs initial
          if (lb.id && lb_id_initial == undefined) {
            lb["bp_annuel_initial"] = "-";
          }
          // cas d'une somme de LB trouvée dans previs initial
          if (lb.id && lb_id_initial) {
            //console.log("somme de LB trouvée dans previs initial")
            //console.log('calcul de bp_annuel_initial SommeComptesPrevis')
            lb["bp_annuel_initial"] = this.SommeComptesPrevis(
              true,
              "initial",
              lb_id_initial.id,
              lb_id_initial.budgetline_categorie_id,
              lb_id_initial.class,
              lb_id_initial
            );
          }
          // cas d'une somme de categorie
          if (!lb.id) {
            //console.log("cas d'une somme de categorie")
            //console.log('calcul de bp_annuel_initial SommeComptesPrevis')
            lb["bp_annuel_initial"] = this.SommeComptesPrevis(
              true,
              "initial",
              null,
              lb.budgetline_categorie_id,
              lb.class,
              lb
            );
          }

          // calcul bp annuel révisé

          if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
            //console.log('calcul de bp_annuel_revise SommeComptesPrevis')
            lb["bp_annuel_revise"] = this.SommeComptesPrevis(
              true,
              "revise",
              lb.id,
              lb.budgetline_categorie_id,
              lb.class,
              lb
            );
          } else {
            lb["bp_annuel_revise"] = "-";
          }

          // calcul prévu
          //console.log('calcul de prevu SommeComptesPrevis')
          lb["prevu"] = this.SommeComptesPrevis(
            null,
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class,
            lb
          );

          // calcul de reel
          if (comptesAssocies.length == 0) {
            lb["reel"] = "-";
          } else {

            lb["reel"] = this.SommeComptesBalance(comptesAssocies, null, lb);
          }

          lb["ecart"] = this.ecart(lb["reel"], lb["prevu"]);
        });
      });

      return previs6;
    },
    previs7() {
      // pour la liste des lignes budgetaires on prend l'etat revise s'il existe, initial sinon
      let etat;
      if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
        etat = "revise";
      } else {
        etat = "initial";
      }

      // construction des lignes du tableau
      let previs7 = [];
      let previous_categorie_id = "";

      _.orderBy(
        _.filter(this.currentPrevis, { class: 7, etat: etat }),
        ["budgetline_categorie_id", "order"],
        ["asc", "asc"]
      ).forEach((element) => {
        // ajout des entetes niv1
        if (
          element.budgetline_categorie_id &&
          previous_categorie_id != element.budgetline_categorie_id
        ) {
          previs7.push({
            budgetline_categorie_id: element.budgetline_categorie_id,
            intitule: _.find(this.categories, {
              id: element.budgetline_categorie_id,
            }).intitule,
            show: "1",
            entete: "entete-niv1",
            class: 7,
          });

          element.entete = null;
          this.$set(element, "show", "1");
          element.class = 7;

          previs7.push(element);
        }
        // ajout entetes niv2
        else if (!element.budgetline_categorie_id) {
          element.intitule =
            "Autres Produits non affectés à une ligne budgétaire";
          this.$set(element, "show", "1");
          //element.show = 1;
          element.entete = "entete-niv2";
          element.class = 7;
          previs7.push(element);
        }
        // ligne budgetaire sans entete
        else {
          element.entete = null;
          this.$set(element, "show", "1");
          previs7.push(element);
        }

        previous_categorie_id = element.budgetline_categorie_id;
      });

      // ajout entete niv3 (TOTAL produit mensuel)
      let niv3 = {};
      this.$set(niv3, "budgetline_categorie_id", null);
      this.$set(niv3, "intitule", "Total produits");
      this.$set(niv3, "entete", "entete-niv3");
      this.$set(niv3, "class", 7);
      this.$set(niv3, "show", "1");

      previs7.push(niv3);

      //on ajoute le resultat des calculs

      previs7.forEach((lb, index) => {
        //console.log('===================== produits')
        //console.log(lb)
        //console.log('===================== produits')

        this.moisExercice.forEach((mois, index) => {
          let comptesAssocies = this.comptes(
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class,
            lb
          );

          if (comptesAssocies.length == 0) {
            lb["realise_n_1"] = "-";
          } else {
            //console.log('calcul de realise_n_1 SommeComptesBalance')
            lb["realise_n_1"] = this.SommeComptesBalance(
              comptesAssocies,
              "annuel",
              lb
            );
          }

          // attention ici pour le calcul du bp initial
          // quand on parcours le previs révisé
          // bl.id doit etre recherché dans le previs initial

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv2
          // dont l'intitulé est null ainsi que budgetline_categorie_id

          // on recherche dans previs initial le meme intitule sauf dans le cas de l'entete niv1
          // dont seul l'intitulé est null

          let lb_id_initial;

          if (lb.entete == "entete-niv2") {
            //console.log("cas entete-niv2")
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: null,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else if (lb.entete == "entete-niv1") {
            //console.log("cas entete-niv1")
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              budgetline_categorie_id: null,
              class: lb.class,
            });
          } else {
            //console.log("cas autre")
            lb_id_initial = _.find(this.currentPrevis, {
              etat: "initial",
              intitule: lb.intitule,
              class: lb.class,
              budgetline_categorie_id: lb.budgetline_categorie_id,
            });
          }

          // cas d'une somme de LB non trouvée dans previs initial
          if (lb.id && lb_id_initial == undefined) {
            lb["bp_annuel_initial"] = "-";
          }

          // cas d'une somme de LB trouvée dans previs initial
          if (lb.id && lb_id_initial) {
            //console.log("somme de LB trouvée dans previs initial")
            //console.log('calcul de bp_annuel_initial SommeComptesPrevis')
            lb["bp_annuel_initial"] = this.SommeComptesPrevis(
              true,
              "initial",
              lb_id_initial.id,
              lb_id_initial.budgetline_categorie_id,
              lb_id_initial.class,
              lb_id_initial
            );
          }

          // cas d'une somme de categorie
          if (!lb.id) {
            //console.log("cas d'une somme de categorie")
            //console.log('calcul de bp_annuel_initial SommeComptesPrevis')
            lb["bp_annuel_initial"] = this.SommeComptesPrevis(
              true,
              "initial",
              null,
              lb.budgetline_categorie_id,
              lb.class,
              lb
            );
          }

          // calcul bp_annuel_revise

          if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
            //console.log('calcul de bp_annuel_revise SommeComptesPrevis')
            lb["bp_annuel_revise"] = this.SommeComptesPrevis(
              true,
              "revise",
              lb.id,
              lb.budgetline_categorie_id,
              lb.class,
              lb
            );
          } else {
            lb["bp_annuel_revise"] = "-";
          }

          // calcul _prevu
          if(lb.intitule=="POF et PET"){
            //console.log('calcul de prevu pour '+ lb.intitule +" :" + this.SommeComptesPrevis(null,null,lb.id,lb.budgetline_categorie_id,lb.class,lb))
          }

          lb["prevu"] = this.SommeComptesPrevis(
            null,
            null,
            lb.id,
            lb.budgetline_categorie_id,
            lb.class,
            lb
          );


          // calcul _reel
          if (comptesAssocies.length == 0) {
            lb["reel"] = "-";
          } else {
            //console.log('calcul de reel SommeComptesBalance')
            // if(lb.intitule=="POF et PET"){
            //   console.log('calcul de reel SommeComptesBalance pour POF et PET')
            //   console.log("this.SommeComptesBalance(comptesAssocies, annuel, lb")
            //   console.log("comptes associés:",comptesAssocies)
            //   console.log("Annuel",null)
            //   console.log("lb",lb)
            //   console.log("this.SommeComptesBalance(comptesAssocies, annuel, lb",this.SommeComptesBalance(comptesAssocies, null, lb))
            // }

            lb["reel"] = this.SommeComptesBalance(comptesAssocies, null, lb);
          }

          lb["ecart"] = this.ecart(lb["reel"], lb["prevu"]);
        });
      });
      return previs7;
    },

    liste_mois_periode_fiscale() {
      //liste des mois de la periode fiscale du client

      let liste_mois_periode_fiscale = [];
      let debut = this.$moment(this.selectedClient.debut_exercice);
      let fin = this.$moment(this.selectedClient.fin_exercice);

      // !!! la periode d'exercice n'est valable que pour le permier exercice !!!  cf recette visio du 06/06/2024
      // si l'annee de l'analyse est > l'année de fin d'exercice => periode exercice = 12 mois     
      
      let nbr_mois_de_la_periode_fiscale;
      //console.log("his.exercice",this.exercice,"fin.year()",fin.year(),"this.exercice > fin.year()",this.exercice > fin.year())
      if(this.exercice > fin.year()){
        nbr_mois_de_la_periode_fiscale = 12
      }
      else{

      nbr_mois_de_la_periode_fiscale = fin.diff(debut, "months");
      }


      const nouveaudebut = this.$moment(this.selectedClient.debut_exercice)
        .clone()
        .year(this.exercice);

      // Boucle pour remplir le tableau avec les mois entre debut_exercice et fin_exercice

      for (let i = 0; i <= nbr_mois_de_la_periode_fiscale; i++) {
        liste_mois_periode_fiscale.push(
          nouveaudebut.format("MMMYY").toLowerCase()
        );
        nouveaudebut.add(1, "month");
      }

      return liste_mois_periode_fiscale;
    },
  },

  watch: {
    kilo_euro(val) {
      EventBus.$emit("kilo_euroChanged", val);
    },

    selectedClient(val) {
      this.exercice = null;
      this.error = null;
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);
      this.moisExercice = null;
      this.exercices = [];
      this.exercice = null;
      this.balances = [];
      this.currentPrevis = [];
      this.pcgs = [];
      this.getPcgs();
      this.getExcercices();
    },
    exercice(val) {
      this.error = null;
      this.balances = [];
    },
  },

  methods: {
    ecart(reel, prevu) {
      if (isNaN(parseFloat(reel)) || isNaN(parseFloat(prevu))) {
        return "-";
      }

      return parseInt(parseInt(reel) - parseInt(prevu));
    },

    clearErrorMessage(fieldName) {
      this.$refs.form.resetValidation(fieldName);
    },

    validateForm() {
      const isFormValid = this.$refs.form.validate(); // Trigger form validation on form submit

      this.currentPrevis = [];
      this.moisExercice = [];
      this.balances = [];
      this.balances_annuelles = [];

      if (isFormValid) {
        this.validform = true;

        // Proceed with form submission or other logic

        this.getPeriodeExcercices();

        this.getbalances();

        this.getCurrentPrevis();

        this.getbalancesannuelles();
      } else {
        this.validform = false;
      }
    },

    excelfields() {
      return {
        Intitule: "intitule",
        "Réalisé N-1": "realise_n_1",
        "BP Annuel initial": "bp_annuel_initial",
        "BP Annuel révisé": "bp_annuel_revise",
        Prévu: "prevu",
        Réel: "reel",
        Ecart: "ecart",
      };
    },

    colspace(nbr) {
      if (nbr) {
        return "\xa0".repeat(nbr);
      }
      let longueurMax = 0;

      for (const objet of this.currentPrevis) {
        if (objet.intitule && objet.intitule.length > longueurMax) {
          longueurMax = objet.intitule.length;
        }
      }

      return "\xa0".repeat(longueurMax / 2);
    },

    /*
     * calcul des sommes dans le previs
     */

    SommeComptesPrevis(annuel, etat, lbid, lbcategorie, lbclass, lb) {
      let filered_previs;
      let data = this.currentPrevis;
      

      /*
      if(lb.intitule=="POF et PET"){
        console.log('appel de SommeComptesPrevis pour POF et PET')
        console.log('annuel '+annuel )
        console.log('etat '+etat )
        console.log('lbid '+lbid )
        console.log('lbcategorie '+lbcategorie )
        console.log('lbclass '+lbclass )
        console.log('lb ',lb )
      }
        */
      /*
      if (
        annuel &&
        lb &&
        etat == "initial" &&
        lb.intitule == "Chiffre d'affaires"
      ) {
        console.log("annuel, etat, lbid, lbcategorie, lbclass, lb");
        console.log(annuel, etat, lbid, lbcategorie, lbclass, lb);
      }
      */

      // si aucun etat n'est transmis
      // on prend l'etat revise s'il existe
      // sinon on prend l'initial

      if (!etat) {
        if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
          etat = "revise";
        } else {
          etat = "initial";
        }
      }

      if (lbid) {
        // on filtre currentPrevis sur l'id de la LB
        filered_previs = _.filter(
          data,
          (item) => item.id == lbid && item.etat == etat
        );
      } else if (lbcategorie) {
        // on filtre currentPrevis sur l'id de la categorie
        filered_previs = _.filter(
          data,
          (item) =>
            item.budgetline_categorie_id == lbcategorie && item.etat == etat
        );
      } else if (lbclass) {
        // on filtre currentPrevis sur l'id de la classe
        filered_previs = _.filter(
          data,
          (item) => item.class == lbclass && item.etat == etat
        );
      } else if (!lbid && !lbcategorie && !lbclass) {
        // on filtre uniquement sur l'etat
        filered_previs = _.filter(data, (item) => item.etat == etat);
      }

      // mois analyse
      // sous la forme ["janv.22","févr.22","mars22"]
      let mois_analyse = this.moisExercice;

      // if (lb.intitule == "POF et PET") {
      //   console.log ('this.moisExercice' + this.moisExercice)
      // }

      /*if (
        annuel &&
        lb &&
        etat == "initial" &&
        lb.intitule == "Chiffre d'affaires"
      ) {
        console.log("mois_analyse", mois_analyse);
      }*/

      //index du mois de debut
      let numeroduMoisdebut = _.findIndex(
        this.liste_mois_periode_fiscale,
        (mois) => mois === mois_analyse[0]
      );
      //index du mois de fin
      let numeroduMoisfin = _.findIndex(
        this.liste_mois_periode_fiscale,
        (mois) => mois === mois_analyse[mois_analyse.length - 1]
      );

      //en cas de BP annuel
      if (annuel) {
        numeroduMoisdebut = 0;
        numeroduMoisfin = this.liste_mois_periode_fiscale.length;
      }

      /*if (
        annuel &&
        lb &&
        etat == "initial" &&
        lb.intitule == "Chiffre d'affaires"
      ) {
        console.log("numeroduMoisdebut", numeroduMoisdebut);
        console.log("numeroduMoisfin", numeroduMoisfin);
      }*/

      /*if (
        annuel &&
        lb &&
        etat == "initial" &&
        lb.intitule == "Chiffre d'affaires"
      ) {
        console.log("filered_previs", filered_previs);
      }*/

      // Utilisez _.reduce pour calculer la somme de tous les mois de mois_debut à mois_fin.
      // X etant le numeroduMois

      //console.log("**********",lb)

      if (lb.intitule == "Chiffre d'affaires" && annuel == null) {
        //console.log("calcul pour previs sur période pour Chiffre d'affaires")
        //console.log("filered_previs",filered_previs)
        //console.log('numeroduMoisdebut',numeroduMoisdebut)
        //console.log('numeroduMoisfin',numeroduMoisfin)
      }

      // le previs se calcul en additionnant les mois depuis le premier mois de l'excercice
      // jusqu'au dernier moi de la periode analysée

      const sommeDesMois = _.reduce(
        filered_previs,
        (acc, objet) => {
          for (let i = numeroduMoisdebut + 1; i <= numeroduMoisfin + 1; i++) {

          // if (lb.intitule == "POF et PET") {
          //     console.log(annuel + " numeroduMoisdebut " + numeroduMoisdebut);
          //     console.log(annuel + " numeroduMoisfin " + numeroduMoisfin);
          //     console.log(annuel + " objet ");
          //     console.log(objet);
          //     console.log(annuel + " acc " + acc);
          //     console.log(annuel + " objet " + objet.intitule);
          //     console.log(annuel + " mois " + i);
          //     console.log(annuel + " objet[mois_${i}] " + objet[`mois_${i}`]);
          //     console.log(annuel + " numero du mois de debut " + (numeroduMoisdebut + 1));
          //     console.log(annuel + " numero du mois de fin " + (numeroduMoisfin + 1));
          // }

            acc += objet[`mois_${i}`];
          }
          return acc;
        },
        0
      );

      //

      return sommeDesMois;
    },

    /*
     * calcul du resultat reel
     */
    ResultatReel() {
      const dateDebut = this.debutperiode;
      const dateFin = this.finperiode;

      //
      // nouvelle formule de calcul CF recette mai 2024 & complétent dans mai 2024 rev1
      //
      // ResultatReel = Total produits réel – Total charges réel

      // Total produits réel se trouve dans la proprité "reel" qui est ajoutée au chargement du previsionnel dans previs7

      const totalProduitsReel =  _.find(this.previs7, { intitule: 'Total produits' }).reel; 
      const totalChargesReel =  _.find(this.previs6, { intitule: 'Total charges' }).reel;

      return parseFloat(parseFloat(totalProduitsReel) - parseFloat(totalChargesReel));


      /*  
      code supprimé suite à recette mai 2024 & complétent dans mai 2024 rev1


      // Filtrer les balances en fonction de la date de début et de fin
      const filteredBalance = _.filter(this.balances, (item) => {
        const clotureDate = this.$moment(item.cloture).format("YYYY-MM-DD");
        return this.$moment(clotureDate).isBetween(
          dateDebut,
          dateFin,
          null,
          "[]"
        );
      });

      //console.log("filteredBalance", filteredBalance);

      // Calculer la somme des soldes pour subcompte1 égal à 7
      const sommeproduits = _.sumBy(filteredBalance, (item) => {
        if (item.subcompte1 === "7") {
          return -parseFloat(item.solde); // Inverser le solde pour subcompte1 égal à 7
        }
        return 0;
      });

      //console.log("sommeproduits", sommeproduits);

      // Calculer la somme des soldes pour subcompte1 égal à 6
      const sommecharges = _.sumBy(filteredBalance, (item) => {
        if (item.subcompte1 === "6") {
          return parseFloat(item.solde);
        }
        return 0;
      });

      //console.log("sommecharges", sommecharges);

      return parseFloat(sommeproduits - sommecharges);
      
      */


    },

    /*
     * calcul du resultat prevu
     */

    ResultatPrevu() {
      let etat;
      // si un previs revise existe sinon on considère le previs initial
      if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
        etat = "revise";
      } else {
        etat = "initial";
      }

      let filered_previs_produits = _.filter(this.currentPrevis, (item) => {
        return item.etat == etat && item.class == 7;
      });
      let filered_previs_charges = _.filter(this.currentPrevis, (item) => {
        return item.etat == etat && item.class == 6;
      });

      let mois_analyse = this.moisExercice;

      //index du mois de debut
      let numeroduMoisdebut = _.findIndex(
        this.liste_mois_periode_fiscale,
        (mois) => mois === mois_analyse[0]
      );
      //index du mois de fin
      let numeroduMoisfin = _.findIndex(
        this.liste_mois_periode_fiscale,
        (mois) => mois === mois_analyse[mois_analyse.length - 1]
      );

      /*console.log("calcul prevu");
      console.log(
        "liste_mois_periode_fiscale",
        this.liste_mois_periode_fiscale
      );
      console.log("mois_analyse", mois_analyse);
      console.log("numeroduMoisdebut", numeroduMoisdebut);
      console.log("numeroduMoisfin", numeroduMoisfin);
      */

      // Utilisez _.reduce pour calculer la somme de tous les mois de mois_1 à mois_x.
      // X etant le numeroduMois

      const TotalCharges = _.reduce(
        filered_previs_charges,
        (acc, objet) => {
          for (let i = numeroduMoisdebut + 1; i <= numeroduMoisfin + 1; i++) {
            acc += objet[`mois_${i}`];
          }
          return acc;
        },
        0
      );

      const TotalProduits = _.reduce(
        filered_previs_produits,
        (acc, objet) => {
          for (let i = numeroduMoisdebut + 1; i <= numeroduMoisfin + 1; i++) {
            acc += objet[`mois_${i}`];
          }
          return acc;
        },
        0
      );

      if (isNaN(parseFloat(TotalProduits)) || isNaN(parseFloat(TotalCharges))) {
        return "-";
      }

      return parseFloat(TotalProduits - TotalCharges);
    },

    /*
     * calcul du resultat annuel revise
     */
    ResultatAnnuelRevise() {
      const TotalCharges = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "revise" && obj.class == 6) {
          return parseFloat(obj.total);
        }
      });

      const TotalProduits = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "revise" && obj.class == 7) {
          return parseFloat(obj.total);
        }
      });

      if (isNaN(TotalProduits) || isNaN(TotalCharges)) {
        return "-";
      }

      return parseFloat(TotalProduits - TotalCharges);
    },

    /*
     * calcul du resultat annuel initial
     */
    ResultatAnnuelInitial() {
      const TotalCharges = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "initial" && obj.class == 6) {
          return parseFloat(obj.total);
        }
      });

      const TotalProduits = _.sumBy(this.currentPrevis, (obj) => {
        if (obj.etat == "initial" && obj.class == 7) {
          return parseFloat(obj.total);
        }
      });

      if (isNaN(TotalProduits) || isNaN(TotalCharges)) {
        return "-";
      }

      return parseFloat(TotalProduits - TotalCharges);
    },

    /*
     * calcul du resultat N-1
     */
    ResultatNmoins1() {
      const TotalCharges = _.sumBy(this.balances_annuelles, (obj) => {
        if (obj.subcompte1 == 6) {
          return parseFloat(obj.solde);
        }
      });

      const TotalProduits = _.sumBy(this.balances_annuelles, (obj) => {
        if (obj.subcompte1 == 7) {
          return -parseFloat(obj.solde);
        }
      });

      if (isNaN(TotalProduits) || isNaN(TotalCharges)) {
        return "-";
      }
      return parseFloat(TotalProduits - TotalCharges);
    },

    /*
     * calcul des sommes dans la balance de comptes associés
     */
    SommeComptesBalance(comptes, annuel, lb) {
      let dataDebutPeriode;
      let dataFinPeriode;
      let debutPeriode = this.debutperiode; //"2023-03-01"
      let finPeriode = this.finperiode; //"2023-03-15"

      // mois abrv.fr avec annee sur 2 chiffres ex. juil.22

      // somme depuis la balance annuelle
      if (annuel == 'annuel') {
        let data = this.balances_annuelles;
        // s'il n'y a pas de balance on retourne "-"
        if (data.length == 0) {
          return "-";
        }

        return Math.round(this.calculerSomme(data, comptes, true, lb.intitule,annuel));
      }
      // somme depuis la balance mensuelle
      else {
        //console.log('perdiode de calcul')

        // filtrer les balances selon le mois de debut et de fin de periode

        // pour la date de debut de période il faut prendre
        // le dernier jour du mois précédent - (cf reunion du 23/01/2024)

        debutPeriode = this.$moment(debutPeriode)
          .subtract(1, 'months') // le mois précédent - (cf reunion du 23/01/2024)
          .endOf("month") // le dernier jour
          .format("YYYY-MM-DD");
        //console.log('this.debutperiode',this.debutperiode)
        //console.log('debutPeriode',debutPeriode)

        finPeriode = this.$moment(finPeriode)
          .endOf("month")
          .format("YYYY-MM-DD");

        // Filtrer les données pour la période de début
        // if(lb.intitule=="POF et PET"){
        //   console.log('Calcul dans balance mensuelle')
        //   console.log('debutPeriode',debutPeriode)
        //   console.log('finperiode',finPeriode)

        // }

        /*
        FIX 0.691
        Quand le debut de periode = 1er mois de l'exercice
        alors debut periode devient = dernier mois de l'exercice précedent
        il faut la balance annuelle et faire le calcul sur ces données.
        */

        let premier_mois_exercice_client = this.$moment(this.selectedClient.debut_exercice).format('M');
        let premier_mois_perdiode = this.$moment(debutPeriode).format('M');

        /*if(lb.intitule=="POF et PET"){
          console.log("premier_mois_exercice_client",premier_mois_exercice_client)
          console.log("premier_mois_perdiode",premier_mois_perdiode)
        }*/

        if(premier_mois_exercice_client== premier_mois_perdiode){
          if(lb.intitule=="POF et PET"){console.log('premier_mois_exercice_client == premier_mois_perdiode => calcul sur balance annuelle');}
          dataDebutPeriode = this.balances_annuelles
        }
        else{
          dataDebutPeriode = this.balances.filter((objet) => {
          return objet.cloture === debutPeriode + "T00:00:00.000000Z";
        });
        }
        
        // Filtrer les données pour la période de fin
        dataFinPeriode = this.balances.filter((objet) => {
          return objet.cloture === finPeriode + "T00:00:00.000000Z";
        });

        // if(lb.intitule=="POF et PET"){
        //   console.log('dataDebutPeriode',dataDebutPeriode)
        //   console.log('dataFinPeriode',dataFinPeriode)
          
        // }
      }

      // si le calcul s'effectue sur les lignes
      // "Autres Charges non affectées à une ligne budgétaire"
      // ou
      // "Autres Produits non affectés à une ligne budgétaire"
      // alors nous ne devons pas tenir compte du radical
      let radical;

      if (
        lb &&
        (lb.intitule == "Autres Produits non affectés à une ligne budgétaire") |
          (lb.intitule == "Autres Charges non affectées à une ligne budgétaire")
      ) {
        radical = false;
      } else {
        radical = true;
      }

      //console.log("dataDebutPeriode");
      //console.log(dataDebutPeriode);

      // Calculer la somme pour la période de début
      const sommeDebutPeriode = this.calculerSomme(
        dataDebutPeriode,
        comptes,
        radical,
        lb.intitule,
        annuel
      );

      // Calculer la somme pour la période de fin
      const sommeFinPeriode = this.calculerSomme(
        dataFinPeriode,
        comptes,
        radical,
        lb.intitule,
        annuel
      );

      //console.log("dataFinPeriode");
      //console.log(dataFinPeriode);

      // Calculer la différence entre les sommes des périodes de début et de fin

      if (isNaN(sommeFinPeriode) || isNaN(sommeDebutPeriode)) {
        return "-";
      }

      if (debutPeriode == finPeriode) {
        return Math.round(sommeFinPeriode);
      }

      const differenceSomme = sommeFinPeriode - sommeDebutPeriode;

      return Math.round(differenceSomme);
    },

    calculerSomme(data, comptes, radical, intitule,annuel) {
      // Utiliser la méthode "reduce" pour calculer la somme en fonction des comptes spécifiés (sans tenir compte du radical).
      // Pour les produits (7) la somme s'effectue sur -solde
      // Pour les charges (6) la somme s'effectue sur +solde

      if (intitule == "Total charges") {
        return Math.round(
          _.sumBy(data, (obj) => {
            if (obj.subcompte1 == 6) {
              return parseFloat(obj.solde);
            }
          })
        );
      }

      if (intitule == "Total produits") {
        return Math.round(
          _.sumBy(data, (obj) => {
            if (obj.subcompte1 == 7) {
              return -parseFloat(obj.solde);
            }
          })
        );
      }

      let sommeTotale;

      sommeTotale = data.reduce((acc, obj) => {
        // Utiliser la méthode "find" pour rechercher l'objet ayant le même compte dans la liste des comptes spécifiés.

        const compteSpecifie = comptes.find((compte) => {
          if (radical == true) {
            return obj.compte.toString().startsWith(compte.replace(/0+$/, ""));
          } else {
            return compte === obj.compte.toString();
          }
        });

        // Si l'objet est trouvé dans la liste des comptes spécifiés, effectuer la somme.
        if (compteSpecifie) {
          const solde = parseFloat(obj.solde); // Convertir le solde en nombre décimal

          if (obj.subcompte1 == "7") {
            acc -= solde;
          } else if (obj.subcompte1 == "6") {
            acc += solde;
          }
        }
        return acc;
      }, 0);

      /*if(intitule=="Frais généraux"){
        console.log("=====================")
        //console.log('calculerSomme(dataDebutPeriode,comptes,radical,lb.intitule)',dataDebutPeriode,comptes,radical,lb.intitule)
        console.log('annuel',annuel)
        console.log('data',data)
        console.log('somme',Math.round(sommeTotale))
        console.log("=====================")
      }*/
      return Math.round(sommeTotale);
    },

    /*
     * Liste des comptes associés
     */
    comptes(etat, lbid, lbcategorie, lbclass) {
      let filered_previs;

      // si aucun etat n'est transmis on prend l'etat revise s'il existe
      // sinon on prend l'initial

      if (!etat) {
        if (_.filter(this.currentPrevis, { etat: "revise" }).length != 0) {
          etat = "revise";
        } else {
          etat = "initial";
        }
      }

      const data = this.currentPrevis;

      if (lbid) {
        // on filtre currentPrevis sur l'id de la LB
        filered_previs = _.filter(
          data,
          (item) => item.id == lbid && item.etat == etat
        );
      } else if (lbcategorie) {
        // on filtre currentPrevis sur l'id de la categorie
        filered_previs = _.filter(
          data,
          (item) =>
            item.budgetline_categorie_id == lbcategorie && item.etat == etat
        );
      } else if (lbclass) {
        // on filtre currentPrevis sur l'id de la classe
        filered_previs = _.filter(
          data,
          (item) => item.class == lbclass && item.etat == etat
        );
      } else if (!lbid && !lbcategorie && !lbclass) {
        // on filtre uniquement sur l'etat
        filered_previs = _.filter(data, (item) => item.etat == etat);
      }

      const numerosDeCompte = filered_previs.flatMap((item) =>
        item.comptes.map((compte) => compte.compte)
      );

      //console.log(etat, lbid, lbcategorie, lbclass);
      // console.log(numerosDeCompte);

      return numerosDeCompte;
    },

    /*
     * Chargement du previs
     */

    getCurrentPrevis() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/suivibudgetaireperso-getprevis/" +
              this.selectedClient.id +
              "/" +
              this.exercice
          )
          .then((response) => {
            console.log("/api/suivibudgetaireperso-getprevis/ OK");
            this.currentPrevis = response.data;
            this.currentPrevis = _.orderBy(
              this.currentPrevis,
              ["etat", "budgetline_categorie_id", "order"],
              ["asc", "asc", "asc"]
            );
            this.Loading = false;

            resolve(response);
          })
          .catch((err) => {
            console.log("/api/suivibudgetaireperso-getprevis/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    /*
     * Liste des mois de l'excercice
     */

    getPeriodeExcercices() {
      this.Loading = true;
      this.serverAlert = null;
      // Créez un nouvel objet URLSearchParams
      const params = new URLSearchParams();
      params.append("debutperiode", this.debutperiode);
      params.append("finperiode", this.finperiode);

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/exerciceperiodeperso/" +
              this.selectedClient.id +
              "/" +
              this.exercice,
            { params: params }
          )
          .then((response) => {
            console.log("/api/exerciceperiodeperso/ OK");
            this.moisExercice = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceperiodeperso/ KO", err);
            if (err.response.status === 422) {
              this.serverAlert = err.response.data.errors;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    /*
     * Liste des exercices
     */

    getExcercices() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/exerciceschoice-suivi/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/exerciceschoice-suivi/ OK");
            this.exercices = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceschoice-suivi/ KO", err);
            console.log(err);

            this.Loading = false;
            reject(err);
          });
      });
    },
    /*
     * PCG du client selectionné
     */

    getPcgs() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/getpcgs/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/getpcgs/ OK");
            this.pcgs = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getpcgs/ KO", err);
            console.log(err);
            this.Loading = false;
            reject(err);
          });
      });
    },
    /*
     * chargement des balances annuelles
     */

    getbalancesannuelles() {
      this.Loading = true;
      //console.log('appel js getbalancesannuelles');  
      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/suivibudgetaire-balanceannuelle/" +
              this.selectedClient.id +
              "/" +
              parseInt(this.exercice - 1)
          )
          .then((response) => {
            console.log("/api/suivibudgetaire-balanceannuelle/ OK");
            this.balances_annuelles = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/suivibudgetaire-balanceannuelle/ KO", err);
            if (err.response.status === 422) {
              this.serverAlert = err.response.data.errors;
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },
    /*
     * chargement des balances mensuelles
     */

    getbalances() {
      this.Loading = true;
      //console.log('appel js getbalances');
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();

        // pour la date de debut de période il faut prendre
        // le dernier jour du mois précédent - (cf reunion du 23/01/2024)
        let debutPeriode = this.$moment(this.debutperiode).subtract(1, 'months').endOf("month").format("YYYY-MM-DD");


        params.append("debutperiode", debutPeriode);
        params.append("finperiode", this.finperiode);

        axios
          .get(
            "/api/suivibudgetaireperso-balancemensuelle/" +
              this.selectedClient.id +
              "/" +
              this.exercice,
            { params: params }
          )
          .then((response) => {
            console.log("/api/suivibudgetaireperso-balancemensuelle/ OK");
            
            this.balances = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/suivibudgetaireperso-balancemensuelle/ KO", err);
            if (err.response.status === 422) {
              this.serverAlert = err.response.data.errors;
              //this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    // toggleAllRowVisibility pour afficher masquer toutes les lignes
    // dans l'exporation image d'un tableau

    toggleAllRowVisibility(Value, targetData) {
      _.map(targetData, function (obj) {
        obj.show = Value;
      });

      this.refresh();
    },
    // toggleRowVisibility pour afficher masquer une ligne
    // avec ses enfants selon les cas
    // target = jeux de donnée cible
    // row = identifiant de la ligne

    refresh() {
      this.produitsDatatableKey++;
      this.exportProduitsDatatableKey++;
      this.chargesDatatableKey++;
      this.exportChargesDatatableKey++;
    },

    toggleRowVisibility(target, index) {
      //this.refreshindex7++;
      let i = index;
      let state = target[index].show;

      // si pas une entete ou si "entete-niv2" ou si "entete-niv3"
      // on ne s'occupe pas des enfants

      if (
        !target[index].entete ||
        target[index].entete == "entete-niv2" ||
        target[index].entete == "entete-niv3"
      ) {
        this.refresh();
        return;
      }

      // si entete = entete-niv1
      // on masque tout les enfants

      do {
        target[i].show = state;

        i++;
      } while (
        target[i] &&
        target[i].budgetline_categorie_id ==
          target[index].budgetline_categorie_id
      );
      //console.log("forceUpdate");
      //this.$forceUpdate();
      this.refresh();
      return;
    },

    exportImage(Elementid) {
      var node = document.getElementById(Elementid);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = Elementid + ".png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    /*
     * format value in K€
     */
    format(value) {
      if (isNaN(parseFloat(value))) {
        return value;
      }
      // formatage en K€ on préfère que les montants soient arrondis à l’unité cf recette du 31/03/2023 et mail du 12/04
      return this.kilo_euro == true
        ? parseFloat(value / 1000).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : parseFloat(value).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },
  },

  mounted() {
    this.getClients().then(() => {
      // recuperation du client selectionné sauvegardé en local storage
      // mise a jour du local storage si entretemps un paramétrage client a été mis à jour

      if (localStorage.selectedClient) {
        var selectedClientInlocalstorage = JSON.parse(
          localStorage.selectedClient
        );
        this.selectedClient = _.find(this.clients, {
          id: selectedClientInlocalstorage.id,
        });
        localStorage.selectedClient = JSON.stringify(this.selectedClient);
      }
    });
  },
};
</script>