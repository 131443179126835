const state = {
    drawer :{
        drawer : false
    }
};

const getters = {};

const actions = {
    toggleDrawer({commit}){
        commit('setDrawer')
    },

    closeDrawer({commit}){
        commit('closeDrawer')
    }


};

const mutations = {
    setDrawer (state){
        state.drawer.drawer = ! state.drawer.drawer
    },
    closeDrawer(state){
        state.drawer.drawer = false
    }
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
    
}