<template>
  <!-- Le template -->
  <v-container>
    <v-row>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">
          Balances Annuelles du client :
        </h1>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row v-if="selectedClient">
      <v-col>
        <v-tabs v-model="tab" class="pb-8" show-arrows>
          <v-tab>N-4<br />{{ annee("n-4") }}</v-tab>
          <v-tab>N-3<br />{{ annee("n-3") }}</v-tab>
          <v-tab>N-2<br />{{ annee("n-2") }}</v-tab>
          <v-tab>N-1<br />{{ annee("n-1") }}</v-tab>
          <v-tab>Historique</v-tab>
          <v-tab>Importer</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pa-8">
          <!--N-4-->
          <v-tab-item>
            <balance-view
              :selectedClient="selectedClient"
              exercice="n-4"
            ></balance-view>
            <!---->
          </v-tab-item>
          <!--N-3-->
          <v-tab-item>
            <balance-view
              :selectedClient="selectedClient"
              exercice="n-3"
            ></balance-view>
            <!---->
          </v-tab-item>

          <!--N-2 -->
          <v-tab-item>
            <balance-view
              :selectedClient="selectedClient"
              exercice="n-2"
            ></balance-view>
            <!---->
          </v-tab-item>

          <!--N-1-->
          <v-tab-item>
            <balance-view
              :selectedClient="selectedClient"
              exercice="n-1"
            ></balance-view>
            <!---->
          </v-tab-item>

          <!--Historique -->
          <v-tab-item>
            <datatable
              :selectedClient="selectedClient"
              :possibleHeaders="balanceannuelleHistory_possibleHeaders"
              :api_endpoint_url="balanceannuelleHistory_apiEndPointUrl"
              :default_column_names="
                balanceannuelleHistory_default_column_names
              "
              :default_column_order="
                balanceannuelleHistory_default_column_order
              "
              :create_route_name="null"
              :show_route_name="balanceannuelleHistory_show_route_name"
              :update_route_name="null"
              :api_delete_endpoint_url="
                balanceannuelleHistory_api_delete_endpoint_url
              "
            ></datatable>
          </v-tab-item>
          <!--Importation balance-->

          <v-tab-item>
            <balanceAnnuelleImportForm
              :clientID="selectedClient.id"
            ></balanceAnnuelleImportForm>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row> <v-col> </v-col></v-row>
  </v-container>
</template>

<script>
import { EventBus } from "../../../EventBus";
// Le script
export default {
  data() {
    return {
      Loading: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      tab: 5, //onglget import activé par defaut

      balanceannuelleHistory_possibleHeaders: [
        {
          text: "Ref",
          value: "id",
          align: "left",
          name: "id",
          filterable: true,
          sortable: true,
          order: 1,
        },
        {
          text: "Date de cloture",
          value: "cloture",
          align: "left",
          name: "cloture",
          filterable: true,
          sortable: true,
          order: 1.1,
        },
        {
          text: "Uploadé le",
          value: "updated_at",
          name: "updated_at",
          filterable: true,
          sortable: true,
          order: 1.1,
        },
        {
          text: "Créé par",
          value: "created_by_user_id.fullname",
          name: "created_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 2,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
        {
          text: "Modifié par",
          value: "updated_by_user_id.fullname",
          name: "updated_by_user_id.fullname",
          filterable: true,
          sortable: false,
          order: 7,
        },

        {
          text: "actions",
          value: "actions",
          name: "actions",
          filterable: false,
          sortable: false,
          order: 9,
        },
      ],
      balanceannuelleHistory_default_column_names: [
        "cloture",
        "updated_at",
        "updated_by_user_id.fullname",
        "actions",
      ],
      balanceannuelleHistory_default_column_order: "updated_at",
      balanceannuelleHistory_api_delete_endpoint_url:
        "/api/balanceannuelle_history/delete/",
      balanceannuelleHistory_show_route_name: "/api/balanceannuelle_history",
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    balanceannuelleHistory_apiEndPointUrl() {
      return (
        "/api/load_balanceannuelle_history?client_id=" + this.selectedClient.id
      );
    },
  },
  watch: {
    selectedClient(val) {
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);
    },
  },
  methods: {
    // retourne une annee en fonction du nombre d'année en arriere (n-1, n-2 etc...)
    annee(param) {
      var dateActuelle = new Date();
      var anneeActuelle = dateActuelle.getFullYear();

      // Calculer l'année en fonction du paramètre "arriere"
      if (param === "n") {
        return anneeActuelle;
      } else if (param.startsWith("n-")) {
        var anneesEnArriere = parseInt(param.slice(2), 10);
        if (!isNaN(anneesEnArriere)) {
          return anneeActuelle - anneesEnArriere;
        }
      }
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },
  },

  mounted() {
    // recuperation sauvegarde du client selectionné
    if (localStorage.selectedClient) {
      this.selectedClient = JSON.parse(localStorage.selectedClient);
    }
    this.getClients();
  },
};
</script>