<template>
  <!-- Le template -->
  <div>
    <v-row dense>
      <v-col
        ><v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher un compte"
          single-line
          hide-details
          dense
        ></v-text-field>
        <v-data-table
          dense
          :headers="headers"
          :items="pcgs"
          :search="search"
          :footer-props="{
            'items-per-page-all-text': 'tous',
            'items-per-page-text': 'lignes par page',
          }"
          :sort-by="'created_at'"
          :sort-desc="true"
        >
          <template v-slot:item.created_at="{ item }">
            <span> {{ formaDatetWithDuration(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.custombgcolor {
  background-color: #1e4461;
}
</style>

<script>
import { EventBus } from "../../EventBus";

// Le script
export default {
  name: "compte-non-affecte",
  props: {
    currentPrevis: { type: Array },
    lbhc: { type: Object },
    classe: { type: Number },
    pcgs: { type: Array },
  },
  data() {
    return {
      search: "",
      selectedClient: null,
      headers: [
        {
          text: "Numéro de compte",
          value: "compte",
          name: "compte",
          filterable: true,
          sortable: true,
          order: 1.1,
        },
        {
          text: "Intitulé",
          value: "intitule",
          name: "intitule",
          filterable: true,
          sortable: true,
          order: 1.2,
        },
        {
          text: "Créé le",
          value: "created_at",
          name: "created_at",
          filterable: true,
          sortable: true,
          order: 6,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    formaDatetWithDuration(date) {
      let fromnow = this.$moment(date).fromNow(); // moment(thedate).fromNow();
      let output = this.$moment(date).format("ddd D MMM YYYY");
      return output + " (" + fromnow + ")";
    },
  },

  mounted() {
    // recuperation sauvegarde du client selectionné
    if (localStorage.selectedClient) {
      this.selectedClient = JSON.parse(localStorage.selectedClient);
    }
    // on client changed
    EventBus.$on("ClientChanged", (client) => {
      this.selectedClient = client;
    });
  },
};
</script>