<template>
  <div>
    <v-navigation-drawer class="d-print-none" v-model="drawer" app clipped>
      <v-list>
        <!-- DASHBOARD -->
        <v-list-item :to="{ name: 'dashboard' }">
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0"></v-divider>

        <!-- USERS -->
        <v-list-item :to="{ name: 'user/list' }">
          <v-list-item-action>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Utilisateurs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0"></v-divider>

        <!-- CLIENTS -->
        <v-list-item :to="{ name: 'client/list' }">
          <v-list-item-action>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Clients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0"></v-divider>

        <!-- PARAMETRAGE CLIENTS -->
        <v-list-item :to="{ name: 'client/parametrage' }">
          <v-list-item-action>
            <v-icon>mdi-office-building-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Paramétrages Clients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0"></v-divider>

        <!-- MENU DIAGNOSTIC -->
        <v-list-group  prepend-icon="mdi-clipboard-text-clock" group="diag">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Diagnostics</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item dense :to="{ name: 'diag/pcgs' }" >
            <v-list-item-icon class="ml-2 mr-4"><v-icon>mdi-folder-edit</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Plan comptable général</v-list-item-title></v-list-item-title>
            
          </v-list-item>
          <v-list-item dense :to="{ name: 'diag/balances' }">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-folder-edit</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Balances Annuelles</v-list-item-title></v-list-item-title>            
          </v-list-item>   

              
          <v-list-item dense :to="{ name: 'diag/analyses' }">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-table-eye</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Analyse</v-list-item-title></v-list-item-title>            
          </v-list-item> 
                         

        </v-list-group>
        <v-divider class="ma-0 pa-0"></v-divider>

        <!-- MENU PREVISIONNEL -->
        <v-list-group  prepend-icon="mdi-calendar-month" group="previsionnel">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Prévisionnels</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item  dense :to="{name :'previsionnels/importCR'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-tray-arrow-up</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Compte de résultat</v-list-item-title></v-list-item-title>            
          </v-list-item>  
          <v-list-item dense :to="{name :'previsionnels/sig'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-table-eye</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>SIG</v-list-item-title></v-list-item-title>            
          </v-list-item> 
          <v-list-item dense :to="{name :'previsionnels/charges-budget'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-table-eye</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Charges / Budget</v-list-item-title></v-list-item-title>            
          </v-list-item>                    
          <v-list-item dense :to="{name :'previsionnels/importPlanfi'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-tray-arrow-up</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Plan de financement</v-list-item-title></v-list-item-title>            
          </v-list-item>   
          <v-list-item dense :to="{name :'previsionnels/importPt'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-tray-arrow-up</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Plan de trésorerie</v-list-item-title></v-list-item-title>            
          </v-list-item>
          <v-list-item dense :to="{name :'previsionnels/bilan-detaille'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-table-eye</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Bilan détaillé</v-list-item-title></v-list-item-title>            
          </v-list-item> 
          <v-list-item dense :to="{name :'previsionnels/bilan-fonctionnel'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-table-eye</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Bilan fonctionnel</v-list-item-title></v-list-item-title>            
          </v-list-item>
          <v-list-item dense :to="{name :'previsionnels/ratio'}">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-table-eye</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Ratio</v-list-item-title></v-list-item-title>            
          </v-list-item>                                                                                                     
                
        </v-list-group>
        <v-divider class="ma-0 pa-0"></v-divider>

        <!-- MENU SUIVI BUDGETAIRE -->
        <v-list-group  prepend-icon="mdi-folder-eye" group="suivis">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Suivis</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item dense :to="{ name: 'suivis/budget-courant' }">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-calendar-clock-outline</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Budget courant</v-list-item-title></v-list-item-title>            
          </v-list-item>

          <v-list-item dense :to="{ name: 'suivis/balances' }">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-calendar-import-outline</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Balances Mensuelles</v-list-item-title></v-list-item-title>
            
          </v-list-item>   

                         
          <v-list-item dense :to="{ name: 'suivis/suivis-budgetaires-cumules' }">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-table-headers-eye</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Suivi Budg. cumulé</v-list-item-title></v-list-item-title>            
          </v-list-item> 

          <v-list-item dense :to="{ name: 'suivis/suivis-budgetaires-personnalises' }">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-calendar-range</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Suivi Budg. personnalisé</v-list-item-title></v-list-item-title>            
          </v-list-item>   

          <v-list-item dense :to="{ name: 'suivis/indicateurs' }">
            <v-list-item-icon  class="ml-2 mr-4"><v-icon>mdi-chart-areaspline</v-icon></v-list-item-icon>
            <v-list-item-title><v-list-item-title>Indicateurs visuels</v-list-item-title></v-list-item-title>            
          </v-list-item>                  
        </v-list-group>
        <v-divider class="ma-0 pa-0"></v-divider>


      </v-list>

    </v-navigation-drawer>
  </div>
</template>

<script>
// Le script
export default {
  name: "drawer_navigation",

  computed: {
    drawer: {
      get() {
        return this.$store.state.currentDrawer.drawer.drawer;
      },
      set(val) {
        return;
      },
    },
  },
};
</script>

<style>
.v-list-item--link:before {
    background-color: #ffffff;
    color:#ffffff;
}
.v-list-item--active .v-icon, .v-list-item--active .v-iconv-list-item__title, .v-list-item--active.v-list-item--link {color :#ffffff !important}
</style>