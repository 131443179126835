var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"11"}},[_c('h1',{staticClass:"mr-3 my-0",staticStyle:{"line-height":"1.5em"}},[_vm._v("\n        Prévisionnels "+_vm._s(_vm.annee)+" "+_vm._s(_vm.etat)+" pour\n        "+_vm._s(_vm.selectedClient.denomination_sociale)+"\n      ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.selectedClient && !_vm.Loading)?_c('v-btn',{attrs:{"to":"/app/suivis/historique-previsionnels-cr"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-history")]),_vm._v("\n        Retour Historique\n      ")],1):_vm._e()],1)],1),_vm._v(" "),(_vm.currentPrevis && !_vm.Loading)?_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"vertical":""}},[_c('v-tab',[_vm._v("Produits")]),_vm._v(" "),_c('v-tab',[_vm._v("Charges")]),_vm._v(" "),_c('v-tab-item',[_c('v-row',{staticClass:"scroll-horizontal",attrs:{"dense":""}},[_c('v-col',[(_vm.currentPrevis && !_vm.Loading)?_c('resultat',{staticStyle:{"padding":"16px 24px"},attrs:{"moisExercice":_vm.moisExercice,"currentPrevis":_vm.currentPrevis}}):_vm._e(),_vm._v(" "),(_vm.currentPrevis && !_vm.Loading)?_c('resultat-classe',{staticStyle:{"padding":"16px 24px"},attrs:{"moisExercice":_vm.moisExercice,"currentPrevis":_vm.currentPrevis,"classe":{ intitule: 'Produits', id: 7 }}}):_vm._e(),_vm._v(" "),_c('v-expansion-panels',{attrs:{"accordion":"","value":[0, 1, 2, 3, 4],"multiple":""}},_vm._l((_vm.categories(7)),function(categorie,indexcat){return _c('v-expansion-panel',{key:indexcat},[_c('v-expansion-panel-header',{attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-icon',{staticClass:"icon"},[_vm._v("$expand")]),_vm._v("\n                            "+_vm._s(categorie.intitule)+"\n                          ")],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.currentPrevis && !_vm.Loading)?_c('resultat-categorie',{attrs:{"moisExercice":_vm.moisExercice,"currentPrevis":_vm.currentPrevis,"categorie":categorie}}):_vm._e()],1)],1)],1)]}}],null,true)}),_vm._v(" "),(_vm.currentPrevis && !_vm.Loading)?_c('v-expansion-panel-content',_vm._l((_vm.lbincategorie(categorie.id)),function(lb,indexlbcat){return _c('ligne-budgetaire',{key:indexlbcat,attrs:{"readonly":true,"pcg":_vm.pcg7,"bgcolor":'blue-grey darken-4',"moisExercice":_vm.moisExercice,"lb":lb,"first":indexlbcat == 0 ? true : false,"last":indexlbcat ==
                        Object.keys(_vm.lbincategorie(categorie.id)).length - 1
                          ? true
                          : false}})}),1):_vm._e()],1)}),1),_vm._v(" "),(_vm.currentPrevis && !_vm.Loading)?_c('ligne-budgetaire-hc',{staticStyle:{"padding":"16px 24px"},attrs:{"readonly":true,"moisExercice":_vm.moisExercice,"currentPrevis":_vm.currentPrevis,"lbhc":_vm.lbhc((_vm.classe = 7)),"pcgs":_vm.pcg7}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-tab-item',[_c('v-row',{staticClass:"scroll-horizontal",attrs:{"dense":""}},[_c('v-col',[(_vm.currentPrevis && !_vm.Loading)?_c('resultat-classe',{staticStyle:{"padding":"16px 24px"},attrs:{"moisExercice":_vm.moisExercice,"currentPrevis":_vm.currentPrevis,"classe":{ intitule: 'Charges', id: 6 }}}):_vm._e(),_vm._v(" "),_c('v-expansion-panels',{attrs:{"accordion":"","value":[0, 1, 2, 3, 4, 5, 6, 7, 8],"multiple":""}},_vm._l((_vm.categories(6)),function(categorie,indexcat){return _c('v-expansion-panel',{key:indexcat},[_c('v-expansion-panel-header',{attrs:{"hide-actions":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var open = ref.open;
return [_c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-icon',{staticClass:"icon"},[_vm._v("$expand")]),_vm._v("\n                            "+_vm._s(categorie.intitule)+"\n                          ")],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.currentPrevis && !_vm.Loading)?_c('resultat-categorie',{attrs:{"moisExercice":_vm.moisExercice,"currentPrevis":_vm.currentPrevis,"categorie":categorie}}):_vm._e()],1)],1)],1)]}}],null,true)}),_vm._v(" "),_c('v-expansion-panel-content',_vm._l((_vm.lbincategorie(categorie.id)),function(lb,indexlbcat){return _c('ligne-budgetaire',{key:indexlbcat,attrs:{"readonly":true,"pcg":_vm.pcg6,"bgcolor":'blue-grey darken-4',"moisExercice":_vm.moisExercice,"lb":lb,"first":indexlbcat == 0 ? true : false,"last":indexlbcat ==
                        Object.keys(_vm.lbincategorie(categorie.id)).length - 1
                          ? true
                          : false}})}),1)],1)}),1),_vm._v(" "),(_vm.currentPrevis && !_vm.Loading)?_c('ligne-budgetaire-hc',{staticStyle:{"padding":"16px 24px"},attrs:{"readonly":true,"pcgs":_vm.pcg6,"moisExercice":_vm.moisExercice,"currentPrevis":_vm.currentPrevis,"lbhc":_vm.lbhc((_vm.classe = 6))}}):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }