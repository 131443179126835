<template>
    <div>
                <v-btn text v-if="isLoggedIn" @click.prevent="logout"><span>Logout</span><v-icon right >mdi-logout</v-icon></v-btn>
                <v-btn text :to="{name:'login'}"  v-if="!isLoggedIn"><span>Login</span><v-icon right >mdi-login</v-icon></v-btn> 
    </div>
</template>

<script>
    // Le script
    export default {
        name : "login_logout_button",

        computed : {
            isLoggedIn : {get(){
                return this.$store.getters['currentUser/isLoggedIn']
                }
            }
        },
        methods :{

            logout() {
                console.log('logout cliqued')
                this.$router.push({name:'welcome'})
                this.$store.dispatch('currentUser/logoutUser')
            }    
        },
    }
</script>