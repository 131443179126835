<template>
  <!-- Le template -->
  <section>
    <v-container>
      <h4 class="mb-8">TVA pour le client</h4>

      <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
        <ul>
          <div v-for="(value, propertyName, index) in formError" :key="index">
            <li v-if="value && Array.isArray(value)">
              {{ propertyName }}: {{ value[0] }}
            </li>
            <li v-if="value && typeof value == 'string'">
              {{ propertyName }}: {{ value }}
            </li>
          </div>
        </ul>
      </v-alert>

      <v-snackbar
        v-model="snackbar"
        bottom
        color="success"
        multi-line
        :timeout="6000"
      >
        <p v-html="snackbar_text"></p>

        <v-btn
          dark
          color="green darken-4"
          @click="
            snackbar_text = '';
            snackbar = false;
          "
          >Fermer</v-btn
        >
      </v-snackbar>
      <v-form ref="form" v-model="valid" v-if="!isClientLoading">
        <v-row>
          <!-- TVA Form   -->
          <v-col>
            <v-text-field
              label="TVA"
              name="tva"
              hint="Veuillez indiquer le taux de TVA. Ex. 5.5 ou 20 ou 2.1 ou 10 <br> doit etre positif et <100"
              persistent-hint
              v-model="client.tva"
              :rules="[rules.numeric, rules.customValidation]"
              :error-messages="formError.tva"
              @focus="formError.tva = null"
            >
              <template v-slot:message="{ message, key }">
                <div v-html="message" :key="key"></div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-btn
          class="mt-4"
          color="primary"
          @click.prevent="submit"
          :disabled="!valid"
        >
          <v-icon left>mdi-check</v-icon>
          Enregistrer
        </v-btn>
      </v-form>
    </v-container>
  </section>
</template>

<script>
// Le script
export default {
  name: "tva-form",
  props: {
    clientID: Number,
  },
  data() {
    return {
      client: { tva: null },

      isSaving: false,
      isClientLoading: true,

      serverAlert: null,
      snackbar_text: "",
      snackbar: false,

      valid: false,

      formError: {
        tva: null,
      },
      rules: {
        numeric: (v) => !isNaN(v) || "Valeur numérique attendue",
        customValidation: (v) => {
          if (v === null || v === "") {
            return true; // La valeur peut être nulle ou vide
          } else {
            if (!isNaN(v) && v >= 0 && v < 100) {
              const parts = v.toString().split(".");
              if (
                parts.length === 1 ||
                (parts.length === 2 && parts[1].length <= 2)
              ) {
                return true; // Nombre positif avec 2 chiffres max après la virgule et inférieur à 100
              }
            }
            return "Valeur invalide. Doit être nulle ou un nombre positif avec 2 chiffres max après la virgule et inférieur à 100";
          }
        },
      },
    };
  },
  watch: {
    clientID() {
      this.getClient();
    },
  },
  methods: {
    /*
     * get client info
     */

    getClient() {
      console.log("get Client info");
      this.isClientLoading = true;
      axios
        .get("/api/client/" + this.clientID + "+?from=client/show")
        .then((response) => {
          Object.assign(this.client, response.data);
          this.isClientLoading = false;
        })
        .catch((err) => {
          console.log("/api/client/" + this.clientID + " KO", err);
          console.log(err.response);
          this.isClientLoading = false;
          if (err.response.status == 403) {
            this.$router.push({ name: "unauthorized" });
          }
        });
    },
    submit() {
      this.isSaving = true;
      this.serverAlert = null;

      let payload = {
        client_id: this.client.id,
        tva: this.client.tva,
      };

      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/client/edit_tva/" + this.client.id, payload)
          .then((response) => {
            console.log("submit OK", response);

            this.isSaving = false;
            this.snackbar = true;
            this.snackbar_text = "TVA modifiée avec succès";

            this.getClient();
          })
          .catch((err) => {
            this.serverAlert = true;
            this.isSaving = false;
            console.log("submit_ko", err);

            if (err.response.status == 422) {
              Object.assign(this.formError, err.response.data.errors);
            }

            this.formError.general = err.response.data.message;
          });
      });
      return;
    },
  },
  mounted() {
    this.getClient();
  },
};
</script>