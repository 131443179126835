import VueRouter from 'vue-router';
import store from './store';

const routes = [

  // WELCOME
  { path: '/', name: 'welcome', component: require('./pages/welcome.vue').default },

  // LOGIN
  {
    path: '/app/login', name: 'login', component: require('./pages/login.vue').default,
    meta: {
      requiresNotAuth: true
    }
  },

  // UNAUTHORIZED
  {
    path: '/app/unauthorized', name: 'unauthorized', component: require('./pages/unauthorized.vue').default,
    meta: {
      requiresAuth: true
    }

  },

  // DASHBOARD
  {
    path: '/app/dashboard', name: 'dashboard', component: require('./pages/dashboard.vue').default,
    meta: {
      requiresAuth: true
    }
  },


  // GESTION DES COMPTES UTILISATEURS

  // list
  {
    path: '/app/user/list', name: 'user/list', component: require('./pages/user/list.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // create
  {
    path: '/app/user/create', name: 'user/create', component: require('./pages/user/form.vue').default,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['currentUser/LoggedUser'].can.create) { return next({ name: 'unauthorized' }) }
      next()
    }
  },

  // edit
  {
    path: '/app/user/edit/:userId', name: 'user/edit', component: require('./pages/user/form.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // show
  {
    path: '/app/user/show/:userId', name: 'user/show', component: require('./pages/user/form.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // GESTION DES CLIENTS

  // list
  {
    path: '/app/client/list', name: 'client/list', component: require('./pages/client/list.vue').default,
    meta: {
      requiresAuth: true
    }
  },
  // create
  {
    path: '/app/client/create', name: 'client/create', component: require('./pages/client/form.vue').default,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['currentUser/LoggedUser'].can.createClient) { return next({ name: 'unauthorized' }) }
      next()
    }
  },

  // edit
  {
    path: '/app/client/edit/:clientId', name: 'client/edit', component: require('./pages/client/form.vue').default,
    meta: {
      requiresAuth: true
    },

  },

  // show
  {
    path: '/app/client/show/:clientId', name: 'client/show', component: require('./pages/client/form.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // PARAMETRAGE COMPTES CLIENTS

  // parametrage
  {
    path: '/app/client/parametrage', name: 'client/parametrage', component: require('./pages/client/parametrage/list.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage ETP UPDATE
  {
    path: '/app/client/parametrage/etp/update/:itemId', name: 'client/parametrage/etp/update', component: require('./pages/client/parametrage/etp_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage DIVIDENDE UPDATE
  {
    path: '/app/client/parametrage/dividende/update/:itemId', name: 'client/parametrage/dividende/update', component: require('./pages/client/parametrage/dividende_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage PRIX MOYEN UPDATE
  {
    path: '/app/client/parametrage/prixmoyen/update/:itemId', name: 'client/parametrage/prixmoyen/update', component: require('./pages/client/parametrage/prixmoyen_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage REMBOURSEMENT EMPRUNT STRUCTUREL UPDATE
  {
    path: '/app/client/parametrage/remboursement_es/update/:itemId', name: 'client/parametrage/remboursement_es/update', component: require('./pages/client/parametrage/remboursement_es_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage  INVESTISSEMENT REALISE UPDATE
  {
    path: '/app/client/parametrage/investissement_realise/update/:itemId', name: 'client/parametrage/investissement_realise/update', component: require('./pages/client/parametrage/investissement_realise_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage AUGMENTATION CAPITAL UPDATE
  {
    path: '/app/client/parametrage/augmentation_capital/update/:itemId', name: 'client/parametrage/augmentation_capital/update', component: require('./pages/client/parametrage/augmentation_capital_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage SUBVENTION INVESTISSEMENT CONVENTIONNE UPDATE
  {
    path: '/app/client/parametrage/subvention_ic/update/:itemId', name: 'client/parametrage/subvention_ic/update', component: require('./pages/client/parametrage/subvention_ic_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // parametrage AUTRE AUGMENTATION QFP UPDATE
  {
    path: '/app/client/parametrage/augmentation_qfp/update/:itemId', name: 'client/parametrage/augmentation_qfp/update', component: require('./pages/client/parametrage/augmentation_qfp_update.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  /*  
  // DIAGNOSTIC
  */

  // pcg
  {
    path: '/app/diag/pcgs', name: 'diag/pcgs', component: require('./pages/diag/pcgs/pcgslist.vue').default,
    meta: {
      requiresAuth: true
    }
  },
  // balances annuelles
  {
    path: '/app/diag/balances', name: 'diag/balances', component: require('./pages/diag/balances/balanceslist.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // Analyses
  {
    path: '/app/diag/analyses', name: 'diag/analyses', component: require('./pages/diag/analyses/analyseslist.vue').default,
    meta: {
      requiresAuth: true
    }
  },


  /*  
  // SUIVI
  */

  // edit prévisionnels CR (Budget courant)
  {
    path: '/app/suivis/budget-courant', name: 'suivis/budget-courant', component: require('./pages/suivis/budget-courant/previsionnels-cr.vue').default,
    meta: {
      requiresAuth: true
    }
  },
  // balances mensuelles
  {
    path: '/app/suivis/balances', name: 'suivis/balances', component: require('./pages/suivis/balances/balanceslist.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // suivi budgetaire mensuel
  {
    path: '/app/suivis/suivis-budgetaires-cumules', name: 'suivis/suivis-budgetaires-cumules', component: require('./pages/suivis/suivis-budgetaires-cumules/suivibudgetairescumules.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // suivi budgetaire cumule
  {
    path: '/app/suivis/suivis-budgetaires-personnalises', name: 'suivis/suivis-budgetaires-personnalises', component: require('./pages/suivis/suivis-budgetaires-personnalises/suivibudgetairespersonnalises.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  // indicateurs visuels
  {
    path: '/app/suivis/indicateurs', name: 'suivis/indicateurs', component: require('./pages/suivis/indicateurs/indicateurs.vue').default,
    meta: {
      requiresAuth: true
    }
  },

  /*
  // PREVISIONNELS
  */



  // historique previs CR (Budget courant)
  {
    path: '/app/previsionnels/historique-previsionnels-cr', name: 'previsionnels/historique-previsionnels-cr', component: require('./pages/suivis/previsionnels/historique.vue').default,
    meta: {
      requiresAuth: true
    }
  },
  // view previs CR from history

  {
    path: '/app/previsionnels/view-previsionnels-cr/', 
    name: 'previsionnels/view-previsionnels-cr', 
    component: require('./pages/suivis/previsionnels/previsionnels-cr-view.vue').default,
    meta: {
      requiresAuth: true
    },
    props: (route) => ({
      selectedClient : '',
      annee : '',
      etat : '',
      ...route.params
  })  

  },
  // Importation Compte de resultat
  {
    path: '/app/previsionnels/importCR/', 
    name: 'previsionnels/importCR', 
    component: require('./pages/suivis/previsionnels/import-cr.vue').default, 
    meta: {
      requiresAuth: true
    },

  },
  // Vue previsionnel SIG
  {
    path: '/app/previsionnels/sig/', 
    name: 'previsionnels/sig', 
    component: require('./pages/suivis/previsionnels/sig.vue').default, 
    meta: {
      requiresAuth: true
    },

  }, 
  // Vue previsionnel Charges / Budget
  {
    path: '/app/previsionnels/charges-budget', 
    name: 'previsionnels/charges-budget', 
    component: require('./pages/suivis/previsionnels/previsionnel-charges-budget.vue').default, 
    meta: {
      requiresAuth: true
    },

  }, 
  // Vue previsionnel Bilan détaillé
  {
    path: '/app/previsionnels/bilan-detaille', 
    name: 'previsionnels/bilan-detaille', 
    component: require('./pages/suivis/previsionnels/previsionnel-bilan-detaille.vue').default, 
    meta: {
      requiresAuth: true
    },

  },  
  
  // Vue previsionnel Bilan fonctionnel
  {
    path: '/app/previsionnels/bilan-fonctionnel', 
    name: 'previsionnels/bilan-fonctionnel', 
    component: require('./pages/suivis/previsionnels/previsionnel-bilan-fonctionnel.vue').default, 
    meta: {
      requiresAuth: true
    },

  },  
  
  // Vue previsionnel Ratio
  {
    path: '/app/previsionnels/ratio', 
    name: 'previsionnels/ratio', 
    component: require('./pages/suivis/previsionnels/previsionnel-ratio.vue').default, 
    meta: {
      requiresAuth: true
    },

  },    
  // Importation Plan financier prévisionnel
  {
    path: '/app/previsionnels/importPlanfi/', 
    name: 'previsionnels/importPlanfi', 
    component: require('./pages/suivis/previsionnels/import-planfi.vue').default, 
    meta: {
      requiresAuth: true
    },

  },
  // Importation Plan tresorerie prévisionnel
  {
    path: '/app/previsionnels/importPlantreso/', 
    name: 'previsionnels/importPt', 
    component: require('./pages/suivis/previsionnels/import-pt.vue').default, 
    meta: {
      requiresAuth: true
    },

  },
  {
    path: '/app/page2', name: 'page2', component: require('./pages/page2.vue').default,
    meta: {
      requiresAuth: true
    }
  },

];

const router = new VueRouter({
  routes,
  mode: 'history'
});


router.beforeEach((to, from, next) => {


  store.dispatch('currentUser/initializeAuth').then(response => {
    console.log('from: ' + from.name + ' to: ' + to.name + ' User Authenticated:' + store.getters['currentUser/isLoggedIn'])

    if (to.matched.some(record => record.meta.requiresNotAuth)) {

      if (store.getters['currentUser/isLoggedIn']) { return next({ name: 'dashboard' }) }
      else { return next({ name: 'login' }) }

    }

    // regle authentification obligatoire
    if (to.matched.some(record => record.meta.requiresAuth)) {

      if (store.getters['currentUser/isLoggedIn']) { next() }
      else { return next({ name: 'login' }) }

    }
  })
    .catch(() => {
      return next({ name: 'login' })
    })
  return next()

})


export default router;
