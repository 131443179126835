<template>
  <!-- Le template -->
  <!-- Le template -->
  <section>
    <v-container>
      <h4 class="mb-8">Importation compte de résultat</h4>

      <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
        <ul>
          <div v-for="(value, propertyName, index) in formError" :key="index">
            <li v-if="value && Array.isArray(value)" >
              {{ propertyName }}: <span class="pre-formatted">{{ value[0] }}</span>
            </li>
            <li v-if="value && typeof value == 'string'" >
              {{ propertyName }}: <span class="pre-formatted">{{ value }}</span>
            </li>
          </div>
        </ul>
      </v-alert>

      <v-snackbar
        v-model="snackbar"
        bottom
        color="success"
        multi-line
        :timeout="6000"
      >
        <p v-html="snackbar_text"></p>

        <v-btn
          dark
          color="green darken-4"
          @click="
            snackbar_text = '';
            snackbar = false;            
          "
          >Fermer</v-btn
        >
      </v-snackbar>
      <v-form ref="form" v-model="valid" >
        <v-row>
          <!-- compte de résultat FILE -->
          <v-col>
            <v-file-input dense show-size
            
            v-model="crfile"
            ref="crfile"
            accept=".csv"
            hint="Fichier au format CSV '(*.csv) avec encodage UTF8 si le fichier comporte des accents."
            persistent-hint
            label="Fichier Compte de résultat (CSV)"
                  :error-messages="formError.crfile"
                  :rules="[rules.required]"
                  @focus="formError.crfile = null" >
            <template v-slot:message="{ message, key }">
            <div class="mt-4" v-html="message"></div>
            </template>      
            </v-file-input>

          </v-col>
        </v-row>
        <v-row>
          <v-col><a @click="downloadCSVModel()">Téléchargez le modèle d'importation</a></v-col>
        </v-row>
        <v-btn
          class="mt-4"
          color="primary"
          @click.prevent="submit"
          :disabled="!valid"
        >
          <v-icon left>mdi-check</v-icon>
          Enregistrer
        </v-btn>
      </v-form>
    </v-container>
  </section>
</template>

<style>
.pre-formatted {white-space: pre-wrap;}
</style>

<script>

import {EventBus} from "../../EventBus";

// Le script
export default {
  name: "crImportForm",
  props: {
    clientID: Number,
  },
  data() {
    return {

      isSaving: false,

      serverAlert: null,
      snackbar_text: "",
      snackbar: false,

      crfile : null,
      valid: false,

      formError: {
        crfile: null,
      },

      rules: {
        required: (v) => !!v || "Ce champ est requis",

      },
    };
  },
  methods: {
    submit() {
      this.isSaving = true;
      this.serverAlert = null;

      let formData = new FormData();

      formData.append("crfile",this.crfile);
      formData.append("client_id", this.clientID);
        
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/previsionnel-import-cr/import/" + this.clientID, formData)
          .then((response) => {
            console.log("submit OK", response);

            this.isSaving = false;
            this.snackbar = true;
            this.snackbar_text = "Fichier Compte de résultat uploadé";
            this.$refs.crfile.reset()
            this.crfile=null
            EventBus.$emit('cruploaded',this.clientID)
            this.$emit('cruploaded',this.clientID)

            
            

          })
          .catch((err) => {
            this.serverAlert = true;
            this.isSaving = false;
            console.log("submit_ko", err);
            if (err.response.status == 422) {
              Object.assign(this.formError, err.response.data.errors);
            }

            this.formError.general = err.response.data.message;
            this.$refs.crfile.reset()
            this.crfile=null
          });
      });
      return;
    }, 

    downloadCSVModel(){
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .get("/api/previsionnel-cr/downloadModel/")
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'cr_previs.csv'); // Nom du fichier à télécharger
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        console.log("Download OK");
          })
          .catch((err) => {
            console.log("submit_ko", err);
          });
      });
    },     
  },
};
</script>