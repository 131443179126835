<template>
  <!-- Le template -->
  <v-container>
    <v-row>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">
          Balances Mensuelles :
        </h1>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <v-spacer></v-spacer>
      </v-col>
      
    </v-row>
    <v-row v-if="selectedClient">
      <v-col>
        <v-tabs v-model="tab" class="pb-8" show-arrows>

          <v-tab v-for="(mois,index) in balancesmensuelleshistory" :key="index">{{ mois.month_year }}</v-tab>

          <v-tab v-if="balancesmensuelleshistory.length!=0">Historique</v-tab> 
          <!-- -->
          <v-tab>Importer</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pa-8">

          <!--onlgets balances-->
          <v-tab-item v-for="(mois,index) in balancesmensuelleshistory" :key="index">
            <balance-mensuelle-view
            :selectedClient="selectedClient"
            :exercice="mois.cloture"></balance-mensuelle-view>           
          </v-tab-item>   

           <!--Historique -->
          
          <v-tab-item v-if="balancesmensuelleshistory.length!=0"> 
            <datatable @elementDeleted="reresh"
            :selectedClient="selectedClient"
            :possibleHeaders="balancemensuelleHistory_possibleHeaders" 
            :api_endpoint_url="balancemensuelleHistory_apiEndPointUrl"
            :default_column_names="balancemensuelleHistory_default_column_names"
            :default_column_order="balancemensuelleHistory_default_column_order"
            :create_route_name="null"
            :show_route_name="balancemensuelleHistory_show_route_name"
            :update_route_name="null"
            :api_delete_endpoint_url="balancemensuelleHistory_api_delete_endpoint_url"></datatable>             
          </v-tab-item>   
          
          <!--Importation balance-->
          
          <v-tab-item>
            <balanceMensuelleImportForm :clientID="selectedClient.id" @balanceuploaded="reresh"></balanceMensuelleImportForm>
          </v-tab-item>


        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row> <v-col> </v-col></v-row>
  </v-container>
</template>

<script>
import { EventBus } from "../../../EventBus";
// Le script
export default {
  data() {
    return {
      Loading: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      tab: null,
      balancesmensuelleshistory : [],


      balancemensuelleHistory_possibleHeaders: [
                {
                text: "Ref",
                value: "id",
                align: "left",
                name: "id",
                filterable: true,
                sortable: true,
                order: 1
                },  
                {
                text: "Date de cloture",
                value: "cloture",
                align: "left",
                name: "cloture",
                filterable: true,
                sortable: true,
                order: 1.1
                }, 
                {
                text: "Uploadé le",
                value: "updated_at",
                name: "updated_at",
                filterable: true,
                sortable: true,
                order: 1.1
                },                                 
                {
                text: "Créé par",
                value: "created_by_user_id.fullname",
                name: "created_by_user_id.fullname",
                filterable: true,
                sortable: false,
                order: 2
                },  
                {
                text: "Créé le",
                value: "created_at",
                name: "created_at",
                filterable: true,
                sortable: true,
                order: 6
                },           
                {
                text: "Modifié par",
                value: "updated_by_user_id.fullname",
                name: "updated_by_user_id.fullname",
                filterable: true,
                sortable: false,
                order: 7
                }, 
                                       
                {
                text: "actions",
                value: "actions",
                name: "actions",
                filterable: false,
                sortable: false,
                order: 9
                }
                ],
        balancemensuelleHistory_default_column_names:[
                "cloture",
                "updated_at",
                "updated_by_user_id.fullname",
                "actions",
                ],
        balancemensuelleHistory_default_column_order: "updated_at" ,  
        balancemensuelleHistory_api_delete_endpoint_url : "/api/balancemensuelle_history/delete/",    
        balancemensuelleHistory_show_route_name: "/api/balancemensuelle_history",                    


      }

  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    balancemensuelleHistory_apiEndPointUrl(){
        return "/api/load_balancemensuelle_history?client_id="+this.selectedClient.id
    },  
  },
  watch: {
    selectedClient(val) {
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);
      this.Loading = true;

      //get last balance
      this.getLastBalance();

    },
  },
  methods: {

    reresh(){
      console.log("emit refresh");
      this.Loading = true;
      this.getLastBalance();


    },
    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist?with_pcg")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
    * Liste des derniers mois de balance mensuelle
    */

    getLastBalance(){

      this.Loading = true;
      
      //load 12 last balances mensuelle
      return new Promise((resolve, reject) => {
        axios
          .get("/api/clientbalancemensuellehistory?client_id="+this.selectedClient.id)
          .then((response) => {
            console.log("/api/clientbalancemensuellehistory/ OK");
            console.log(response);
            this.balancesmensuelleshistory = response.data;

            console.log(this.balancesmensuelleshistory.length)
            
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/clientbalancemensuellehistory/ KO", err);
            console.log(err);
            this.Loading = true;
            reject(err);
          });
      });

    }
  },

  mounted() {

    this.getClients().then((response)=>{

      // recuperation du client selectionné sauvegardé en local storage
      if (localStorage.selectedClient) {
        this.selectedClient = JSON.parse(localStorage.selectedClient);
      }

      // si pas présent dans le listing client (clients filtrés avec pcg)
      // on affecte selectedClient à null

      if (!_.find(this.clients,{'id':this.selectedClient.id})) {
        this.selectedClient = null;        
      }


    });

    

  },
};
</script>