<template>
  <section>
    <!-- Le template -->
    <!--Barre outil listing-->
    <v-row>
      <!--Search-->
      <v-col align="center" justify="center" cols="auto">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche"
          single-line
          dense
        ></v-text-field>
      </v-col>
      <!--Colonne visibility-->
      <v-col align="center" justify="center" cols="auto">
        <v-select
          v-model="defaultHeaders"
          :items="possibleHeaders"
          label="Visibilité des colonnes"
          multiple
          return-object
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{
              defaultHeaders.length +
              " colonnes sur " +
              possibleHeaders.length +
              " possibles"
            }}</span>
          </template>
        </v-select>
      </v-col>
      <!--Export excel-->
      <v-col align="center" justify="center" cols="auto">
        <export-excel
          :data="myitems"
          :name="excel_file_name"
          :fields="excelheaders"
        >
          <v-btn color="grey darken-3">
            <v-icon dark left>mdi-file-export-outline</v-icon>Excel
          </v-btn>
        </export-excel>
      </v-col>
    </v-row>
    <!--DataTable -->
    <v-data-table
      sortBy="id"
      :footer-props="{
        'items-per-page-all-text': 'tout',
        'items-per-page-text': 'Résultats par page :',
      }"
      @update:items-per-page="setItemPerPage"
      :headers="headers"
      :items="myitems"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      class="elevation-1"
    >
      <!--FOOTER SLOTS -->
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >Lignes: {{ pageStart }} à {{ pageStop }} sur
        {{ itemsLength }}</template
      >

      <!-- VARIABLE SLOTS-->
      <template v-slot:item.pcg.variable="{ item }">
        <v-switch v-model="item.pcg.variable" readonly></v-switch>
      </template>

      <!-- COMPTE SLOTS-->
      <template v-slot:item.compte="{ item }">
        <span>{{ item.compte }}</span
        ><span v-if="item.comptes.length"
          ><span v-for="compte in item.comptes"> / {{ compte.compte }}</span>
        </span>
      </template>

      <!-- DATE SLOTS-->
      <template v-slot:item.created_at="{ item }">
        <span> {{ formaDatetWithDuration(item.created_at) }}</span>
      </template>
      <template v-slot:item.updated_at="{ item }">
        <span> {{ formaDatetWithDuration(item.updated_at) }}</span>
      </template>
      <template v-slot:item.cloture="{ item }">
        <span> {{ formaDatetWithDuration(item.cloture) }}</span>
      </template>
    </v-data-table>
  </section>
</template>

<script>
// Le script

import { EventBus } from "../EventBus";

export default {
  name: "datatable-balance",
  props: {
    selectedClient: Object,
    possibleHeaders: Array,
    api_endpoint_url: String,
    default_column_names: Array,
    default_column_order: String,
    create_route_name: String,
    show_route_name: String,
    update_route_name: String,
    api_delete_endpoint_url: String,
    excel_file_name: String,
  },
  data() {
    return {
      search: null,
      headers: [],
      options: { page: 1, itemsPerPage: 10 },
      defaultHeaders: [],
      myitems: [],
      excelheaders: {},
      totalItems: 0,
      datatableQuery: null,
      loading: true,
      columns: {},
      dialog: false,
      deleteItem: null,
      snackbar_text: null,
      snackbar: false,
      serverAlert: null,
    };
  },
  computed: {
    maxId() {
      return _.maxBy(this.myitems, "id").id;
    },

    //computed params to return pagination and search criteria
    params(nv) {
      return {
        ...this.options,
        query: this.search,
      };
    },
  },
  watch: {
    api_endpoint_url: function (newVal, oldVal) {
      console.log("api_endpoint_url changed");
      console.log(this.api_endpoint_url);
      this.setDatatableQuery();
      this.getDataFromApi();
    },

    defaultHeaders(val) {
      console.log("defaultHeaders changed");
      this.headers = _.orderBy(this.defaultHeaders, "order", "asc");
      this.headers.forEach((header) => {
        if (header.text != "actions") {
          this.excelheaders[header.text] = header.value;
        }
      });
    },
    params: {
      handler() {
        console.log("params changed");
        this.setDatatableQuery();
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        console.log("search changed");
        this.options.page = 1;
        if (this.search != "") {
          this.filtered = true;
        } else {
          this.filtered = false;
        }
      },
    },
  },
  methods: {
    setItemPerPage(val) {
      localStorage.list_item_per_page = val;
      this.options.itemsPerPage = parseInt(localStorage.list_item_per_page);
    },
    formaDatetWithDuration(date) {
      let fromnow = this.$moment(date).fromNow(); // moment(thedate).fromNow();
      let output = this.$moment(date).format("ddd D MMM YYYY");
      return output + " (" + fromnow + ")";
    },

    setDatatableQuery() {
      //Based on our Headers we create query data for DataTables
      //I've added a new param "searchable" to let DataBales know that this column is not searchable
      //You can also set name as "table.column eg users.name" if you select from more then table to avoid "Ambitious column name error from SQL"

      for (var i = 0; i < this.headers.length; i++) {
        this.columns[i] = {
          data: this.headers[i].value,
          name:
            typeof this.headers[i].name != "undefined"
              ? this.headers[i].name
              : this.headers[i].value,
          searchable: this.headers[i].filterable,
          orderable: this.headers[i].sortable,
          search: { value: null, regex: false },
        };
      }

      this.datatableQuery = {};
      this.datatableQuery.length = this.params.itemsPerPage;
      this.datatableQuery.start =
        this.params.page == 1
          ? 0
          : this.params.itemsPerPage * (this.params.page - 1); //set offset
      this.datatableQuery.search = { value: this.params.query }; //our search query
      this.datatableQuery.columns = this.columns;

      let sortBy_index = _.findIndex(this.headers, {
        value: this.params.sortBy[0],
      });

      if (sortBy_index != -1) {
        this.datatableQuery.order = {
          0: {
            column: sortBy_index,
            dir: this.params.sortDesc[0] === true ? "desc" : "asc",
          },
        };
      } else {
        this.datatableQuery.order = {
          0: {
            column: _.findIndex(this.headers, {
              value: this.default_column_order,
            }),
            dir: "asc",
          },
        };
      }

      this.datatableQuery = window.jQuery.param(this.datatableQuery);
    },

    // Fetching the data
    getDataFromApi() {
      console.log("getDataFromApi");
      console.log(
        "---getDataFromApi : " +
          this.api_endpoint_url +
          "&" +
          this.datatableQuery
      );
      this.loading = true;

      axios
        .get(this.api_endpoint_url + "&" + this.datatableQuery)
        .then((response) => {
          console.log("datafetched");
          console.log("---datafetched" + response.data.data);

          // traitement de la reponse
          // la relation item.comptes contient des doublons car
          // le belongstomany ne tient pas compte de l'année de cloture
          /*
          if(response.data.data){
            response.data.data.forEach((item) => {
              if (item.comptes.length != 0) {
                item.comptes=_.uniqBy(item.comptes,'compte')              
              }
            });
          }*/

          // liste des comptes groupés
          /*
          console.log("liste des comptes groupés")
          let groupitems= _.filter(response.data.data, function(o) { return o.comptes.length >0; });
          console.log(groupitems)
          */

          //traitement des comptes groupés
          /*
          console.log("traitement des comptes groupés")
          

          for(let item of groupitems) {
            console.log(item)
            // on ajoute au compte les (solde, debit,credit) des comptes associés
            let debit =  _.sumBy(item.comptes,'debit')           
            let credit = _.sumBy(item.comptes,'credit') 
            let solde  = _.sumBy(item.comptes,'solde') 

            console.log(debit,credit,solde)

            item.debit = Number((parseFloat(item.debit) + parseFloat(debit)).toFixed(2)) 
            item.credit = Number((parseFloat(item.credit) + parseFloat(credit)).toFixed(2)) 
            item.solde = Number((parseFloat(item.solde) + parseFloat(solde)).toFixed(2)) 
 
            for (const remove of item.comptes){
              console.log(remove)
              // on retire le compte associé de la liste
              response.data.data=_.filter(response.data.data, function(o) { return o.compte!=remove.compte ; });
            }            
          }
          */
          console.log("---myitems 1" + this.myitems);
          this.myitems = response.data.data;
          console.log("---myitems 2" + this.myitems);

          if (this.search) {
            this.totalItems = response.data.recordsFiltered;
          } else {
            this.totalItems = response.data.recordsTotal;
          }
          if (response.data.error != "") {
            this.serverAlert = response.data.error;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("erreur", err);
          this.serverAlert = err;
          this.loading = false;
        });
    },
  },
  mounted() {
    // sauvegarde des options d'affichage de la table
    if (localStorage.projetlistitemperpage) {
      this.options.itemsPerPage = parseInt(localStorage.projetlistitemperpage);
    }

    // initialisation des colonnes par defaut
    this.defaultHeaders = _.filter(this.possibleHeaders, (o) => {
      return this.default_column_names.includes(o.name);
    });

    // initialisation des colonnes excel
    this.headers = this.defaultHeaders;

    this.headers.forEach((header) => {
      if (header.text != "actions") {
        this.excelheaders[header.text] = header.value;
      }
    });

    // refresh data on event emited

    // on pcg uploaded
    EventBus.$on("pcgUploaded", (data) => {
      console.log("pcgUploaded");
      this.getDataFromApi();
    });

    // on pcg uploaded
    EventBus.$on("balanceannuelleUploaded", (data) => {
      console.log("balanceannuelleUploaded");
      this.getDataFromApi();
    });

    // on element deleted
    EventBus.$on("elementDeleted", (data) => {
      console.log("catch event elementDeleted from" + data);
      this.getDataFromApi();
    });
  },
};
</script>