var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"align":"center","justify":"center","cols":"auto"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche","single-line":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"align":"center","justify":"center","cols":"auto"}},[_c('v-select',{attrs:{"items":_vm.possibleHeaders,"label":"Visibilité des colonnes","multiple":"","return-object":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(_vm.defaultHeaders.length +
            " colonnes sur " +
            _vm.possibleHeaders.length +
            " possibles"))]):_vm._e()]}}]),model:{value:(_vm.defaultHeaders),callback:function ($$v) {_vm.defaultHeaders=$$v},expression:"defaultHeaders"}})],1),_vm._v(" "),_c('v-col',{attrs:{"align":"center","justify":"center","cols":"auto"}},[_c('export-excel',{attrs:{"data":_vm.myitems,"name":_vm.excel_file_name,"fields":_vm.excelheaders}},[_c('v-btn',{attrs:{"color":"grey darken-3"}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-file-export-outline")]),_vm._v("Excel\n        ")],1)],1)],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sortBy":"id","footer-props":{
      'items-per-page-all-text': 'tout',
      'items-per-page-text': 'Résultats par page :',
    },"headers":_vm.headers,"items":_vm.myitems,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:items-per-page":_vm.setItemPerPage,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v("Lignes: "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" sur\n      "+_vm._s(itemsLength))]}},{key:"item.pcg.variable",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"readonly":""},model:{value:(item.pcg.variable),callback:function ($$v) {_vm.$set(item.pcg, "variable", $$v)},expression:"item.pcg.variable"}})]}},{key:"item.compte",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.compte))]),(item.comptes.length)?_c('span',_vm._l((item.comptes),function(compte){return _c('span',[_vm._v(" / "+_vm._s(compte.compte))])}),0):_vm._e()]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formaDatetWithDuration(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formaDatetWithDuration(item.updated_at)))])]}},{key:"item.cloture",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formaDatetWithDuration(item.cloture)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }