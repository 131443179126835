<template>
  <div>
    <!-- Modal -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Confirmer la suppression ?</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                {{ lb.intitule }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="cancel">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click.stop="confirm"
            >Confirmer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row dense :class="bgcolor + ' no-gutters mb-1 py-2'">
      <v-col>
        <v-row dense class="flex-nowrap no-gutters px-1 py-1">
          <v-col cols="1">
            <v-row dense class="flex-nowrap no-gutters">
              <v-col class="" cols="auto">
                <v-tooltip top v-if="!first && !readonly">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" x-small @click.native.stop="lbup(lb)"
                      ><v-icon>mdi-arrow-up-thick</v-icon></v-btn
                    >
                  </template>
                  <span>Monter la ligne</span>
                </v-tooltip>
                <v-tooltip top v-if="!last && !readonly">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      x-small
                      @click.native.stop="lbdown(lb)"
                      ><v-icon>mdi-arrow-down-thick</v-icon></v-btn
                    >
                  </template>
                  <span>Descendre la ligne</span>
                </v-tooltip>
              </v-col>
              <v-col class="ml-8 mr-7" cols="auto">
                <v-tooltip top v-if="!first && !readonly">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      x-small
                      @click.native.stop="modalDelete(lb)"
                      ><v-icon>mdi-minus</v-icon></v-btn
                    >
                  </template>
                  <span>Supprimer la ligne</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="" cols="4">
            <v-text-field
              class="custom-padding-text"
              v-model="lb.intitule"
              label="Intitulé de la ligne budgétaire"
              outlined
              dense
              hide-details
              dark
              :readonly="readonly"
            ></v-text-field>
          </v-col>
          <v-col class="ml-4" cols="6">
            <v-autocomplete
              dense
              :clearable="!readonly"
              multiple
              chips
              small-chips
              :deletable-chips="!readonly"
              hide-details
              label="Comptes"
              :items="pcgselectionnable()"
              item-text="compte"
              item-value="id"
              v-model="lb.comptes"
              return-object
              :readonly="readonly"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense class="flex-nowrap no-gutters px-1 py-1">
          <v-col class="custom-min-width-resultat px-1 pt-1">€</v-col>
          <v-col
            class="px-0 pt-1"
            v-for="(month, index) in moisExercice"
            :key="index"
          >
            <v-text-field
              class="custom-padding-text"
              v-model="lb['mois_' + parseInt(index + 1)]"
              :label="month"
              outlined
              dense
              hide-details
              dark
              :readonly="readonly"
            ></v-text-field>
          </v-col>

          <v-col class="px-1 pt-1" style="border-left: 1px solid gray">
            <v-text-field
              class="custom-padding-text"
              :value="TotalValue()"
              label="TOTAL"
              outlined
              readonly
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.custom-padding-text >>> .v-input__slot {
  padding-left: 5px !important; /* Personnalisez le padding selon vos besoins */
  padding-right: 5px !important;
  min-width: 45px !important;
}

.custom-padding-text >>> input {
  font-size: 0.75em !important;
}

.custom-padding-text >>> .v-input__slot {
  padding-left: 5px !important; /* Personnalisez le padding selon vos besoins */
  padding-right: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 15px !important;
}

.custom-padding-text >>> label {
  margin-bottom: 0px !important;
  top: 9px !important;
}
</style>
  
  <script>
export default {
  name: "ligne-budgetaire",
  props: {
    moisExercice: { type: Array },
    bgcolor: { type: String },
    lb: { type: Object },
    first: { type: Boolean },
    last: { type: Boolean },
    pcg: { type: Array },
    readonly: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      total: null,
      dialog: false,
    };
  },
  computed: {},

  methods: {
    pcgselectionnable() {
      const comptes_dispos = this.pcg;
      const comptes_selectionnes = this.lb.comptes;

      if (comptes_selectionnes) {
        const comptes_selectionnables =
          comptes_dispos.concat(comptes_selectionnes);

        return _.sortBy(comptes_selectionnables, "compte");
      }
      return _.sortBy(comptes_dispos, "compte");
    },
    modalDelete(lb) {
      this.dialog = true;
    },

    cancel() {
      this.dialog = false;
    },

    confirm(lb) {
      this.dialog = false;
      this.lbdelete(lb);
    },

    lbup() {
      this.$emit("lbup", this.lb);
    },

    lbdown() {
      this.$emit("lbdown", this.lb);
    },

    lbdelete() {
      // event
      this.$emit("lbdelete", this.lb);
    },

    TotalValue() {
      // Inclure uniquement les propriétés commençant par 'mois_' pour la somme
      const proprietesCommencantParPrefixe = [];

      for (const key in this.lb) {
        if (key.startsWith("mois_")) {
          proprietesCommencantParPrefixe.push(key);
        }
      }
      var sommeValeurs = _.sum(
        Object.values(_.pick(this.lb, proprietesCommencantParPrefixe)).map(
          (value) => parseInt(value, 10)
        )
      );

      this.lb.total = sommeValeurs;

      // pour l'affichage on formate avec separateur milliers
      return this.lb.total.toLocaleString("fr-FR");
    },
  },
  watch: {
    lb: {
      handler() {
        // event
        this.$emit("lbupdate", this.lb);
      },
      deep: true,
    },

    "lb.comptes": {
      handler(newValues, oldValues) {
        // event
        this.$emit("comptesupdate", {
          oldValues: oldValues,
          newValues: newValues,
        });
      },
    },
  },
  mounted() {},
};
</script>
  