<template>
    <div>
        <v-app-bar-nav-icon v-if="isLoggedIn" @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
    </div>
</template>

<script>
    // Le script
    export default {
        name : "drawer_button",
        computed : {
            isLoggedIn : {get(){return this.$store.getters['currentUser/isLoggedIn']}
            }
        },
      
        methods :{
            toggleDrawer(){
                this.$store.dispatch('currentDrawer/toggleDrawer')
            }
        }
    }
</script>