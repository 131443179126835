<template>
  <!-- Le template -->
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">Indicateurs :</h1>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <!-- annee -->
        <v-spacer></v-spacer>
        <v-select
          v-if="selectedClient && !Loading"
          v-model="exercice"
          :items="exercices"
          item-text="annee"
          item-value="annee"
          label="Année du début d'exercice"
          persistent-hint
          return-object
          single-line
        ></v-select>
        <v-spacer></v-spacer>
        <!--select current month -->
        <v-select
          v-if="exercice && !Loading"
          v-model="selectedMonth"
          :items="moisExercice"
          label="Choisissez le mois"
          persistent-hint
          return-object
          single-line
        ></v-select>
        <v-spacer></v-spacer>
        <!--Switch K€ -->
        <v-switch
          class="mr-3 my-0"
          dense
          label="K€"
          v-model="kilo_euro"
        ></v-switch>
      </v-col>
    </v-row>

    <div
      v-if="
        exercice != null &&
        selectedMonth != null &&
        LoadingMoisExcercice == false
      "
    >
      <v-row>
        <!-- Chiffre d'affaire annuel cible -->
        <v-col class="white ma-1">
          <v-btn
            @click="exportImage('cacible')"
            color="grey darken-3"
            class="mr-1"
          >
            <v-icon dark left>mdi-file-image</v-icon>image
          </v-btn>

          <v-row id="cacible" class="white mr-1">
            <v-col>
              <cacible
                :selectedMonth="selectedMonth"
                :selectedClient="selectedClient"
                :exercice="exercice"
                :balances="balances"
                :kilo_euro="kilo_euro"
                :situationMonth="this.situationMonth()"
                :currentPrevis="currentPrevis"
                :moisExercice="moisExercice"
              ></cacible>
            </v-col>
          </v-row>
        </v-col>

        <!-- Chiffre d'affaire cumulé -->
        <v-col class="white ma-1">
          <v-btn
            @click="exportImage('cacumule')"
            color="grey darken-3"
            class="mr-1"
          >
            <v-icon dark left>mdi-file-image</v-icon>image
          </v-btn>
          <v-row id="cacumule" class="white mr-1">
            <v-col
              ><cacumule
                :selectedMonth="selectedMonth"
                :moisExercice="moisExercice"
                :selectedClient="selectedClient"
                :exercice="exercice"
                :balances="balances"
                :kilo_euro="kilo_euro"
                :situationMonth="this.situationMonth()"
                :currentPrevis="currentPrevis"
              ></cacumule
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <!-- Chiffre d'affaire mensuel -->
        <v-col class="white ma-1">
          <v-btn
            @click="exportImage('camensuel')"
            color="grey darken-3"
            class="mr-1"
          >
            <v-icon dark left>mdi-file-image</v-icon>image
          </v-btn>

          <v-row id="camensuel" class="white mr-1">
            <v-col>
              <camensuel
                :selectedMonth="selectedMonth"
                :moisExercice="moisExercice"
                :selectedClient="selectedClient"
                :exercice="exercice"
                :balances="balances"
                :kilo_euro="kilo_euro"
                :situationMonth="this.situationMonth()"
                :currentPrevis="currentPrevis"
              ></camensuel>
            </v-col>
          </v-row>
        </v-col>

        <!-- EMPLACEMENT LIBRE -->
        <v-col class="white ma-1">
          <v-row class="white mr-1">
            <v-col class="text-black"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <!--  Budgets depense annuel cible -->
        <v-col class="white ma-1">
          <v-btn
            @click="exportImage('budgetdepense')"
            color="grey darken-3"
            class="mr-1"
          >
            <v-icon dark left>mdi-file-image</v-icon>image
          </v-btn>
          <v-row id="budgetdepense" class="white mr-1">
            <v-col>
              <budgetscible
                :selectedMonth="selectedMonth"
                :moisExercice="moisExercice"
                :selectedClient="selectedClient"
                :exercice="exercice"
                :balances="balances"
                :kilo_euro="kilo_euro"
                :situationMonth="this.situationMonth()"
                :currentPrevis="currentPrevis"
              ></budgetscible>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <!-- SIG -->
        <v-col class="white ma-1">
          <v-btn @click="exportImage('sig')" color="grey darken-3" class="mr-1">
            <v-icon dark left>mdi-file-image</v-icon>image
          </v-btn>
          <v-row id="sig" class="white mr-1">
            <v-col class="text-black">
              <sig
                :selectedMonth="selectedMonth"
                :moisExercice="moisExercice"
                :selectedClient="selectedClient"
                :exercice="exercice"
                :balances="balances"
                :kilo_euro="kilo_euro"
                :situationMonth="this.situationMonth()"
                :currentPrevis="currentPrevis"
              ></sig>
            </v-col>
          </v-row>
        </v-col>

        <!-- suivi du resultat net cumulé  -->
        <v-col class="white ma-1">
          <v-btn
            @click="exportImage('resnet')"
            color="grey darken-3"
            class="mr-1"
          >
            <v-icon dark left>mdi-file-image</v-icon>image
          </v-btn>

          <v-row id="resnet" class="white mr-1">
            <v-col>
              <resnetcumule
                :selectedMonth="selectedMonth"
                :moisExercice="moisExercice"
                :selectedClient="selectedClient"
                :exercice="exercice"
                :balances="balances"
                :kilo_euro="kilo_euro"
                :situationMonth="this.situationMonth()"
                :currentPrevis="currentPrevis"
              ></resnetcumule>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "../../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  data() {
    return {
      Loading: true,
      LoadingMoisExcercice: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      exercices: [],
      exercice: null,
      selectedMonth: null,
      kilo_euro: false,
      balances: [],
      moisExercice: null,
      currentPrevis: [],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
  },
  watch: {
    selectedClient(val) {
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);
      this.exercice = null;
      this.moisExercice = null;
      this.selectedMonth = null;
      this.balances = [];
      this.currentPrevis = [];

      this.getExcercices();
    },

    exercice(val) {
      this.balances = [];
      this.currentPrevis = [];
      this.selectedMonth = null;
      this.getPeriodeExcercices();
      this.getCurrentPrevis();
      this.getbalances();
    },
  },
  methods: {
    situationMonth() {
      let situationMonth = this.$moment("1970-01-01"); // Initialisation à une date très ancienne

      this.balances.forEach((item) => {
        const dateCourante = this.$moment(item.cloture);
        if (dateCourante.isAfter(situationMonth)) {
          situationMonth = dateCourante;
        }
      });

      return situationMonth.format("MMMM");
    },

    exportImage(Elementid) {
      var node = document.getElementById(Elementid);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = Elementid + ".png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
     * Liste des exercices
     */

    getExcercices() {
      this.Loading = true;
      return new Promise((resolve, reject) => {
        axios
          .get("/api/exerciceschoice-suivi/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/exerciceschoice-suivi/ OK");
            this.exercices = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceschoice-suivi/ KO", err);
            console.log(err);

            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
     * chargement des balances mensuelles
     */

    getbalances() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/suivibudgetaire-balancemensuelle/" +
              this.selectedClient.id +
              "/" +
              this.exercice
          )
          .then((response) => {
            console.log("/api/suivibudgetaire-balancemensuelle/ OK");
            this.balances = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/suivibudgetaire-balancemensuelle/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    /*
     * Liste des mois de l'excercice
     */

    getPeriodeExcercices() {
      this.Loading = true;
      this.LoadingMoisExcercice = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/exerciceperiode/" +
              this.selectedClient.id +
              "/" +
              this.exercice
          )
          .then((response) => {
            console.log("/api/exerciceperiode/ OK");
            this.moisExercice = response.data;
            this.LoadingMoisExcercice = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceperiode/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            reject(err);
          });
      });
    },

    /*
     * Chargement du previs
     */

    getCurrentPrevis() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/suivibudgetaire-getprevis/" +
              this.selectedClient.id +
              "/" +
              this.exercice
          )
          .then((response) => {
            console.log("/api/getprevis/ OK");
            this.currentPrevis = response.data;
            this.currentPrevis = _.orderBy(
              this.currentPrevis,
              ["etat", "budgetline_categorie_id", "order"],
              ["asc", "asc", "asc"]
            );
            // on prend uniquement l'etat revise s'il existe
            // si non on prend l'état initial
            const previsrevise = _.filter(this.currentPrevis, {
              etat: "revise",
            });
            if (previsrevise.length > 0) {
              this.currentPrevis = previsrevise;
            }

            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getprevis/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            reject(err);
          });
      });
    },
  },

  mounted() {
    // recuperation sauvegarde du client selectionné
    if (localStorage.selectedClient) {
      this.selectedClient = JSON.parse(localStorage.selectedClient);
    }
    this.getClients();
  },
};
</script>