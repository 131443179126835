<template>
  <section>
    <!--Snackbar-->
    <v-snackbar v-model="snackbar" bottom color="success" multi-line :timeout="6000">
      {{snackbar_text}}
      <v-btn dark color="green darken-4" @click="snackbar = false">Fermer</v-btn>      
    </v-snackbar>       
    <!-- Modal Rename Compte-->
    <v-dialog v-model="Renamedialog" max-width="500px">

      <v-card v-if="Renameitem">
                <v-alert v-if="serverAlert" type="error" 
                close-text="Fermer"
                dismissible>
                <ul>
                    <div v-for="(value, propertyName, index) in formError" :key="index">
                        <li v-if="value && Array.isArray(value)">{{ propertyName }}: {{ value[0] }}</li> 
                        <li v-if="value && typeof value =='string'">{{ propertyName }}: {{ value }}</li>                      
                    </div>
                </ul>
                </v-alert>        
        <v-card-title>
          <span class="headline">Renommer l'intitule du compte</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field  
                label="Intitule" 
                hide-details="auto" 
                v-model="Renameitem.custom_intitule.intitule"
                :error-messages="formError.custom_intitule"
                :rules="[rules.required]"
                @focus="formError.custom_intitule = null"
                @click:clear="Renameitem.custom_intitule =null"
                  >
                </v-text-field>
                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="cancelRename">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click.stop="restoreRename">Restaurer l'intitulé initial</v-btn>
          <v-btn color="blue darken-1" text @click.stop="rename">Confirmer</v-btn>
          
          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Group Compte-->
    <v-dialog v-model="Groupdialog" max-width="500px">

      <v-card v-if="PossibleGroupitem">
                <v-alert v-if="serverAlert" type="error" 
                close-text="Fermer"
                dismissible>
                <ul>
                    <div v-for="(value, propertyName, index) in formError" :key="index">
                        <li v-if="value && Array.isArray(value)">{{ propertyName }}: {{ value[0] }}</li> 
                        <li v-if="value && typeof value =='string'">{{ propertyName }}: {{ value }}</li>                      
                    </div>
                </ul>
                </v-alert>        
        <v-card-title>
          <span class="headline">Grouper le compte</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                    <v-autocomplete v-if="Groupitem" clearable multiple chips small-chips deletable-chips  
                                              
                        label="Compte(s) associé(s)"
                        v-model="Groupitem.comptes"
                        :items="PossibleGroupitem"
                        item-value="compteclient"
                        item-text="compte"
                        return-object
                        :error-messages="formError.group"                    
                    ></v-autocomplete>                 
                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="cancelGroup">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click.stop="group">Confirmer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Le template -->
    <!--Barre outil listing-->
    <v-row>
      <!--Search-->
      <v-col align="center" justify="center" cols="auto">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche"
          single-line
          dense
        ></v-text-field>
      </v-col>
      <!--Colonne visibility-->
      <v-col align="center" justify="center" cols="auto">
        <v-select
          v-model="defaultHeaders"
          :items="possibleHeaders"
          label="Visibilité des colonnes"
          multiple
          return-object
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{
              defaultHeaders.length +
              " colonnes sur " +
              possibleHeaders.length +
              " possibles"
            }}</span>
          </template>
        </v-select>
      </v-col>
      <!--Export excel-->
      <v-col align="center" justify="center" cols="auto">
        <export-excel :data="myitems" :name="excel_file_name" :fields="excelheaders">
          <v-btn color="grey darken-3">
            <v-icon dark left>mdi-file-export-outline</v-icon>Excel
          </v-btn>
        </export-excel>
      </v-col>
      <!--Bouton creation-->
      <v-col class="text-right" cols="auto">
        <v-btn
          color="primary"
          width="100%"
          v-if="create_route_name && canCreateEtp === true"
          :to="{ name: create_route_name , params: { clientID: selectedClient.id }}"
        >
          <v-icon dark left>mdi-plus</v-icon>Ajouter un élément
        </v-btn>
      </v-col>
    </v-row>
    <!--DataTable -->
    <v-data-table
      sortBy="id"
      :footer-props="{
        'items-per-page-all-text': 'tout',
        'items-per-page-text': 'Résultats par page :',
      }"
      @update:items-per-page="setItemPerPage"
      :headers="headers"
      :items="myitems"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      class="elevation-1"
    >
      <!--FOOTER SLOTS -->
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >Lignes: {{ pageStart }} à {{ pageStop }} sur
        {{ itemsLength }}</template
      >

      <!-- COMPTE SLOTS-->
      <template v-slot:item.compte="{ item }">

        <span>{{item.compte}}</span><span v-if="item.comptes.length"><span v-for="compte in item.comptes"> / {{compte.compte}}</span></span>  
        
        <v-btn icon x-small class="ml-2"          
          title="Grouper"
          @click.stop="showGroupdialog(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </template>

      <!-- INTITULE SLOTS-->
      <template v-slot:item.intitule="{ item }">
        <span v-if="item.custom_intitule"> {{item.custom_intitule.intitule}}</span>
        <span v-else>{{item.intitule}}</span>
        <v-btn icon x-small class="ml-2"          
          title="Modifier"
          @click.stop="showRenamedialog(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >        
        
      </template>

      <!-- VARIABLE SLOTS-->
      <template v-slot:item.variable="{ item }">        
        <v-switch v-if="item.subcompte1==6" v-model="item.variable" @change="updateVariable(item)"></v-switch>  
        <v-switch v-else v-model="item.variable" readonly></v-switch>      
      </template>

      <!-- DATE SLOTS-->
      <template v-slot:item.created_at="{ item }">
        <span> {{ formaDatetWithDuration(item.created_at) }}</span>
      </template>
      <template v-slot:item.updated_at="{ item }">
        <span> {{ formaDatetWithDuration(item.updated_at) }}</span>
      </template>
    </v-data-table>
  </section>
</template>

<script>
// Le script

import {EventBus} from "../EventBus";

export default {
  name: "customPcgDatatable",
  props: {
    selectedClient : Object,
    possibleHeaders: Array,
    api_endpoint_url: String,
    default_column_names: Array,
    default_column_order: String,
    create_route_name: String,
    show_route_name: String,
    update_route_name: String,
    api_delete_endpoint_url: String,
    excel_file_name: String,
  },
  data() {
    return {
      search: null,
      headers: [],
      options: { page: 1, itemsPerPage: 10 },
      defaultHeaders: [],
      myitems: [],
      allcomptes: [],
      excelheaders: {},
      totalItems: 0,
      datatableQuery: null,
      loading: true,
      columns: {},
      Renamedialog: false,
      Renameitem: null,
      Groupdialog: false,
      Groupitem:null,
      PossibleGroupitem:null,
      deleteItem: null,
      snackbar_text:null,
      snackbar:false,
      serverAlert:null,

      formError: {
        custom_intitule:null,
        group:null,
        
      },      

      rules: {
        required: (v) => !!v || "Ce champ est requis",}      

    };
  },
  computed: {
    maxId(){
      return _.maxBy(this.myitems,'id').id
    },
    
    //policies
    canCreateEtp: {
      get() {
        return true;
      },
    }, //this.$store.getters['currentUser/LoggedUser'].can.create
    //computed params to return pagination and search criteria
    params(nv) {
      return {
        ...this.options,
        query: this.search,
      };
    },
  },
  watch: {
    api_endpoint_url: function (newVal, oldVal) {
      this.setDatatableQuery();
      this.getDataFromApi();
    },

    defaultHeaders(val) {
      this.headers = _.orderBy(this.defaultHeaders, "order", "asc");
      this.headers.forEach((header) => {
        if (header.text != "actions") {
          this.excelheaders[header.text] = header.value;
        }
      });
    },
    params: {
      handler() {
        this.setDatatableQuery();
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
        if (this.search != "") {
          this.filtered = true;
        } else {
          this.filtered = false;
        }
      },
    },
  },
  methods: {
     
    cancelRename() {
      this.Renamedialog = false;
      this.Renameitem = null;
      this.formError.custom_intitule=null;
      this.formError.general=null;      
    },
    rename(item) {

      if (this.Renameitem != null) {

                let url = "/api/pcg_compte/update/"+this.Renameitem.id
                let payload = this.Renameitem;

                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post(url, payload)
                    .then(response => {
                        this.Renamedialog = false;
                        this.Renameitem = null;
                        this.formError.custom_intitule=null;
                        this.formError.general=null;
                        this.getDataFromApi()
                        
                    })
                    .catch(err => {
                    this.serverAlert= true;    
                    console.log("submit_ko", err);

                    if (err.response.status == 422) {
                        console.log(err.response.data.errors)                        
                        this.formError.custom_intitule=err.response.data.errors['custom_intitule.intitule']
                    }

                    this.formError.general = err.response.data.message;

                    });


                })
      }
    },
    updateVariable(item){

      let url = "/api/pcg_compte/variable/"+item.id
      let payload = {"variable":item.variable};
                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post(url, payload)
                    .then(response => {
                        this.getDataFromApi()                        
                    })
                    .catch(err => {console.log("submit_ko", err);});


                })

    },
    restoreRename(item) {

      if (this.Renameitem != null) {

                let url = "/api/pcg_compte/restore/"+this.Renameitem.id
                let payload = this.Renameitem;

                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post(url, payload)
                    .then(response => {

                        this.Renamedialog = false;
                        this.Renameitem = null;
                        this.formError.custom_intitule=null;
                        this.formError.general=null;
                        this.getDataFromApi()
                        
                    })
                    .catch(err => {
                    this.serverAlert= true;    
                    console.log("submit_ko", err);

                    if (err.response.status == 422) {
                        console.log(err.response.data.errors)                        
                        this.formError.custom_intitule=err.response.data.errors['custom_intitule.intitule']
                    }

                    this.formError.general = err.response.data.message;

                    });


                })
      }
    },    

    group(item) {

      if (this.Groupitem != null) {

                let url = "/api/pcg_compte/group/"+this.Groupitem.id
                let payload = this.Groupitem;

                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post(url, payload)
                    .then(response => {

                        this.Groupdialog = false;
                        this.Groupitem = null;
                        this.formError.group=null;
                        this.formError.general=null;
                        this.getDataFromApi()
                        
                    })
                    .catch(err => {
                    this.serverAlert= true;    
                    console.log("submit_ko", err);

                    if (err.response.status == 422) {
                        console.log(err.response.data.errors)                        
                        this.formError.group=err.response.data.errors.group
                    }

                    this.formError.general = err.response.data.message;

                    });


                })
      }
    },

    showGroupdialog(item){
      //console.log(item)
      this.PossibleGroupitem = null

      this.Groupitem =  JSON.parse(JSON.stringify(item)); // clone without reactivness
      this.Groupitem.comptes.push({'compte':item.compte,'compteclient':item.compteclient})

      // get possible assoc compte
      axios
        .get("/api/get_possible_assoc_compte/"+item.id)
        .then((response)=>{
          this.PossibleGroupitem = response.data;
          
          // on ajoute à la liste les comptes déjà groupés de l'element courrant 
          for(let compte of item.comptes) {
          this.PossibleGroupitem.push({'compte':compte.compte,'compteclient':compte.compteclient})    
          }
          // on ajoute à la liste l'element courrant 
          this.PossibleGroupitem.push(item)   
          this.Groupdialog = true;   
          
        })
        .catch((err) => {
          console.log("erreur", err);
        });


    }, 
    cancelGroup() {
      this.Groupdialog = false;
      this.Groupitem = null;
      this.PossibleGroupitem = null

      this.formError.custom_intitule=null;
      this.formError.general=null;      
    },       

    showRenamedialog(item){

      this.Renameitem =  JSON.parse(JSON.stringify(item)); // clone without reactivness
      if(!this.Renameitem.custom_intitule){this.Renameitem.custom_intitule = {"intitule":this.Renameitem.intitule}}
      
      this.Renamedialog = true;

    },

    setItemPerPage(val) {
      localStorage.list_item_per_page = val;
      this.options.itemsPerPage = parseInt(localStorage.list_item_per_page);
    },
    formaDatetWithDuration(date) {
      let fromnow = this.$moment(date).fromNow(); // moment(thedate).fromNow();
      let output = this.$moment(date).format("ddd D MMM YYYY");
      return output + " (" + fromnow + ")";
    },

    setDatatableQuery() {
      //Based on our Headers we create query data for DataTables
      //I've added a new param "searchable" to let DataBales know that this column is not searchable
      //You can also set name as "table.column eg users.name" if you select from more then table to avoid "Ambitious column name error from SQL"

      for (var i = 0; i < this.headers.length; i++) {
        this.columns[i] = {
          data: this.headers[i].value,
          name:
            typeof this.headers[i].name != "undefined"
              ? this.headers[i].name
              : this.headers[i].value,
          searchable: this.headers[i].filterable,
          orderable: this.headers[i].sortable,
          search: { value: null, regex: false },
        };
      }

      this.datatableQuery = {};
      this.datatableQuery.length = this.params.itemsPerPage;
      this.datatableQuery.start =
        this.params.page == 1
          ? 0
          : this.params.itemsPerPage * (this.params.page - 1); //set offset
      this.datatableQuery.search = { value: this.params.query }; //our search query
      this.datatableQuery.columns = this.columns;

      let sortBy_index = _.findIndex(this.headers, {
        value: this.params.sortBy[0],
      });

      if (sortBy_index != -1) {
        this.datatableQuery.order = {
          0: {
            column: sortBy_index,
            dir: this.params.sortDesc[0] === true ? "desc" : "asc",
          },
        };
      } else {
        this.datatableQuery.order = {
          0: {
            column: _.findIndex(this.headers, {
              value: this.default_column_order,
            }),
            dir: "asc",
          },
        };
      }

      this.datatableQuery = window.jQuery.param(this.datatableQuery);
    },

    // Fetching the data
    getDataFromApi() {

      this.loading = true;

      axios
        .get(this.api_endpoint_url + "&" + this.datatableQuery)
        .then((response) => {
          this.allcomptes = response.data.data;    
          this.myitems = response.data.data;        

          if (this.search) {
            this.totalItems = response.data.recordsFiltered;
          } else {
            this.totalItems = response.data.recordsTotal;
          }
          if (response.data.error != "") {
            this.serverAlert = response.data.error;
          }
          this.loading = false;

           
        })
        .catch((err) => {
          console.log("erreur", err);
          this.serverAlert = err;
          this.loading = false;
        });
    },
  },
  mounted() {

    // sauvegarde des options d'affichage de la table
    if (localStorage.projetlistitemperpage) {
      this.options.itemsPerPage = parseInt(localStorage.projetlistitemperpage);
    }

    // initialisation des colonnes par defaut
    this.defaultHeaders = _.filter(this.possibleHeaders, (o) => {
      return this.default_column_names.includes(o.name);
    });

    // initialisation des colonnes excel
    this.headers = this.defaultHeaders;

    this.headers.forEach((header) => {
      if (header.text != "actions") {
        this.excelheaders[header.text] = header.value;
      }
    });

    // refresh data on event emited

    // on pcg uploaded
    EventBus.$on('pcgUploaded', data => {
      console.log("pcgUploaded")
      this.getDataFromApi()
    })

    // elementDeleted
      EventBus.$on('elementDeleted', data => {
      console.log("elementDeleted")
      this.getDataFromApi()
    })  

  },
};
</script>