<template>
  <div>
    <v-row>
      <v-col class="black--text text-center text-h5"
        >Situation à {{ selectedMonth }} : Suivi du CA cumulé

        <BarcaCumule
          v-if="loaded"
          :data="chartDatas"
          :options="chartOptions"
          ref="cacumule"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Bar as BarcaCumule } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "caCumule",
  components: { BarcaCumule },
  props: {
    selectedClient: { type: Object },
    moisExercice: { type: Array },
    exercice: { type: Number },
    balances: { type: Array },
    currentPrevis: { type: Array },
    kilo_euro: { type: Boolean },
    situationMonth: { type: String },
    selectedMonth: { type: String },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    cumulreel() {
      let values = this.get_CA_reel_cumule([
        -701, -702, -703, -704, -705, -706, -707, -708, -7092, -7093, -7094,
        -7095, -7096, -7097, -7098,
      ]);

      return values.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },
    cumulprevu() {
      let values = this.get_CA_prevu_cumule(); // [-20,21,22,23,25,26,27,28,29,30,31,32]

      return values.map((valeur) => {
        if (this.kilo_euro == true) {
          return parseFloat(valeur / 1000);
        }
        return parseFloat(valeur);
      });
    },

    chartOptions() {
      return {
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: this.unite(),
            },
          },
        },
      };
    },
    chartDatas() {
      return {
        labels: this.moisExercice,
        datasets: [
          {
            type: "line",
            label: "Réel",
            data: this.cumulreel,
            backgroundColor: "#ffbde9",
            borderColor: "#ffbde9",
            borderWidth: 2,
          },
          {
            label: "Prévu",
            data: this.cumulprevu,
            backgroundColor: "#0070c0",
          },
        ],
      };
    },
  },
  methods: {
    unite() {
      if (this.kilo_euro == true) {
        return "K€";
      }
      return "€";
    },

    get_CA_prevu_cumule() {
      const sommesParMoisCumules = [];
      let cumul = 0; // Variable de cumul initialement à zéro

      if (this.moisExercice == null) {
        return sommesParMoisCumules;
      }

      // Boucler à travers les mois du tableau moisExercice
      for (const moisCourant of this.moisExercice) {
        // Filtrer les éléments de la collection avec budgetline_categorie_id égal à 1 (cat CA)
        const currentPrevis_cat_CA = this.currentPrevis.filter(
          (item) => item.budgetline_categorie_id === 1
        );

        // Calculer la somme des valeurs pour le mois courant
        const sommeMoisCourant = currentPrevis_cat_CA.reduce(
          (somme, item) =>
            somme + item[`mois_${this.moisExercice.indexOf(moisCourant) + 1}`],
          0
        );

        // Ajouter le cumul de la somme au tableau sommesParMoisCumules
        cumul += sommeMoisCourant;
        // Ajouter la somme au tableau sommesParMois
        sommesParMoisCumules.push(cumul);
      }

      return sommesParMoisCumules;
    },

    get_CA_reel_cumule(comptes) {
      let sommesMensuelles = {};
      let sommeSolde = 0;
      let valeursCumulees = [];

      if (this.moisExercice == null || this.balances.length == 0) {
        return valeursCumulees;
      }

      // on filtre les balances sur la classe = 7
      const filteredBalances = _.filter(this.balances, { subcompte1: "7" });

      // Parcourir les moisExercice
      this.moisExercice.forEach((moisCourant) => {
        // Si l'objet sommesMensuelles n'a pas encore de clé pour ce mois, utilisez la somme du mois précédent
        if (!sommesMensuelles[moisCourant]) {
          sommesMensuelles[moisCourant] = sommeSolde;
        }

        // on reinitialise sommeSolde à 0 (car les valeurs des balances sont déjà un cumul d'un mois sur l'autre)
        sommeSolde = 0;

        // Parcourir les balances
        filteredBalances.forEach((objet) => {
          const solde = parseFloat(objet.solde);
          if (!isNaN(solde)) {
            comptes.forEach((numero) => {
              if (objet.compte.startsWith(Math.abs(numero).toString())) {
                const moisCloture = this.$moment(objet.cloture).format("MMMYY");
                // Si le mois de clôture correspond au mois en cours, ajoutez ou soustrayez le solde
                if (moisCloture === moisCourant) {
                  sommeSolde += numero < 0 ? -solde : solde;
                }
              }
            });
          }
        });

        // Mettre à jour la somme pour le mois en cours si la somme est différent de 0
        // sinon on laisse la valeur qui a déjà été initialisée avec le mois précédent
        if (sommeSolde != 0) {
          sommesMensuelles[moisCourant] = sommeSolde;
          valeursCumulees.push(sommeSolde);
        } else {
          valeursCumulees.push(sommesMensuelles[moisCourant]);
          sommeSolde = sommesMensuelles[moisCourant];
        }
      });

      return valeursCumulees;
    },
  },

  mounted() {
    this.loaded = true;
  },
};
</script>