import Vue from "vue";
import Vuex from "vuex";

// modules list
import currentUser from "./modules/currentUser";
import currentDrawer from "./modules/currentDrawer"

Vue.use(Vuex);

export default new Vuex.Store({
    modules : {
        currentUser,
        currentDrawer
    }
})