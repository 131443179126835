<!-- Le template -->
<template>
  <v-container :key="componentKey">
    <v-row class="mt-1">
      <v-col cols="12" class="d-flex justify-start align-center">
        <!--Choix du client-->
        <h3 class="mr-3 my-0">Bilan fonctionnel prévis. de :</h3>
        <v-autocomplete
          class="mr-3 my-0"
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
          dense
          :rules="[rules.required]"
        ></v-autocomplete>

        <!--Annee-->
        <v-select
          v-if="!diagnostic_years_loading"
          menu-props="auto"
          class="mr-4 my-0"
          dense
          multiple
          small-chips
          deletable-chips
          @change="get_crs()"
          label="Année de prévisionnel"
          v-model="diagnostic_years"
          :items="possibles_diagnostic_years"
          :rules="[rules.required_select]"
        ></v-select>

        <!--Switch K€ -->
        <v-switch
          class="mr-3 my-0"
          dense
          label="K€"
          v-model="kilo_euro"
        ></v-switch>

      </v-col>
    </v-row>
    <v-row v-if="selectedClient && diagnostic_years.length != 0">
      <v-col>
        
            <v-row>
              <v-col class="d-flex justify-space-between pb-5 align-center">
                <h6 class="mb-0"></h6>
                <div class="d-flex justify-space-between">
                  <v-btn
                    @click="exportImage('bilanfonctionnelimage')"
                    color="grey darken-3"
                    class="mr-1"
                  >
                    <v-icon dark left>mdi-file-image</v-icon>image
                  </v-btn>

                  <export-excel
                    v-if="!bf_loading"
                    :data="bfs"
                    name="bilan-fonctionnel.xls"
                    :fields="set_excelHeaders()"
                  >
                    <v-btn color="grey darken-3 ">
                      <v-icon dark left>mdi-file-export-outline</v-icon>
                      Excel
                    </v-btn>
                  </export-excel>
                </div>
              </v-col>
            </v-row>

            <!--  BILAN FONCTIONNEL -->

            <v-progress-linear
              v-if="GlobalLoading"
              color="light-blue darken-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <!-- BF Visible Table -->
            <v-simple-table
              v-if="!bf_loading && !GlobalLoading"
              fixed-header
              dark
              dense
              id="5"
              :key="bilanfDatatableKey"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" style="padding: 5px" rowspan="2">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="selectall_bf"
                            @click="toggleAllRowVisibility(selectall_bf, bfs)"
                            true-value="1"
                            false-value="0"
                            v-on="on"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span>Tout Masquer (pour l'exportation en image)</span>
                      </v-tooltip>
                    </th>
                    <th class="text-left">Intitulé</th>
                    <th
                      v-for="annee in diagnostic_years"
                      :key="annee.entete1"
                      class="text-right euro_header border-left"
                    >
                      {{ annee }}<br />
                      <span v-if="kilo_euro">K</span>€
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bf, bfindex) in bfs"
                    :key="bfindex"
                    :class="`rang` + bf.rang"
                  >
                    <td class="checkboxTD">
                      <v-tooltip top>
                        <template #activator="{ on }">
                          <v-checkbox
                            v-model="bfs[bfindex].show"
                            :input-value="bfs[bfindex].show"
                            @click="
                              toggleRowVisibility(
                                bfs[bfindex].show,
                                bfs,
                                bfindex
                              )
                            "
                            v-on="on"
                            true-value="1"
                            false-value="0"
                            dense
                            hide-details
                            color="grey lighten-5"
                          ></v-checkbox>
                        </template>
                        <span
                          >Masquer cette ligne pour l'exportation en image</span
                        >
                      </v-tooltip>
                    </td>
                    <td>{{ bf.intitule }}</td>
                    <template v-for="annee in diagnostic_years">
                      <td :key="annee.index" class="text-right border-left">
                        {{ format(bf[annee], bf) }}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!-- BF Export Image Table -->
            <div style="position: absolute; z-index: -1">
              <v-simple-table
                id="bilanfonctionnelimage"
                v-if="!bf_loading && !GlobalLoading"
                light
                dense
                class="pb-3 imgtable"
                :key="exportBilanfDatatableKey"
              >
                <template v-slot:default>
                  <thead>
                    <tr style="background-color: #0c6682">
                      <th class="text-left" style="color: #ffffff !important">
                        Intitulé
                      </th>
                      <th
                        class="text-right euro_header border-left"
                        style="color: #ffffff !important"
                        v-for="annee in diagnostic_years"
                        :key="annee.entete1"
                      >
                        {{ annee }}<br /><span v-if="kilo_euro">K</span>€
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(bf, bfindex) in bfs">
                      <tr
                        v-if="bfs[bfindex].show == '1'"
                        :key="bfindex"
                        :class="`rang` + bf.rang"
                      >
                        <td>{{ bf.intitule }}</td>
                        <template v-for="annee in diagnostic_years">
                          <td :key="annee.index" class="text-right border-left">
                            {{ format(bf[annee], bf) }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

      </v-col>
    </v-row>
    <v-row><v-col></v-col></v-row>
  </v-container>
</template>

<style>
.imgtable td,
.imgtable th {
  height: auto !important;
  line-height: 1.3 !important;
}
.imgtable .rang4 td {
  padding: 8px !important;
}

/* 
alternance gris / blanc pour l'export en image des tableaux
tout en blanc cf recette 14/12

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}
*/

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}

/* .rang0 {font-weight: 200;} */

.rang0 ul {
  margin-bottom: 0px !important;
  font-size: 0.9em !important;
}

.rang0 .value {
  font-size: 0.9em !important;
}

.pourcent {
  font-style: italic;
}

.rang2 {
  background-color: #a6a6a6 !important;
}

.rang3 {
  background-color: #1ab7ea !important;
}

.rang4 {
  background-color: #0c6682 !important;
}

.rang4 td {
  padding: 20px !important;
}

.checkboxTD {
  width: 10px !important;
  padding: 5px 0 5px 5px !important;
}
.imgtable .rang4,
.imgtable .rang3 {
  color: #ffffff;
  font-weight: 400;
}
.imgtable .rang2 {
  color: #000000;
  font-weight: 400;
}
.br_header {
  width: 220px;
}
.euro_header {
  width: 100px;
}
.pourcent_header {
  width: 75px;
}

.border-left {
  border-left: 1px solid gray;
}
</style>

<script>
import { EventBus } from "../../../EventBus";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

// Le script
export default {
  data() {
    return {
      rules: {
        required: (v) => !!v || "Ce champ est requis",
        required_select: (v) => v.length > 0 || "Ce champ est requis",
      },


      bilanfDatatableKey: 0,
      exportBilanfDatatableKey: 0,
      alert : false,

      componentKey: 0,

      GlobalLoading: true,

      Loading: true,
      pfi_loading: true,
      cr_loading: true,
      bd_loading: true,
      bf_loading:true,
      balance_loading:true,
      bds_initial_loading:true,
      

      diagnostic_years_loading: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      possibles_diagnostic_years: [],
      diagnostic_years: [],
      kilo_euro: false,

      crs: [], // compte de résultat par année
      pfis: [], // plan de financement par année
      pts: [], // plan de tresorerie par année      
      balances : [], // balance annuelle par année

      bds_initial : [], // bilan détaillé initial chargé depuis php
      // (= bilan détaillé annee N-1 le vrai issue de la derniere balance annuelle connue) 

      bds: [
        {
            'intitule': "BILAN ACTIF",
            'category': "BILAN ACTIF",
            'order' : 1,
            'rang': 4,
            'entete' : true,
            'show' : "1"
        },
        {
              //N : ∑(109) – 40(pfi)N
              //N+1 : ∑(109) – 40(pfi)N – 40(pfi)N+1  = BilanD(2)N – 40(pfi)N+1

              'intitule': "Capital souscrit non appelé & souscrit, appelé non versé",
              'category' : "TOTAL BILAN ACTIF",
              'order': 2, 
              'rang' : 2,
              'ajouter': [{ base: "bds_initial", lignes: [2] },],
              'soustraire': [{ base: "pfis", lignes: [40] },], 
              'ajouter_next': [{ base: "bds", lignes: [2] },],
              'soustraire_next': [{ base: "pfis", lignes: [40] },], 
              'show' : "1"
        },            
            {

                //N : ∑( 201,203,204,205,206,207,208,232,211,212,213,214,215,218,231,237,238,261,266,267,268,271,272,
                // 274,275,761,27682,27684,27685,27688,801,2803,2804,2805,2807, 2808,2811,2812,2813,2814,2815, 2818,
                // 2905,2906,2907,2908,2911,2931,2961,2966,2967,2968,2971, 2972,2974,2975,2976) + 2(pfi) – 56(cr) – 67(cr)
                //N+1 : 3(bilan)N + 2(pfi) – 56(cr) – 67(cr)

                'intitule':"Actif Net immobilisé",                
                'category' : "TOTAL BILAN ACTIF",
                'order': 3,
                'rang' : 2,
                 
                'ajouter': [
                  { base :"bds_initial", lignes : [3]},
                  { base: "pfis", lignes: [2]}, 
                  { base: "crs", lignes: [63,71]},                             
                ], 
                'soustraire': [{ base: "crs", lignes: [56,67,75] },],  
                'ajouter_next': [{ base: "bds", lignes: [3] }, {base: "pfis", lignes: [2]},{base: "crs", lignes: [63,71]}],
                'soustraire_next': [{ base: "crs", lignes: [56,67,75] },], 
                'show' : "1"
            },
            {
                'intitule':"Immobilisations incorporelles",                
                'category' : "Actif Net immobilisé",                
                'order': 4,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },
            {
              'intitule': "Frais d'établissement",
              'category' : "Immobilisations incorporelles",
              'order': 5,
              'rang' : 0,
              'force-zero' : true,
              'show' : "1"
            },
            {
              'intitule': "Frais de recherche et de développement",
              'category' : "Immobilisations incorporelles",
              'order': 6,
              'rang' : 0,
              'force-zero' : true,
              'show' : "1"
            },
            {
                'intitule': "Concessions, brevets, licences, marques, procédés et droits",
                'category' : "Immobilisations incorporelles",
                'order': 7,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },
            {
                'intitule': "Fonds commercial (dont droit au bail)",
                'category' : "Immobilisations incorporelles",
                'order': 8,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },                         
            {
                'intitule': "Avances et acomptes",
                'category' : "Immobilisations incorporelles",
                'order': 9,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"   
            },
            {   
                'intitule': "Immobilisations incorporelles informatique",
                'category' : "Immobilisations incorporelles",
                'order': 10,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"              
            },   
            {
                'intitule': "Autres",
                'category' : "Immobilisations incorporelles",
                'order': 11,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },                  
            {
                'intitule':"Immobilisations corporelles",                
                'category' : "Actif Net immobilisé",
                'order': 12,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },           
            {
                'intitule': "Terrains",
                'category' : "Immobilisations corporelles",
                'order': 13,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },  
            {
                'intitule': "Constructions",
                'category' : "Immobilisations corporelles",
                'order': 14,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            }, 
            {
                'intitule': "Installations techniques, matériel et outillage industriels",
                'category' : "Immobilisations corporelles",
                'order': 15,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },  
            {
                'intitule': "Immobilisations corporelles en cours",
                'category' : "Immobilisations corporelles",
                'order': 16,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"               
            }, 
            {
                'intitule': "Avances et acomptes",
                'category' : "Immobilisations corporelles",
                'order': 17,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },  
            {
                'intitule': "Autres",
                'category' : "Immobilisations corporelles",
                'order': 18,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"

            },                        
            {
                'intitule':"Immobilisations financières",                
                'category' : "Actif Net immobilisé",
                'order': 19,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            }, 
            {
                'intitule': "Participations",
                'category' : "Immobilisations financières",
                'order': 20,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            }, 
            {
                'intitule': "Créances rattachées à des participations",
                'category' : "Immobilisations financières",
                'order': 21,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            }, 
            {
                'intitule': "Autres titres immobilisés",
                'category' : "Immobilisations financières",
                'order': 22,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },           
            {
                'intitule': "Prêts",
                'category' : "Immobilisations financières",
                'order': 23,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            },  
            {
                'intitule': "Autres",
                'category' : "Immobilisations financières",
                'order': 24,
                'rang' : 0,
                'force-zero' : true,
                'show' : "1"
            }, 
            {
                // 59(pfi) + 60(pfi) + 61(pfi) + 62(pfi) + 12(cr)-58(cr)                
                'intitule':"Stocks",                
                'category' : "TOTAL BILAN ACTIF",
                'order': 25,
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [59,60,61,62] },{ base: "crs", lignes: [12] }],
                'soustraire': [{ base: "crs", lignes: [58] },], 
                'show' : "1"
            },
            {
                'intitule': "Matières premières et autres approvisionnements",
                'category' : "Stocks",
                'order': 26,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },  
            {
                'intitule': "En-cours de production (biens et services)",
                'category' : "Stocks",
                'order': 27,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },   
            {
                'intitule': "Produits intermédiaires et finis",
                'category' : "Stocks",
                'order': 28,
                'rang' : 1,   
                'force-zero' : true ,
                'show' : "1"            
            },  
            {
                'intitule': "Marchandises",
                'category' : "Stocks",
                'order': 29,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },            
              
            {
                // 63(pfi)
                'intitule':"Avances et acomptes versés sur commandes",                
                'category' : "TOTAL BILAN ACTIF",
                'order': 30,
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [63] },],
                'show' : "1"
            },            
            {
                // -57(cr) + 11(cr)  attention formule modifiée cf commentaire cdc
                'intitule':"Créances",                
                'category' : "TOTAL BILAN ACTIF",
                'order': 31,
                'rang' : 2,
                'ajouter': [{ base: "crs", lignes: [11] },{ base: "pfis", lignes: [64] }],
                'soustraire': [{ base: "crs", lignes: [57] },],
                'show' : "1"
            },
            {
                'intitule': "Clients et comptes rattachés",
                'category' : "Créances",
                'order': 32,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            }, 
            {
                'intitule': "Subventions à recevoir",
                'category' : "Créances",
                'order': 33,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },
            {
                'intitule': "Autres créances",
                'category' : "Créances",
                'order': 34,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },
            {
                // 95(pt)
                // formule modifiée suite à recette 11/07/2024 
                // bonne formule : 56pfi
                'intitule':"Trésorerie active",                
                'category' : "TOTAL BILAN ACTIF",
                'order': 35,
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [56] },],
                'show' : "1"
            },
            {
                'intitule': "VMP",
                'category' : "Trésorerie active",
                'order': 36,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            }, 
            {
                'intitule': "Caisse",
                'category' : "Trésorerie active",
                'order': 37,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },             
            {
                // 95(pt)
                // formule modifiée suite à recette 11/07/2024 
                // bonne formule : 56pfi                
                'intitule': "Disponibilités",
                'category' : "Trésorerie active",
                'order': 38,
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [56] },],
                'show' : "1"
            },
            {
                // 68(pfi)
                'intitule':"Autres",                
                'category' : "TOTAL BILAN ACTIF",
                'order': 39,
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [68] },],
                'show' : "1"
            },
            {
                // 68(pfi)
                'intitule': "Charges constatées d'avance", 
                'category' : "Autres",
                'order': 40,
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [68] },],
                'show' : "1"
            },  
            {
                'intitule': "Divers",
                'category' : "Autres",
                'order': 41,
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },
            
            // TOTAL BILAN ACTIF
            {
                //2(bilan)+3(bilan)+25(bilan)+30(bilan)+31(bilan)+35(bilan)+39(bilan)
                
                'intitule':"TOTAL BILAN ACTIF",                
                'category' : "TOTAL BILAN ACTIF",                
                'order': 42,
                'rang' : 3,
                'ajouter': [{ base: "bds", lignes: [2,3,25,30,31,35,39] },],
                'ajouter_next': [{ base: "bds", lignes: [2,3,25,30,31,35,39] },],
                'show' : "1",
                'soustotal' : true  // quand soustotal, l'ajout_next doit être calculé sur l'annee N 
            },
            //  BILAN PASSIF
            {
                'intitule': "BILAN PASSIF",
                'category' : "BILAN PASSIF",
                'order': 43, 
                'rang' : 4,    
                'entete' : true,
                'show' : "1"        
            },
            // capitaux propres
            // ordre : 44
            // placé apres ordre 50 car sous total de 45,46,47,48,49,50

            {
                //N+1 : 45(bilan)N + 41(pfi)N+1
                'intitule': "Capital social, primes d'émission",
                'category' : "Capitaux Propres",
                'order': 45,
                'rang' : 1,
                'show' : "1",
                'ajouter': [
                  { base: "bds_initial", lignes: [45] },
                  { base: "pfis", lignes: [41] },
                ],                 
                'ajouter_next': [
                  { base: "bds", lignes: [45] },
                  { base: "pfis", lignes: [41] }], 
            
            },
            {
                // N+1 :  46(N) + 41(pfi)N+1
                // "Apport associatif" uniquement pour les associations
                
                'intitule': "Apport associatif",
                'category' : "Capitaux Propres",
                'order': 46,
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [41] },{ base: "bds_initial", lignes: [46] }],                 
                'ajouter_next': [{ base: "bds", lignes: [46] },{ base: "pfis", lignes: [41] }],
                'show' : "1"
            },  
            {
                // N : 47(bilan)N-1 
                // N+1 : 47(bilan)N 

                'intitule': "Ecart de réévaluation",
                'category' : "Capitaux Propres",
                'order': 47,
                'rang' : 1,
                'ajouter': [{ base: "bds_initial", lignes: [47] },], 
                'ajouter_next': [{ base: "bds", lignes: [47] }],
                'show' : "1"
            },
            
             // ici order 48 déplacé en dessous de 49 car sous total des ordres 48 et 49

            /*{
                //N : ∑(-106,-11,-12)
                //N+1 : 48(bilan)n + 49(bilan)n – 34(pfi)N

                'intitule': "Réserves, report à nouveau",
                'category' : "Capitaux Propres",
                'order': 48,
                'rang' : 1,
                'soustraire': [{ base: "balances", lignes: [11,12,106] },], 
                'ajouter_next': [{ base: "bds", lignes: [48,49] }],
                'soustraire_next': [{ base: "pfis", lignes: [34] }],  
                'show' : "1"              
            },*/


            {
                // 2(cr)+3(cr)+5(cr)+6(cr)-16(cr)-22(cr)-23(cr) +4(cr)+8(cr)+7(cr)+14(cr)-52(cr)-53(cr)-51(cr) 
                // +9(cr)-54(cr)+10(cr)-55(cr)+62(cr)-65(cr)-66(cr)+63(cr)-67(cr)+70(cr)+72(cr)+73(cr)-76(cr)
                // -77(cr)+71(cr)-75(cr)-80(cr) +81(cr) -79(cr)
                // =
                // ∑ (2,3,4,5,6,7,8,9,10,14,62,63,70,71,72,73,81,-16,-22,-23,-51,-52,-53,-54,-55,-65,-66,-67,-75,-76,-77,-79,-80) cr


                // formule modifiée suite à recette 11/07/2024 
                // bonne formule : 82crs

                'intitule': "Résultat",
                'category' : "Capitaux Propres",
                'order': 49,
                'rang' : 1,
                
                'ajouter': [{ base: "crs", lignes: [82]},],                                

                'show' : "1"
            },
            {
                //N : ∑(-106,-11,-12)
                //N+1 : 48(bilan)n + 49(bilan)n – 34(pfi)N

                'intitule': "Réserves, report à nouveau",
                'category' : "Capitaux Propres",
                'order': 48,
                'rang' : 1, 
                'ajouter': [{ base: "bds_initial", lignes: [48,49] }],  
                'soustraire': [{ base: "pfis", lignes: [34] }],             
                'ajouter_next': [{ base: "bds", lignes: [48,49] }],
                'soustraire_next': [{ base: "pfis", lignes: [34] }],  
                'show' : "1"              
            },             
            {
                // N : 50(bilan initial)N-1  – 70(cr)N+1 + 42(pfi)N+1

                'intitule': "Subvention d'investissement",
                'category' : "Capitaux Propres",
                'order': 50,
                'rang' : 1,
                'ajouter': [
                  { base: "bds_initial", lignes: [50] },
                  { base: "pfis", lignes: [42] },
              ],
                'soustraire': [{ base: "crs", lignes: [70] }], 

                'ajouter_next': [
                  { base: "bds", lignes: [50] },
                  { base: "pfis", lignes: [42] }
                ],
                'soustraire_next': [{ base: "crs", lignes: [70] }],
                'show' : "1"                
            }, 

            {
                //45(bilan)+46(bilan)+47(bilan)+48(bilan)+49(bilan)+50(bilan)
                'intitule':"Capitaux Propres",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 44,
                'rang' : 2,
                'ajouter': [{ base: "bds", lignes: [45,46,47,48,49,50] },],
                'ajouter_next': [{ base: "bds", lignes: [45,46,47,48,49,50] },],
                'show' : "1",
                'soustotal' : true  // quand soustotal, l'ajout_next doit être calculé sur l'annee N 
            },   
            
            // ordre 51 deplacé en dessous de 53
            // car sous total de 52 +53

          /*  {
                //N : ∑(-14,-15,-19)balance + ∑(59,– 13,-8,7)cr
                //N+1 : ∑(52,53)bilan   ∑(7,-8,-13,59)cr

                'intitule':"Provisions et fonds dédiés",                
                'category' : "TOTAL BILAN PASSIF",                
                'order': 51,
                'rang' : 2,
                'ajouter': [{ base: "crs", lignes: [59,7] },],
                'soustraire': [{ base: "crs", lignes: [13,8] },{ base: "balances", lignes: [14,15,19] }],
                'ajouter_next': [{ base: "bds", lignes: [52,53] },{ base: "crs", lignes: [59,7] }],
                'soustraire_next': [{ base: "crs", lignes: [13,8] }],  
                'show' : "1"                 
            },*/
            
            
            {
                
                //N : 52(bilan)N-1 +59(cr)N –13(cr)N

                'intitule': "Provisions pour risques et charges",
                'category' : "Provisions et fonds dédiés",
                'order': 52,
                'rang' : 1,
                'ajouter': [
                  { base: "bds_initial", lignes: [52] },
                  { base: "crs", lignes: [59] },
                ],
                'soustraire': [{ base: "crs", lignes: [13] }],

                'ajouter_next': [
                  { base: "bds", lignes: [52] },
                  { base: "crs", lignes: [59] }],
                'soustraire_next': [{ base: "crs", lignes: [13] }],  
                'show' : "1"
            },  
            {
                
                //N : 53(bilan)N-1 - 8(cr)N  +7(cr)N

                'intitule': "Fonds dédiés",
                'category' : "Provisions et fonds dédiés",
                'order': 53,
                'rang' : 1,
                'ajouter': [
                  { base: "bds_initial", lignes: [53] },
                  { base: "crs", lignes: [7] }],
                'soustraire': [{ base: "crs", lignes: [8] }],
                'ajouter_next': [
                  { base: "bds", lignes: [53] },
                  { base: "crs", lignes: [7] }],
                'soustraire_next': [{ base: "crs", lignes: [8] }], 
                'show' : "1"
            },   
            {
                
                //N : ∑(52,53)bilan n 

                'intitule':"Provisions et fonds dédiés",                
                'category' : "TOTAL BILAN PASSIF",                
                'order': 51,
                'rang' : 2,
                'ajouter': [{ base: "bds_initial", lignes: [52,53] },],
                'ajouter_next': [{ base: "bds", lignes: [52,53] },],                
                'show' : "1",
                'soustotal' : true  // quand soustotal, l'ajout_next doit être calculé sur l'annee N                  
            },                     
            {
                // 48pfi
                // changement de formule cf commentaire cdc

                // N : Dettes MLT bilan N-1 + 45(pfi) + 47(pfi) + 48(pfi) – 27(pfi) – 28(pfi) – 30(pfi) 
                // N+1 : 54(bilan)N + 45(pfi)N+1  + 47(pfi)N+1 + 48(pfi)N+1 – 27(pfi)N+1 – 28(pfi)N+1 – 30(pfi)N+1 
                
                // remodification de formule suite à recette du 11/07/2024
                // la bonne formule : = bilan55 = 54(bilan n-1) + 45(pfi)  + 47(pfi) + 48(pfi) – 27(pfi) – 28(pfi)– 30(pfi)

                'intitule':"Dettes MLT",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 54,
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [45,47,48] },{ base: "bds_initial", lignes: [54] }],
                'soustraire': [{ base: "pfis", lignes: [27,28,30] },],
                'ajouter_next': [{ base: "bds", lignes: [54] },{ base: "pfis", lignes: [45,47,48] }],
                'soustraire_next': [{ base: "pfis", lignes: [27,28,30] }],

                'show' : "1"

            }, 
            {
                // 14pt
                // modification de formule suite à recette du 11/07/2024
                // la bonne formule : = bilan55 = 54(bilan n-1) + 45(pfi)  + 47(pfi) + 48(pfi) – 27(pfi) – 28(pfi)– 30(pfi)

                'intitule': "Dettes financières",
                'category' : "Dettes MLT",
                'order': 55,
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [45,47,48] },{ base: "bds_initial", lignes: [54] }],
                'soustraire': [{ base: "pfis", lignes: [27,28,30] },],
                'ajouter_next': [{ base: "bds", lignes: [54] },{ base: "pfis", lignes: [45,47,48] }],
                'soustraire_next': [{ base: "pfis", lignes: [27,28,30] }],
                'show' : "1"
            },          
            {
                // 63pfi
                // modification de formule suite à recette du 11/07/2024
                // la bonne formule : 69pfi

                'intitule':"Avances et acomptes reçus sur commandes",   //Avces et acptes reçus sur cdes              
                'category' : "TOTAL BILAN PASSIF",
                'order': 56,
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [69] },],
                'show' : "1"
            },   
            {
                // 70pfi
                'intitule':"Fournisseurs",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 57,
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [70] },],
                'show' : "1"
            },            
            {
                // 71pfi
                'intitule':"Dettes fiscales et sociales",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 58, 
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [71] },],
                'show' : "1"
            },
            {
                // 72pfi
                'intitule':"Personnel",                
                'category' : "Dettes fiscales et sociales",
                'order': 59,
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [72] },],
                'show' : "1"
            },          
            {
                // 73pfi
                'intitule':"Organismes sociaux",                
                'category' : "Dettes fiscales et sociales",
                'order': 60, 
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [73] },],
                'show' : "1"
            },                       
            {
                // 74pfi
                'intitule':"Etat",                
                'category' : "Dettes fiscales et sociales",
                'order': 61, 
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [74] },],
                'show' : "1"
            },

            // ordre 62 déplacé en dessous de 64
            // car ref 64 dois exister pour le calcul
            /*
            {
                // 75pfi
                'intitule':"Autres dettes CT",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 62, 
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [75] },],
                'show' : "1"
            },*/
            
            

            {
                // 76pfi
                'intitule':"Dettes sur immobilisations",                
                'category' : "Autres dettes CT",
                'order': 63, 
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [76] },],
                'show' : "1"
            },
            {
                // 44pfi

                // changement de formule cf commentaire cdc
                // N : Compte courant associé bilan N-1 + 44(pfi) – 32(pfi)  + 46(pfi) – 29(pfi) 
                // N+1 : 64(bilan)N + 44(pfi)N+1 – 32(pfi)N+1+ 46(pfi)N+1 – 29(pfi)N+1

                // pb avec Compte courant associé bilan N-1  nous n'avons pas de bilan previsionnel  N-1

                // modification formule suite à recette 11/07/2024
                // 64(bilan N-1)+44(pfi)–32(pfi) +46(pfi)–29(pfi) 

                'intitule':"Comptes courants associés",                
                'category' : "Autres dettes CT",
                'order': 64,
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [44,46] },{ base: "bds_initial", lignes: [64] },], 
                'soustraire': [{ base: "pfis", lignes: [32,29] },],
                'ajouter_next': [{ base: "pfis", lignes: [44,46] },{ base: "bds", lignes: [64] }],
                'soustraire_next': [{ base: "pfis", lignes: [32,29] },],
                'show' : "1"
            },
            {
                // 75pfi

                // modification formule suite à recette du 11/07/2024
                // bonne formule :
                // (63,64,65)bilan = 64bilan + 76pfi  + 44pfi -32pfi + 46pfi -29pfi + 52pfi

                'intitule':"Autres dettes CT",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 62, 
                'rang' : 2,
                'ajouter': [{ base: "bds_initial", lignes: [64] },{ base: "pfis", lignes: [76,44,46,52] }],  
                'soustraire': [{ base: "pfis", lignes: [32,29] },],
                'ajouter_next': [{ base: "bds", lignes: [64] },{ base: "pfis", lignes: [76,44,46,52] }],
                'soustraire_next': [{ base: "pfis", lignes: [32,29] },],

                'show' : "1"
            },            
            

            {
                // 52pfi
                'intitule':"Autres",                
                'category' : "Autres dettes CT",
                'order': 65, 
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [52] },],
                'show' : "1"
            },                                
            {
                //N+1 : 67(bilan)N + 51(pfi)N+1 – 33(pfi)N+1

                'intitule':"Trésorerie passive",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 66, 
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [51] },{ base: "bds_initial", lignes: [67] }],
                'soustraire': [{ base: "pfis", lignes: [33] }], 
                'ajouter_next': [{ base: "bds", lignes: [67] },{ base: "pfis", lignes: [51] }], 
                'soustraire_next': [{ base: "pfis", lignes: [33] }],    
                'show' : "1"
            },
            {
                //N+1 : 67(bilan)N + 51(pfi)N+1 – 33(pfi)N+1

                'intitule':"Découverts et concours bancaires",                
                'category' : "Trésorerie passive",
                'order': 67, 
                'rang' : 1,   
                'ajouter': [{ base: "pfis", lignes: [51] },{ base: "bds_initial", lignes: [67] }],
                'soustraire': [{ base: "pfis", lignes: [33] }], 
                'ajouter_next': [{ base: "bds", lignes: [67] },{ base: "pfis", lignes: [51] }], 
                'soustraire_next': [{ base: "pfis", lignes: [33] }],  
                'show' : "1"        
            },          
            {
                //69(bilan) +70(bilan) = 78(pfi)

                'intitule':"Autres",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 68, 
                'rang' : 2,
                'ajouter': [{ base: "pfis", lignes: [78] },],
                'show' : "1"
            }, 
            {
                //78(pfi)
                'intitule':"Produits constatés d'avance",                
                'category' : "Autres",
                'order': 69, 
                'rang' : 1,
                'ajouter': [{ base: "pfis", lignes: [78] },],
                'show' : "1"
            },            
            {
                'intitule':"Divers",                
                'category' : "Autres",
                'order': 70, 
                'rang' : 1,
                'force-zero' : true,
                'show' : "1"
            },                        
            // TOTAL BILAN PASSIF
            {
                //44(bilan) + 51(bilan) + 54(bilan)+ 56(bilan) +57(bilan) +58(bilan) +62(bilan) +66(bilan) + 68(bilan)
                // modification formule cf commentaire cdc

                'intitule':"TOTAL BILAN PASSIF",                
                'category' : "TOTAL BILAN PASSIF",
                'order': 71, 
                'rang' : 3,
                'ajouter': [{ base: "bds", lignes: [44,51,54,56,57,58,62,66,68] },],
                'show' : "1",
                'soustotal' : true  // quand soustotal, l'ajout_next doit être calculé sur l'annee N 
            }
      ], 

      // bilan fonctionnel

      /*
        struture

              'intitule': string,
              'category' : string,
              'order': int, 
              'rang' : int,

              // la premiere annee (si base = balances, la valeur a ajouter est a prendre sur l'annee N-1) 
              'ajouter': [{ base: "balances|bds|pfis|pts|crs", lignes: [number] },{...}],
              // la premiere annee (si base = balances, la valeur a soustraire est a prendre sur l'annee N-1)
              'soustraire': [{ base: "balances|bds|pfis|pts|crs", lignes: [] },,{...}], 
              // les annees suivantes  (si base = bds, la valeur a ajouter est a prendre sur l'annee N-1)
              'ajouter_next': [{ base: "balances|bds|pfis|pts|crs", lignes: [] },,{...}],
              // les annees suivantes (si base = bds, la valeur a soustraire est a prendre sur l'annee N-1)
              'soustraire_next': [{ base: "balances|bds|pfis|pts|crs", lignes: [] },,{...}], 
              'entete' : true | false (default) 
              'force_zero' : true | false (default  

              //// si formule N et N+1  identique  => ajouter_next et soustraire_next ne seront pas defins

      */



      bfs: [
        {

            // (44,51,54,-3,-2) bilan detaille
            // => 
            // 44 = (45,46,47,48,49,50) bilan detaille
            //      45 =  
            //    'ajouter': [{ base: "pfis", lignes: [41] },],
            //    'soustraire': [{ base: "balances", lignes: [101,108,104] },], 
            //    'ajouter_next': [{ base: "bds", lignes: [45] },{ base: "pfis", lignes: [41] }],
            //      46
            //    'ajouter': [{ base: "pfis", lignes: [41] },],
            //    'soustraire': [{ base: "balances", lignes: [101,108,104] },], 
            //    'ajouter_next': [{ base: "bds", lignes: [45] },{ base: "pfis", lignes: [41] }],
            //      47
            //    'soustraire': [{ base: "balances", lignes: [105,107] },], 
            //    'ajouter_next': [{ base: "bds", lignes: [45] }],
            //      48
            //    'soustraire': [{ base: "balances", lignes: [11,12,106] },], 
            //    'ajouter_next': [{ base: "bds", lignes: [48,49] }],
            //    'soustraire_next': [{ base: "pfis", lignes: [34] }], 
            //      49
            //    'ajouter': [{ base: "crs", lignes: [2,3,4,5,6,7,8,9,10,14,62,63,70,71,72,73,81] },],
            //    'soustraire': [{ base: "crs", lignes: [16,22,23,51,52,53,54,55,65,66,67,75,76,77,80] },],
            //      50
            //    'ajouter': [{ base: "pfis", lignes: [42] },],
            //    'soustraire': [{ base: "balances", lignes: [13] },{ base: "crs", lignes: [70] }], 
            //    'ajouter_next': [{ base: "bds", lignes: [50] }],
            //    'soustraire_next': [{ base: "crs", lignes: [70] }],
            //
            //     51
            //    'ajouter': [{ base: "crs", lignes: [59,7] },],
            //    'soustraire': [{ base: "crs", lignes: [13,8] },{ base: "balances", lignes: [14,15,19] }],
            //    'ajouter_next': [{ base: "bds", lignes: [52,53] },{ base: "crs", lignes: [59,7] }],
            //    'soustraire_next': [{ base: "crs", lignes: [13,8] }],      
            //
            //      54
            //    'ajouter': [{ base: "pfis", lignes: [48] },],
            //
            //      3
            //    'ajouter': [{base: "balances", lignes: [201,203,204,205,206,207,208,232,211,212,213,214,215,218,231,237,238,261,266,267,268,271,272,274,275,761,27682,27684,27685,27688,801,2803,2804,2805,2807, 2808,2811,2812,2813,2814,2815, 2818,2905,2906,2907,2908,2911,2931,2961,2966,2967,2968,2971, 2972,2974,2975,2976]},{ base: "pfis", lignes: [2]},], 
            //    'soustraire': [{ base: "crs", lignes: [56,67] },], 
            //    'ajouter_next': [{ base: "bds", lignes: [3] }, {base: "pfis", lignes: [2]}],
            //    'soustraire_next': [{ base: "cr", lignes: [56,67] },], 
            //
            //      2
            //    'ajouter': [{ base: "balances", lignes: [109] },],
            //    'soustraire': [{ base: "pfis", lignes: [40] },], 
            //    'ajouter_next': [{ base: "bds", lignes: [2] },],
            //    'soustraire_next': [{ base: "pfis", lignes: [40] },], 

            
            'intitule': "Fonds de roulement",
            'order' : 1,
            'show' : "1",
            'ajouter': [{ base: "bds", lignes: [44,51,54] },],
            'soustraire': [{ base: "bds", lignes: [3,2] },], 
          
        },
        {

            //57(pfi)

            // modification formule cf commentaire cdc
            // 25 (bilan) +30 (bilan) +31 (bilan) +39 (bilan) - 56 (bilan) - 57 (bilan) - 58 (bilan)  - 62 (bilan) - 68 (bilan)


            'intitule': "Besoin en Fonds de roulement",
            'order' : 1,
            'show' : "1",
            'ajouter': [{ base: "bds", lignes: [25,30,31,39] },],
            'soustraire': [{ base: "bds", lignes: [56,57,58,62,68] },],
          
        },
        {
            //(35,-66) bilan detaille
            // => 35 
            // = 'ajouter': [{ base: "pts", lignes: [95] },],
            // => 66 
            // =  'ajouter': [{ base: "pfis", lignes: [51] },],
            //    'soustraire': [{ base: "balances", lignes: [5186,519,511,'c512','c512','c517'] },{ base: "pfis", lignes: [33] }], 
            //    'ajouter_next': [{ base: "bds", lignes: [67] },{ base: "pfis", lignes: [51] }], 
            //    'soustraire_next': [{ base: "pfis", lignes: [33] }],   
            
            'intitule': "Trésorerie nette",
            'order' : 1,
            'show' : "1",
            'ajouter': [{ base: "bds", lignes: [35] },],
            'soustraire': [{ base: "bds", lignes: [66] },]           
        },        

      ], 


      selectall_bf: "1",

    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
  },

  watch: {
    kilo_euro(val) {
      EventBus.$emit("kilo_euroChanged", val);
    },
    //possibles_diagnostic_years
    diagnostic_years(val, old) {

      this.alert = false;

      if (!_.isEqual(val, old)) {this.diagnostic_years = _.sortBy(val);}

      if (this.possibles_diagnostic_years.length > 0 && this.selectedClient) {

        this.cr_loading = true;
        this.pt_loading = true; 
        this.pfi_loading = true; 
        this.bd_loading = true;   
        this.balance_loading=true;   
        this.GlobalLoading = true;

        this.get_crs().then(() => {
          this.cr_loading = false;
            this.get_pfis().then(() => {
              this.pfi_loading = false;
                this.get_pts().then(() => {
                  this.pt_loading = false; 
                  this.get_balances().then(() => {
                    this.balance_loading = false; 
                    // la première année selectionnée doit être = l'année de cloture de la dernière balance +1
                    let year =0;
                    if(this.balances.length!=0){
                      year = this.$moment(this.balances[0].cloture).year();
                      if(this.diagnostic_years[0]!=year+1){                      
                        this.alert = "La première année de prévisionnel sélectionnée doit être obligatoirement l'année qui suit la dernière balance annuelle connue"
                        return
                      }                    
                    }

                    this.get_bds_initial().then(() => {

                      this.bd_initial_loading = false

                      this.set_bds().then(() => {
                        this.bd_loading = false; 
                        this.set_bfs().then(() => {  
                          this.bf_loading = false; 
                          this.GlobalLoading = false;
                        })
                      })
                    })    
                  })  
                })
              });
        });
      }
    },

    selectedClient(val) {
      this.GlobalLoading = true;

      this.diagnostic_years = [];

      if (val) {
        //chargement de la liste des années de balance annuelle
        this.getDiagnosticYears();
        localStorage.selectedClient = JSON.stringify(val);
        EventBus.$emit("ClientChanged", val);
      }
    },
  },

  methods: {
    refresh() {
      this.bilanfDatatableKey++;
      this.exportBilanfDatatableKey++;
    },

    // toggleAllRowVisibility pour afficher masquer toutes les lignes
    // dans l'exporation image d'un tableau

    toggleAllRowVisibility(state, targetData) {      
      _.map(targetData, function (obj) {
        obj.show = state;
      });
      this.refresh();
    },
    // toggleRowVisibility pour afficher masquer une ligne
    // avec ses enfants selon les cas
    // target = jeux de donnée cible
    // row = identifiant de la ligne

    toggleRowVisibility(state, target, index) {
      let i = index;

      // si rang = 3
      // on ne s'occupe pas des enfants
      if (target[index].rang == 3) {
        target[index].show = state;
      } else if (target == this.ffs) {
        do {
          target[i].show = state;
          i--;
        } while (target[i] && target[i].rang < target[index].rang);
      } else {
        do {
          target[i].show = state;
          i++;
        } while (target[i] && target[i].rang < target[index].rang);
      }
      this.refresh();
    },

    exportImage(table) {
      var node = document.getElementById(table);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "bilan-fonctionnel.png";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },

    set_excelHeaders() {
      var headers = { intitule: "intitule" };
      this.diagnostic_years.forEach((year) => {
        headers["année " + year] = year;
      });
      return headers;
    },

    /*
     * format value in K€
     */
    format(value, item) {
      

      if (isNaN(parseFloat(value)) || item.taux || item.sans_unite) {
        if (isNaN(value)) {
          return value;
        } else {
          return parseFloat(value).toFixed(1);
        }
      }
      if (item.jour || item.annee) {
        return Math.round(value);
      }
      // formatage en K€ on préfère que les montants soient arrondis à l’unité cf recette du 31/03/2023 et mail du 12/04
      return this.kilo_euro == true
        ? parseFloat(value / 1000).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : parseFloat(value).toLocaleString("fr-FR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
    },


    /*
     * somme selon class
     * type : ajouter | soustraire
     */

get_montant_balances(comptes, annee, type) {


    var total = 0

    // Filtrer la collection de balances pour l'année donnée
    const filtered = _.filter(this.balances, (item) => new Date(item.cloture).getFullYear() === annee);


    _.forEach(comptes,(compte)=>{

      var prefix = compte.toString().charAt(0);
      var numPart;
      

      if (prefix === 'c' || prefix === 'd') {numPart =  compte.slice(1);}
      else{numPart =  compte.toString();}

      var subcompte
      if(numPart.length<=4){
        subcompte =  'subcompte' + numPart.length;       
        }
      else{
        subcompte =  null ;
        while (numPart.length < 11) {numPart += '0';}
      }

      const filteredBalances = _.filter(filtered, (item) => {
        if (subcompte) {return item[subcompte] === numPart;}
        else{return item.compte === numPart;}

      })

      const totalSolde = filteredBalances.reduce((acc, item) => {
        let solde = item.solde;
        if (prefix === 'c' && solde < 0) {solde = Math.abs(solde);} 
        else if (prefix === 'c' && solde > 0) {solde = 0}
        else if (prefix === 'd' && solde > 0) {solde = Math.abs(solde);}
        else if (prefix === 'd' && solde < 0) {solde = 0;}

        
        return parseFloat(acc) + parseFloat(solde);
      }, 0);

      //console.log("somme des comptes "+compte, totalSolde) 
      
      total += totalSolde 

    })


    if(type=="soustraire"){return -total}else{return total}
},

/*
  *  somme des montants bds_initial selon reference
  */

  //// bds_initial (= bilan détaillé annee N-1 le vrai issue de la derniere balance annuelle connue) (references, annee, type){
  
  get_montant_bds_initial(references,type){
  //console.log('appel get_montant_bds_initial', references)

  var total= 0
  // Filtrer les bds_initial dont la reference se trouve dans references
  const filteredCollection = _.filter(this.bds_initial, (item) => _.includes(references, item.neworder));
  //console.log(filteredCollection)    
  // Calculer la somme des montant des objets filtrés
  const totalMontant = _.sumBy(filteredCollection, (item) => parseFloat(item.montant));

  if(type=="soustraire"){return -totalMontant}else{return totalMontant}

  },

/*
*  somme des montants dans pfis selon reference & annee
 */

get_montant_pfis(references, annee, type){

var total = 0
// Filtrer la collection de pfis pour l'année donnée
const filtered = _.filter(this.pfis, (item) => item.annee === annee);
// Filtrer les pfi dont la reference se trouve dans references
const filteredCollection = _.filter(filtered, (item) => _.includes(references, item.order));

// Calculer la somme des 'montant' des objets filtrés
const totalMontant = _.sumBy(filteredCollection, (item) => parseFloat(item.montant));

if(type=="soustraire"){return -totalMontant}else{return totalMontant}


},

/*
*  somme des montants dans crs selon reference & annee
 */

get_montant_crs(references, annee, type){

var total = 0
// Filtrer la collection de pfis pour l'année donnée
const filtered = _.filter(this.crs, (item) => item.annee === annee);
// Filtrer les crs dont la reference se trouve dans references
const filteredCollection = _.filter(filtered, (item) => _.includes(references, item.order));

// Calculer la somme des 'montant' des objets filtrés
const totalMontant = _.sumBy(filteredCollection, (item) => parseFloat(item.montant));

if(type=="soustraire"){return -totalMontant}else{return totalMontant}


},
/*
*  somme des montants dans pts selon reference & annee
*/

get_montant_pts(references, annee, type){

var total = 0
// Filtrer la collection de pfis pour l'année donnée
const filtered = _.filter(this.pts, (item) => item.annee === annee);
// Filtrer les pts dont la reference se trouve dans references
const filteredCollection = _.filter(filtered, (item) => _.includes(references, item.order));

// Calculer la somme des 'montant' des objets filtrés
// ici il faut prendre le montant du dernier mois de la periode d'exercice du client
var lastmonth

var premiere_annee_exercice_client = this.selectedClient.fin_exercice.slice(0, 4)

// si l'année considérée > à la premiere annee d'exercice du client
// la periode d'exercice se base toujours sur 12 mois
if(annee>premiere_annee_exercice_client){lastmonth = 'mois_12';}
// 
else{
    const startDate_exercice = this.$moment(this.selectedClient.debut_exercice);
    const endDate_exercice = this.$moment(this.selectedClient.fin_exercice);  
    const monthsDifference = endDate_exercice.diff(startDate_exercice, 'months');
    lastmonth = 'mois_' + monthsDifference

}
const totalMontant = _.sumBy(filteredCollection, (item) => parseFloat(item[lastmonth]));

if(type=="soustraire"){return -totalMontant}else{return totalMontant}


},

/*
*  somme des montants dans bds selon reference & annee
*/

get_montant_bds(references, annee, type){

var total = 0

// Filtrer les bds dont la reference se trouve dans references
const filteredCollection = _.filter(this.bds, (item) => _.includes(references, item.order));

// Calculer la somme des 'montant' des objets filtrés
const totalMontant = _.sumBy(filteredCollection, (item) => parseFloat(item[annee]));

if(type=="soustraire"){return -totalMontant}else{return totalMontant}


},
    /*
     * Construction du Bilan détaillé
     */

    set_bds() {

      var years_values;

      this.bd_loading = true;

      return new Promise((resolve, reject) => {


        //contruction du bilan detaillé

        //console.log('statut_juridique ' + this.selectedClient.statut_juridique)

        _.forEach(this.diagnostic_years, (annee) => {

          //console.log('=============annee================',annee)

          _.forEach(this.bds, (bd, key) => {

            //console.log('**********')
            //console.log('intitule :',bd.intitule,annee)
            //console.log('intitule ordre:',bd.order)
            

            // la ligne est une entete
            if(bd.entete==true){
              //console.log('entete')
              this.bds[key] = Object.assign({}, this.bds[key], this.bds[key][annee]='');
              return; 
            }
            // la ligne est forcée à zero
            if(bd['force-zero']==true){
              //console.log('forcé à zero')
              this.bds[key] = Object.assign({}, this.bds[key], this.bds[key][annee]=0);
              return; 
            }

            // si l'intitule est "Capital social, primes d'émission" 
            // si le client est une association (ASSO) : on force à zéro

            if(bd.intitule=="Capital social, primes d'émission" && this.selectedClient.statut_juridique=="ASSO" ){
              //console.log("Capital social, primes d'émission forcé à zero pour les associations")
              this.bds[key] = Object.assign({}, this.bds[key], this.bds[key][annee]=0);  
              return;
            }

            // si l'intitule est "Apport associatif"
            // si le client n'est pas une association (STARTUP ou PME) : on force à zéro
            if(bd.intitule=="Apport associatif" && this.selectedClient.statut_juridique!="ASSO" ){
              //console.log("Apport associatif forcé à zero pour les sociétés")
              this.bds[key] = Object.assign({}, this.bds[key], this.bds[key][annee]=0);  
              return;
            }

            // la premiere annee (si base = balances, la valeur a prendre est a prendre sur l'annee N-1) 
            // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)


            var total_montant_ajouter = 0
            var total_montant_soustraire = 0
            var montant_balances = 0
            var montant_pfis = 0
            var montant_crs = 0
            var montant_bds = 0
            var total_montant = 0
            var montant_bds_initial = 0
            
            // cas de la premiere annnee
            if(this.diagnostic_years[0]==annee){

              _.forEach(this.bds[key]['ajouter'], (ajout, ajoutkey) => {

                //total_montant_ajouter = 0
                montant_balances = 0
                montant_pfis = 0
                montant_crs = 0
                montant_bds = 0
                montant_bds_initial = 0

                if(ajout.base=="bds_initial"){
                  montant_bds_initial = this.get_montant_bds_initial(ajout.lignes, annee-1, 'ajouter')                  
                  //console.log('premiere annee',annee,'dans bds_initial, ajouter ',ajout.lignes, 'montant bds_initial=',montant_bds_initial)  
                }

                if(ajout.base=="balances"){
                  montant_balances = this.get_montant_balances(ajout.lignes, annee-1, 'ajouter')                  
                  //console.log('premiere annee',annee,'dans balances, ajouter ',ajout.lignes, 'montant balances=',montant_balances)  
                }

                if(ajout.base=="pfis"){
                  montant_pfis = this.get_montant_pfis(ajout.lignes, annee, 'ajouter')                 
                  //console.log('premiere annee',annee,'dans pfis, ajouter  ',ajout.lignes, 'montant pfis=',montant_pfis)  
                }

                if(ajout.base=="crs"){
                  montant_crs = this.get_montant_crs(ajout.lignes, annee, 'ajouter')                  
                  //console.log('premiere annee',annee,'dans crs, ajouter ',ajout.lignes, 'montant crs=',montant_crs)
                }   
                
                
                if(ajout.base=="bds"){
                  montant_bds = this.get_montant_bds(ajout.lignes, annee, 'ajouter')                  
                  //console.log('premiere annee',annee,'dans bds, ajouter',ajout.lignes,'montant bds=',montant_bds)
                }                 

                //console.log('sous total a ajouter precedent',total_montant_ajouter)
                
                total_montant_ajouter =  parseFloat(parseFloat(total_montant_ajouter) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds) + parseFloat(montant_bds_initial))
                
                
                //console.log('montant_balances',montant_balances)
                //console.log('montant_pfis',montant_pfis)
                //console.log('montant_crs',montant_crs)
               //console.log('montant_bds',montant_bds)
                //console.log('montant_bds_initial',montant_bds_initial)

                //console.log('sous total a ajouter : montant_balances + montant_pfis + montant_crs + montant_bds + montant_bds_initial = ' + parseFloat(parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds)+ parseFloat(montant_bds_initial)))
                //console.log('nouveau sous total a ajouter : ' + total_montant_ajouter)

              })
              //console.log('total a ajouter : montant_balances + montant_pfis + montant_crs + montant_bds + montant_bbds_initial')
              //console.log('total a ajouter : ' + total_montant_ajouter)

              _.forEach(this.bds[key]['soustraire'], (soustraire) => {

                //total_montant_soustraire = 0

                montant_balances = 0
                montant_pfis = 0
                montant_crs = 0
                montant_bds = 0
                total_montant = 0
                montant_bds_initial = 0


                if(soustraire.base=="bds_initial"){
                  montant_bds_initial = this.get_montant_bds_initial(soustraire.lignes, annee-1, 'soustraire')                  
                 //console.log('premiere annee',annee,'dans bds_initial, soustraire ',soustraire.lignes, 'montant_bds_initial=',montant_bds_initial)  
                }
                if(soustraire.base=="balances"){
                  montant_balances = this.get_montant_balances(soustraire.lignes, annee-1, 'soustraire')
                  //console.log('premiere annee',annee,'dans balances, soustraire ',soustraire.lignes, 'montant balances=',montant_balances)  
                }              
                if(soustraire.base=="pfis"){

                  montant_pfis = this.get_montant_pfis(soustraire.lignes, annee, 'soustraire')                 
                  //console.log('premiere annee',annee,'dans pfis, soustraire  ',soustraire.lignes, 'montant pfis=',montant_pfis)  
                }
                if(soustraire.base=="crs"){
                  montant_crs = this.get_montant_crs(soustraire.lignes, annee, 'soustraire')                  
                  //console.log('premiere annee',annee,'dans crs, soustraire ',soustraire.lignes, 'montant crs=',montant_crs)
                }                      
                if(soustraire.base=="bds"){

                  montant_bds = this.get_montant_bds(soustraire.lignes, annee, 'soustraire')                  
                  //console.log('premiere annee',annee,'dans bds, soustraire',soustraire.lignes,'montant bds=',montant_bds)
                }                 

                total_montant_soustraire = parseFloat(parseFloat(total_montant_soustraire) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds) + parseFloat(montant_bds_initial))
 
              })  
              //console.log('total a soustraire : montant_balances + montant_pfis + montant_crs + montant_bds + montant_bds_initial')
              //console.log('total a soustraire : ' + total_montant_soustraire)


              total_montant = total_montant_ajouter + total_montant_soustraire


              //console.log("valeur pour l'intitule: total_montant_ajouter - total_montant_soustraire = " + total_montant_ajouter + "+" + total_montant_soustraire)
              //console.log("valeur pour l'intitule:" + total_montant)

              this.bds[key] = Object.assign({}, this.bds[key], this.bds[key][annee]=total_montant);
              
              
            }

            // cas des annees suivantes
            else{

              // si ajouter_next est definit
              
              if(this.bds[key]['ajouter_next']){
                //console.log("ajouter_next definit")
                _.forEach(this.bds[key]['ajouter_next'], (ajout_next) => {

                  //total_montant_ajouter = 0
                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_bds = 0

                  // les années suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver
                  if(ajout_next.base=="balances"){
                    montant_balances = this.get_montant_balances(ajout_next.lignes, annee, 'ajouter')
                    //console.log('annee ',annee,' dans balances, ajouter_next ',ajout_next.lignes, 'montant balances=',montant_balances)
                    }
                  if(ajout_next.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(ajout_next.lignes, annee, 'ajouter')
                    //console.log('annee ',annee,' dans pfis, ajouter_next  ',ajout_next.lignes, 'montant pfis=',montant_pfis)  
                   }
                  if(ajout_next.base=="crs"){
                    montant_crs = this.get_montant_crs(ajout_next.lignes, annee, 'ajouter')
                    //console.log('annee ',annee,' dans crs, ajouter_next  ',ajout_next.lignes, 'montant crs=',montant_crs)  
                     }                  
                  if(ajout_next.base=="bds"){
                    // attention ici quand il s'agit d'un sous total il faut prendre l'annee courante
                    if(bd.soustotal){
                      //console.log('soustotal automatique sur anne N')
                      montant_bds = this.get_montant_bds(ajout_next.lignes, annee, 'ajouter')
                      }
                    else{
                      montant_bds = this.get_montant_bds(ajout_next.lignes, annee-1, 'ajouter')
                      }
                    //console.log('annee ',annee,' dans bds, ajouter_next  ',ajout_next.lignes, 'montant bds=',montant_bds)  
                     }
                  
                  //console.log('sous total a ajouter_next precedent',total_montant_ajouter)
                  total_montant_ajouter = parseFloat(parseFloat(total_montant_ajouter) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds))
                
                //console.log('montant_balances',montant_balances)
                //console.log('montant_pfis',montant_pfis)
                //console.log('montant_crs',montant_crs)
                //console.log('montant_bds',montant_bds)

                //console.log('sous total a ajouter_next : montant_balances + montant_pfis + montant_crs + montant_bds = ' + parseFloat(parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds)))
                //console.log('nouveau sous total a ajouter_next : ' + total_montant_ajouter)                  

                })
                //console.log('total a ajouter_next : montant_balances + montant_pfis + montant_crs + montant_bds')
                //console.log('total a ajouter_next : ' + total_montant_ajouter)
              }
              else{

                // ajouter_next non definit => on prend ajouter
                //console.log("ajouter_next non definit")

                _.forEach(this.bds[key]['ajouter'], (ajout) => {

                  // total_montant_ajouter = 0
                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_bds = 0

                  // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver
                  if(ajout.base=="balances"){
                    montant_balances = this.get_montant_balances(ajout.lignes, annee, 'ajouter')
                    //console.log('annee ',annee,' dans balances, ajouter ',ajout.lignes, 'montant balances=',montant_balances)
                    }
                  if(ajout.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(ajout.lignes, annee, 'ajouter')
                    //console.log('annee ',annee,' dans pfis, ajouter  ',ajout.lignes, 'montant pfis=',montant_pfis)  

                     }
                  if(ajout.base=="crs"){
                    montant_crs = this.get_montant_crs(ajout.lignes, annee, 'ajouter')
                    //console.log('annee ',annee,' dans crs, ajouter  ',ajout.lignes, 'montant crs=',montant_crs)  
                    
                    }                  
                  if(ajout.base=="bds"){

                    // attention ici quand il s'agit d'un sous total il faut prendre l'annee courante
                    if(bd.soustotal){montant_bds = this.get_montant_bds(ajout.lignes, annee, 'ajouter')}
                    else{montant_bds = this.get_montant_bds(ajout.lignes, annee-1, 'ajouter')}

                    //console.log('annee ',annee,' dans bds, ajouter  ',ajout.lignes, 'montant bds=',montant_bds)  
                     }

                  //console.log('sous total a ajouter precedent',total_montant_ajouter)

                  total_montant_ajouter = parseFloat(parseFloat(total_montant_ajouter) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds))
                
                //console.log('montant_balances',montant_balances)
                //console.log('montant_pfis',montant_pfis)
                //console.log('montant_crs',montant_crs)
                //console.log('montant_bds',montant_bds)

                //console.log('sous total a ajouter : montant_balances + montant_pfis + montant_crs + montant_bds = ' + parseFloat(parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds)))
                //console.log('nouveau sous total a ajouter : ' + total_montant_ajouter) 

                })
                //console.log('total a ajouter : montant_balances + montant_pfis + montant_crs + montant_bds')
                //console.log('total a ajouter : ' + total_montant_ajouter)

              }


              if(this.bds[key]['soustraire_next']){
                // si soustraire_next est definit
                //console.log("soustraire_next definit")
                _.forEach(this.bds[key]['soustraire_next'], (soustraire_next) => {

                  // total_montant_soustraire = 0
                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_bds = 0
                  total_montant = 0
                  


                  // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver

                  if(soustraire_next.base=="balances"){
                    montant_balances = this.get_montant_balances(soustraire_next.lignes, annee, 'soustraire')
                    //console.log('annee ',annee,' dans balances, soustraire_next ',soustraire_next.lignes, 'montant balances=',montant_balances)
                    }
                  if(soustraire_next.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(soustraire_next.lignes, annee, 'soustraire') 
                    //console.log('annee ',annee,' dans pfis, soustraire_next  ',soustraire_next.lignes, 'montant pfis=',montant_pfis)  
                    }
                  if(soustraire_next.base=="crs"){
                    montant_crs = this.get_montant_crs(soustraire_next.lignes, annee, 'soustraire')
                    //console.log('annee ',annee,' dans crs, soustraire_next  ',soustraire_next.lignes, 'montant crs=',montant_crs) 
                     }                  
                  if(soustraire_next.base=="bds"){
                    montant_bds = this.get_montant_bds(soustraire_next.lignes, annee-1, 'soustraire')
                    //console.log('annee ',annee,' dans bds, soustraire_next  ',soustraire_next.lignes, 'montant bds=',montant_bds) 
                     }
                 // console.log('sous total a soustraire_next precedent',total_montant_soustraire)
                  
                  total_montant_soustraire = parseFloat(parseFloat(total_montant_soustraire) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds))
                //console.log('montant_balances',montant_balances)
                //console.log('montant_pfis',montant_pfis)
                //console.log('montant_crs',montant_crs)
                //console.log('montant_bds',montant_bds)

                //console.log('sous total a soustraire_next : montant_balances + montant_pfis + montant_crs + montant_bds = ' + parseFloat(parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds)))
                //console.log('nouveau sous total a soustraire_next : ' + total_montant_soustraire) 


                })
                //console.log('total a soustraire_next : montant_balances + montant_pfis + montant_crs + montant_bds')
                //console.log('total a soustraire_next : ' + total_montant_soustraire)
              }
              else{

               // console.log("soustraire_next non definit")

                // soustraire_next non definit => on prend soustraire
                _.forEach(this.bds[key]['soustraire'], (soustraire) => {

                  //total_montant_soustraire = 0
                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_bds = 0
                  total_montant = 0

                  // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver

                  if(soustraire.base=="balances"){
                    montant_balances = this.get_montant_balances(soustraire.lignes, annee, 'soustraire')
                    //console.log('annee ',annee,' dans balances, soustraire ',soustraire.lignes, 'montant balances=',montant_balances)
                    }
                  if(soustraire.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(soustraire.lignes, annee, 'soustraire')
                    //console.log('annee ',annee,' dans pfis, soustraire  ',soustraire.lignes, 'montant pfis=',montant_pfis)  
                     }
                  if(soustraire.base=="crs"){
                    montant_crs = this.get_montant_crs(soustraire.lignes, annee, 'soustraire')
                    //console.log('annee ',annee,' dans crs, soustraire  ',soustraire.lignes, 'montant crs=',montant_crs)
                      }                  
                  if(soustraire.base=="bds"){
                    montant_bds = this.get_montant_bds(soustraire.lignes, annee-1, 'soustraire')
                    //console.log('annee ',annee,' dans bds, soustraire  ',soustraire.lignes, 'montant bds=',montant_bds) 
                     }

                  //console.log('sous total a soustraire precedent',total_montant_soustraire)

                  total_montant_soustraire = parseFloat(parseFloat(total_montant_soustraire) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds))

                //console.log('montant_balances',montant_balances)
                //console.log('montant_pfis',montant_pfis)
                //console.log('montant_crs',montant_crs)
                //console.log('montant_bds',montant_bds)

                //console.log('sous total a soustraire : montant_balances + montant_pfis + montant_crs + montant_bds = ' + parseFloat(parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_bds)))
                //console.log('nouveau sous total a soustraire : ' + total_montant_soustraire) 

                })  
                //console.log('total a soustraire : montant_balances + montant_pfis + montant_crs + montant_bds')
                //console.log('total a soustraire : ' + total_montant_soustraire)              

              }
              total_montant = total_montant_ajouter + total_montant_soustraire
              this.bds[key] = Object.assign({}, this.bds[key], this.bds[key][annee]=total_montant);

            }
          })
        })
        this.bds=_.orderBy(this.bds, ['order'], ['asc'])
        this.bd_loading = false;
        resolve();
      })
    },

    /*
     * Construction du Bilan fonctionnel
     */

    set_bfs() {

      var years_values;

      this.bf_loading = true;

      return new Promise((resolve, reject) => {


        //contruction du bilan detaillé
        _.forEach(this.diagnostic_years, (annee) => {

          //console.log('=============annee================',annee)

          _.forEach(this.bfs, (bf, key) => {


            //console.log('bf',bf.intitule)

            // la ligne est une entete
            if(bf.entete==true){
              //console.log('entete')
              this.bfs[key] = Object.assign({}, this.bfs[key], this.bfs[key][annee]='');
              return; 
            }
            // la ligne est forcée à zero
            if(bf['force-zero']==true){
              //console.log('forcé à zero')
              this.bfs[key] = Object.assign({}, this.bfs[key], this.bfs[key][annee]=0);
              return; 
            }

            // la premiere annee (si base = balances, la valeur a prendre est a prendre sur l'annee N-1) 
            // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)


            var total_montant_ajouter = 0
            var total_montant_soustraire = 0
            var montant_balances = 0
            var montant_pfis = 0
            var montant_crs = 0
            var montant_pts = 0
            var montant_bds = 0
            var total_montant = 0
            
            // cas de la premiere annnee
            if(this.diagnostic_years[0]==annee){

              _.forEach(this.bfs[key]['ajouter'], (ajout, ajoutkey) => {

                total_montant_ajouter = 0

                if(ajout.base=="balances"){
                  montant_balances = this.get_montant_balances(ajout.lignes, annee-1, 'ajouter')                  
                  //console.log('premiere annee',annee,'dans balances, ajouter ',ajout.lignes, 'montant balances=',montant_balances)  
               }

                if(ajout.base=="pfis"){
                  montant_pfis = this.get_montant_pfis(ajout.lignes, annee, 'ajouter')                 
                  //console.log('premiere annee',annee,'dans pfis, ajouter  ',ajout.lignes, 'montant pfis=',montant_pfis)  
                }

                if(ajout.base=="crs"){
                  montant_crs = this.get_montant_crs(ajout.lignes, annee, 'ajouter')                  
                  //console.log('premiere annee',annee,'dans crs, ajouter ',ajout.lignes, 'montant crs=',montant_crs)
                }   
                
                if(ajout.base=="pts"){
                  montant_pts = this.get_montant_pts(ajout.lignes, annee, 'ajouter')                  
                  //console.log('premiere annee',annee,'dans pts, ajouter',ajout.lignes, 'montant pts=',montant_pts)
                } 
                
                if(ajout.base=="bds"){
                  montant_bds = this.get_montant_bds(ajout.lignes, annee, 'ajouter')                  
                  console.log('premiere annee',annee,'dans bds, ajouter',ajout.lignes,'montant bds=',montant_bds)
                }                 

                total_montant_ajouter = montant_balances + montant_pfis + montant_crs + montant_pts + montant_bds

              })

              _.forEach(this.bfs[key]['soustraire'], (soustraire) => {

                total_montant_soustraire = 0

                montant_balances = 0
                montant_pfis = 0
                montant_crs = 0
                montant_pts = 0
                montant_bds = 0
                total_montant = 0

                if(soustraire.base=="balances"){
                  montant_balances = this.get_montant_balances(soustraire.lignes, annee-1, 'soustraire')
                  //console.log('premiere annee',annee,'dans balances, soustraire ',soustraire.lignes, 'montant balances=',montant_balances)  
                }              
                if(soustraire.base=="pfis"){

                  montant_pfis = this.get_montant_pfis(soustraire.lignes, annee, 'soustraire')                 
                  //console.log('premiere annee',annee,'dans pfis, soustraire  ',soustraire.lignes, 'montant pfis=',montant_pfis)  
                }


                if(soustraire.base=="crs"){
                  montant_crs = this.get_montant_crs(soustraire.lignes, annee, 'soustraire')                  
                  //console.log('premiere annee',annee,'dans crs, soustraire ',soustraire.lignes, 'montant crs=',montant_crs)
                }   
                
                if(soustraire.base=="pts"){

                  montant_pts = this.get_montant_pts(soustraire.lignes, annee, 'soustraire')                  
                  //console.log('premiere annee',annee,'dans pts, soustraire',soustraire.lignes, 'montant pts=',montant_pts)
                } 
                
                if(soustraire.base=="bds"){

                  montant_bds = this.get_montant_bds(soustraire.lignes, annee, 'soustraire')                  
                  console.log('premiere annee',annee,'dans bds, soustraire',soustraire.lignes,'montant bds=',montant_bds)
                }                 

                //total_montant_soustraire = montant_balances + montant_pfis + montant_crs + montant_pts + montant_bds
                total_montant_soustraire = parseFloat(parseFloat(total_montant_soustraire) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_pts) + parseFloat(montant_bds))
              })  
              
              total_montant = total_montant_ajouter + total_montant_soustraire
              console.log('montant',total_montant)
              this.bfs[key] = Object.assign({}, this.bfs[key], this.bfs[key][annee]=total_montant);
              
              
            }

            // cas des annees suivantes
            else{

              // si ajouter_next est definit
              
              if(this.bfs[key]['ajouter_next']){
                //console.log("ajouter_next definit")
                _.forEach(this.bfs[key]['ajouter_next'], (ajout_next) => {

                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_bds = 0
                  montant_pts = 0

                  // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver
                  if(ajout_next.base=="balances"){
                    montant_balances = this.get_montant_balances(ajout_next.lignes, annee, 'ajouter')
                    }
                  if(ajout_next.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(ajout_next.lignes, annee, 'ajouter')
                     }
                  if(ajout_next.base=="crs"){
                    montant_crs = this.get_montant_crs(ajout_next.lignes, annee, 'ajouter')
                     }
                  if(ajout_next.base=="pts"){
                    montant_pts = this.get_montant_pts(ajout_next.lignes, annee, 'ajouter') 
                    }
                  if(ajout_next.base=="bds"){
                    // attention ici quand il s'agit d'un sous total il faut prendre l'annee courante
                    if(bf.soustotal){
                      //console.log('soustotal automatique sur anne N')
                      montant_bds = this.get_montant_bds(ajout_next.lignes, annee, 'ajouter')
                      }
                    else{
                      montant_bds = this.get_montant_bds(ajout_next.lignes, annee, 'ajouter')
                      }
                    }

                  //total_montant_ajouter = montant_balances + montant_pfis + montant_crs + montant_pts + montant_bds
                  total_montant_ajouter = parseFloat(parseFloat(total_montant_ajouter) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_pts)+ parseFloat(montant_bds))

                })
              }
              else{

                // ajouter_next non definit => on prend ajouter
                // console.log("ajouter_next non definit")

                _.forEach(this.bfs[key]['ajouter'], (ajout) => {

                  
                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_bds = 0 
                  montant_pts = 0   

                  // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver
                  if(ajout.base=="balances"){
                    montant_balances = this.get_montant_balances(ajout.lignes, annee, 'ajouter')
                    }
                  if(ajout.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(ajout.lignes, annee, 'ajouter')
                   }
                  if(ajout.base=="crs"){
                    montant_crs = this.get_montant_crs(ajout.lignes, annee, 'ajouter')
                    }
                  if(ajout.base=="pts"){
                    montant_pts = this.get_montant_pts(ajout.lignes, annee, 'ajouter')
                     }
                  if(ajout.base=="bds"){
                    // attention ici quand il s'agit d'un sous total il faut prendre l'annee courante
                    if(bf.soustotal){montant_bds = this.get_montant_bds(ajout.lignes, annee, 'ajouter')}
                    else{montant_bds = this.get_montant_bds(ajout.lignes, annee, 'ajouter')} //-1
                    console.log('annee',annee,'dans bds, ajouter',ajout.lignes,'montant bds=',montant_bds)
                    }

                  //total_montant_ajouter = montant_balances + montant_pfis + montant_crs + montant_pts + montant_bds
                  total_montant_ajouter = parseFloat(parseFloat(total_montant_ajouter) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_pts) + parseFloat(montant_bds))
                })

              }


              if(this.bfs[key]['soustraire_next']){
                // si soustraire_next est definit
                console.log("soustraire_next definit")
                _.forEach(this.bfs[key]['soustraire_next'], (soustraire_next) => {

                  // total_montant_soustraire = 0
                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_pts = 0
                  montant_bds = 0
                  total_montant = 0

                  // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver

                  if(soustraire_next.base=="balances"){
                    montant_balances = this.get_montant_balances(soustraire_next.lignes, annee, 'soustraire')
                    }
                  if(soustraire_next.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(soustraire_next.lignes, annee, 'soustraire') 
                    }
                  if(soustraire_next.base=="crs"){
                    montant_crs = this.get_montant_crs(soustraire_next.lignes, annee, 'soustraire')
                     }
                  if(soustraire_next.base=="pts"){
                    montant_pts = this.get_montant_pts(soustraire_next.lignes, annee, 'soustraire')
                     }
                  if(soustraire_next.base=="bds"){
                    montant_bds = this.get_montant_bds(soustraire_next.lignes, annee, 'soustraire') //-1
                     }

                  //total_montant_soustraire = montant_balances + montant_pfis + montant_crs + montant_pts + montant_bds
                  total_montant_soustraire = parseFloat(parseFloat(total_montant_soustraire) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_pts) + parseFloat(montant_bds))
                })
              }
              else{

                //console.log("soustraire_next non definit")

                // soustraire_next non definit => on prend soustraire
                _.forEach(this.bfs[key]['soustraire'], (soustraire) => {

                  montant_balances = 0
                  montant_pfis = 0
                  montant_crs = 0
                  montant_pts = 0
                  montant_bds = 0
                  total_montant = 0

                  // les annees suivantes  (si base = bds, la valeur a prendre est a prendre sur l'annee N-1)                  
                  // normalement il n'y a pas de balance les années suivante
                  // ce cas e devrait pas arriver
                  if(soustraire.base=="balances"){
                    montant_balances = this.get_montant_balances(soustraire.lignes, annee, 'soustraire')
                    }
                  if(soustraire.base=="pfis"){
                    montant_pfis = this.get_montant_pfis(soustraire.lignes, annee, 'soustraire') 
                    }
                  if(soustraire.base=="crs"){
                    montant_crs = this.get_montant_crs(soustraire.lignes, annee, 'soustraire') 
                    }
                  if(soustraire.base=="pts"){
                    montant_pts = this.get_montant_pts(soustraire.lignes, annee, 'soustraire') 
                    }
                  if(soustraire.base=="bds"){
                    montant_bds = this.get_montant_bds(soustraire.lignes, annee, 'soustraire')  // -1
                    console.log('annee',annee,'dans bds, soustraire',soustraire.lignes,'montant bds=',montant_bds)
                    }

                  //total_montant_soustraire = montant_balances + montant_pfis + montant_crs + montant_pts + montant_bds
                  total_montant_soustraire = parseFloat(parseFloat(total_montant_soustraire) + parseFloat(montant_balances) + parseFloat(montant_pfis) + parseFloat(montant_crs) + parseFloat(montant_pts)+ parseFloat(montant_bds))

                })                

              }
              total_montant = total_montant_ajouter + total_montant_soustraire
              console.log('montant',total_montant)
              this.bfs[key] = Object.assign({}, this.bfs[key], this.bfs[key][annee]=total_montant);

            }
          })
        })
        this.bfs=_.orderBy(this.bfs, ['order'], ['asc'])
        this.bf_loading = false;
        resolve();
      })
    },

    /*
     * Compte de resultat
     */

     get_crs() {
      this.cr_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/previsionnel-sig-load-cr", payload)
          .then((response) => {
            this.crs = _.orderBy(response.data, ["order"], ["asc"]);
            this.cr_loading = false;
            resolve();
          })
          .catch((err) => {
            this.cr_loading = false;
            reject(err);
          });
      });
    },

    /*
     * plan de financement previs
     */

     get_pfis() {
      this.pfi_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/previsionnel-bd/pfi", payload)
          .then((response) => {
            this.pfis = _.orderBy(response.data, ["order"], ["asc"]);
            this.pfi_loading = false;
            resolve();
          })
          .catch((err) => {
            this.pfi_loading = false;
            reject(err);
          });
      });
    },    

    /*
     * plan de tresoreris previs
     */

     get_pts() {
      this.pt_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/previsionnel-bd/pt", payload)
          .then((response) => {
            this.pts = _.orderBy(response.data, ["order"], ["asc"]);
            this.pt_loading = false;
            resolve();
          })
          .catch((err) => {
            this.pt_loading = false; 
            reject(err);
          });
      });
    },  
    
    /*
     * balances annuelles
     */

     get_balances() {
      this.balance_loading = true;

      let payload = {
        client_id: this.selectedClient.id,
        annees: this.diagnostic_years,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/previsionnel-bd/balances", payload)
          .then((response) => {
            this.balances = _.orderBy(response.data, ["order"], ["asc"]);
            this.balance_loading = false;
            resolve();
          })
          .catch((err) => {
            this.balance_loading = false;
            reject(err);
          });
      });
    }, 
    
    
     /*
     * bilan detaillé initial 
      (= bilan détaillé annee N-1 le vrai issue de la derniere balance annuelle connue)
     */  

     get_bds_initial() {
      this.bds_initial_loading = true;

      var annee_initiale= this.diagnostic_years[0]-1

      let payload = {
        client_id: this.selectedClient.id,
        annees: [annee_initiale], // on va recherche le bilan de l'année antérieure à la première année de diagnotique
      };

      return new Promise((resolve, reject) => {
        axios
          .post("/api/diag/analyse/bd", payload)
          .then((response) => {

            this.bds_initial = _.orderBy(response.data, ["order"], ["asc"]);

            _.map(this.bds_initial, function (obj) {

              // on decale l'index order de 1 pour coller aux references du fichier excel de recette
              // et on nomme cette propriete neworder

              obj.neworder = parseInt(obj['order'])+1
              
              // comme il n'y a qu'une anne initiale on place la valeur associee à l'annee dans une prop "montant"
              obj.montant = obj[annee_initiale]

            })           

            this.bds_initial_loading = false;
            resolve();
          })
          .catch((err) => {
            this.bds_initial_loading = false;

            reject(err);
          });
      });
    },     
    /*
     * ordre croissant des onglets années
     */

    orderYear() {
      this.diagnostic_years = _.sortBy(this.diagnostic_years);
    },

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            // console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

    getDiagnosticYears() {
      if (!this.selectedClient.id) {
        this.diagnostic_years = [];
        return;
      }
      this.diagnostic_years_loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/previsionnel-bd/years/"+this.selectedClient.id
          )
          .then((response) => {
            this.possibles_diagnostic_years = response.data ;

            // les trois dernieres années sont selectionnées par default
            this.diagnostic_years = _.sortBy(
              _.take(this.possibles_diagnostic_years, 3)
            );

            this.diagnostic_years_loading = false;
            resolve(response);
          })
          .catch((err) => {
            this.possibles_diagnostic_years = [];
            this.diagnostic_years_loading = false;
            reject(err);
          });
      });
    },
  },

  mounted() {
    this.getClients().then(() => {
      // recuperation du client selectionné sauvegardé en local storage
      // mise a jour du local storage si entretemps un paramétrage client a été mis à jour

      if (localStorage.selectedClient) {
        var selectedClientInlocalstorage = JSON.parse(
          localStorage.selectedClient
        );
        this.selectedClient = _.find(this.clients, {
          id: selectedClientInlocalstorage.id,
        });
        localStorage.selectedClient = JSON.stringify(this.selectedClient);
      }
    });
  },
};
</script>