<template>
  <!-- Le template -->
  <!-- Le template -->
  <section>
    <v-container>
      <h4 class="mb-8">Importation du PCG</h4>

      <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
        <ul>
          <div v-for="(value, propertyName, index) in formError" :key="index">
            <li v-if="value && Array.isArray(value)" >
              {{ propertyName }}: <span class="pre-formatted">{{ value[0] }}</span>
            </li>
            <li v-if="value && typeof value == 'string'" >
              {{ propertyName }}: <span class="pre-formatted">{{ value }}</span>
            </li>
          </div>
        </ul>
      </v-alert>

      <v-snackbar
        v-model="snackbar"
        bottom
        color="success"
        multi-line
        :timeout="6000"
      >
        <p v-html="snackbar_text"></p>

        <v-btn
          dark
          color="green darken-4"
          @click="
            snackbar_text = '';
            snackbar = false;            
          "
          >Fermer</v-btn
        >
      </v-snackbar>
      <v-form ref="form" v-model="valid" >
        <v-row>
          <!-- PCG FILE -->
          <v-col>
            <v-file-input dense show-size
            
            v-model="pcgfile"
            ref="pcgfile"
            accept=".csv"
            hint="Fichier au format CSV '(*.csv) avec encodage UTF8 si le fichier comporte des accents<br>
            Deux colonnes sont attendues.<br>
            Colonne 1 : numéro de compte (4 caractères minimum 11 caractères maximum)<br>
            Colonne 2 : libellé (non vide)<br>
            Les numéros de compte pourront comporter des lettres mais ne peuvent pas être présente dans les 3 premiers caractères.<br>
            Une ligne par compte"
            persistent-hint
            label="Fichier PCG"
                  :error-messages="formError.pcgfile"
                  :rules="[rules.required]"
                  @focus="formError.pcgfile = null" >
            <template v-slot:message="{ message, key }">
            <div class="mt-4" v-html="message"></div>
            </template>      
            </v-file-input>

          </v-col>
        </v-row>
        <v-btn
          class="mt-4"
          color="primary"
          @click.prevent="submit"
          :disabled="!valid"
        >
          <v-icon left>mdi-check</v-icon>
          Enregistrer
        </v-btn>
      </v-form>
    </v-container>
  </section>
</template>

<style>
.pre-formatted {white-space: pre-wrap;}
</style>

<script>

import {EventBus} from "../../EventBus";

// Le script
export default {
  name: "pcgImport",
  props: {
    clientID: Number,
  },
  data() {
    return {

      isSaving: false,

      serverAlert: null,
      snackbar_text: "",
      snackbar: false,

      pcgfile : null,

      valid: false,

      formError: {
        pcgfile: null,
      },

      rules: {
        required: (v) => !!v || "Ce champ est requis",

      },
    };
  },
  methods: {
      reload(){

          window.location.reload()
      },
    submit() {
      this.isSaving = true;
      this.serverAlert = null;

      let formData = new FormData();

      formData.append("pcgfile",this.pcgfile);
      formData.append("client_id", this.clientID);
        console.log(formData)
      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/pcg_history/create/" + this.clientID, formData)
          .then((response) => {
            console.log("submit OK", response);

            this.isSaving = false;
            this.snackbar = true;
            this.snackbar_text = "Fichier PCG uploadé";
            this.$refs.pcgfile.reset()
            this.pcgfile=null
            EventBus.$emit('pcgUploaded',this.clientID)


            
            

          })
          .catch((err) => {
            this.serverAlert = true;
            this.isSaving = false;
            console.log("submit_ko", err);

            if (err.response.status == 422) {
              Object.assign(this.formError, err.response.data.errors);
            }

            this.formError.general = err.response.data.message;
            this.$refs.pcgfile.reset()
            this.pcgfile=null
          });
      });
      return;
    },      
  },
};
</script>