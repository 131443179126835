<template>
    <!-- Le template -->
    <v-container fill-height v-if="isLoggedIn">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title v-if="!isLoading"> {{formTitle}}  <span v-if="$route.name!='user/create'">{{user.fullname}}</span></v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-progress-linear :active="isLoading" color="red" :indeterminate="true" />

          <v-card-text class="pt-8 pb-4">
                <v-alert v-if="serverAlert" type="error" 
                close-text="Fermer"
                dismissible>
                <ul>
                    <div v-for="(value, propertyName, index) in formError" :key="index">
                        <li v-if="value && Array.isArray(value)">{{ propertyName }}: {{ value[0] }}</li> 
                        <li v-if="value && typeof value =='string'">{{ propertyName }}: {{ value }}</li>                      
                    </div>
                </ul>
                </v-alert>

        <v-snackbar
      v-model="snackbar"
      bottom
      color="success"      
      multi-line
      :timeout=6000 
   >
      <p v-html="snackbar_text"></p>
        
      <v-btn dark color="green darken-4" @click="snackbar_text =''; snackbar= false">Fermer</v-btn>
      <v-btn v-if="userModified_saved" dark color="green darken-4" @click="snackbar= false" :to="{name:'user/list'}">Fermer et retourner au listing</v-btn>
    </v-snackbar>

            <v-form ref="form" v-model="valid" v-if="!isLoading">
              <v-row>
                <v-col>
                    <v-radio-group class="mb-6 mt-0"
                    
                    :disabled="$route.name=='user/show' || $route.name=='user/edit'"
                    v-model="userModified.profile"
                    mandatory
                    row
                    hide-details
                    :rules="[rules.required]"
                    :error-messages="formError.profile"
                    @focus="formError.profile=null"                  >
                    
                    <v-radio  label="Admin cabinet" value="admin_cabinet"></v-radio>
                    <v-radio  label="Collaborateur" value="collaborateur"></v-radio>
                    <v-radio  v-if="this.LoggedUser.office.id==1" label="Consultant" value="consultant"></v-radio>
                  </v-radio-group>

                  <v-text-field
                    
                    label="Nom"
                    name="name"
                    :disabled="$route.name=='user/show'"
                    v-model="userModified.name"
                    :rules="[rules.required]"
                    :error-messages="formError.name"
                    @focus="formError.name=null"
                    required
                  />
                  <v-text-field 
                    
                    label="Prénom"
                    name="firstname"
                    :disabled="$route.name=='user/show'"
                    v-model="userModified.firstname"
                    :rules="[rules.required]"
                    :error-messages="formError.firstname"
                    @focus="formError.firstname=null"
                    required
                  /> 
                  <v-text-field
                    
                    label="Téléphone"
                    name="tel"
                    :disabled="$route.name=='user/show'"
                    v-model="userModified.tel"
                    :rules="[rules.required]"
                    :error-messages="formError.tel"
                    @focus="formError.tel=null"
                    required
                  />
                 

                  <v-text-field   class="mb-8"                  
                    label="Email"
                    name="email"
                    type="email"
                    :disabled="$route.name=='user/show'"
                    v-model="userModified.email"
                    prepend-icon="mdi-at"
                    :rules="[rules.required,rules.validEmail]"
                    :error-messages="formError.email"
                    @focus="formError.email=null"
                  />



                    <fieldset v-if="$route.name!='user/show'" >
                    <legend>Password</legend>
                        <v-text-field
                            
                            label="Password"
                            name="password"
                            type="password"
                            v-model="userModified.password"
                            prepend-icon="mdi-lock"
                            :rules="rules.passwordRules"
                            :error-messages="formError.password"
                            autocomplete="new-password"
                            @focus="formError.password=null"
                        />
                        <v-text-field
                            
                            label="Confirmation du PassWord"
                            name="password_confirmation"
                            type="password"
                            v-model="userModified.password_confirmation"
                            prepend-icon="mdi-lock"
                            :rules="rules.passwordConfirmed"
                            :error-messages="formError.password_confirmation"
                            @focus="formError.password_confirmation=null"
                        />
                </fieldset>
                </v-col>

                <v-col>

                <v-autocomplete                     
                    hint="Rechercher ou creez un nouveau cabinet"
                    label="Cabinet associé"
                    name="office.id"
                    v-model="userModified.office.id"
                    required
                    :clearable="!(( this.$route.name=='user/create' && this.userModified.profile=='consultant' ) || this.$route.name=='user/edit')"
                    :items="offices"
                    :search-input.sync="searchOffice"
                    :disabled="this.LoggedUser.profile!='admin_principal'||this.$route.name=='user/show' || this.$route.name=='user/edit' || this.LoggedUser.profile=='consultant'"
                    @keyup.enter="createOffice(searchOffice)"   
                    :rules="[rules.required]"
                    :error-messages="formError.office"
                    @focus="formError.office=null"                 
                                       
                >
                    <template v-slot:no-data>
                    <v-list-item>
                        <v-list-item-content>
                        <v-list-item-title>
                            Aucun cabinet trouvé pour la recherche"<strong>{{searchOffice}}</strong>"
                            <br>. Pour créer le cabinet Appuyez sur :<kbd>ENTER</kbd>
                        </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </template>
                </v-autocomplete>


                   
                  <v-text-field v-if="this.$route.name=='user/edit' && this.userModified.profile=='admin_cabinet'"
                    
                    label="Modifier le nom du cabinet"
                    name="office.name"                    
                    v-model="userModified.office.name"
                    :rules="[rules.required]"
                    :error-messages="formError.office"
                    @focus="formError.office=null"
                    required                    
                  />
                
                
                <div v-if="userModified.profile=='collaborateur' || userModified.profile=='consultant'">
                    <p v-if="!userModified.office.id">Veuillez sélectionner un cabinet pour pouvoir associer cet utilisateur à un client</p>    
                    <!--:disabled="$route.name=='user/show' || userModified.can.modifyClientAffectation==false"-->
                                
                    <v-autocomplete class="mt-8" v-if="userModified.office.id && !isClientsLoading"
                        :disabled = this.isClientAffectationDisabled
                        
                        label="Client(s) associé(s)"
                        v-model="userModified.clients"
                        :items="clients"
                        clearable
                        chips
                        small-chips 
                        deletable-chips                   
                        multiple
                        item-value="id"
                        return-object
                    ></v-autocomplete>
                </div>
                                              
                
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="red" @click.prevent="$router.go(-1)">
              <v-icon left>mdi-arrow-left-circle</v-icon>Retour
            </v-btn>
            <v-spacer />
            <v-btn color="primary" @click.prevent="restore"  v-if="$route.name!='user/show'">
              <v-icon left>mdi-history</v-icon>Restaurer
            </v-btn>
            <v-btn color="primary" @click.prevent="submit" :disabled="!valid"  v-if="$route.name!='user/show'">
              <v-icon left>mdi-check</v-icon>
              {{submitLabel}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
    // Le script
    export default {
        data() {return {

            
            isOfficesLoading:true,
            isUserLoading:true,
            isClientsLoading:true,
            isSaving:false,

            formTitle: null,
            submitLabel: null,

            serverAlert: null,            
            snackbar_text : '', 
            snackbar : false,                         

            user:{
                    name : null,
                    firstname : null,
                    email : null,
                    tel : null,
                    profile : 'collaborateur',
                    office : {name: null, id: null},
                    clients : [],
                    password : null,
                    password_confirmation : null,
                    can : {
                        modifyUserAffectation : true
                    }
                },

            userModified: {
                    name : null,
                    firstname : null,
                    email : null,
                    tel : null,
                    profile :'collaborateur',
                    office : {name: null, id: null},
                    clients : [],  
                    password : null,
                    password_confirmation : null,
                    can : {
                        modifyUserAffectation : true
                    }                                  
            },

            

            userModified_saved:false,

            offices:null,
            searchOffice:null,

            clients:null,

            valid: false,

            formError: {
                name: null,
                firstname: null,
                email: null,
                tel: null,
                password: null,
                password_confirmation: null,
                profile: null,
                office : null,
                general : null,
               
            },

            rules: {
                validEmail: v => /.+@.+\..+/.test(v) || "L'email doit être valide",

                required: v => !!v || "Ce champ est requis",

                passwordRules: [

                    
                    v => {
                        if(this.$route.name=='user/create' && (v=='' || v==null )){return "Le password Obligatoire !"}
                        return true
                    },  
                    v => {
                        if(v && v.length < 8 ){return "Le password doit comporter 8 caractères minimum"}
                        return true
                    }                 
                ],

                passwordConfirmed: [
                v => {
                    if(v != this.userModified.password ){return "La confirmation du password a échoué"}
                    return true
                }

                ]
            },            

          

        }},
        computed : {
            isLoading: function(){
                if(this.isSaving ||this.isUserLoading || this.isOfficesLoading ||this.isUserLoading ){ return true}
                return false
            },

            LoggedUser () {
                return this.$store.getters["currentUser/LoggedUser"]
            },
            isLoggedIn (){
                return this.$store.getters["currentUser/isLoggedIn"]
            },
            isClientAffectationDisabled (){
                if(this.$route.name=='user/show'){return true}
                if(this.userModified.can.modifyClientAffectation==false){return true}
                return false
            }

        },
        watch:{


            'userModified.profile' : function(newVal, oldVal){

                    if(newVal=='consultant' ){
                        this.userModified.office={name:'socoandfi',id:1}
                    }
                    if(newVal=='collaborateur'){
                        // si le profil cree est un collaborateur
                        // le cabinet prend automatiquement la valeur du cabinet de l'utilisateur courrant
                        this.userModified.office={
                            name:this.LoggedUser.office.name,
                            id:this.LoggedUser.office.id
                            }                       
                    }
            },


            'userModified.office.id' : function(newVal, oldVal){                
                
                // en cas de changement du cabinet (uniquement en creation)
                // on charge la liste de client une nouvelle fois
                if(newVal && this.$route.name=='user/create'){

                    this.getClients().then(()=>{

                        // on filtre les choix de client possible selon le cabinet choisi
                        this.clients=_.filter(this.clients, ['office_id', newVal]);

                        // on reinitialise les clients associés
                        this.userModified.clients = []


                    });
                }

            },



        },
        methods : {

            /*
             * get user info
             */            

            getUser(){
                this.isUserLoading = true
                axios.get('/api/user/'+this.$route.params.userId+'?from='+this.$route.name )
                .then((response)=>{
                    Object.assign(this.user, response.data)  
                    Object.assign(this.userModified, response.data)
                    this.user.password=null
                    this.user.password_confirmation=null
                    this.userModified.password=null
                    this.userModified.password_confirmation=null

                    this.isUserLoading = false
                    
                })
                .catch((err)=>{
                    console.log('/api/user/'+this.$route.params.userId+' KO',err)
                    console.log(err)
                    if (err.response.status == 403) {
                        this.$router.push({name:'unauthorized'})
                    }                    
                    this.isUserLoading = false
                })

            },
            /*
             * Liste des cabinets
             */
            getCabinets(){
                this.isOfficesLoading = true

                return new Promise((resolve, reject) => {
                    axios.get('/api/officelist')
                    .then((response)=>{
                        console.log('/api/officelist/ OK')
                        this.offices = response.data.data                   
                        this.isOfficesLoading = false 
                        resolve(response)                   
                    })
                    .catch((err)=>{
                        console.log('/api/user/'+this.$route.params.userId + ' KO')
                        console.log(err)
                        this.isOfficesLoading = false
                        reject(err)
                    })
                })

            },

            /*
             * Liste des clients
             */
            getClients(){
                this.isClientsLoading = true

                return new Promise((resolve, reject) => {
                    axios.get('/api/clientlist')
                    .then((response)=>{
                        console.log('/api/clientlist/ OK')
                        this.clients = response.data.data                   
                        this.isClientsLoading = false 
                        resolve(response)                    
                    })
                    .catch((err)=>{
                        console.log('/api/clientlist/ KO',err)
                        console.log(err)
                        this.isClientsLoading = false
                        reject(err)
                    })
                })

            },            

            /*
             * create Cabinets
             */ 

            createOffice(searchOffice) {
                if (!_.find(this.offices, { value: searchOffice })) {
                    axios
                    .post("/api/office/create", { name: searchOffice })
                    .then(response => {  
                        console.log("/api/office/create OK")                  
                        this.offices.push({
                        value: response.data.id,
                        text: searchOffice,
                        });
                        this.snackbar=true
                        this.snackbar_text="<b>Cabinet créé!</b><br>N'oubliez pas de creer un profil admin cabinet par la suite"
                    })
                    .catch(err => {
                        console.log("/api/office/create KO")      
                        console.log(err,err.response)
                        if(err.response.status==422){
                            this.serverAlert='';
                            Object.assign(this.formError, err.response.data.errors);
                        }
                        else if(err.response.status==403){this.formError.general="Vous n'avez pas l'authorisation de créer un cabinet"}
                        else{this.formError.general=err}
                        
                    });
                }
            },
            
            restore(){

                console.log('restore')

                if(this.$route.name=='user/create'){

                        this.isUserLoading = false
                        this.userModified=null;
                        this.userModified=JSON.parse(JSON.stringify(this.user)); // clone without reference   
                        this.userModified.office.id=1

                                     
                }
                else{
                    this.getUser();
                  
                }
            },
            
            submit(){

                console.log('submit clicked');

                this.isSaving = true;
                this.serverAlert= null;
                let url

                let payload = this.userModified;


                if(this.$route.name=='user/edit'){url="/api/user/edit/"+this.userModified.id}
                else{url="/api/user/create"}

                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post(url, payload)
                    .then(response => {

                        console.log('submit OK',response);
                        
                        this.isSaving = false;
                        this.snackbar= true 
                        this.userModified_saved = true
                        if(this.$route.name=='user/edit'){this.snackbar_text="Utilisateur modifié avec succès"}
                        else{this.snackbar_text="Utilisateur ajouté avec succès"}
                        
                        this.restore();                        
                        
                    })
                    .catch(err => {
                    this.serverAlert= true;    
                    this.isSaving = false;
                    console.log("submit_ko", err.response);

                    if (err.response.status == 422) {
                        Object.assign(this.formError, err.response.data.errors);
                    }

                    this.formError.general = err.response.data.message;

                    });


                })
                return ;

            },            


        },
        mounted(){


            switch (this.$route.name) {
            case 'user/show':

                this.formTitle="Détail de l'utilisateur"
                this.getUser();
                break;

            case 'user/edit':

                this.formTitle="Modification de l'utilisateur"
                this.submitLabel="Mettre à jour"
                this.getUser();
                break;

            case 'user/create':

                this.formTitle="Création de l'utilisateur"
                this.submitLabel="Créer"
                this.userModified=JSON.parse(JSON.stringify(this.user)); // clone without reference
                this.isUserLoading = false              
                break;

            default:
                console.log("Sorry, we are out of "+$route.name);
            }


            this.getCabinets();
            this.getClients();


        }
    }
</script>
<style>
fieldset {
    border: 1px solid #333333;
    padding: 10px;
    background-color: #191919;
    }
fieldset legend {
    font-size:16px;
    width : auto;
    padding : 0px 8px;
    margin-bottom : 0px;
} 
.v-input--radio-group label {margin-bottom:0px}

</style>