<template>
  <!-- Le template -->
  <section>
    <v-container>
        <h4 class='mb-8'>
      Période d'exercice pour le client
      <small>(valable uniquement pour la première année. Les exercices suivants sont automatiquement sur 12 mois)</small>
    </h4>

    
      <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
        <ul>
          <div v-for="(value, propertyName, index) in formError" :key="index">
            <li v-if="value && Array.isArray(value)">
              {{ propertyName }}: {{ value[0] }}
            </li>
            <li v-if="value && typeof value == 'string'">
              {{ propertyName }}: {{ value }}
            </li>
          </div>
        </ul>
      </v-alert>

      <v-snackbar
        v-model="snackbar"
        bottom
        color="success"
        multi-line
        :timeout="6000"
      >
        <p v-html="snackbar_text"></p>

        <v-btn
          dark
          color="green darken-4"
          @click="
            snackbar_text = '';
            snackbar = false;
          "
          >Fermer</v-btn
        >
        
      </v-snackbar>
      <v-form ref="form" v-model="valid" v-if="!isClientLoading">
        <v-row>
          <!-- start date -->
          <v-col>
            <v-menu
              offset-y
              max-width="290px"
              min-width="290px"
              transition="scale-transition"
              :close-on-content-click="false"
              v-model="startdatepickermenu"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  readonly
                  clearable
                  class="my-0 py-0"
                  :value="client.debut_exercice ? $moment(client.debut_exercice).format('DD/MM/YYYY') : null"
                  label="Début exercice"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  :error-messages="formError.debut_exercice"
                  :rules="[validateStartDate(client.debut_exercice)]"
                  @focus="formError.debut_exercice = null"
                  @click:clear="client.debut_exercice = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="fr"
                color="primary"
                first-day-of-week="1"
                :show-current="true"
                v-model="client.debut_exercice"
                @change="startdatepickermenu = false"
              />
            </v-menu>
          </v-col>
          <!-- end date -->
          <v-col>
            <v-menu
              offset-y
              max-width="290px"
              min-width="290px"
              transition="scale-transition"
              :close-on-content-click="false"
              v-model="enddatepickermenu"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  readonly
                  clearable
                  class="my-0 py-0"
                  :value="client.fin_exercice ? $moment(client.fin_exercice).format('DD/MM/YYYY') : null"
                  label="Fin d'exercice"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  :error-messages="formError.fin_exercice"
                  :rules="[validateStartDate(client.fin_exercice)]"
                  @focus="formError.fin_exercice = null"
                  @click:clear="client.fin_exercice = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="fr"
                color="primary"
                first-day-of-week="1"
                :show-current="true"
                v-model="client.fin_exercice"
                @change="enddatepickermenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-btn class='mt-4' color="primary" @click.prevent="submit" :disabled="!valid">
          <v-icon left>mdi-check</v-icon>
          Enregistrer
        </v-btn>
      </v-form>
    </v-container>
  </section>
</template>

<script>
// Le script
export default {
  name: "exercice-form",
  props: {
    clientID: Number,
  },
  data() {
    return {
      client: { debut_exercice: null, fin_exercice: null },

      isSaving: false,
      isClientLoading: true,

      startdatepickermenu: false,
      enddatepickermenu: false,

      serverAlert: null,
      snackbar_text: "",
      snackbar: false,

      valid: false,

      formError: {
        debut_exercice: null,
        fin_exercice: null,
      },

      rules: {
        required: (v) => !!v || "Ce champ est requis",

        positif: (v) => v >= 0 || "Valeur positive attendue",

        numeric: (v) => !isNaN(v) || "Valeur numérique attendue",

        fourdigits: (v) => /^\d{4}$/.test(v) || "4 chiffres attendus",
      },
    };
  },
  watch: {
    clientID() {
      this.getClient();
    },
  },
  methods: {
  
    validateStartDate() {
      let start_date = this.$moment(this.client.debut_exercice, "YYYY-MM-DD");
      let end_date = this.$moment(this.client.fin_exercice, "YYYY-MM-DD");

      if (
        this.client.debut_exercice == "" ||
        this.client.debut_exercice === null
      ) {
        return "La date de début est obligatoire";
      }
      if (start_date.isAfter(end_date)) {
        return "La date de début doit être antérieure à la date de fin";
      }
      return true;
    },

    validateEndDate() {
      let start_date = this.$moment(this.client.debut_exercice, "YYYY-MM-DD");
      let end_date = this.$moment(this.client.fin_exercice, "YYYY-MM-DD");

      if (this.client.fin_exercice == "" || this.client.fin_exercice === null) {
        return "La date de fin est obligatoire";
      }
      if (end_date.isBefore(start_date)) {
        return "La date de fin doit être postérieure à la date de début";
      }
      return true;
    },
    /*
     * get client info
     */

    getClient() {
      console.log("get Client info");
      this.isClientLoading = true;
      axios
        .get("/api/client/" + this.clientID + "+?from=client/show")
        .then((response) => {
          Object.assign(this.client, response.data);
          this.isClientLoading = false;
        })
        .catch((err) => {
          console.log("/api/client/" + this.clientID + " KO", err);
          console.log(err.response);
          this.isClientLoading = false;
          if (err.response.status == 403) {
            this.$router.push({ name: "unauthorized" });
          }
        });
    },
    submit() {
      this.isSaving = true;
      this.serverAlert = null;

      let payload = {
        client_id: this.client.id,
        debut_exercice: this.client.debut_exercice,
        fin_exercice: this.client.fin_exercice,
      };

      console.log(payload);

      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/client/edit_exercice/" + this.client.id, payload)
          .then((response) => {
            console.log("submit OK", response);

            this.isSaving = false;
            this.snackbar = true;
            this.snackbar_text = "Période modifiée avec succès";

            this.getClient();
          })
          .catch((err) => {
            this.serverAlert = true;
            this.isSaving = false;
            console.log("submit_ko", err);

            if (err.response.status == 422) {
              Object.assign(this.formError, err.response.data.errors);
            }

            this.formError.general = err.response.data.message;
          });
      });
      return;
    },
  },
  mounted() {
    this.getClient();
  },
};
</script>