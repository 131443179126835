<template>
  <section>
    <!--Snackbar-->
    <v-snackbar v-model="snackbar" bottom color="success" multi-line :timeout="6000">
      {{snackbar_text}}
      <v-btn dark color="green darken-4" @click="snackbar = false">Fermer</v-btn>      
    </v-snackbar>       
    <!-- Modal -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Confirmer la suppression ?</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="deleteItem != null">
            <v-row>
              <v-col> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="cancel">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click.stop="confirm"
            >Confirmer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Le template -->
    <!--Barre outil listing-->
    <v-row>
      <!--Search-->
      <v-col align="center" justify="center" cols="auto">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche"
          single-line
          dense
        ></v-text-field>
      </v-col>
      <!--Colonne visibility-->
      <v-col align="center" justify="center" cols="auto">
        <v-select
          v-model="defaultHeaders"
          :items="possibleHeaders"
          label="Visibilité des colonnes"
          multiple
          return-object
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{
              defaultHeaders.length +
              " colonnes sur " +
              possibleHeaders.length +
              " possibles"
            }}</span>
          </template>
        </v-select>
      </v-col>
      <!--Export excel-->
      <v-col align="center" justify="center" cols="auto">
        <export-excel :data="myitems" :name="excel_file_name" :fields="excelheaders">
          <v-btn color="grey darken-3">
            <v-icon dark left>mdi-file-export-outline</v-icon>Excel
          </v-btn>
        </export-excel>
      </v-col>
      <!--Bouton creation-->
      <v-col class="text-right" cols="auto">
        <v-btn
          color="primary"
          width="100%"
          v-if="create_route_name && canCreateEtp === true"
          :to="{ name: create_route_name , params: { clientID: selectedClient.id }}"
        >
          <v-icon dark left>mdi-plus</v-icon>Ajouter un élément
        </v-btn>
      </v-col>
    </v-row>
    <!--DataTable -->
    <v-data-table
      sortBy="id"
      :footer-props="{
        'items-per-page-all-text': 'tout',
        'items-per-page-text': 'Résultats par page :',
      }"
      @update:items-per-page="setItemPerPage"
      :headers="headers"
      :items="myitems"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      class="elevation-1"
    >
      <!--FOOTER SLOTS -->
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >Lignes: {{ pageStart }} à {{ pageStop }} sur
        {{ itemsLength }}</template
      >

      <!--ACTIONS SLOTS-->
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="update_route_name && item.can.update == true"
          icon
          :to="{ name: update_route_name, params: { itemId: item.id } }"
          title="Modifier"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <!-- est une route JS-->
        <v-btn
          v-if="show_route_name && item.can.view == true & !show_route_name.startsWith('/api')"
          icon
          :to="{ name: show_route_name, params: { itemId: item.id } }"
          title="Voir le détail"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
        <!-- n'est pas une route (for download for ex.)-->
        <v-btn
          v-if="show_route_name && item.can.view == true & show_route_name.startsWith('/api')"
          icon
          :href="show_route_name +'/'+ item.id"
          title="Télécharger"
          ><v-icon>mdi-file-download</v-icon></v-btn
        >

        <!-- supression (cas PCG History le dernier PCG ne peut pas être supprimé-->
        <v-btn
          v-if="api_delete_endpoint_url && item.can.delete == true && item.id!=maxId && api_delete_endpoint_url.startsWith('/api/pcg_history')"
          icon
          color="red"
          title="Supprimer"
          ><v-icon @click="modalDelete(item)">mdi-delete</v-icon></v-btn
        >
        <!-- supression -->
        <v-btn
          v-if="api_delete_endpoint_url && item.can.delete == true && !api_delete_endpoint_url.startsWith('/api/pcg_history')"
          icon
          color="red"
          title="Supprimer"
          ><v-icon @click="modalDelete(item)">mdi-delete</v-icon></v-btn
        >       
             
      </template>

      <!-- Numero de compte-->
      <template v-slot:item.compte="{ item }">
        <span :class="notUsedInDiag(item) ? 'red--text' : 'default' ">{{item.compte}}</span>    
      </template> 


      <!-- VARIABLE SLOTS-->
      <template v-slot:item.variable="{ item }">
        <v-switch v-model="item.variable" readonly></v-switch>    
      </template>      

      <!-- DATE SLOTS-->
      <template v-slot:item.created_at="{ item }">
        <span> {{ formaDatetWithDuration(item.created_at) }}</span>
      </template>
      <template v-slot:item.updated_at="{ item }">
        <span> {{ formaDatetWithDuration(item.updated_at) }}</span>
      </template>
      <template v-slot:item.cloture="{ item }">
        <span> {{ formaDatetWithDuration(item.cloture) }}</span>
      </template>      
    </v-data-table>
    <v-row v-if="api_endpoint_url.includes('load_pcg') && !this.no_show_info"><v-col class="text-right red--text" cols="auto"><strong>Les comptes indiqués en rouge ne sont pas utilisés dans le Compte de Résultat ni le Bilan</strong></v-col>
    </v-row>
  </section>
</template>

<script>
// Le script

import {EventBus} from "../EventBus";

export default {
  name: "datatable",
  props: {
    selectedClient : Object,
    possibleHeaders: Array,
    api_endpoint_url: String,
    default_column_names: Array,
    default_column_order: String,
    create_route_name: String,
    show_route_name: String,
    update_route_name: String,
    api_delete_endpoint_url: String,
    excel_file_name: String,
    no_show_info : Boolean,
  },
  data() {
    return {
      search: null,
      headers: [],
      options: { page: 1, itemsPerPage: 10 },
      defaultHeaders: [],
      myitems: [],
      excelheaders: {},
      totalItems: 0,
      datatableQuery: null,
      loading: true,
      columns: {},
      dialog: false,
      deleteItem: null,
      snackbar_text:null,
      snackbar:false,
      serverAlert:null,
      comptesUtilisesDansDiag : [
        //dans CR
        701, 702, 703, 707,7091, 7092, 7093, 7097,704, 705, 706, 708,
        7094, 7095, 7096, 7098, 74,73,71,72,789,75,781,79,76,
        786,777,787,775,77,
        689,601,602,604,607,608,609,603,605,606,
        6061,6063,6064,611,612,6132,613,614,615,616,617,618,619,621,
        622,623,6231,6233,624,625,6251,626,627,628,629,
        63,641,644,64,65,681,66,661,686,687,67,675,691,
        695,696,698,699,61,
        53,
        //dans bilan
        109,169,101,108,104,102,103,105,107,106,11,12,13,14,15,19,16,17,
        201,2801,203,2803,205,2805,2905,206,207,2807,2906,2907,
        208,2808,2908,237,211,212,2811,2812,2911,213,214,2813,2814,
        215,2815,218,2818,231,2931,238,261,266,2961,2966,267,268,
        2967,2968,271,272,27682,2971,2972,274,27684,2974,275,2761,27685,
        27688,2975,2976,269,279,
        31,32,391,392,33,34,393,394,35,395,37,397,
        4091,411,413,416,417,418,412,491,441,4191,400,401,403,4081,4088,
        4096,4097,4098,425,4287,4456,44581,44582,4387,43,45,487,
        44583,44586,4487,462,465,4687,495,496,421,422,423,424,427,4282,
        4284,4286,452,451,455,456,458,486,481,476,477,
        442,4455,4457,44584,44587,446,447,4482,4486,443,444,404,405,4084,
        4196,4197,4198,464,4686,4680,467,478,
        50,52,59,54,51,509,5186,519,512,517,517,
      ],

    };
  },
  computed: {
    maxId(){
      return _.maxBy(this.myitems,'id').id
    },
    
    //policies
    canCreateEtp: {
      get() {
        return true;
      },
    }, //this.$store.getters['currentUser/LoggedUser'].can.create
    //computed params to return pagination and search criteria
    params(nv) {
      return {
        ...this.options,
        query: this.search,
      };
    },
  },
  watch: {
    api_endpoint_url: function (newVal, oldVal) {
      console.log("api_endpoint_url changed");
      console.log(this.api_endpoint_url);
      this.setDatatableQuery();
      this.getDataFromApi();
    },

    defaultHeaders(val) {
      console.log("defaultHeaders changed");
      this.headers = _.orderBy(this.defaultHeaders, "order", "asc");
      this.headers.forEach((header) => {
        if (header.text != "actions") {
          this.excelheaders[header.text] = header.value;
        }
      });
    },
    params: {
      handler() {
        console.log("params changed");
        this.setDatatableQuery();
        this.getDataFromApi();
      },
      deep: true,
    },
    search: {
      handler() {
        console.log("search changed");
        this.options.page = 1;
        if (this.search != "") {
          this.filtered = true;
        } else {
          this.filtered = false;
        }
      },
    },
  },
  methods: {

            //retourne true si le compte n'est pas utilisé dans un calcul de diagnostic
            notUsedInDiag(item){

              if(_.indexOf(this.comptesUtilisesDansDiag,parseInt(item.subcompte1))!=-1){
                return false;
              }
              if(_.indexOf(this.comptesUtilisesDansDiag,parseInt(item.subcompte2))!=-1){
                return false;
              }
              if(_.indexOf(this.comptesUtilisesDansDiag,parseInt(item.subcompte3))!=-1){
                return false;
              }  
              if(_.indexOf(this.comptesUtilisesDansDiag,parseInt(item.subcompte4))!=-1){
                return false;
              }  
              if(_.indexOf(this.comptesUtilisesDansDiag,parseInt(item.compte.substring(0,5)))!=-1){
                return false;
              }                                        

              return true;


            },

            deleteFromApi() {

            this.loading = true;
            console.log(this.api_delete_endpoint_url + this.deleteItem.id);

            return new Promise((resolve, reject) => {
            axios
                .delete(this.api_delete_endpoint_url + this.deleteItem.id)
                .then(response => {
                 EventBus.$emit('elementDeleted',this.api_delete_endpoint_url + this.deleteItem.id)
                this.$emit('elementDeleted',this.api_delete_endpoint_url + this.deleteItem.id) 

                console.log("item deleted from api", response);
                this.deleteItem = null;
                this.getDataFromApi();
                this.snackbar_text="Elément supprimé avec succès"
                this.snackbar=true;
                this.loading = false;
                this.dialog = false
                resolve(response)
                
                })
                .catch(err => {
                console.log("item deleted from api failed", err);
                this.serverAlert = err.response.data.message;
                this.loading = false;
                this.dialog = false
                reject(err);
                });
            })
            },       
    cancel() {
      this.dialog = false;
      this.deleteItem = null;
      console.log("cancel");
      console.log("this.deleteItem", this.deleteItem);
    },
    confirm(item) {
      console.log("clic onfirmed");
      console.log("this.deleteItem", this.deleteItem);

      if (this.deleteItem != null) {
        this.deleteFromApi(this.deleteItem.id).then((response) => {
          this.deleteItem = null;
          //this.close();
          this.getDataFromApi();
        });
      }
    },

    modalDelete(item) {
      let deleteItemIndex = this.myitems.indexOf(item);
      this.deleteItem = this.myitems[deleteItemIndex];

      this.dialog = true;
      console.log("modal shown");
      console.log("deleteItem", this.deleteItem);
    },

    setItemPerPage(val) {
      localStorage.list_item_per_page = val;
      this.options.itemsPerPage = parseInt(localStorage.list_item_per_page);
    },
    formaDatetWithDuration(date) {
      let fromnow = this.$moment(date).fromNow(); // moment(thedate).fromNow();
      let output = this.$moment(date).format("ddd D MMM YYYY");
      return output + " (" + fromnow + ")";
    },

    setDatatableQuery() {
      //Based on our Headers we create query data for DataTables
      //I've added a new param "searchable" to let DataBales know that this column is not searchable
      //You can also set name as "table.column eg users.name" if you select from more then table to avoid "Ambitious column name error from SQL"

      for (var i = 0; i < this.headers.length; i++) {
        this.columns[i] = {
          data: this.headers[i].value,
          name:
            typeof this.headers[i].name != "undefined"
              ? this.headers[i].name
              : this.headers[i].value,
          searchable: this.headers[i].filterable,
          orderable: this.headers[i].sortable,
          search: { value: null, regex: false },
        };
      }

      this.datatableQuery = {};
      this.datatableQuery.length = this.params.itemsPerPage;
      this.datatableQuery.start =
        this.params.page == 1
          ? 0
          : this.params.itemsPerPage * (this.params.page - 1); //set offset
      this.datatableQuery.search = { value: this.params.query }; //our search query
      this.datatableQuery.columns = this.columns;

      let sortBy_index = _.findIndex(this.headers, {
        value: this.params.sortBy[0],
      });

      if (sortBy_index != -1) {
        this.datatableQuery.order = {
          0: {
            column: sortBy_index,
            dir: this.params.sortDesc[0] === true ? "desc" : "asc",
          },
        };
      } else {
        this.datatableQuery.order = {
          0: {
            column: _.findIndex(this.headers, {
              value: this.default_column_order,
            }),
            dir: "asc",
          },
        };
      }

      this.datatableQuery = window.jQuery.param(this.datatableQuery);
    },

    // Fetching the data
    getDataFromApi() {
      console.log("getDataFromApi");

      this.loading = true;

      axios
        .get(this.api_endpoint_url + "&" + this.datatableQuery)
        .then((response) => {
          console.log("datafetched");
          this.myitems = response.data.data;
          if (this.search) {
            this.totalItems = response.data.recordsFiltered;
          } else {
            this.totalItems = response.data.recordsTotal;
          }
          if (response.data.error != "") {
            this.serverAlert = response.data.error;
          }
          this.loading = false;

           console.log(_.maxBy(this.myitems,'id'))
        })
        .catch((err) => {
          console.log("erreur", err);
          this.serverAlert = err;
          this.loading = false;
        });
    },
  },
  mounted() {

    console.log("mounted");

    console.log(this.possibleHeaders);
    console.log(this.default_column_names);

    // sauvegarde des options d'affichage de la table
    if (localStorage.projetlistitemperpage) {
      this.options.itemsPerPage = parseInt(localStorage.projetlistitemperpage);
    }

    // initialisation des colonnes par defaut
    this.defaultHeaders = _.filter(this.possibleHeaders, (o) => {
      return this.default_column_names.includes(o.name);
    });

    // initialisation des colonnes excel
    this.headers = this.defaultHeaders;

    this.headers.forEach((header) => {
      if (header.text != "actions") {
        this.excelheaders[header.text] = header.value;
      }
    });




    // refresh data on event emited
    // on augmentation_qfpAdded
    EventBus.$on('augmentation_qfpAdded', data => {
      console.log("augmentation_qfpAdded")
      this.getDataFromApi()
    }) 

    //on subventionicAdded
    EventBus.$on('subvention_icAdded', data => {
      console.log("subvention_icAdded")
      this.getDataFromApi()
    })   

    //on augmentation_capitalpAdded
    EventBus.$on('augmentation_capitalpAdded', data => {
      console.log("augmentation_capitalpAdded")
      this.getDataFromApi()
    })    
    // on investissementrealiseAdded
    EventBus.$on('investissementrealiseAdded', data => {
      console.log("investissementrealiseAdded")
      this.getDataFromApi()
    })
    // on remboursement_esAdded
    EventBus.$on('remboursement_esAdded', data => {
      console.log("remboursement_esAdded")
      this.getDataFromApi()
    })

    // on prixmoyenAdded
    EventBus.$on('prixmoyenAdded', data => {
      console.log("prixmoyenAdded")
      this.getDataFromApi()
    })
    // on dividendeAdded
    EventBus.$on('dividendeAdded', data => {
      console.log("dividendeAdded")
      this.getDataFromApi()
    })


    // on etpAdded
    EventBus.$on('etpAdded', data => {
      console.log("etpAdded")
      this.getDataFromApi()
    })

    // on pcg uploaded
    EventBus.$on('pcgUploaded', data => {
      console.log("pcgUploaded")
      this.getDataFromApi()
    })

    // on pcg uploaded
    EventBus.$on('balanceannuelleUploaded', data => {
      console.log("balanceannuelleUploaded")
      this.getDataFromApi()
    })    

    // on element deleted
    EventBus.$on('elementDeleted', data => {
      console.log("catch event elementDeleted from"+data)
      this.getDataFromApi()
    }) 

    // on balance mensuelle uploaded
    EventBus.$on('balanceuploaded', data => {
      console.log("balanceuploaded")
      this.getDataFromApi()
    }) 

  },
};
</script>