<template>
  <!-- Le template -->
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="elevation-5">
          <v-toolbar color="primary" dark flat class="pa-0">
            <v-toolbar-title v-if="!isLoading" class="pa-0 ma-0">
              Ajouter un ETP
            </v-toolbar-title>
          </v-toolbar>
          <v-progress-linear
            :active="isLoading"
            color="red"
            :indeterminate="true"
          />

          <v-card-text class="pt-8 pb-4">
            <v-alert
              v-if="serverAlert"
              type="error"
              close-text="Fermer"
              dismissible
            >
              <ul>
                <div
                  v-for="(value, propertyName, index) in formError"
                  :key="index"
                >
                  <li v-if="value && Array.isArray(value)">
                    {{ propertyName }}: {{ value[0] }}
                  </li>
                  <li v-if="value && typeof value == 'string'">
                    {{ propertyName }}: {{ value }}
                  </li>
                </div>
              </ul>
            </v-alert>

            <v-snackbar
              v-model="snackbar"
              bottom
              color="success"
              multi-line
              :timeout="6000"
            >
              <p v-html="snackbar_text"></p>
              <v-btn
                dark
                color="green darken-4"
                @click="
                  snackbar_text = '';
                  snackbar = false;
                "
                >Fermer</v-btn
              >
            </v-snackbar>

            <v-form ref="form" v-model="valid" v-if="!isLoading">
              <v-row>
                <v-col>
                  <!--Annee-->
                  <v-text-field 
                  dense
                    required
                    v-model="annee"
                    label="Année"
                    name="annee"
                    :rules="[
                      rules.required,
                      rules.numeric,
                      rules.fourdigits,
                      rules.positif,
                    ]"
                                        :error-messages="formError.annee"
                                        @focus="formError.annee = null"  >


                  </v-text-field>
                  <!--<v-select
                  dense
                    v-model="annee"                    
                    :items="annees"
                    item-text="cloture"
                    item-value="cloture"
                    label="Année"                                       
                    single-line
                    :rules="[
                      rules.required,
                      rules.numeric,
                      rules.fourdigits,
                      rules.positif,
                    ]"
                    :error-messages="formError.annee"
                    @focus="formError.annee = null"                    
                  ></v-select>-->
                </v-col>
                <v-col>
                  <!--ETP DIRECT-->
                  <v-text-field
                    dense
                    required
                    label="ETP direct"
                    name="etp_direct"
                    v-model="etp_direct"
                    :rules="[rules.required, rules.numeric, rules.positif]"
                    :error-messages="formError.etp_direct"
                    @focus="formError.etp_direct = null"
                  />
                </v-col>
                <v-col>
                  <!--ETP INDIRECT-->
                  <v-text-field
                    dense
                    required
                    label="ETP indirect"
                    name="etp_indirect"
                    v-model="etp_indirect"
                    :rules="[rules.required, rules.numeric, rules.positif]"
                    :error-messages="formError.etp_indirect"
                    @focus="formError.etp_indirect = null"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn color="primary" @click.prevent="submit" :disabled="!valid">
              <v-icon left>mdi-check</v-icon>
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Le script
import { EventBus } from "../../EventBus";

export default {
  name: "etp-createform",
  props: {
    selectedClient: Object,
  },
  data() {
    return {
      loading: true,
      isSaving: false,

      serverAlert: null,
      snackbar_text: "",
      snackbar: false,

      annees: [],

      annee: null,
      etp_direct: null,
      etp_indirect: null,

      client: {},

      valid: false,

      formError: {
        annee: null,
        etp_direct: null,
        etp_indirect: null,
      },

      rules: {
        required: (v) => !!v || "Ce champ est requis",

        positif: (v) => v >= 0 || "Valeur positive attendue",

        numeric: (v) => !isNaN(v) || "Valeur numérique attendue",

        fourdigits: (v) => /^\d{4}$/.test(v) || "4 chiffres attendus",
      },
    };
  },
  computed: {
    isLoading: function () {
      if (this.isSaving) {
        return true;
      }
      return false;
    },
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
  },
  watch: {

    selectedClient(){
      console.log('clientchanged '+this.selectedClient.id)
      this.getannees();
    }

  },
  methods: {
    getannees() {
      this.loading = true;
      this.serverAlert = false;

      axios
        .get(
          "/api/load_balanceannuelle_history_select?client_id=" +
            this.selectedClient.id
        )
        .then((response) => {

          this.annees = _.uniq(_.map(response.data,function(v){return v.cloture.substring(0,4)}));

          this.Loading = false;          
          
        })
        .catch((err) => {
          this.Loading = false;
          
        });
    },

    submit() {
      this.isSaving = true;
      this.serverAlert = null;

      let payload = {
        annee: this.annee,
        etp_direct: this.etp_direct,
        etp_indirect: this.etp_indirect,
      };

      console.log(payload);

      axios.get("/sanctum/csrf-cookie").then((response) => {
        axios
          .post("/api/etp/create/" + this.selectedClient.id, payload)
          .then((response) => {
            console.log("submit OK", response);

            this.isSaving = false;
            this.snackbar = true;
            this.snackbar_text = "Element ajouté avec succès";

            this.annee = null;
            this.etp_direct = null;
            this.etp_indirect = null;

            EventBus.$emit("etpAdded", this.selectedClient.id);
          })
          .catch((err) => {
            this.serverAlert = true;
            this.isSaving = false;
            console.log("submit_ko", err);

            if (err.response.status == 422) {
              Object.assign(this.formError, err.response.data.errors);
            }

            this.formError.general = err.response.data.message;
          });
      });
      return;
    },
  },
  mounted() {
    
    this.getannees();
  },
};
</script>
<style>
fieldset {
  border: 1px solid #333333;
  padding: 10px;
  background-color: #191919;
}
fieldset legend {
  font-size: 16px;
  width: auto;
  padding: 0px 8px;
  margin-bottom: 0px;
  color: gray;
}
.v-input--radio-group label {
  margin-bottom: 0px;
}
</style>