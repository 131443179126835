/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.Vue = require('vue');

import "vuetify/dist/vuetify.min.css";
import Vuetify from "vuetify";
import store from "./store"
import Vue from 'vue';

import VueRouter from 'vue-router';

import excel from 'vue-excel-export'



const moment = require('moment')
require('moment/locale/fr')
Vue.use(require('vue-moment'), { moment });

Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(excel)

// Components delcaration (Blocks within pages & layouts)
Vue.component('app_router', require('./components/app_router.vue').default);
Vue.component('drawer_navigation', require('./components/drawerNavigation.vue').default);
Vue.component('drawer_button', require('./components/drawerButton.vue').default);
Vue.component('datatable', require('./components/Datatable.vue').default);
Vue.component('exercice-form', require('./components/forms/exerciceForm.vue').default);
Vue.component('tva-form', require('./components/forms/tvaForm.vue').default);
Vue.component('etp-createform', require('./components/forms/etpcreateForm.vue').default);
Vue.component('dividende-createform', require('./components/forms/dividendecreateForm.vue').default);
Vue.component('prixmoyen-createform', require('./components/forms/prixmoyencreateForm.vue').default);
Vue.component('remboursement_es-createform', require('./components/forms/remboursement_es_createForm.vue').default);
Vue.component('investissement_realise-createform', require('./components/forms/investissement_realise_createForm.vue').default);
Vue.component('augmentation_capital-createform', require('./components/forms/augmentation_capital_createForm.vue').default);
Vue.component('subvention_ic-createform', require('./components/forms/subvention_ic_createForm.vue').default);
Vue.component('augmentation_qfp-createform', require('./components/forms/augmentation_qfp_createForm.vue').default);

Vue.component('version', require('./components/version.vue').default);
Vue.component('pcgImportForm', require('./components/forms/pcgImportForm.vue').default);
Vue.component('pcgView', require('./components/pcgView.vue').default);
Vue.component('pcgReinitialisation', require('./components/PcgReinitialisation.vue').default);
Vue.component('pcgImportForm', require('./components/forms/pcgImportForm.vue').default);
Vue.component('customPcgView', require('./components/customPcgView.vue').default);
Vue.component('customPcgDatatable', require('./components/customPcgDatatable.vue').default);
Vue.component('balanceAnnuelleImportForm', require('./components/forms/balanceAnnuelleImportForm.vue').default);
Vue.component('balanceView', require('./components/balanceView.vue').default);
Vue.component('datatable-balance', require('./components/DatatableBalance.vue').default);

// suivi
Vue.component('balanceMensuelleImportForm', require('./components/balanceMensuelleImportForm.vue').default);
Vue.component('balanceMensuelleView', require('./components/balanceMensuelleView.vue').default);

// previsionnel
Vue.component('resultat', require('./components/previsionnel/resultat.vue').default);
Vue.component('resultat-categorie', require('./components/previsionnel/resultatCategorie.vue').default);
Vue.component('resultat-classe', require('./components/previsionnel/resultatClasse.vue').default);
Vue.component('ligne-budgetaire', require('./components/previsionnel/ligneBudgetaire.vue').default);
Vue.component('ligne-budgetaire-hc', require('./components/previsionnel/ligneBudgetaire-hc.vue').default);
Vue.component('comptes-non-affectes', require('./components/previsionnel/comptes-non-affectes.vue').default);
Vue.component('crImportForm', require('./components/previsionnel/import-cr.vue').default);
Vue.component('datatable-cr', require('./components/previsionnel/datatable-cr.vue').default);
Vue.component('planfiImportForm', require('./components/previsionnel/import-planfi.vue').default);
Vue.component('datatable-planfi', require('./components/previsionnel/datatable-planfi.vue').default);
Vue.component('ptImportForm', require('./components/previsionnel/import-pt.vue').default);
Vue.component('datatable-pt', require('./components/previsionnel/datatable-pt.vue').default);


// indicateurs visuels - charts
Vue.component('cacible', require('./components/charts/caCible.vue').default);
Vue.component('cacumule', require('./components/charts/caCumule.vue').default);
Vue.component('camensuel', require('./components/charts/caMensuel.vue').default);
Vue.component('budgetscible', require('./components/charts/budgetsCible.vue').default);
Vue.component('sig', require('./components/charts/sig.vue').default);
Vue.component('resnetcumule', require('./components/charts/resnetCumule.vue').default);


// tool bar
Vue.component('login_logout_button', require('./components/toolbarLoginLogout.vue').default);


import routes from './routes';
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({

    el: '#app',
    store,
    router: routes,
    vuetify: new Vuetify({
        theme: {
            dark: true,
            /*themes: {
                dark: {
                    primary: '#CDDC39',
                    secondary: '#696969',
                    accent: '#8c9eff',
                    error: '#b71c1c',
                },
            },
            */
        },
    }),

});