<template>
    <div>

    <!--Server ALert -->
    <v-alert v-if="serverAlert" type="error" close-text="Fermer" dismissible>
        <ul>
                    <div v-for="(value, propertyName, index) in formError" :key="index">
                        <li v-if="value && Array.isArray(value)">{{ propertyName }}: {{ value[0] }}</li> 
                        <li v-if="value && typeof value =='string'">{{ propertyName }}: {{ value }}</li>                      
                    </div>
        </ul>
    </v-alert>

    <!--Snackbar-->
    <v-snackbar v-model="snackbar" bottom color="success" multi-line :timeout="6000">
      {{snackbar_text}}
      <v-btn dark color="green darken-4" @click="snackbar = false">Fermer</v-btn>      
    </v-snackbar>         
    <!-- Modal -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline"><v-icon >mdi-alert</v-icon>Confirmer la réinitialisation ?<v-icon >mdi-alert</v-icon></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col> 
                  <p>La réinitialisation du PCG entrainera la suppression des elements suivants : </p>
                  <ul>
                      <li>L'historique des PCGs de ce client</li>
                      <li>Le PCG actuel de ce client</li>
                      <li>Le PCG modifié de ce client</li>
                      <li>Les balances de ce client</li>
                  </ul>

              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="cancel">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click.stop="confirm"
            >Confirmer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> 

          <v-btn  @click="modalDelete()"        
          
          color="red"
          title="Réinitialiser le PCG"
          ><v-icon left >mdi-alert</v-icon>
          R&Eacute;INITIALISATION DU PCG
          </v-btn
        >
    </div>
</template>

<script>
    // Le script
    import {EventBus} from "../EventBus";

    export default {
        name : "pcg-reinitialisation",
          props: {
            selectedClient : Object,
            },
        data() {
        return {
            serverAlert : false,
            snackbar : false,
            snackbar_text:null,
            dialog : false,
            formError : {},

            
        }},
        computed : {
            isLoggedIn : {get(){return this.$store.getters['currentUser/isLoggedIn']}
            }
        },
      
        methods :{
    cancel() {
      this.dialog = false;

    },  
    
    confirm() {
        this.dialog = false;
        this.PCG_Reinitialize();
        console.log(this.selectedClient)

    },
    modalDelete() {
        console.log("delete cliqued")
      this.dialog = true;
      this.serverAlert = false;
      this.snackbar = false;
      this.snackbar_text= null;

    },

    PCG_Reinitialize() {


        return new Promise((resolve, reject) => {
            axios
                .delete("/api/pcg_history/reinitialize/" + this.selectedClient.id)
                .then(response => {
                 EventBus.$emit('elementDeleted','/pcg_history/reinitialize/' + this.selectedClient.id)                
                this.snackbar_text="Elément supprimé avec succès"
                this.snackbar=true;
                this.loading = false;
                this.dialog = false
                resolve(response)
                
                })
                .catch(err => {
                console.log("item deleted from api failed");
                console.log(err.response);
                this.serverAlert = true;
                this.formError.general = err.response.data.exception;
                this.loading = false;
                this.dialog = false
                reject(err);
                });
            })
            },            

        }
    }
</script>