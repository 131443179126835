<template>
  <!-- Le template -->
  <v-container fluid class="pa-10">
    <v-row dense>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">
          Prévisionnels {{ annee }} {{ etat }} pour
          {{ selectedClient.denomination_sociale }}
        </h1>

        <v-spacer></v-spacer>

        <v-btn
          v-if="selectedClient && !Loading"
          to="/app/suivis/historique-previsionnels-cr"
        >
          <v-icon left>mdi-history</v-icon>
          Retour Historique
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="currentPrevis && !Loading"
      ><v-col>
        <v-tabs vertical>
          <v-tab>Produits</v-tab>
          <v-tab>Charges</v-tab>

          <!-- panels Produits-->
          <v-tab-item>
            <v-row dense class="scroll-horizontal"
              ><v-col>
                <resultat
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                ></resultat>

                <resultat-classe
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :classe="{ intitule: 'Produits', id: 7 }"
                ></resultat-classe>
                <v-expansion-panels accordion :value="[0, 1, 2, 3, 4]" multiple>
                  <v-expansion-panel
                    v-for="(categorie, indexcat) in categories(7)"
                    :key="indexcat"
                  >
                    <v-expansion-panel-header hide-actions>
                      <template v-slot:default="{ open }">
                        <div>
                          <v-row dense>
                            <v-col>
                              <v-icon class="icon">$expand</v-icon>
                              {{ categorie.intitule }}
                            </v-col>
                          </v-row>
                          <v-row dense
                            ><v-col>
                              <resultat-categorie
                                v-if="currentPrevis && !Loading"
                                :moisExercice="moisExercice"
                                :currentPrevis="currentPrevis"
                                :categorie="categorie"
                              ></resultat-categorie>
                            </v-col>
                          </v-row>
                        </div>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content v-if="currentPrevis && !Loading">
                      <ligne-budgetaire
                        v-for="(lb, indexlbcat) in lbincategorie(categorie.id)"
                        :readonly="true"
                        :pcg="pcg7"
                        :key="indexlbcat"
                        :bgcolor="'blue-grey darken-4'"
                        :moisExercice="moisExercice"
                        :lb="lb"
                        :first="indexlbcat == 0 ? true : false"
                        :last="
                          indexlbcat ==
                          Object.keys(lbincategorie(categorie.id)).length - 1
                            ? true
                            : false
                        "
                      ></ligne-budgetaire>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <ligne-budgetaire-hc
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :readonly="true"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :lbhc="lbhc((classe = 7))"
                  :pcgs="pcg7"
                ></ligne-budgetaire-hc>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- panels Charges-->
          <v-tab-item>
            <v-row dense class="scroll-horizontal"
              ><v-col>
                <resultat-classe
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :classe="{ intitule: 'Charges', id: 6 }"
                ></resultat-classe>
                <v-expansion-panels
                  accordion
                  :value="[0, 1, 2, 3, 4, 5, 6, 7, 8]"
                  multiple
                >
                  <v-expansion-panel
                    v-for="(categorie, indexcat) in categories(6)"
                    :key="indexcat"
                  >
                    <v-expansion-panel-header hide-actions
                      ><!--hide-actions-->

                      <template v-slot:default="{ open }">
                        <div>
                          <v-row dense>
                            <v-col>
                              <v-icon class="icon">$expand</v-icon>
                              {{ categorie.intitule }}
                            </v-col>
                          </v-row>
                          <v-row dense
                            ><v-col
                              ><resultat-categorie
                                v-if="currentPrevis && !Loading"
                                :moisExercice="moisExercice"
                                :currentPrevis="currentPrevis"
                                :categorie="categorie"
                              ></resultat-categorie></v-col
                          ></v-row>
                        </div>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ligne-budgetaire
                        v-for="(lb, indexlbcat) in lbincategorie(categorie.id)"
                        :readonly="true"
                        :pcg="pcg6"
                        :key="indexlbcat"
                        :bgcolor="'blue-grey darken-4'"
                        :moisExercice="moisExercice"
                        :lb="lb"
                        :first="indexlbcat == 0 ? true : false"
                        :last="
                          indexlbcat ==
                          Object.keys(lbincategorie(categorie.id)).length - 1
                            ? true
                            : false
                        "
                      ></ligne-budgetaire>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <ligne-budgetaire-hc
                  style="padding: 16px 24px"
                  v-if="currentPrevis && !Loading"
                  :readonly="true"
                  :pcgs="pcg6"
                  :moisExercice="moisExercice"
                  :currentPrevis="currentPrevis"
                  :lbhc="lbhc((classe = 6))"
                ></ligne-budgetaire-hc>
              </v-col>
            </v-row>
          </v-tab-item> </v-tabs
      ></v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.scroll-horizontal {
  overflow-x: auto;
  /* white-space: nowrap; Pour que les éléments restent sur une seule ligne */
}

.v-expansion-panel-header--active .icon {
  transform: rotate(-180deg);
}
</style>
<script>
import { EventBus } from "../../../EventBus";

// Le script
export default {
  props: ["selectedClient", "annee", "etat"],
  data() {
    return {
      Loading: true,
      showOnScroll: false,
      ClientsLoading: true,
      tab: null,
      moisExercice: null,
      currentPrevis: null,
      error: null,
      pcgs: [],
      pcgs_original: [],

      snackbar_text: "",
      snackbar: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },

    produits() {
      let produitfilter = _.filter(
        this.currentPrevis,
        (item) => item.class == 7 && !_.isNil(item.budgetline_categorie_id)
      );

      return _.orderBy(produitfilter, "budgetline_categorie_id");
    },

    charges() {
      return _.filter(
        this.currentPrevis,
        (item) => item.class == 6 && !_.isNil(item.budgetline_categorie_id)
      );
    },
    pcg6() {
      return _.filter(this.pcgs, (item) => item.subcompte1 == 6);
    },
    pcg7() {
      return _.filter(this.pcgs, (item) => item.subcompte1 == 7);
    },
  },
  watch: {
    selectedClient(val) {
      this.error = null;

      console.log("chargement du pcg du client");
      this.getPcgs();

      console.log("chargement du chois d'exercice");
      this.getExcercices();
    },
  },
  methods: {
    categories(classe) {
      if (classe == 7) {
        return [
          { intitule: "Chiffre d'afffaires", id: 1, order: 1 },
          { intitule: "Subvention d'exploitation", id: 2, order: 2 },
          { intitule: "Autres produits d'exploitation", id: 3, order: 3 },
          { intitule: "Autres produits financiers", id: 4, order: 4 },
          { intitule: "Autres produits exceptionnels", id: 5, order: 5 },
        ];
      }
      if (classe == 6) {
        return [
          { intitule: "Achats", id: 6, order: 1 },
          { intitule: "Frais généraux", id: 7, order: 2 },
          {
            intitule: "Impôts, taxes et versements assimilés",
            id: 8,
            order: 3,
          },
          { intitule: "Masse salariale", id: 9, order: 4 },
          {
            intitule: "Dotations aux amortissements et provisions",
            id: 10,
            order: 5,
          },
          { intitule: "Charges financières", id: 11, order: 6 },
          { intitule: "Autres charges d'exploitation", id: 12, order: 7 },
          { intitule: "Autres charges financières", id: 13, order: 8 },
          { intitule: "Autres charges exceptionnelles", id: 14, order: 9 },
        ];
      }
    },

    // ligne budgetaire hors categorie en fonction de la classe
    lbhc(classe) {
      return _.find(this.currentPrevis, {
        budgetline_categorie_id: null,
        class: classe,
      });
    },

    // Fonction pour trouver l'index de l'objet par son "order" et "categorie"
    findIndexByOrderAndCategory(order, categorie) {
      const filteredItems = _.filter(this.currentPrevis, {
        budgetline_categorie_id: categorie,
      });
      return filteredItems.findIndex(
        (item) =>
          item.order == order && item.budgetline_categorie_id == categorie
      );
    },

    // liste des previs pour une categorie
    lbincategorie(budgetline_categorie_id) {
      return _.filter(
        this.currentPrevis,
        (item) => item.budgetline_categorie_id == budgetline_categorie_id
      );
    },

    /*
     * PCG du client selectionné
     */

    getPcgs() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/getpcgs/" + this.selectedClient.id)
          .then((response) => {
            console.log("/api/getpcgs/ OK");
            this.pcgs = response.data;
            this.pcgs_original = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getpcgs/ KO", err);
            console.log(err);
            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
     * Liste des mois de l'excercice
     */

    getPeriodeExcercices() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/exerciceperiode/" + this.selectedClient.id + "/" + this.annee
          )
          .then((response) => {
            console.log("/api/exerciceperiode/ OK");
            this.moisExercice = response.data;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/exerciceperiode/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    /*
     * Chargement du previs
     */

    getCurrentPrevis() {
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            "/api/getprevis/" +
              this.selectedClient.id +
              "/" +
              this.annee +
              "/" +
              this.etat
          )
          .then((response) => {
            console.log("/api/getprevis/ OK");
            this.currentPrevis = response.data;
            this.Loading = false;

            this.recomputePcg();

            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getprevis/ KO", err);
            if (err.response.status === 422) {
              this.error = err.response.data.message;
            }

            this.Loading = true;
            reject(err);
          });
      });
    },

    recomputePcg() {
      // on part d'un nouveau pcg basé suyr le pcg original
      this.pcgs = this.pcgs_original;

      // liste des comptes présent dans une categorie
      // qui sont à exclure du pcg
      const prefixesToExclude = [];

      const filteredPrevis = this.currentPrevis.filter(
        (item) => item.budgetline_categorie_id !== null
      );

      filteredPrevis.forEach((item) => {
        item.comptes.forEach((compte) => {
          const prefix = compte.compte.match(/^\d+[^0]/)[0];
          if (!prefixesToExclude.includes(prefix)) {
            prefixesToExclude.push(prefix);
          }
        });
      });

      // Filtrer les comptes qui ne partagent pas les mêmes préfixes que prefixesToExclude
      this.pcgs = this.pcgs.filter((pcg) => {
        const comptePrefix = pcg.compte.match(/^\d+[^0]/)[0];
        return !prefixesToExclude.some(
          (prefix) => comptePrefix === prefix || comptePrefix.startsWith(prefix)
        );
      });

      _.orderBy(this.pcgs, "compte");

      // on met à jour dans current previs
      // pour les produits  hors categorie
      let pcg7 = (_.find(this.currentPrevis, {
        class: 7,
        budgetline_categorie_id: null,
      }).comptes = this.pcg7);

      // on met à jour dans current previs
      // pour les charges hors categorie
      let pcg6 = (_.find(this.currentPrevis, {
        class: 6,
        budgetline_categorie_id: null,
      }).comptes = this.pcg6);
    },
  },

  mounted() {
    //this.$route.params.clientId
    console.log("this.$route.params");
    console.log(this.$route.params);

    console.log(this.selectedClient.id);

    this.getPcgs()
      .then(() => {
        this.getPeriodeExcercices();
      })
      .then(() => {
        this.getCurrentPrevis();
      });
  },
};
</script>