<template>
  <!-- Le template -->
  <v-container>
    <v-row>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">
          PCG Client :
        </h1>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <v-spacer></v-spacer>
      </v-col>
      
    </v-row>
    <v-row v-if="selectedClient">
      <v-col>
        <v-tabs v-model="tab" class="pb-8" show-arrows>
          <v-tab>PCG Client</v-tab>
          <v-tab>PCG Modifié</v-tab>
          <v-tab>Historique</v-tab>
          <v-tab>Importer</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pa-8">
          <!--PCG Client-->
          <v-tab-item>  
            <pcgView
            :selectedClient="selectedClient">
            </pcgView>          
          </v-tab-item>
          <!--PCG Modifié-->
          <v-tab-item>   
            <customPcgView
            :selectedClient="selectedClient">
            </customPcgView>                    
          </v-tab-item>
          <!--Historique-->
          <v-tab-item>
            <datatable 
            excel_file_name="historique_pcg.xls"
            :selectedClient="selectedClient"
            :possibleHeaders="pcgHistory_possibleHeaders" 
            :api_endpoint_url="pcgHistory_apiEndPointUrl"
            :default_column_names="pcgHistory_default_column_names"
            :default_column_order="pcgHistory_default_column_order"
            :create_route_name="pcgHistory_create_route_name"
            :show_route_name="pcgHistory_show_route_name"
            :update_route_name="pcgHistory_update_route_name"
            :api_delete_endpoint_url="pcgHistory_api_delete_endpoint_url"></datatable>   
            <pcg-reinitialisation
            :selectedClient="selectedClient"
            ></pcg-reinitialisation>           
          </v-tab-item>
          <!--Importer-->
          <v-tab-item>
            <pcgImportForm
            :clientID="selectedClient.id"></pcgImportForm>
          </v-tab-item>   
           



        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row> <v-col> </v-col></v-row>
  </v-container>
</template>

<script>
import PcgReinitialisation from '../../../components/PcgReinitialisation.vue';
import {EventBus} from "../../../EventBus";

// Le script
export default {
  components: { PcgReinitialisation },

  data() {
    return {
      Loading : true,
      ClientsLoading : true,
      selectedClient: null,
      clients: [],
      tab: null,
pcgHistory_possibleHeaders: [
                {
                text: "Ref",
                value: "id",
                align: "left",
                name: "id",
                filterable: true,
                sortable: true,
                order: 1
                },  
                {
                text: "Créé par",
                value: "created_by_user_id.fullname",
                name: "created_by_user_id.fullname",
                filterable: true,
                sortable: false,
                order: 5
                },  
                {
                text: "Créé le",
                value: "created_at",
                name: "created_at",
                filterable: true,
                sortable: true,
                order: 6
                },           
                {
                text: "Modifié par",
                value: "updated_by_user_id.fullname",
                name: "updated_by_user_id.fullname",
                filterable: true,
                sortable: false,
                order: 7
                },  
                {
                text: "Modifié le",
                value: "updated_at",
                name: "updated_at",
                filterable: true,
                sortable: true,
                order: 1.1
                }, 
                                        
                {
                text: "actions",
                value: "actions",
                name: "actions",
                filterable: false,
                sortable: false,
                order: 9
                }                                                                       
            ],
            pcgHistory_default_column_names: [
                "updated_at",
                "updated_by_user_id.fullname",
                "actions",
                ],
            pcgHistory_default_column_order : "updated_at" ,  
            pcgHistory_show_route_name : "/api/pcg_history",
            pcgHistory_create_route_name : null,
            pcgHistory_update_route_name : null,
            pcgHistory_api_delete_endpoint_url : "/api/pcg_history/delete/",

};    
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    pcgHistory_apiEndPointUrl(){
        return "/api/load_pcg_history?client_id="+this.selectedClient.id
    },       
                               
  },
  watch: {
    selectedClient(val){
          localStorage.selectedClient = JSON.stringify(val)   
          EventBus.$emit('ClientChanged',val)       
    }
  },
  methods: {

    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

  },

  mounted() {

     // recuperation sauvegarde du client selectionné
     if(localStorage.selectedClient){
        this.selectedClient = JSON.parse(localStorage.selectedClient);
     }
      this.getClients();
  },
};
</script>