<template>
    <!-- Le template -->
    <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card class="elevation-5">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title v-if="!isLoading"> Ajouter un Investissement Réalisé </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-progress-linear :active="isLoading" color="red" :indeterminate="true" />

          <v-card-text class="pt-8 pb-4">
                <v-alert v-if="serverAlert" type="error" 
                close-text="Fermer"
                dismissible>
                <ul>
                    <div v-for="(value, propertyName, index) in formError" :key="index">
                        <li v-if="value && Array.isArray(value)">{{ propertyName }}: {{ value[0] }}</li> 
                        <li v-if="value && typeof value =='string'">{{ propertyName }}: {{ value }}</li>                      
                    </div>
                </ul>
                </v-alert>

        <v-snackbar
      v-model="snackbar"
      bottom
      color="success"      
      multi-line
      :timeout=6000 
   >
      <p v-html="snackbar_text"></p>
        
      <v-btn dark color="green darken-4" @click="snackbar_text =''; snackbar= false">Fermer</v-btn>

    </v-snackbar>

            <v-form ref="form" v-model="valid" v-if="!isLoading">
              <v-row>
                <v-col>
                  <!--Annee-->
                  <v-select
                  dense
                    v-model="annee"                    
                    :items="annees"
                    item-text="cloture"
                    item-value="cloture"
                    label="Année"                                       
                    single-line
                    :rules="[
                      rules.required,
                      rules.numeric,
                      rules.fourdigits,
                      rules.positif,
                    ]"
                    :error-messages="formError.annee"
                    @focus="formError.annee = null"                    
                  ></v-select>
                </v-col>
                <v-col>
                  <!--INVESTISSEMENT-->  
                  <v-text-field  dense required                 
                    label="Montant"
                    name="montant"  
                    v-model="montant"                                         
                    :rules="[rules.required,rules.numeric,rules.positif]"
                    :error-messages="formError.montant"
                    @focus="formError.montant=null"                    
                  />
                </v-col>             
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click.prevent="submit" :disabled="!valid">
              <v-icon left>mdi-check</v-icon>
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    </div>
</template>

<script>
    // Le script
    import {EventBus} from "../../EventBus";

    export default {
        name: "investissement_realise-createform",
        props: {
          selectedClient : Object,
        },       
        data() {return {
      loading: true,
      isSaving: false,
            isSaving:false,

            serverAlert: null,            
            snackbar_text : '', 
            snackbar : false,   

                  annees: [],

            annee : null,
            montant: null, 

            client:{},

            valid: false,

            formError: {
                annee: null,
                montant: null,            
            },

            rules: {

                required: v => !!v || "Ce champ est requis",

                positif: v => v>=0 || "Valeur positive attendue",

                numeric: v => !isNaN(v) || "Valeur numérique attendue",

                fourdigits: v => /^\d{4}$/.test(v) || "4 chiffres attendus",

                

            },            

          

        }},
        computed : {
            isLoading: function(){
                if(this.isSaving ){ return true}
                return false
            },
            isLoggedIn (){                
                return this.$store.getters["currentUser/isLoggedIn"]
            },            
        },
        watch:{         


    selectedClient(){
      console.log('clientchanged '+this.selectedClient.id)
      this.getannees();
    }
        },
        methods : {
    getannees() {
      this.loading = true;
      this.serverAlert = false;

      axios
        .get(
          "/api/load_balanceannuelle_history_select?client_id=" +
            this.selectedClient.id
        )
        .then((response) => {

          console.log(response.data);

          if (response.data.length == 0) {
            this.formError.balance =
              "Vous devez importer une balance annuelle avant d'ajouter un élément";
            this.serverAlert = true;
          }

          this.annees = _.uniq(_.map(response.data,function(v){return v.cloture.substring(0,4)}));

          this.Loading = false;          
          
        })
        .catch((err) => {
          this.Loading = false;
          
        });
    },           
            
            submit(){

                this.isSaving = true;
                this.serverAlert= null;

                let payload = {annee:this.annee,montant:this.montant};

                console.log(payload);

                axios.get('/sanctum/csrf-cookie').then(response =>{
                axios
                    .post("/api/investissement_realise/create/"+this.selectedClient.id, payload)
                    .then(response => {

                        console.log('submit OK',response);
                        
                        this.isSaving = false;
                        this.snackbar= true 
                        this.snackbar_text="Element ajouté avec succès"
                       
                        this.annee=null; 
                        this.montant=null;    
                        
                        EventBus.$emit('investissementrealiseAdded',this.selectedClient.id)
                        
                    })
                    .catch(err => {
                    this.serverAlert= true;    
                    this.isSaving = false;
                    console.log("submit_ko", err);

                    if (err.response.status == 422) {
                        Object.assign(this.formError, err.response.data.errors);
                    }

                    this.formError.general = err.response.data.message;

                    });


                })
                return ;

            },            


        },
        mounted(){
    this.getannees();
        }
    }
</script>