import router from '../../routes';

const state = {

    user:null,

};

const getters = {

    isLoggedIn(state) {        
        if (state.user){return true}
        return false
    },
    LoggedUser(state){        
        return state.user
    },
};


const actions = {

    loginUser({commit,state},credentials){        
        
        return new Promise((resolve, reject) => {
        axios.get('/sanctum/csrf-cookie').then(response =>{            
            
            axios.post("/login", credentials)
            .then((response) => {

                resolve(response)                   
                axios.get("/api/user").then((response) => {
                        commit('setUser',response.data)  
                        router.push({name:'dashboard'})                                 
                }) 

                }).catch((err) => {
                    reject(err);
                })
            })
        })
        
    },
    
    logoutUser({commit, dispatch},state){
        return new Promise((resolve, reject) => { 
            dispatch('currentDrawer/closeDrawer',{},{root:true})
            axios.get('/sanctum/csrf-cookie').then(response =>{
            axios.post('/logout')
            .then((response) => {
                console.log("logout axios.post('/logout') OK")
                commit('unsetUser')
                router.push({name:'login'})      
                resolve(response) 
            })
            .catch((err) => {
                commit('unsetUser') 
                reject(err)
            })
        })           
      
    })
    },


    initializeAuth({commit, dispatch},state){

        console.log("try to reconnect user from local storage")
        let user = localStorage.getItem("auth");
        
        //return;
        if(user){
            console.log("local storage present")
            console.log("verification de la session...")
        return Promise.all([
            axios.get("/api/user")
            .then((response) => { 
                console.log("session ok")
                commit('setUser',response.data)
            })
            .catch((err) =>{
                console.log("session notok")
                commit('unsetUser')
            })
        ])
        }
        else {
            console.log("local storage non present")
            commit('unsetUser')

        }
    }
    

};

const mutations = {
    setUser(state,user){
        console.log('mutations setUser')
        state.user = user
        localStorage.setItem('auth',JSON.stringify(user))
    },
    unsetUser(state){
        console.log('mutations unsetUser')
        localStorage.removeItem('auth')
        state.user = null;
        
    },
};

export default {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
    
}