<template>
  <div class="pa-3">
    <v-row>
      <v-col class="black--text text-center text-h5"
        >Situation à {{ selectedMonth }}
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <v-col class="black--text text-center text-h5" cols="4">
        Chiffre d'affaires <br />annuel cible<br />
        <b
          >{{ formatNumber(keuro(caCible)) }}<span v-if="kilo_euro">K</span>€</b
        >
      </v-col>
      <v-col cols="8">
        <Doughnut
          v-if="loaded"
          :data="chartData"
          :options="chartOptions"
          :plugins="[doughnutPointer]"
        ></Doughnut>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col class="black--text text-center" cols="8">
        {{ this.realisepourcent }} % ({{ formatNumber(keuro(this.realise)) }}
        <span v-if="kilo_euro">K</span>€) réalisé à {{ selectedMonth }}</v-col
      >
    </v-row>
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "DoughnutChart",
  components: { Doughnut },
  props: {
    selectedClient: { type: Object },
    exercice: { type: Number },
    balances: { type: Array },
    currentPrevis: { type: Array },
    kilo_euro: { type: Boolean },
    situationMonth: { type: String },
    selectedMonth: { type: String },
    moisExercice: { type: Array },
  },
  data() {
    return {
      loaded: false,
      DateCloturePlusProche: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  watch: {
    selectedMonth() {
      this.DateCloturePlusProche = this.getDateCloturePlusProche();
    },
  },
  computed: {
    realise() {
      return this.SommeComptesBalance([
        -701, -702, -703, -704, -705, -706, -707, -708, -7092, -7093, -7094,
        -7095, -7096, -7097, -7098,
      ]);
    },

    realisepourcent() {
      return Math.round(
        parseFloat((100 * parseFloat(this.realise)) / parseFloat(this.caCible))
      );
    },

    resteafaire() {
      return parseFloat(parseFloat(this.caCible) - parseFloat(this.realise));
    },

    restafairepourcent() {
      return Math.round(parseFloat(100 - parseFloat(this.realisepourcent)));
    },

    caCible() {
      const lignesCA = _.filter(this.currentPrevis, {
        budgetline_categorie_id: 1,
      });
      let sommeTotalCA = _.sumBy(lignesCA, (item) => parseFloat(item.total));
      return parseFloat(sommeTotalCA);
    },

    chartData() {
      const realise =
        (100 *
          this.SommeComptesBalance([
            -701, -702, -703, -704, -705, -706, -707, -708, -7092, -7093, -7094,
            -7095, -7096, -7097, -7098,
          ])) /
        this.caCible;

      let resteafaire = 100 - realise;
      if (resteafaire < 0) {
        resteafaire = 0;
      }

      return {
        labels: ["Réalisé", "Reste à faire"],
        datasets: [
          {
            backgroundColor: ["#ffbde9", "#0070c0"],
            data: [realise, resteafaire],
            cutout: "65%",
          },
        ],
      };
    },

    doughnutPointer() {
      const doughnutPointer = {
        id: "doughnutPointer",
        afterDatasetsDraw: (chart, args, plugins) => {
          const { ctx, data } = chart;

          ctx.save();

          const xCenter = chart.getDatasetMeta(0).data[0].x;
          const yCenter = chart.getDatasetMeta(0).data[0].y;

          const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
          const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
          const DoughnutThickness = outerRadius - innerRadius;

          const pointerColor = "#003c96";
          const pointerValue = this.selectedMonthCaCiblePourcent();
          const pointerRadius = 5;
          const angle = Math.PI / 180;

          // total value
          function summArray(arr) {
            return arr.reduce((acc, current) => acc + current, 0);
          }
          const dataPointArray = data.datasets[0].data.map((datapoint) => {
            return datapoint;
          });

          const totalSum = summArray(dataPointArray);
          const targetPointerRotation = (pointerValue / totalSum) * 360;
          const datapointPercentage =
            (data.datasets[0].data[0] / totalSum) * 100;

          // text
          ctx.font = "bold 20px sans-serif";
          ctx.fillStyle = "black";
          ctx.textAlign = "center";
          ctx.baseLine = "middle";
          ctx.fillText("Réalisation", xCenter, yCenter - 10);
          ctx.fillText(
            `${datapointPercentage.toFixed(1)}%`,
            xCenter,
            yCenter + 15
          );

          //pointer
          ctx.translate(xCenter, yCenter);
          ctx.rotate(angle * targetPointerRotation);

          ctx.beginPath();
          ctx.fillStyle = pointerColor;
          // roundRect( x, y, width,height,radius)
          ctx.roundRect(
            0 - 2,
            -outerRadius - 5,
            4,
            DoughnutThickness + 10,
            pointerRadius
          );
          ctx.fill();

          ctx.restore();
        },
      };
      return doughnutPointer;
    },
  },

  methods: {
    formatNumber(nombre) {
      return nombre.toLocaleString("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }); //
    },

    keuro(nombre) {
      if (this.kilo_euro) {
        return nombre / 1000;
      }
      return nombre;
    },

    getDateCloturePlusProche() {
      // recherche de la date de cloture de balance la plus proche de selectedMonth
      // sans la depasser

      let selectedCloture = this.$moment(this.selectedMonth, "MMMYY").endOf(
        "month"
      );

      // clone balances and remove its reactivity
      let sortedBalances = JSON.parse(JSON.stringify(this.balances));

      // Trier la collection en fonction de la proximité des dates avec 'selectedCloture'
      sortedBalances.sort((a, b) => {
        // Convertir les dates 'cloture' de chaque élément en objets Moment
        let dateA = this.$moment(a.cloture);
        let dateB = this.$moment(b.cloture);

        // Calculer les différences entre les dates et les comparer
        return (
          Math.abs(dateA.diff(selectedCloture)) -
          Math.abs(dateB.diff(selectedCloture))
        );
      });

      // La date la plus proche sans la dépasser est maintenant dans le premier élément de la collection triée
      let dateCloturePlusProche =
        sortedBalances.length > 0
          ? this.$moment(sortedBalances[0].cloture)
          : null;

      return dateCloturePlusProche;
    },

    /*
     * calcul des sommes de compte dans la balance mensuelle la plus proche
     * de selectedMonth
     */
    SommeComptesBalance(comptes) {
      let sommeSolde = 0;

      // Filtrer la collection pour ne garder que les objets avec la date de clôture la plus proche de selectedMonth
      const filteredBalances = _.filter(this.balances, (item) =>
        this.$moment(item.cloture).isSame(this.DateCloturePlusProche)
      );

      filteredBalances.forEach((objet) => {
        const solde = parseFloat(objet.solde);
        if (!isNaN(solde)) {
          comptes.forEach((numero) => {
            // Vérifier si la propriété "compte" commence par le numéro de compte
            if (objet.compte.startsWith(Math.abs(numero).toString())) {
              // Ajouter ou soustraire le solde en fonction du signe du numéro de compte
              sommeSolde += numero < 0 ? -solde : solde;
            }
          });
        }
      });

      return sommeSolde;
    },

    selectedMonthCaCiblePourcent() {
      // index du mois selectionné
      const selectedMonthIndex = _.indexOf(
        this.moisExercice,
        this.selectedMonth
      );

      // filtre les previs sur la categorie ID = 1 (CA)
      const lignesCA = _.filter(this.currentPrevis, {
        budgetline_categorie_id: 1,
      });

      // valeur cumulé du CA pour le mois selectionné

      const selectedMonthcaCibleValue = _.sumBy(lignesCA, (item) => {
        let total = 0;
        for (let i = 1; i <= selectedMonthIndex + 1; i++) {
          total += parseFloat(item["mois_" + i]);
        }
        return total;
      });

      // pourcentage par rapport à CAcible sur l'année
      const selectedMonthcaCiblePourcent =
        (100 * selectedMonthcaCibleValue) / this.caCible;

      return selectedMonthcaCiblePourcent;
    },
  },

  mounted() {
    this.DateCloturePlusProche = this.getDateCloturePlusProche();
    this.loaded = true;
  },
};
</script>