<template>
  <div>
    <v-row>
      <v-col
        >Prévisionnel pour tous les autres comptes non affectés à une ligne
        budgétaire</v-col
      >
    </v-row>

    <v-row dense class="grey darken-1 no-gutters mb-1 py-2">
      <v-col>
        <v-row dense class="flex-nowrap no-gutters px-1 py-0">
          <v-col class="custom-min-width-resultat px-1 pt-1">€</v-col>
          <v-col
            class="px-0 pt-1"
            v-for="(month, index) in moisExercice"
            :key="index"
          >
            <v-text-field
              class="custom-padding"
              v-model="lbhc['mois_' + parseInt(index + 1)]"
              :label="month"
              outlined
              dense
              hide-details
              dark
              :readonly="readonly"
            ></v-text-field>
          </v-col>

          <v-col class="px-1 pt-1 py-0" style="border-left: 1px solid gray">
            <v-text-field
              class="custom-padding"
              :value="TotalValue()"
              label="TOTAL"
              outlined
              readonly
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- liste des comptes non affectés-->
        <v-row dense no-gutters class="pt-2">
          <v-col class="offset-1">
            <v-expansion-panels :value="0" style="margin-left: -12px">
              <v-expansion-panel>
                <v-expansion-panel-header dense
                  >liste des comptes non affectés</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <comptes-non-affectes
                    :classe="lbhc.class"
                    :currentPrevis="currentPrevis"
                    :pcgs="pcgs"
                  ></comptes-non-affectes>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.custom-padding >>> .v-input__slot {
  padding-left: 5px !important; /* Personnalisez le padding selon vos besoins */
  padding-right: 5px !important;
  min-width: 45px !important;
}

.custom-padding >>> input {
  font-size: 0.75em !important;
}

.custom-padding-text >>> label {
  margin-bottom: 0px !important;
  top: 9px !important;
}
</style>
  
  <script>
export default {
  name: "ligne-budgetaire-hc",
  props: {
    moisExercice: { type: Array },
    currentPrevis: { type: Array },
    lbhc: { type: Object },
    pcgs: { type: Array },
    readonly: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      total: null,
    };
  },
  computed: {},

  methods: {
    TotalValue() {
      // Inclure uniquement les propriétés commençant par 'mois_' pour la somme
      const proprietesCommencantParPrefixe = [];

      for (const key in this.lbhc) {
        if (key.startsWith("mois_")) {
          proprietesCommencantParPrefixe.push(key);
        }
      }
      var sommeValeurs = _.sum(
        Object.values(_.pick(this.lbhc, proprietesCommencantParPrefixe)).map(
          (value) => parseInt(value, 10)
        )
      );

      this.lbhc.total = sommeValeurs;

      // pour l'affichage on formate avec separateur milliers
      return this.lbhc.total.toLocaleString("fr-FR");
    },
  },
  watch: {
    lbhc: {
      handler(newValues) {
        // event
        this.$emit("lbHCUpdate", this.lbhc);
      },
      deep: true,
    },
  },
  mounted() {},
};
</script>
  