<template>
  <!-- Le template -->
  <v-container>
    <v-row>
      <v-col cols="11" class="d-flex justify-start align-center">
        <h1 class="mr-3 my-0" style="line-height: 1.5em">
          Compte de résultat :
        </h1>
        <v-autocomplete
          label="Rechercher Sélectionner un client"
          :items="clients"
          clearable
          deletable-chips
          item-value="id"
          return-object
          v-model="selectedClient"
        ></v-autocomplete>
        <v-spacer></v-spacer>
      </v-col>
      
    </v-row>
    <v-row v-if="selectedClient">
      <v-col>
        <v-tabs v-model="tab" class="pb-8" show-arrows>

          <v-tab v-for="(cr,index) in crprevis" :key="index">{{ cr.annee }}</v-tab>

          <v-tab v-if="crprevis.length!=0">Historique</v-tab> 
          <v-tab v-if="crprevis.length!=0">Vue globale</v-tab>
          <!-- -->
          <v-tab>Importer</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pa-8">

          <!--onlgets des années-->
          <v-tab-item v-for="(cr,index) in crprevis" :key="index">
            <datatable-cr
            :selectedClient="selectedClient"
            :annee="cr.annee"
            :api_endpoint_url="crprevis_apiEndPointUrl"
            
            
            ></datatable-cr>           
          </v-tab-item>   

           <!--Historique -->
          
          <v-tab-item v-if="crprevis.length!=0"> 
            <datatable @elementDeleted="reresh"
            :selectedClient="selectedClient"
            :possibleHeaders="crprevis_possibleHeaders" 
            :api_endpoint_url="crprevis_history_apiEndPointUrl"
            :default_column_names="crprevis_default_column_names"
            :default_column_order="crprevis_default_column_order"
            :create_route_name="null"
            :show_route_name="crprevis_show_route_name"
            :update_route_name="null"
            :api_delete_endpoint_url="crprevis_api_delete_endpoint_url"></datatable>             
          </v-tab-item> 

          <!--Vue globale -->
          <v-tab-item v-if="crprevis.length!=0">
            <datatable-cr
            :api_endpoint_url="crprevis_apiEndPointUrl"
            :selectedClient="selectedClient"
            :annee=null                       
            ></datatable-cr> 
          </v-tab-item>
          
          <!--Importation CR-->
          
          <v-tab-item>  
                    
            <crImportForm :clientID="selectedClient.id" @crprevisuploaded="reresh"></crImportForm>
          </v-tab-item>


        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row> <v-col> </v-col></v-row>
  </v-container>
</template>

<script>
import { EventBus } from "../../../EventBus";
// Le script
export default {
  data() {
    return {
      Loading: true,
      ClientsLoading: true,
      selectedClient: null,
      clients: [],
      tab: null,
      crprevis : [],


      crprevis_possibleHeaders: [ 
                {
                text: "Année",
                value: "annee",
                align: "left",
                name: "annee",
                filterable: true,
                sortable: true,
                order: 1.1
                }, 
                {
                text: "Uploadé le",
                value: "updated_at",
                name: "updated_at",
                filterable: true,
                sortable: true,
                order: 1.1
                },                                 
                {
                text: "Créé par",
                value: "created_by_user_id.fullname",
                name: "created_by_user_id.fullname",
                filterable: true,
                sortable: false,
                order: 2
                },  
                {
                text: "Créé le",
                value: "created_at",
                name: "created_at",
                filterable: true,
                sortable: true,
                order: 6
                },           
                {
                text: "Modifié par",
                value: "updated_by_user_id.fullname",
                name: "updated_by_user_id.fullname",
                filterable: true,
                sortable: false,
                order: 7
                }, 
                                       
                {
                text: "actions",
                value: "actions",
                name: "actions",
                filterable: false,
                sortable: false,
                order: 9
                }
                ],
        crprevis_default_column_names:[
                "annee",
                "updated_at",
                "updated_by_user_id.fullname",
                "actions",
                ],
        crprevis_default_column_order: "updated_at" ,  
        crprevis_api_delete_endpoint_url : "/api/cr_history/delete/",    
        crprevis_show_route_name: "/api/crprevis/download",                    


      }

  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["currentUser/isLoggedIn"];
    },
    crprevis_history_apiEndPointUrl(){
        return "/api/load_crprevis_history?client_id="+this.selectedClient.id
    }, 
    crprevis_apiEndPointUrl(){
        return "/api/loadcrprevis?client_id="+this.selectedClient.id
    },      
  },
  watch: {
    selectedClient(val) {
      localStorage.selectedClient = JSON.stringify(val);
      EventBus.$emit("ClientChanged", val);
      this.Loading = true;

      //get last balance
      this.getLastcrprevis();

    },
  },
  methods: {

    reresh(){
      
      this.Loading = true;
      this.getLastcrprevis();


    },
    /*
     * Liste des clients
     */

    getClients() {
      this.ClientsLoading = true;
      this.Loading = true;

      return new Promise((resolve, reject) => {
        axios
          .get("/api/userclientlist?with_pcg")
          .then((response) => {
            console.log("/api/userclientlist/ OK");
            this.clients = response.data.data;
            this.ClientsLoading = false;
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/userclientlist/ KO", err);
            console.log(err);
            this.ClientsLoading = false;
            this.Loading = false;
            reject(err);
          });
      });
    },

    /*
    * Liste des dernieres annees compte dee resultats prévisionnels
    */

    getLastcrprevis(){

      this.Loading = true;
      
      //
      return new Promise((resolve, reject) => {
        axios
          .get("/api/getcrprevis?client_id="+this.selectedClient.id)
          .then((response) => {
            console.log("/api/getcrprevis/ OK");
            this.crprevis = response.data;           
            this.Loading = false;
            resolve(response);
          })
          .catch((err) => {
            console.log("/api/getcrprevis/ KO", err);
            console.log(err);
            this.Loading = true;
            reject(err);
          });
      });

    }
  },

  mounted() {

    this.getClients().then((response)=>{

      // recuperation du client selectionné sauvegardé en local storage
      if (localStorage.selectedClient) {
        this.selectedClient = JSON.parse(localStorage.selectedClient);
      }

      // si pas présent dans le listing client (clients filtrés avec pcg)
      // on affecte selectedClient à null

      if (!_.find(this.clients,{'id':this.selectedClient.id})) {
        this.selectedClient = null;        
      }


    });

    EventBus.$on("cruploaded", (data) => {
      this.getLastcrprevis();
    });

    

  },
};
</script>